import {
  ApplicationConfigurationStore,
  IApplicationScreenModel,
  ICommonAppState,
} from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ApplicationConfigurationScreensList as ApplicationConfigurationScreensListDefinition } from "./ApplicationConfigurationScreensList";

const mapStateToProps = (state: ICommonAppState) => {
  return {
    isLoadingData: state.applicationConfiguration.isLoadingData,
    platforms: state.common.platforms,
    actionType: state.applicationConfiguration.actionType,
    error: state.applicationConfiguration.error,
    screensTypes: state.applicationConfiguration.screensTypes,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteScreen: (screen: IApplicationScreenModel) => {
    return dispatch(ApplicationConfigurationStore.Actions.deleteScreen(screen));
  },
  getConfiguration: (id: number) => {
    return dispatch(ApplicationConfigurationStore.Actions.getConfiguration(id));
  },
});

export const ApplicationConfigurationScreensList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApplicationConfigurationScreensListDefinition));
