import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { IModelBase } from "../../models";
import { AxiosSubscriber } from "../AxiosSubscriber";

export abstract class ServiceBase<TEntity extends IModelBase, TKey> {
  abstract get url(): string;

  public get = (id: TKey): Observable<TEntity> =>
    new Observable(
      (observer: Observer<TEntity>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              id,
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/Get`,
        })
    );

  public delete = (data: TEntity): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Delete`,
        })
    );

  public insert = (data: TEntity): Observable<TEntity> =>
    new Observable(
      (observer: Observer<TEntity>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/Insert`,
        })
    );

  public update = (data: TEntity): Observable<TEntity> =>
    new Observable(
      (observer: Observer<TEntity>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Update`,
        })
    );

  public select = (params = {}): Observable<TEntity[]> =>
    new Observable(
      (observer: Observer<TEntity[]>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );
}
