import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { getConfigFailure, getConfigSuccess } from "./actions";
import { GET_CONFIGURATION, IGetConfigAction } from "./types";
import { ConfigService } from "../../services/Config";
import { IConfigModel } from "../../models/Config";
import { IErrorModel } from "../../models/ErrorModel";
import { ICommonAppState } from "../types";

const configService: ConfigService = new ConfigService();

const configurationEpic = (
  action$: ActionsObservable<IGetConfigAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(GET_CONFIGURATION),
    switchMap(() =>
      configService.get().pipe(
        map((data: IConfigModel) => {
          return getConfigSuccess(data);
        }),
        catchError((error: IErrorModel) => of(getConfigFailure(error)))
      )
    )
  );

export const configEpics = [configurationEpic];
