import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { AxiosSubscriber } from "../AxiosSubscriber";
import { INotificationTypeModel } from "../../models";
import { PromisifiableBase } from "../Base/PromisifiableBase";

export class NotificationTypesService extends PromisifiableBase {
  get url(): string {
    return "/NotificationTypes";
  }

  public select = (): Observable<INotificationTypeModel[]> =>
    new Observable(
      (observer: Observer<INotificationTypeModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );
}
