/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IdentifierType } from "../../../enums";
import { ConvertHelper } from "../../../helpers/convert.helper";
import {
  IAssetCollectionListModel,
  IAssetCollectionModel,
  IAssetCollectionSearchFilterModel,
  IAssetListModel,
  IErrorModel,
} from "../../../models";
import { HttpFactory } from "../../../services";
import { IDataProvider } from "../IDataProvider";
import { InternalDataProvider } from "../Internal";
import { ModelsMapperHelper } from "./helpers";
import {
  HttpRequestFulfilledInterceptor,
  HttpRequestRejectedInterceptor,
  HttpResponseFulfilledInterceptor,
  HttpResponseRejectedInterceptor,
  PlayListService,
} from "./services";

const playListService = new PlayListService();

const apiUrl = ConvertHelper.toString(process.env.REACT_APP_JWP_API_URL);
const cmsUrl = ConvertHelper.toString(process.env.REACT_APP_JWP_CMS_URL);

export class JWPlayerDataProvider implements IDataProvider {
  private _internalDataProvider = new InternalDataProvider();

  initHttpFactory() {
    this._internalDataProvider.initHttpFactory();
    const httpFactory = HttpFactory.getInstance("JWPlayer");
    httpFactory.baseUrl = apiUrl;
    httpFactory.addRequestInterceptor(
      "HttpRequestInterceptor",
      HttpRequestFulfilledInterceptor,
      HttpRequestRejectedInterceptor
    );
    httpFactory.addResponseInterceptor(
      "HttpResponseInterceptor",
      HttpResponseFulfilledInterceptor,
      HttpResponseRejectedInterceptor
    );
  }

  AssetCollection = {
    Options: {
      IdentifierType: IdentifierType.String,
      InsertEnabled: false,
      UpdateEnabled: false,
      DeleteEnabled: false,
    },
    link(data: IAssetCollectionModel) {
      const q = data.Name?.replaceAll(" ", "+");
      return { url: `${cmsUrl}/playlists?q=${q}`, target: "_blank" };
    },
    async get(id: string): Promise<IAssetCollectionModel> {
      try {
        const playlist = await playListService.get(id).toPromise();
        const result = ModelsMapperHelper.toAssetCollectionModel(playlist);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error as IErrorModel);
      }
    },
    async insert(_: IAssetCollectionModel): Promise<IAssetCollectionModel> {
      return Promise.reject("Not implemented");
    },
    async update(_: IAssetCollectionModel): Promise<IAssetCollectionModel> {
      return Promise.reject("Not implemented");
    },
    async delete(_: IAssetCollectionModel): Promise<void> {
      return Promise.reject("Not implemented");
    },
    async search(
      filter: IAssetCollectionSearchFilterModel
    ): Promise<IAssetCollectionListModel> {
      const result: IAssetCollectionListModel = {
        Entities: [],
        TotalCount: 0,
        Filter: filter,
      };

      try {
        const response = await playListService
          .search({
            page: filter.PageNumber,
            page_length: filter.PageSize,
            title: filter.FullTextSearch,
          })
          .toPromise();

        result.Entities = response.playlists.map((playlist) => {
          return ModelsMapperHelper.toAssetCollectionModel(playlist);
        });
        result.PageNumber = response.page;
        result.PageSize = response.page_length;
        result.TotalCount = response.total;

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error as IErrorModel);
      }
    },
    async searchAssetsByAssetCollectionId(
      _: IAssetCollectionSearchFilterModel
    ): Promise<IAssetListModel> {
      return Promise.reject("Not implemented");
    },
  };
}
