import React from "react";
import "./SectionGrid.scss";

interface SectionGridProps {
  style?: React.CSSProperties;
}

export const SectionGrid: React.FC<SectionGridProps> = ({
  children,
  style,
}) => (
  <div className="section-grid" style={style}>
    {children}
  </div>
);
