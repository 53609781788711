import {
  AdvertisementBoardType,
  AdvertisementStore,
  IAdvertisementBlockModel,
  IAdvertisementBoardModel,
  IErrorModel,
  IStateModel,
  RecordStatus,
} from "@bms/common-services";
import {
  Button,
  Content,
  Icon,
  Layout,
  Modal,
  NotificationService,
  PageContent,
  PageHeader,
  Sider,
  Spin,
  TabPane,
  Tabs,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { ActionCreator } from "redux";
import { ROUTES } from "../../constants";
import {
  AdvertisementBoardProvider,
  IAdvertisementBoardContext,
} from "../../context";
import {
    advertisementBlockStatusHelper as utils,
    advertisementBoardLeadHelper
} from "../../helpers";
import { AdvertisementBoardDetailsForm } from "../AdvertisementBoardDetailsForm";
import "./AdvertisementBoardDetails.scss";

const notificationService = NotificationService.getInstance();

export interface IAdvertisementBoardDetailsProps
  extends WithTranslation,
    RouteComponentProps<{ id: string }> {
  actionType?: string;
  advertisementBlock: IStateModel<IAdvertisementBlockModel>;
  advertisementBoard?: IAdvertisementBoardModel;
  getAdvertisementBoard: ActionCreator<
    AdvertisementStore.Types.IGetAdvertisementBoardAction
  >;
  isProcessingData: boolean;
  isLoadingData: boolean;
  updateAdvertisementBlockWithDetails: ActionCreator<
    AdvertisementStore.Types.IUpdateAdvertisementBlockWithDetailsAction
  >;
  error?: IErrorModel;
}

export interface IAdvertisementBoardDetailsState {
  activeTabKey: string;
  advertisementBlock: IStateModel<IAdvertisementBlockModel>;
  advertisementBoard?: IAdvertisementBoardModel;
  hasChanges: boolean;
  isAfterDelete: boolean;
  shouldExitAfterSave: boolean;
}

export class AdvertisementBoardDetails extends React.Component<
  IAdvertisementBoardDetailsProps,
  IAdvertisementBoardDetailsState
> {
  public state: Readonly<IAdvertisementBoardDetailsState> = {
    activeTabKey:
      new URLSearchParams(this.props.location.search).get("tab") ?? "DETAILS",
    advertisementBlock: this.props.advertisementBlock,
    advertisementBoard: this.props.advertisementBoard,
    hasChanges: false,
    isAfterDelete: false,
    shouldExitAfterSave: false
  };
  videoRef: React.RefObject<HTMLVideoElement>;

  constructor(props: IAdvertisementBoardDetailsProps) {
    super(props);

    this.videoRef = React.createRef();
  }

  public componentDidMount() {
    const { getAdvertisementBoard, match } = this.props;

    getAdvertisementBoard(match.params.id);
  }

  componentDidUpdate(prevProps: IAdvertisementBoardDetailsProps) {
    const { actionType, error, t } = this.props;

    if (prevProps.actionType === actionType) {
      return;
    }

    switch (actionType) {
      case AdvertisementStore.Consts
        .UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS_FAILURE:
        notificationService.error({
          message: t("UPDATE_FAILURE"),
          description: error ? error.Message : undefined,
        });
        if (this.state.shouldExitAfterSave) {
            window.history.back();
        }
        break;
      case AdvertisementStore.Consts
        .UPDATE_ADVERTISEMENT_BLOCK_WITH_DETAILS_SUCCESS:
        notificationService.success({
          message: t("UPDATE_SUCCESS"),
        });
        if (this.state.isAfterDelete) {
          const id = this.props.advertisementBlock.Data?.Id;
          this.props.history.push(
            `${ROUTES.ADVERTISEMENT_BLOCK_DETAILS}/${id}?tab=ADVERTISEMENT_BOARDS`
          );
        } else if (this.state.shouldExitAfterSave) {
            window.history.back();
        }
        break;
      default:
        break;
    }
  }

  static getDerivedStateFromProps(
    props: IAdvertisementBoardDetailsProps,
    state: IAdvertisementBoardDetailsState
  ) {
    var obj: any = {};
    if (props.advertisementBlock !== state.advertisementBlock) {
      obj.advertisementBlock = props.advertisementBlock;
    }
    if (props.advertisementBoard !== state.advertisementBoard) {
      obj.advertisementBoard = props.advertisementBoard;
    }

    if (Object.keys(obj).length === 0) {
      return null;
    }
    return obj;
  }

  private onTabClick = (key: string) => {
    if (key != "PREVIEW") {
      if (this.videoRef.current) {
        this.videoRef.current.pause();
      }
    }
    this.setState({ activeTabKey: key });
  };

  private onSaveClick = () => {
    const {
      advertisementBlock,
      updateAdvertisementBlockWithDetails
    } = this.props;

    if( advertisementBlock.Data ){
      advertisementBlock.Data.Boards = advertisementBlock.Data.Boards.map( board => {return {...board,TextColor:(board.ShowText? board.TextColor: '')}} );
    }
    updateAdvertisementBlockWithDetails(advertisementBlock);
  };

  public onRefreshClick = () => {
    const { getAdvertisementBoard, match } = this.props;

    getAdvertisementBoard(match.params.id);
  };

  private onDeleteClick = () => {
    const { t, advertisementBoard } = this.props;

    if (!advertisementBoard) {
      return;
    }

    Modal.confirm({
      title: t("DELETE_ADVERTISEMENT_BOARD"),
      content: t(
        "BOARD_DETAILS_DELETE_ACTION_MESSAGE",
        {
          advertisementBoardName: advertisementBoard.Name
        }
      ),
      okText: t("OK"),
      cancelText: t("CANCEL"),
      onOk: this.onConfirmDelete,
    });
  };

  public onConfirmDelete = () => {
    const {
      advertisementBlock,
      advertisementBoard,
      updateAdvertisementBlockWithDetails
    } = this.props;

    if (advertisementBlock.Data) {
      advertisementBlock.Data.Boards = advertisementBlock.Data.Boards.map(
        x => x.Id == advertisementBoard?.Id ? {
          ...advertisementBoard,
          RecordStatus: RecordStatus.Deleted
        } : x
      );

      updateAdvertisementBlockWithDetails(advertisementBlock);

      this.setState({ isAfterDelete: true });
    }
  };

  renderContent = (advertisementBoard: IAdvertisementBoardModel | undefined) => {
    if (!advertisementBoard) return;

    const { t } = this.props;

    const origWidth = 1280;
    const origHeight = 720;

    const scaledWidth = 640;
    const scaledHeight = 360;
    const scaledWidthForDisplay = "640px";
    const scaledHeightForDisplay = "360px";
    const isLeadBoard = ( advertisementBoardLeadHelper.isLead(advertisementBoard) );

    if (advertisementBoard?.BoardType == AdvertisementBoardType.Image) {
        return (
            <div
              style={{ position: "relative", paddingBottom: "56.2%" }}
            >
              <img
                style={{
                  position: "absolute",
                  objectFit: "cover",
                  width: scaledWidthForDisplay,
                  height: scaledHeightForDisplay,
                }}
                alt={advertisementBoard?.Name}
                src={advertisementBoard?.ContentUrl}
              />
            </div>
        )
    }
    if (isLeadBoard) {
        let left = advertisementBoard?.InputX;
        let top = advertisementBoard?.InputY;
        let width = advertisementBoard?.InputWidth;
        let height = advertisementBoard?.InputHeight;
        let consentHeight = (height ? height : 70);

        const consentText = advertisementBoard?.ConsentText;
        let consentTextColor = advertisementBoard?.ConsentTextColor;
        let titleColor = advertisementBoard?.TextColor;
        
        if (titleColor) {
            if (titleColor[0] !== "#") {
                titleColor = "#" + titleColor;
            }
        } else {
            titleColor = "#ffffff";
        }
        if (consentTextColor) {
          if (consentTextColor[0] !== "#") {
              consentTextColor = "#" + consentTextColor;
          }
        } else {
            consentTextColor = "#ffffff";
        }
        if (left === undefined) { left = 85; }
        if (top === undefined) { top = 130; }
        if (width === undefined) { width = 460; }
        if (height === undefined) { height = 70; }

        left = left / origWidth * scaledWidth;
        top = top / origHeight * scaledHeight;
        width = width / origWidth * scaledWidth;
        height = height / origHeight * scaledHeight;
        consentHeight = consentHeight / origHeight * scaledHeight;

        const renderInputTitle = () => {
            if (advertisementBoard?.ShowText) {
                const titleText = ( advertisementBoardLeadHelper.isLeadPhone(advertisementBoard)? 
                  t("HBBTV_FILL_IN_PHONE_NUMBER") :
                  t("HBBTV_FILL_IN_EMAIL_ADDRESS"));
                return (
                    <div
                        style={{
                          color: titleColor,
                          width: width,
                          height: '30px',
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                    >
                      {titleText}
                    </div>
                )
            }
        }

        return (
            <div
              style={{ position: "relative", paddingBottom: "56.2%" }}
            >
              <img
                style={{
                  position: "absolute",
                  objectFit: "cover",
                  width: scaledWidthForDisplay,
                  height: scaledHeightForDisplay,
                }}
                alt={advertisementBoard?.Name}
                src={advertisementBoard?.ContentUrl}
              />
              <div style={{
                position: "absolute",
                left: left,
                top: top,
              }}>
                {renderInputTitle()}
                <div
                  style={{
                    backgroundColor: "#dddddd",
                    width: width,
                    height: height,
                  }}
                /> 
                <div className="consent" style={{
                  color: consentTextColor,
                  top: (advertisementBoard.ShowText? 30:0),
                  height: consentHeight,
                  border: '1px solid ' + consentTextColor,
                  position: 'absolute',
                  right: -210,
                  width: 200,
                  textTransform: 'uppercase',
                }}>
                  <span className="consent__button" style={{
                    height: consentHeight,
                    lineHeight: consentHeight +'px',
                    width: consentHeight-4,
                    fontSize: consentHeight/3,
                    display: 'block',
                    fontWeight: 'bold',
                    float: 'left',
                  }}>ok</span>
                  <span className="consent__text" style={{
                    display: 'table',
                    height: consentHeight,
                    lineHeight: consentHeight/4 +'px',
                    width: 195 - consentHeight,
                    fontSize: consentHeight/4,
                    overflow: 'hidden',
                  }}><p style={{
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    textAlign: 'left',
                  }}>
                      {consentText}
                    </p></span>
                </div>
              </div>
            </div>
        )
    }
    if (advertisementBoard?.BoardType == AdvertisementBoardType.Video) {
        return (
          <React.Fragment key={advertisementBoard.ContentUrl}>
            <div
              style={{ position: "relative", paddingBottom: "56.2%" }}
            >
            <video
              ref={this.videoRef}
              style={{
                position: "absolute",
                objectFit: "cover",
                width: scaledWidthForDisplay,
                height: scaledHeightForDisplay,
              }}
              controls>
                  <source src={advertisementBoard.ContentUrl} />
                  Your browser does not support HTML5 video
            </video>
            </div>
          </React.Fragment>
        )
    }
  };

  renderActionSave = () => {
    const { advertisementBlock, t } = this.props;

    if (!utils.isEditable(advertisementBlock)) {
      return null;
    }

    return (
      <Button
        key="action-save"
        icon={<Icon type="save" />}
        title={t("SAVE_DATA")}
        type="primary"
        htmlType="submit"
        loading={advertisementBlock.IsProcessing}
        disabled={advertisementBlock.IsProcessing}
        onClick={this.onSaveClick}
      >
        {t("BUTTON_SAVE")}
      </Button>
    );
  }

  renderActionDelete = () => {
    const { advertisementBlock, t } = this.props;

    if (!utils.isEditable(advertisementBlock)) {
      return null;
    }

    return (
      <Button
        icon={<Icon type="delete" />}
        title={t("DELETE_DATA")}
        onClick={this.onDeleteClick}
      />
    );
  }

  onScreenExit = () => {
      const { advertisementBlock, advertisementBoard, t } = this.props;

      if (!utils.isEditable(advertisementBlock) || !advertisementBoard) {
        return window.history.back();
      }

      Modal.confirm({
        title: "",
        content: t("DETAILS_EXIT_ACTION_MESSAGE"),
        okText: t("SAVE"),
        cancelText: t("CANCEL"),
        onOk: this.saveAndExit,
        onCancel: () => { window.history.back(); }
      });
  }

  saveAndExit = () => {
      this.setState({ shouldExitAfterSave: true });
      this.onSaveClick();
  }

  public render() {
    const {
      isProcessingData,
      isLoadingData,
      t
    } = this.props;
    const {
      advertisementBoard,
      activeTabKey,
      hasChanges
    } = this.state;

    const advertisementBoardContext: IAdvertisementBoardContext = {
      advertisementBoard,
      onAdvertisementBoardChange: (advertisementBoardData: IAdvertisementBoardModel) => {
        const { advertisementBoard } = this.state;

        if (!advertisementBoard) {
          return;
        }

        Object.assign(advertisementBoard, advertisementBoardData);

        if (advertisementBoard.RecordStatus === RecordStatus.NoChange) {
          advertisementBoard.RecordStatus = RecordStatus.Updated;
        }

        this.setState({ advertisementBoard: advertisementBoard, hasChanges: true });
      },
    };

    const zeroWidthTriggerStyle: React.CSSProperties = {
      top: "60px",
      height: "33px",
      fontSize: "18px",
      lineHeight: "33px",
    };

    const propertiesSiderStyle: React.CSSProperties = {
      display: activeTabKey === "DETAILS" ? "none" : "block",
    };

    return (
      <div className="AdvertisementBoardDetails">
        <Spin spinning={isProcessingData || isLoadingData}>
          <AdvertisementBoardProvider value={advertisementBoardContext}>
            <PageHeader
              title={advertisementBoard?.Name}
              onBack={this.onScreenExit}
              extra={
                <>
                  {this.renderActionSave()}
                  <Button
                    key="action-reload"
                    icon={<Icon type="reload" />}
                    onClick={this.onRefreshClick}
                    title={t("REFRESH_DATA")}
                  />
                  {this.renderActionDelete()}
                </>
              }
            ></PageHeader>
            <PageContent>
              <Layout>
                <Content>
                  <Tabs
                    defaultActiveKey={activeTabKey}
                    onTabClick={this.onTabClick}
                  >
                    <TabPane key="DETAILS" tab={t("DETAILS")}>
                      <AdvertisementBoardDetailsForm
                        key={`AdvertisementBoardDetailsForm-${advertisementBoard?.Id}`}
                        isActive={activeTabKey === "DETAILS"}
                      />
                    </TabPane>
                    <TabPane key="PREVIEW" tab={t("PREVIEW")}>
                        {this.renderContent(advertisementBoard)}
                    </TabPane>
                  </Tabs>
                </Content>
                <Sider
                  className="AdvertisementBoardDetails__Properties"
                  style={propertiesSiderStyle}
                  collapsible={true}
                  defaultCollapsed={true}
                  reverseArrow={true}
                  collapsedWidth={0}
                  width={500}
                  zeroWidthTriggerStyle={zeroWidthTriggerStyle}
                >
                  <div className="AdvertisementBoardDetails__Properties--container">
                    <div className="AdvertisementBoardDetails__Properties--header">
                      <div className="title">
                        <h1>
                          {t("PROPERTIES")}
                        </h1>
                      </div>
                    </div>
                  </div>
                </Sider>
              </Layout>
            </PageContent>
          </AdvertisementBoardProvider>
        </Spin>
      </div>
    );
  }
}
