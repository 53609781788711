import React from "react";
import AsyncSelect, { Props as ReactSelectProps } from "react-select/async";
import cx from "classnames";
import "./Select.scss";

export const Select = <T extends {}>(props: ReactSelectProps<T>) => {
  const { children, className, ...rest } = props;

  return (
    <AsyncSelect
      className={cx("gm-select-container", className)}
      classNamePrefix="gm-select"
      {...rest}
    />
  );
};
