import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useShakaPlayer } from "../../helpers";

import "./Player.scss";
import "shaka-player/dist/controls.css";

export interface IPlayerRef {
  play: () => void;
  pause: () => void;
}

export interface IPlayerProps {
  contentUrl?: string;
  contentType?: string;
  fill?: boolean;
}

const DEFAULT_CONTENT_URL =
  "https://docs.evostream.com/sample_content/assets/hls-bunny-166/playlist.m3u8";

export const Player = forwardRef((props: IPlayerProps, vsRef) => {
  const { contentUrl = DEFAULT_CONTENT_URL, contentType, fill = true } = props;
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoComponentRef = useRef<HTMLVideoElement>(null);
  const {
    initPlayer,
    loadContent,
    clearPlayer,
    isInitialized,
  } = useShakaPlayer();

  useImperativeHandle(
    vsRef,
    () => {
      return {
        play: () => {
          videoComponentRef.current?.play();
        },
        pause: () => {
          videoComponentRef.current?.pause();
        },
      };
    },
    [videoComponentRef.current]
  );

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDoubleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {
    if (videoComponentRef.current && videoContainerRef.current) {
      initPlayer(videoComponentRef.current, videoContainerRef.current);
    }
    () => clearPlayer();
  }, []);

  useEffect(() => {
    if (isInitialized) {
      loadContent(contentUrl, contentType);
    }
  }, [contentUrl, isInitialized]);

  return (
    <div className="video-player-container">
      <div
        data-shaka-player-container
        ref={videoContainerRef}
        className="video-container"
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        <video
          data-shaka-player
          ref={videoComponentRef}
          id="video"
          style={{ maxWidth: "100vw", maxHeight: "100vh" }}
          width={"100%"}
          height={"100%"}
          autoPlay={true}
        />
      </div>
    </div>
  );
});
