import { DatePicker as AntDatePicker } from "antd";
import {
  PickerBaseProps,
  PickerDateProps,
  PickerTimeProps,
} from "antd/lib/date-picker/generatePicker";
import moment from "antd/node_modules/moment";
import React from "react";

declare type DatePickerProps =
  | Omit<PickerBaseProps<moment.Moment>, "defaultValue" | "value">
  | Omit<PickerDateProps<moment.Moment>, "defaultValue" | "value">
  | Omit<PickerTimeProps<moment.Moment>, "defaultValue" | "value">;

export declare type IDatePickerProps = DatePickerProps & {
  defaultValue?: Date | string | undefined;
  value?: Date | string | undefined;
};

export const DatePicker: React.FC<IDatePickerProps> = (props) => {
  const { defaultValue, value, ...restProps } = props;
  return (
    <AntDatePicker
      defaultValue={defaultValue ? moment(defaultValue) : undefined}
      value={value ? moment(value) : undefined}
      {...restProps}
    />
  );
};
