import React from "react";
import cx from "classnames";
import { Row as AntRow } from "antd";
import { RowProps as AntRowProps } from "antd/lib/row";
import "./Row.scss";

export interface IRowProps extends AntRowProps {
  direction?: "column" | "column-reverse" | "row" | "row-reverse";
  wrap?: "nowrap" | "wrap" | "wrap-reverse";
}

export const Row = (props: IRowProps) => {
  const { children, className, direction, wrap, ...rest } = props;
  const rowClassName = cx(className, {
    [`flex-${direction}`]: direction,
    [`flex-${wrap}`]: wrap,
  });
  return (
    <AntRow className={rowClassName} {...rest}>
      {children}
    </AntRow>
  );
};
