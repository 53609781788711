import {
  AssetService,
  AssetType,
  IAssetModel,
  IAssetSearchFilterModel,
  useDataLoader,
} from "@bms/common-services";
import {
  Button,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  ITableRowSelection,
  Modal,
  PageContent,
  PageHeader,
  Table,
  Tag,
} from "@bms/common-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterCleanIcon } from "../../../../resources/icons";

const assetService = new AssetService().promisify();

interface IAssetBrowserModalProps {
  selectionMode: "single" | "multiple";
  visible: boolean;
  ignoredAssetIds?: number[];
  onCancel?: () => void;
  onSelect?: (row: IAssetModel) => void;
  selectedType?: AssetType;
}

export const AssetBrowserModal: React.FC<IAssetBrowserModalProps> = (props) => {
  const {
    selectionMode,
    visible,
    ignoredAssetIds,
    onSelect,
    onCancel,
    selectedType,
  } = props;
  const { t } = useTranslation();
  const isSingleSelection = selectionMode === "single";

  const [filter, setFilter] = useState<IAssetSearchFilterModel>({
    PageSize: 10,
    PageNumber: 1,
    IncludeCount: true,
    Types: selectedType ? [selectedType] : undefined,
  });

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const assetLoader = useDataLoader({
    loader: () => assetService.search(filter),
    deps: [filter],
  });

  const assetTypesLoader = useDataLoader({
    loader: () => assetService.getAssetTypes(),
    deps: [],
  });

  const pagination: ITablePaginationConfig = {
    current: filter.PageNumber,
    defaultPageSize: 10,
    pageSize: filter.PageSize,
    pageSizeOptions: ["10", "30", "50", "100"],
    showSizeChanger: true,
    showTotal: (total, range) =>
      t("TABLE_PAGINATION_TOTAL", {
        rangeFrom: range[0],
        rangeTo: range[1],
        total: total,
      }),
    total: assetLoader.data?.TotalCount ?? 0,
    onChange: (page: number, pageSize?: number) => {
      setFilter({
        ...filter,
        PageNumber: page,
        PageSize: pageSize ?? filter.PageSize,
      });
    },
  };

  const getColumns = (): Array<ITableColumnProps<IAssetModel>> => {
    let columns = [
      {
        key: "Title",
        dataIndex: "Title",
        title: t("MODEL_TITLE"),
      },
      {
        key: "AssetTypeCode",
        dataIndex: "AssetTypeCode",
        title: t("MODEL_ASSET_TYPE_CODE"),
        width: "200px",
        filters: assetTypesLoader.data?.map((assetType) => ({
          text: assetType.DisplayName,
          value: assetType.Code,
        })),
        filteredValue: filter.Types || null,
        render: (text: any, row: IAssetModel) => {
          return (
            <Tag color={row.AssetTypeCode ? "#f50" : "#1890ff"}>
              {row.AssetTypeCode}
            </Tag>
          );
        },
      },
      {
        key: "Action",
        dataIndex: "Action",
        title: t("TABLE_ACTION_COLUMN"),
        width: "150px",
        render: (text: any, row: IAssetModel) => {
          const actions: React.ReactNode[] = [];

          if (!ignoredAssetIds?.includes(row.Id || -1)) {
            actions.push(
              <Button
                key="add"
                icon={<Icon type="plus" />}
                onClick={() => {
                  internalOnSelect(row.Id);
                }}
              >
                {t("BUTTON_ADD")}
              </Button>
            );
          }

          return actions;
        },
      },
    ];

    return isSingleSelection ? columns.slice(0, -1) : columns;
  };

  const onClearFilters = () => {
    setFilter({
      PageNumber: 1,
      PageSize: 10,
      IncludeCount: true,
    });
  };

  const onSearch = (value: string) =>
    setFilter({
      ...filter,
      FullTextSearch: value || undefined,
      PageNumber: 1,
    });

  const internalOnSelect = (assetId: number) => {
    const asset = assetLoader.data?.Entities.find(
      (asset) => asset.Id === assetId
    );

    if (onSelect && asset) {
      onSelect(asset);

      if (onCancel && isSingleSelection) {
        onCancel();
      }
    }
  };

  const renderFooter = () => {
    if (selectionMode === "single") {
      return (
        <>
          <Button
            type="primary"
            disabled={selectedRows.length === 0}
            onClick={() => {
              internalOnSelect(selectedRows[0]);
            }}
          >
            {t("BUTTON_SELECT")}
          </Button>
        </>
      );
    }

    return null;
  };

  const rowSelection: ITableRowSelection<IAssetModel> = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IAssetModel[]) => {
      if (selectedRows.length > 0) {
        const asset = selectedRows[0];

        setSelectedRows([asset.Id]);
      }
    },
    type: "radio",
    selectedRowKeys: selectedRows,
  };

  const onRefreshClick = () => setFilter({ ...filter });

  const onTableRow = (asset: IAssetModel) => {
    return {
      onClick: () => setSelectedRows([asset.Id]),
      onDoubleClick: (event: React.MouseEvent) => {
        setSelectedRows([asset.Id]);
        internalOnSelect(asset.Id);
      },
    };
  };

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    setFilter({
      ...filter,
      Types: filters.AssetTypeCode?.length
        ? filters.AssetTypeCode.map((row) => `${row}`)
        : undefined,
      PageSize: pagination.pageSize,
      PageNumber: pagination.current,
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      closable={false}
      centered={true}
      maskClosable={true}
      footer={renderFooter()}
      width="50%"
    >
      <PageContent>
        <PageHeader
          title={t("ASSET_BROWSER_TITLE")}
          extra={
            <>
              <InputSearch
                key="search"
                placeholder={t("SEARCH_PLACEHOLDER")}
                defaultValue={filter.FullTextSearch}
                onSearch={onSearch}
                style={{ width: 250 }}
                allowClear
              />
              <Button
                key="reload"
                shape="circle"
                icon={<Icon type="reload" />}
                onClick={onRefreshClick}
                title={t("BUTTON_REFRESH_TITLE")}
              />
              <Button
                shape="circle"
                icon={<Icon component={FilterCleanIcon} />}
                onClick={onClearFilters}
                title={t("MENU_OPTION_CLEAR_FILTERS")}
              />
              <Button
                shape="circle"
                type="primary"
                icon={<Icon type="close" />}
                onClick={onCancel}
                title={t("ADD_ASSET_PRICE")}
              />
            </>
          }
        />
        <Table<IAssetModel>
          columns={getColumns()}
          rowKey="Id"
          dataSource={assetLoader.data?.Entities}
          loading={assetLoader.loading}
          pagination={pagination}
          rowSelection={isSingleSelection ? rowSelection : undefined}
          onRow={isSingleSelection ? onTableRow : undefined}
          onChange={onTableChange}
        />
      </PageContent>
    </Modal>
  );
};
