import {
  AssetImageType,
  AssetStore,
  CommonStore,
  IAssetImageModel,
  IAssetModel,
  IAssetSearchFilterModel,
  ICommonAppState,
} from "@bms/common-services";
import {
  Button,
  Heading,
  Icon,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  Modal,
  NotificationService,
  Popconfirm,
  setTableColumnSearchProps,
  Table,
  Tag,
} from "@bms/common-ui";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FilterCleanIcon } from "../../../../resources/icons";
import { FormModal } from "../../../../components";
import { AssetImageModal } from "../AssetImageModal";
import "./AssetImages.scss";

const notificationService = NotificationService.getInstance();

const assetSelector = (state: ICommonAppState) => state.asset;
const commonSelector = (state: ICommonAppState) => state.common;

export interface IAssetImagesProps {
  asset?: IAssetModel;
  refreshAsset: () => void;
}

export type IAssetImagesTypesInUse = {
  [key: string]: AssetImageType[];
};

export const AssetImages: React.FC<IAssetImagesProps> = (props) => {
  const { asset, refreshAsset } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImageUrl, setPreviewImageUrl] = useState<string>("");
  const [showCoversModal, setShowCoversModal] = useState<boolean>(false);
  const [editableImage, setEditableImage] = useState<IAssetImageModel>({});
  const [stateFilter, setStateFilter] = useState<IAssetSearchFilterModel>({
    PageSize: 10,
    PageNumber: 1,
    IncludeCount: true,
  });
  const [statePagination, setStatePagination] = useState<number>(1);
  const { platforms } = useSelector(commonSelector);
  const { assetImageTypes, action, assetImages } = useSelector(assetSelector);
  const { data: assetImageTypesData = [] } = assetImageTypes;
  const getPlatformTypes = useCallback(
    () => dispatch(CommonStore.Actions.selectPlatforms()),
    [dispatch]
  );
  const getAssetImageTypes = useCallback(
    () => dispatch(AssetStore.Actions.getAssetImageTypes()),
    [dispatch]
  );

  const getAssetImagesTypesInUse = useCallback(() => {
    const platformsWithType: IAssetImagesTypesInUse = platforms
      .map((platform) => {
        const typesInUse: AssetImageType[] =
          asset?.Images?.filter(
            (image) => image.PlatformCode === platform.Code
          ).map((image) => image.AssetImageTypeCode!) || [];

        return {
          [platform.Code]: typesInUse,
        };
      })
      .reduce((a, b) => Object.assign(a, b), {});

    return platformsWithType;
  }, [asset, platforms]);

  const handleClickPreviewImage = (url: string) => {
    setPreviewVisible(true);
    setPreviewImageUrl(url);
  };

  const deleteAssetImage = useCallback(
    (data: IAssetImageModel) =>
      dispatch(AssetStore.Actions.deleteAssetImage(data)),
    [dispatch]
  );

  const cleanupAfterActions = () => {
    setShowCoversModal(false);
    setEditableImage({});

    if (asset) {
      refreshAsset();
    }
  };

  useEffect(() => {
    getPlatformTypes();
    getAssetImageTypes();
  }, []);

  useEffect(() => {
    switch (action?.type) {
      case "UPDATE_ASSET_IMAGE_SUCCESS":
        cleanupAfterActions();
        notificationService.success({
          message: t("UPDATE_ASSET_IMAGE_SUCCESS"),
        });
        break;
      case "UPDATE_ASSET_IMAGE_FAILURE":
        notificationService.error({
          message: t("UPDATE_ASSET_IMAGE_FAILURE"),
          description: action?.error?.Message,
        });
        break;
      case "ADD_ASSET_IMAGE_SUCCESS":
        cleanupAfterActions();
        notificationService.success({
          message: t("ADD_ASSET_IMAGE_SUCCESS"),
        });
        break;
      case "ADD_ASSET_IMAGE_FAILURE":
        notificationService.error({
          message: t("ADD_ASSET_IMAGE_FAILURE"),
          description: action?.error?.Message,
        });
        break;
      case "DELETE_ASSET_IMAGE_SUCCESS":
        cleanupAfterActions();
        notificationService.success({
          message: t("DELETE_ASSET_IMAGE_SUCCESS"),
        });
        break;
      case "DELETE_ASSET_IMAGE_FAILURE":
        notificationService.error({
          message: t("DELETE_ASSET_IMAGE_FAILURE"),
          description: action?.error?.Message,
        });
        break;
      case "UPLOAD_ASSET_CONTENT_FILE_SUCCESS":
        notificationService.success({
          message: t("UPLOAD_ASSET_CONTENT_FILE_SUCCESS"),
        });
        break;
      case "UPLOAD_ASSET_CONTENT_FILE_FAILURE":
        notificationService.error({
          message: t("UPLOAD_ASSET_CONTENT_FILE_FAILURE"),
          description: action?.error?.Message,
        });
        break;
    }
  }, [action]);

  const getColumnsProps = (): Array<ITableColumnProps<IAssetImageModel>> => {
    return [
      {
        key: "Image",
        dataIndex: "Image",
        width: "150px",
        title: t("COMMON_IMAGE"),
        render: (text: any, row: IAssetImageModel) => {
          return (
            <img
              src={row.Url}
              alt=""
              className="imagePreview"
              onClick={() => handleClickPreviewImage(row.Url!)}
            />
          );
        },
      },
      {
        key: "Url",
        dataIndex: "Url",
        title: t("COMMON_URL"),
        ellipsis: true,
        ...setTableColumnSearchProps("Url"),
        onFilter: (value, record) => record.Url!.includes(`${value}`),
        filteredValue: stateFilter.Url ? [stateFilter.Url] : null,
        render: (text: any, row: IAssetImageModel) => {
          return (
            <span
              title={row.Url}
              className="imageUrl"
              onClick={() => {
                setShowCoversModal(true);
                setEditableImage(row);
              }}
            >
              {row.Url}
            </span>
          );
        },
      },
      {
        key: "AssetImageTypeDisplayName",
        dataIndex: "AssetImageTypeDisplayName",
        width: "125px",
        title: t("ASSET_IMAGE_TYPE"),
        filters: assetImageTypesData.map((type) => ({
          text: type.DisplayName,
          value: type.Code,
        })),
        filteredValue: stateFilter.AssetImageTypeDisplayNames || null,
        onFilter: (value, record) =>
          record.AssetImageTypeCode!.includes(`${value}`),
      },
      {
        key: "PlatformDisplayName",
        dataIndex: "PlatformDisplayName",
        width: "160px",
        title: t("ASSET_IMAGE_PLATFORM"),
        filters: platforms.map((platform) => ({
          text: platform.DisplayName,
          value: platform.Code,
        })),
        filteredValue: stateFilter.PlatformDisplayNames || null,
        onFilter: (value, record) => record.PlatformCode!.includes(`${value}`),
        render: (text: any, row: IAssetImageModel) => {
          return (
            <Tag colorRotate={row.PlatformDisplayName}>
              {row.PlatformDisplayName}
            </Tag>
          );
        },
      },
      {
        key: "ImageResolutionDisplayName",
        width: "140px",
        dataIndex: "ImageResolutionDisplayName",
        align: "center",
        title: t("ASSET_IMAGE_RESOLUTION"),
        render: (text: string, row: IAssetImageModel) => {
          return (
            <>
              {row.Width && row.Height
                ? `${row.Width} x ${row.Height}`
                : t("COMMON_UNKNOWN")}
            </>
          );
        },
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        width: "110px",
        title: t("TABLE_ACTIONS_COLUMN"),
        render: (_, assetImage: IAssetImageModel) => (
          <Popconfirm
            title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              await deleteAssetImage(assetImage);
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("BUTTON_DELETE")}
            />
          </Popconfirm>
        ),
      },
    ];
  };

  const tablePagination: ITablePaginationConfig = {
    pageSize: stateFilter.PageSize,
    total: asset?.Images?.length,
    current: statePagination,
    showTotal: (total, range) =>
      t("TABLE_PAGINATION_TOTAL", {
        rangeFrom: range[0],
        rangeTo: range[1],
        total: total,
      }),
    showSizeChanger: true,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "30", "50", "100"],
  };

  const cancelAssetImageModal = () => {
    setEditableImage({});
    setShowCoversModal(false);
    Modal.destroyAll();
  };

  const onDeleteAssetImage = () => {
    deleteAssetImage(editableImage);
    Modal.destroyAll();
  };

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    const filter: IAssetSearchFilterModel = { ...stateFilter };

    filter.Url =
      filters.Url && filters.Url.length ? `${filters.Url[0]}` : undefined;

    filter.AssetImageTypeDisplayNames = filters.AssetImageTypeDisplayName
      ?.length
      ? filters.AssetImageTypeDisplayName.map((row) => `${row}`)
      : undefined;

    filter.PlatformDisplayNames = filters.PlatformDisplayName?.length
      ? filters.PlatformDisplayName.map((row) => `${row}`)
      : undefined;

    setStateFilter(filter);
    setStatePagination(pagination.current!);
  };

  const onClearFiltersClick = () => {
    setStateFilter({});
  };

  return (
    <div className="CoverList">
      <Heading
        title={t("ASSET_IMAGE_LIST_TITLE")}
        actions={
          <>
            <Button
              shape="circle"
              key="clear-filters"
              icon={<Icon component={FilterCleanIcon} />}
              onClick={onClearFiltersClick}
              title={t("MENU_OPTION_CLEAR_FILTERS")}
            />
            <Button
              shape="circle"
              type="primary"
              key="add"
              icon={<Icon type="plus" />}
              onClick={() => setShowCoversModal(true)}
              title={t("ASSET_IMAGE_BUTTON_ADD")}
            />
          </>
        }
      />
      <Table<IAssetImageModel>
        columns={getColumnsProps()}
        rowKey="Guid"
        dataSource={asset?.Images}
        loading={assetImages.isProcessing}
        pagination={false}
        onChange={onTableChange}
      />
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        preview
      >
        <img alt="image" src={previewImageUrl} />
      </Modal>
      <FormModal
        isVisible={showCoversModal}
        isLoading={assetImages.isProcessing}
        isNewForm={editableImage.Id ? false : true}
        isDeleteButtonEnabled={editableImage.Id ? true : false}
        createFormTitle={t("ASSET_IMAGE_FORM_ADD_TITLE")}
        editFormTitle={t("ASSET_IMAGE_FORM_EDIT_TITLE")}
        modalClassName="AssetImageModal"
        submitFormName="AssetImageModal"
        onCloseModal={cancelAssetImageModal}
        onDeleteButtonClick={onDeleteAssetImage}
      >
        <AssetImageModal
          assetImage={editableImage}
          assetId={asset?.Id!}
          assetImagesTypesInUse={getAssetImagesTypesInUse()}
        />
      </FormModal>
    </div>
  );
};
