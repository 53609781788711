import { IAssetPeopleModel } from "@bms/common-services";
import {
  Col,
  Form,
  IFormValues,
  Input,
  required,
  Row,
  Spin,
} from "@bms/common-ui";
import React from "react";

import { useTranslation } from "react-i18next";

interface IDictionaryPeopleFormProps {
  person: IAssetPeopleModel;
  isProcessing?: boolean;
  addNewPerson: (person: IAssetPeopleModel) => void;
  updatePerson: (person: IAssetPeopleModel) => void;
}

export const DictionaryPeopleForm = ({
  person,
  addNewPerson,
  updatePerson,
  isProcessing,
}: IDictionaryPeopleFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const newPerson = person.Id < 0;

  const onFinish = async (values: IFormValues) => {
    const data: IAssetPeopleModel = {
      ...person,
      ...values,
    };

    if (newPerson) {
      addNewPerson(data);
    } else {
      updatePerson(data);
    }
  };

  return (
    <Spin spinning={isProcessing}>
      <Form
        name="DictionaryPeopleForm"
        className="DictionaryPeopleForm"
        form={form}
        layout="vertical"
        initialValues={{ ...person }}
        onFinish={onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            <Form.Item
              name="FullName"
              label={t("DICTIONARY_PEOPLE_COLUMN_TITLE")}
              rules={[required()]}
            >
              <Input placeholder={t("DICTIONARY_PEOPLE_COLUMN_TITLE")} />
            </Form.Item>

            {/*
            // Role: "", TODO: add to backend "role" for people
            <Form.Item name="Role" label={t("DICTIONARY_PEOPLE_COLUMN_ROLE")}>
              <Input placeholder={t("DICTIONARY_PEOPLE_COLUMN_ROLE")} />
            </Form.Item> */}
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
