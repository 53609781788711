import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { push } from "connected-react-router";
import { HTTP_ERROR, ROUTES_COMMON } from "../../constants";
import { DeviceHelper, TimeHelper } from "../../helpers";
import { IErrorModel, ITokenModel } from "../../models";
import { dispatch } from "../../store/actions";
import { AuthService } from "../AuthService";
import { StorageManager } from "../StorageManager";

let isRefreshing = false;
let failedQueue: {
  resolve: (value?: unknown) => void;
  reject: (reason?: any) => void;
}[] = [];

const authService: AuthService = new AuthService();

const processQueue = (error?: IErrorModel, token?: string) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  failedQueue = [];
};

export const HttpRequestFulfilledInterceptor = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const session: ITokenModel = StorageManager.getValue("session");

  if (!config.headers) {
    config.headers = {};
  }

  if (
    session &&
    session.Token &&
    !config.headers.hasOwnProperty("Authorization")
  ) {
    config.headers.Authorization = `Bearer ${session.Token}`;
  }

  if (
    config.headers.hasOwnProperty("Authorization") &&
    !config.headers.Authorization
  ) {
    delete config.headers.Authorization;
  }

  if (!config.headers["Content-Type"]) {
    config.headers["Content-Type"] = "application/json; charset=UTF-8";
  }

  if (!config.headers["X-TimeZoneOffset"]) {
    config.headers["X-TimeZoneOffset"] = `${-TimeHelper.getTimeZoneOffset()}`;
  }

  return config;
};

export const HttpRequestRejectedInterceptor = (error: IErrorModel): any => {
  return Promise.reject(error);
};

export const HttpResponseFulfilledInterceptor = (
  response: AxiosResponse
): AxiosResponse => {
  return response;
};

export const HttpResponseRejectedInterceptor = async (
  error: IErrorModel | any
): Promise<any> => {
  const { config, response } = error;
  const originalRequest = config;

  if (
    response?.status === HTTP_ERROR.AUTHENTICATION_FAILED &&
    response?.config?.url === "/Authorization/SignIn"
  ) {
    return Promise.reject(new Error(response?.data?.Message || "Unauthorized"));
  }

  if (response?.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      // Check if refresh token request failed
      if (response?.config?.url === "/Authorization/RefreshToken") {
        isRefreshing = false;
        dispatch(push(ROUTES_COMMON.LOGIN));

        return Promise.reject(new Error("Unable to refresh token"));
      }

      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers["Authorization"] = "Bearer " + token;

          return axios(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;

    let session = StorageManager.getValue("session") as ITokenModel;

    try {
      const deviceInfo = DeviceHelper.deviceInfo();
      let newSession: ITokenModel | undefined = undefined;
      let refreshToken = session?.RefreshToken;

      if (refreshToken) {
        // Try to refresh token
        const refreshTokenResponse = await authService
          .refreshToken(refreshToken)
          .toPromise();

        if (refreshTokenResponse.AuthorizationToken) {
          StorageManager.setValue(
            "session",
            refreshTokenResponse.AuthorizationToken
          );
          newSession = refreshTokenResponse.AuthorizationToken;
        } else {
          // Try to login as Anonymous
          const loginResponse = await authService
            .signIn({ Device: deviceInfo })
            .toPromise();

          if (loginResponse.AuthorizationToken) {
            StorageManager.setValue("user", loginResponse.User);
            StorageManager.setValue(
              "session",
              loginResponse.AuthorizationToken
            );

            newSession = loginResponse.AuthorizationToken;
          }
        }
      }

      if (!newSession) {
        dispatch(push(ROUTES_COMMON.LOGIN));

        return Promise.reject(new Error("Unable to refresh token"));
      }

      originalRequest.headers["Authorization"] = "Bearer " + newSession.Token;
      processQueue(undefined, newSession.Token);

      return Promise.resolve(axios(originalRequest));
    } catch (error) {
      processQueue(error as IErrorModel, undefined);
      return Promise.reject(error);
    } finally {
      isRefreshing = false;
    }
  }

  return Promise.reject(error);
};
