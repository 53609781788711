import { AuthStore, IUserInfoModel, UserStore } from "@bms/common-services";
import {
  Avatar,
  Col,
  Dropdown,
  Header,
  Icon,
  Menu,
  MenuItem,
  Row,
} from "@bms/common-ui";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import resources from "../../resources";
import { IAppState } from "../../store";
import { LanguageSelect } from "../LanguageSelect";

import "./Navbar.scss";

const useInitials = () => {
  const currentUser = useSelector<IAppState, IUserInfoModel | undefined>(
    (state) => state.auth.user
  );

  if (!currentUser) {
    return "";
  }

  if (currentUser.Initials) {
    return currentUser.Initials;
  }

  return (currentUser.FullName || currentUser.Email)
    ?.toUpperCase()
    .split(" ")
    .slice(0, 2)
    .map((str) => str[0])
    .join("");
};

export const Navbar: React.FC = () => {
  const initials = useInitials();
  const [t] = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UserStore.Actions.getProfile());
  }, [dispatch]);

  const onSignOut = useCallback(() => {
    dispatch(AuthStore.Actions.signOut());
  }, [dispatch]);

  const avatarUrl = useSelector<IAppState, string | undefined>(
    (state) => state.user.profile?.AvatarUrl
  );

  return (
    <Header className="navbar">
      <Row align="middle" justify="space-between" gutter={16}>
        <Col flex="auto">
          <img className="navbar-logo" src={resources.logo} alt="logo" />
        </Col>
        <Col>
          <LanguageSelect />
        </Col>
        <Col>
          <Dropdown
            overlay={
              <Menu>
                <MenuItem onClick={onSignOut}>
                  <Icon type="logout" />
                  {t("MENU_SIGN_OUT", "Logout")}
                </MenuItem>
              </Menu>
            }
          >
            <Avatar className="navbar-avatar" size="large" src={avatarUrl}>
              {initials}
            </Avatar>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};
