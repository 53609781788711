import { ViewModelBase } from "../Base";
import { RoomUserModel } from "./RoomUserModel";

export class RoomModel extends ViewModelBase {
  public Id?: number;

  public IsPrivate?: boolean;

  public Description?: string;

  public Created?: string;

  public Avatar?: string;

  public Users?: RoomUserModel[];
}
