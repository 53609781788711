import { ViewModelBase } from "../Base/ViewModelBase";

export class MessageUserModel extends ViewModelBase {
  public UserId?: number;

  public FullName?: string;

  public AvatarPath?: string;

  public AvatarUrl?: string;
}
