import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ICommonAppState, SourceEntitiesStore } from "../store";

const sourceEntitiesSelector = (state: ICommonAppState) =>
  state.sourceEntities;

export const useExternalSources = () => {
  const sources = useSelector(sourceEntitiesSelector);
  const dispatch = useDispatch();


  useEffect(() => {
    if (!sources.length) {
      dispatch(SourceEntitiesStore.Actions.getSourceEntities())
    }
  }, [])

  return sources;
}