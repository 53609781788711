import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const SUPPORTED_LANGUAGES = ["en", "pl"];

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `./locales/{{lng}}/{{ns}}.json?version=${Date.now()}`,
    },
    detection: {
      lookupQuerystring: "lang",
      lookupCookie: "lang",
      lookupLocalStorage: "lang",
      lookupSessionStorage: "lang",
    },
    debug: false,
    whitelist: SUPPORTED_LANGUAGES,
    defaultNS: "scmtool",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ["scmtool"],
  });

export default i18n;
