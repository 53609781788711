import {
  Button,
  Col,
  Form,
  Icon,
  IFormInstance,
  IFormValues,
  Input,
  Row,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ApplicationResourceType } from "@bms/common-services";
import { ApplicationSectionMenuPropertyModel } from "../../models";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import { ApplicationSectionMenuItemsModal } from "../../../../components/ApplicationSectionMenuItemsModal/ApplicationSectionMenuItemsModal";
import { ApplicationResourceUploadModal } from "../ApplicationResourceUploadModal";
import { ImagePreviewModal } from "../ImagePreviewModal";
import { ImagePreview } from "../ImagePreview";

import "./ApplicationSectionMenuProperties.scss";

export interface IApplicationSectionMenuPropertiesStateProps {
  isProcessingData: boolean;
}

export interface IApplicationSectionMenuPropertiesDispatchProps {}

export interface IApplicationSectionMenuPropertiesOwnProps {}

export interface IApplicationSectionMenuPropertiesProps
  extends IApplicationSectionMenuPropertiesStateProps,
    IApplicationSectionMenuPropertiesDispatchProps,
    IApplicationSectionMenuPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface IApplicationSectionMenuPropertiesState
  extends IComponentPropertiesBaseState {
  itemsModalVisible: boolean;
  isItemsPropertyCreated: boolean;
  applicationResourceUploadModalVisible: boolean;
  backgroundPreviewModalVisible: boolean;
}

export class ApplicationSectionMenuProperties extends ComponentPropertiesBase<
  IApplicationSectionMenuPropertiesProps,
  IApplicationSectionMenuPropertiesState,
  ApplicationSectionMenuPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
    itemsBrowserVisible: false,
  };

  public state: Readonly<IApplicationSectionMenuPropertiesState> = {
    itemsModalVisible: false,
    isItemsPropertyCreated: false,
    applicationResourceUploadModalVisible: false,
    backgroundPreviewModalVisible: false,
  };

  formRef = React.createRef<IFormInstance>();

  getPropertiesModel(): ApplicationSectionMenuPropertyModel {
    const { component } = this.props;
    const properties = new ApplicationSectionMenuPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    component.Name = values.Name;
  };

  public onItemsModalCancel = () => {
    this.setState({ itemsModalVisible: false });
  };

  public onItemsPropertyCreate = () => {
    this.setState({ isItemsPropertyCreated: true });
  };

  private checkIfPropertyAlreadyExists = this.props?.component?.Properties?.find(
    (property) => property.Name === "Items"
  );

  public renderItemsProperty = () => {
    const { t } = this.props;
    const { itemsModalVisible } = this.state;

    return (
      <Form.Item
        name="Items"
        label={t("CONFIGURATION_ITEMS__MODAL_TITLE")}
        initialValue={this.properties.Items}
      >
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item>{this.properties.Items?.length || 0}</Form.Item>
            {itemsModalVisible && (
              <ApplicationSectionMenuItemsModal
                onItemsModalCancel={this.onItemsModalCancel}
                modalVisible={itemsModalVisible}
              />
            )}
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                this.setState({ itemsModalVisible: true });

                if (
                  !this.state.isItemsPropertyCreated &&
                  !this.checkIfPropertyAlreadyExists
                ) {
                  this.properties.Items = [];
                  this.onPropertyChange("Items");
                  this.onItemsPropertyCreate();
                }
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  public onApplicationResourceUploadCancel = () => {
    this.setState({ applicationResourceUploadModalVisible: false });
  };

  public onApplicationResourceUploadSuccess = (
    filePath?: string,
    fileUrl?: string
  ) => {
    this.properties.BackgroundUrl = filePath;
    this.properties.BackgroundAbsoluteUrl = fileUrl;
    this.onPropertyChange("BackgroundUrl");
    this.formRef?.current?.setFieldsValue({
      BackgroundUrl: filePath,
    });
    this.setState({ applicationResourceUploadModalVisible: false });
  };

  public onShowBackgroundPreviewModal = () => {
    const videoUrl =
      this.properties.BackgroundAbsoluteUrl ?? this.properties.BackgroundUrl;
    if (!videoUrl) {
      return;
    }

    this.setState({ backgroundPreviewModalVisible: true });
  };

  public onCloseBackgroundPreviewModal = () => {
    this.setState({ backgroundPreviewModalVisible: false });
  };

  public renderBackgroundUrlProperty = () => {
    const { isProcessingData, t, component } = this.props;
    const {
      applicationResourceUploadModalVisible,
      backgroundPreviewModalVisible,
    } = this.state;

    const backgroundUrl =
      this.properties.BackgroundAbsoluteUrl || this.properties.BackgroundUrl;

    return (
      <>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item
              name="BackgroundUrl"
              label={t("CONFIGURATION_PROPERTY__BACKGROUND_URL")}
              initialValue={this.properties.BackgroundUrl}
              extra={
                <p>
                  {t("CONFIGURATION_IMAGE__SUGGESTED_RESOLUTION", {
                    width: 1920,
                    height: 94,
                  })}
                </p>
              }
            >
              <Input
                disabled={isProcessingData}
                placeholder={t("https://")}
                onChange={(e) => {
                  this.properties.BackgroundUrl = e.target.value;
                  this.onPropertyChange("BackgroundUrl");
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 16]}>
          <Col span={21} push={5}>
            <ImagePreview
              imageSrc={backgroundUrl}
              className="ApplicationSectionMenuProperties_ImagePreview"
            />
          </Col>
          <Col span={3} style={{ textAlign: "right" }}>
            <ApplicationResourceUploadModal
              key={`ApplicationResourceUploadModal-${component.Id}`}
              visible={applicationResourceUploadModalVisible}
              applicationConfigurationId={component.ApplicationConfigurationId}
              resourceType={ApplicationResourceType.Image}
              onCancel={this.onApplicationResourceUploadCancel}
              onSuccess={this.onApplicationResourceUploadSuccess}
            />
            <Button
              icon={<Icon type="upload" />}
              title={t("CONFIGURATION_BUTTON__UPLOAD_VIDEO_TITLE")}
              onClick={() => {
                this.setState({ applicationResourceUploadModalVisible: true });
              }}
            />
            {backgroundUrl && (
              <>
                <ImagePreviewModal
                  visible={backgroundPreviewModalVisible}
                  imageSrc={backgroundUrl}
                  onCancel={this.onCloseBackgroundPreviewModal}
                />
                <Button
                  title={t("CONFIGURATION_BUTTON__PREVIEW_IMAGE_TITLE")}
                  style={{ marginTop: "8px" }}
                  icon={<Icon type="Eye" style={{ fontSize: "20px" }} />}
                  onClick={() => this.onShowBackgroundPreviewModal()}
                />
              </>
            )}
          </Col>
        </Row>
      </>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 19 },
      },
    };
    const { component, t } = this.props;

    return (
      <Form
        name="ApplicationSectionMenuProperties"
        {...formItemLayout}
        onFinish={this.onFinish}
        ref={this.formRef}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderItemsProperty()}
            {this.renderBackgroundUrlProperty()}
          </Col>
        </Row>
      </Form>
    );
  }
}
