import React, { useCallback, useEffect, useState } from "react";

declare var shaka: any;

export const useShakaPlayer = () => {
  const [player, setPlayer] = useState<any>();
  const [ui, setUi] = useState();
  const [videoContainerEl, setVideoContainerEl] = useState<HTMLDivElement>();
  const [videoEl, setVideoEl] = useState<HTMLVideoElement>();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (videoEl && videoContainerEl) {
      initShakaPlayer();
    }
  }, [videoEl, videoContainerEl]);

  const initPlayer = (
    videoRef: HTMLVideoElement,
    videoContainerRef: HTMLDivElement
  ) => {
    setVideoContainerEl(videoContainerRef);
    setVideoEl(videoRef);
  };

  const initShakaPlayer = () => {
    shaka.polyfill.installAll();
    if (videoEl && shaka.Player.isBrowserSupported()) {
      loadShakaPlayer();
    } else {
      console.error("Browser not supported!");
    }
  };

  const loadShakaPlayer = useCallback(() => {
    const shakaPlayer = new shaka.Player(videoEl);
    const ui = new shaka.ui.Overlay(shakaPlayer, videoContainerEl, videoEl);
    (window as any).player = shakaPlayer;
    shakaPlayer.addEventListener("error", handleErrorEvent);
    shakaPlayer
      .getNetworkingEngine()
      .registerRequestFilter(function (_: any, request: any) {
        request.allowCrossSiteCredentials = true;
      });
    setIsInitialized(true);
    setPlayer(shakaPlayer);
    setUi(ui);
  }, [videoContainerEl, videoEl]);

  const loadContent = async (contentUrl: string, contentType?: string) => {
    try {
      await player.load(contentUrl, null, contentType);
      console.log("The video has now been loaded!");
    } catch (e) {
      onError(e);
    }
  };

  const clearPlayer = () => {
    if (player) {
      player.destroy();
    }
  };

  const handleErrorEvent = (event: any) => {
    onError(event.detail);
  };

  const onError = (e: any) => {
    console.error("Error code", e.code, "object", e);
  };

  return { initPlayer, loadContent, clearPlayer, isInitialized };
};
