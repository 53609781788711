export enum ComponentName {
  TextWidget = "TextWidget",
  InfoBoxWidget = "InfoBoxWidget",
  PlaylistWidget = "PlaylistWidget",
  QuestionAnswerWidget = "QuestionAnswerWidget",
  PollWidget = "PollWidget",
  FeedWidget = "FeedWidget",
  MediaWidget = "MediaWidget",
  MemeWidget = "MemeWidget",
  InstagramWidget = "InstagramWidget",
  SpotifyArtistWidget = "SpotifyArtistWidget",
  SpotifyAnthemWidget = "SpotifyAnthemWidget",
  AstronomyWidget = "AstronomyWidget",
  FileRequestWidget = "FileRequestWidget",
  SocialLinksWidget = "SocialLinksWidget",
  TopFriendsWidget = "TopFriendsWidget",
}
