import { Slider as AntSlider } from "antd";
import { SliderRangeProps, SliderSingleProps } from "antd/lib/slider";
import React from "react";

export type ISliderProps = SliderRangeProps | SliderSingleProps;

export class Slider extends React.PureComponent<ISliderProps> {
  public render() {
    const { children, ...rest } = this.props;
    return <AntSlider {...rest}>{children}</AntSlider>;
  }
}
