import React from "react";
import { TimePicker as AntTimePicker } from "antd";
import { Moment as AntMoment } from "antd/node_modules/moment";
import { PickerTimeProps } from "antd/lib/date-picker/generatePicker/index";

export declare type IDatePickerMoment = AntMoment;
export interface TimePickerProps
  extends Omit<PickerTimeProps<IDatePickerMoment>, "picker"> {
  addon?: () => React.ReactNode;
  popupClassName?: string;
}

export const TimePicker: React.FC<TimePickerProps> = (props) => {
  return <AntTimePicker {...props} />;
};
