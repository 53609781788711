import {
  Button,
  Form,
  Icon,
  Modal,
  NotificationService,
  PageContent,
  PageHeader,
  TabPane,
  Tabs,
  useSearchParamsTabs,
} from "@bms/common-ui";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import { generateBreadcrumb } from "../../../../helpers";
import { ROUTES } from "../../constants/routes";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TabGeneral, TabContent, TabRecipients } from "./tabs";
import { useHistory, useParams } from "react-router";
import {
  INotificationModel,
  NotificationsService,
  RecordStatus,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";

const userNotificationService = new NotificationsService().promisify();
const notificationService = NotificationService.getInstance();

export const NotificationDetails = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [activeTabKey] = useSearchParamsTabs("GENERAL");
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    form.resetFields();
  }, []);

  const { data: notification } = useDataLoader({
    loader: () => userNotificationService.get(+id),
    deps: [id],
  });

  const [updateNotification, updateNotificationState] = useServiceCaller(
    async (data: INotificationModel) => {
      const result = await userNotificationService.update({
        ...data,
        RecordStatus: RecordStatus.Updated,
        Id: +id,
        Guid: notification?.Guid,
      });
      if (result.ok) {
        notificationService.success({
          message: t("NOTIFICATION_UPDATE_SUCCESSFULLY"),
        });
      } else {
        notificationService.error({
          message: t("NOTIFICATION_UPDATE_FAILURE"),
        });
      }
      return result.ok;
    },
    []
  );

  const [deleteNotification] = useServiceCaller(async () => {
    const result = await userNotificationService.delete(notification!);
    if (result.ok) {
      notificationService.success({
        message: t("NOTIFICATION_DELETE_SUCCESSFULLY"),
      });
      history.push(ROUTES.NOTIFICATION_LIST);
    } else {
      notificationService.error({
        message: t("NOTIFICATION_DELETE_FAILURE"),
      });
    }
    return result.ok;
  }, []);

  const getBreadcrumbProps = (): BreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.NOTIFICATION_LIST}`,
        breadcrumbName: t("NOTIFICATIONS"),
      },
      {
        path: `${ROUTES.NOTIFICATION_DETAILS}/${id}`,
        breadcrumbName: notification?.NamePreview ?? t("NOTIFICATIONS"),
      },
    ]);
  };

  const getExtraButtons = (
    <>
      <Button
        onClick={() => onDeleteClick()}
        title={t("NOTIFICATION_DELETE")}
        shape="circle"
        type="primary"
        disabled
        icon={<Icon type="delete" />}
      />
    </>
  );

  const onDeleteClick = () => {
    if (!notification) {
      return;
    }

    Modal.confirm({
      title: t("COMMON_DELETE"),
      content: t("DELETE_NOTIFICATION_MESSAGE", notification.Name),
      okText: t("COMMON_DELETE"),
      cancelText: t("COMMON_CANCEL"),
      onOk: deleteNotification,
    });
  };

  return (
    <PageContent>
      <PageHeader
        breadcrumb={getBreadcrumbProps()}
        title={t("NOTIFICATION_DETAILS")}
        extra={getExtraButtons}
      />
      <Tabs activeKey={activeTabKey} destroyInactiveTabPane={false}>
        <TabPane key="GENERAL" tab={t("NOTIFICATION_GENERAL")}>
          {notification && (
            <TabGeneral
              notification={notification}
              onSubmit={updateNotification}
            />
          )}
        </TabPane>
        <TabPane key="CONTENT" tab={t("NOTIFICATION_CONTENT")}>
          {notification && (
            <TabContent
              notification={notification}
              onSubmit={updateNotification}
            />
          )}
        </TabPane>

        <TabPane key="RECIPIENTS" tab={t("NOTIFICATION_RECIPIENTS")}>
          {notification && (
            <TabRecipients
              notification={notification}
              onSubmit={updateNotification}
            />
          )}
        </TabPane>
      </Tabs>
    </PageContent>
  );
};
