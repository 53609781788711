import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AssetService,
  IAssetPeopleModel,
  RecordStatus,
  useServiceCaller,
} from "@bms/common-services";
import {
  Button,
  Empty,
  IBreadcrumbProps,
  Icon,
  ITableColumnProps,
  ITableFilter,
  NotificationService,
  PageContent,
  PageHeader,
  Pagination,
  Popconfirm,
  setTableColumnSearchProps,
  Table,
} from "@bms/common-ui";
import { FormModal } from "../../../../components";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import { DictionaryPeopleForm } from "../DictionaryPeopleForm";
import { ROUTES } from "../../../../modules/Dictionaries/constants";
import { useTableDataProvider } from "../../../../helpers";

import "./DictionaryPeople.scss";
import { generateBreadcrumb } from "../../../../helpers";
import { FilterCleanIcon } from "../../../../resources/icons";

const notificationService = NotificationService.getInstance();
const assetService = new AssetService().promisify();

const defaultPerson: IAssetPeopleModel = {
  Id: -1,
  FullName: "",
  // Role: "", TODO: add to backend "role" for people
  RecordStatus: RecordStatus.Inserted,
};

export const DictionaryPeople = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [personId, setPersonId] = useState<IAssetPeopleModel>(defaultPerson);
  const [isProcessing, setIsProcessing] = useState(false);
  const validPersonId = personId.Id <= 0;

  const {
    dataLoader: peopleLoader,
    filters,
    pagination,
  } = useTableDataProvider({
    filtersSchema: {
      FullName: "string",
      SortAscending: "boolean",
    },
    loader: (filters, pagination) =>
      assetService.searchAssetPeople({
        FullTextSearch: filters.FullName,
        ...pagination,
      }),
    deps: [],
    onError: (error) =>
      notificationService.error({
        message: t(
          "LOADING_DATA_ERROR_MESSAGE",
          "There was an error while loading data."
        ),
        description: error.Message,
      }),
  });

  const [addNewPerson] = useServiceCaller(async (person: IAssetPeopleModel) => {
    setIsProcessing(true);
    const res = await assetService.addAssetPeople({
      ...person,
      RecordStatus: RecordStatus.Inserted,
    });
    if (res.ok) {
      notificationService.success({
        message: t("DICTIONARY_PEOPLE_INSERT_SUCCESS"),
      });
      await peopleLoader.refresh();
      setIsProcessing(false);
      personModal();
    } else {
      notificationService.error({
        message: t("DICTIONARY_PEOPLE_INSERT_FAILURE"),
        description: res.error?.Message,
      });
      setIsProcessing(false);
    }
  }, []);

  const [updatePerson] = useServiceCaller(async (person: IAssetPeopleModel) => {
    setIsProcessing(true);
    const res = await assetService.updateAssetPeople({
      ...person,
      RecordStatus: RecordStatus.Updated,
    });
    if (res.ok) {
      notificationService.success({
        message: t("DICTIONARY_PEOPLE_UPDATE_SUCCESS"),
      });
      await peopleLoader.refresh();
      setIsProcessing(false);
      personModal();
    } else {
      notificationService.error({
        message: t("DICTIONARY_PEOPLE_UPDATE_FAILURE"),
        description: res.error?.Message,
      });
      setIsProcessing(false);
    }
  }, []);

  const [deletePerson] = useServiceCaller(async (person: IAssetPeopleModel) => {
    setIsProcessing(true);
    const res = await assetService.deleteAssetPeople({
      ...person,
      RecordStatus: RecordStatus.Deleted,
    });
    if (res.ok) {
      notificationService.success({
        message: t("DICTIONARY_PEOPLE_DELETE_SUCCESS"),
      });
      await peopleLoader.refresh();
      setIsProcessing(false);
      personModal();
    } else {
      notificationService.error({
        message: t("DICTIONARY_PEOPLE_DELETE_FAILURE"),
        description: res.error?.Message,
      });
      setIsProcessing(false);
    }
  }, []);

  const getColumnNames = useMemo((): Array<
    ITableColumnProps<IAssetPeopleModel>
  > => {
    return [
      {
        key: "FullName",
        dataIndex: "FullName",
        title: t("DICTIONARY_PEOPLE_COLUMN_TITLE"),
        sorter: (a, b) => a.FullName.localeCompare(b.FullName),
        filteredValue: filters.asTableArray.FullName,
        ...setTableColumnSearchProps(
          "FullName",
          t("DICTIONARY_PEOPLE_COLUMN_TITLE")
        ),
        render: (_: any, row: IAssetPeopleModel) => {
          return (
            <a
              title={row.FullName}
              className="FullName"
              onClick={() => (personModal(), setPersonId(row))}
            >
              {row.FullName}
            </a>
          );
        },
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_: any, row: IAssetPeopleModel) => (
          <Popconfirm
            title={t(
              "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
              "Are you sure you want to delete element?"
            )}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              const res = await assetService.deleteAssetPeople({
                ...row,
                RecordStatus: RecordStatus.Deleted,
              });
              if (res.ok) {
                notificationService.success({
                  message: t("DICTIONARY_PEOPLE_DELETE_SUCCESS"),
                });
                await peopleLoader.refresh();
              } else {
                notificationService.error({
                  message: t("DICTIONARY_PEOPLE_DELETE_FAILURE"),
                  description: res.error?.Message,
                });
              }
            }}
            okText={t("BUTTON_YES", "Yes")}
            cancelText={t("BUTTON_NO", "No")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT", "Delete element")}
            />
          </Popconfirm>
        ),
      },
      // Role, TODO: add to backend "role" for people
      // {
      //   key: "Role",
      //   dataIndex: "Role",
      //   title: t("DICTIONARY_PEOPLE_COLUMN_ROLE"),
      // },
    ];
  }, []);

  const onTableChange = (
    _: any,
    incomingFilters: ITableFilter,
    sorter: any
  ) => {
    const SortAscending = sorter.order === "ascend" && true;
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
      SortAscending: SortAscending,
    }));
  };

  const onRemovePerson = useCallback(() => {
    deletePerson(personId);
  }, [personId]);

  const personModal = useCallback(() => {
    setShowForm((showForm) => !showForm);
    setPersonId(defaultPerson);
  }, [showForm]);

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.DICTIONARY_BASE}`,
        breadcrumbName: t("MENU_ADMINISTRATION_DICTIONARIES"),
      },
      {
        path: `${ROUTES.DICTIONARY_PEOPLE}`,
        breadcrumbName: t("DICTIONARY_PEOPLE_TITLE"),
      },
    ]);
  };
  useEffect(() => {
    filters.clear();
  }, []);

  const headerButtons = useMemo(() => {
    return (
      <>
        <Button
          key="add"
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          onClick={personModal}
          title={t("DICTIONARY_PEOPLE_MODAL_BUTTON_NEW")}
        />
        <Button
          key="refresh"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={peopleLoader.refresh}
          title={t("BUTTON_REFRESH_TITLE")}
        />
      </>
    );
  }, []);

  return (
    <div className="DictionaryPeople">
      <FormModal
        isLoading={isProcessing}
        isVisible={showForm}
        isNewForm={validPersonId}
        isDeleteButtonEnabled={!validPersonId}
        createFormTitle={t("DICTIONARY_PEOPLE_MODAL_NEW")}
        editFormTitle={t("DICTIONARY_PEOPLE_MODAL_EDIT")}
        modalClassName="DictionaryPeopleModal"
        submitFormName="DictionaryPeopleForm"
        onCloseModal={personModal}
        onDeleteButtonClick={onRemovePerson}
      >
        <DictionaryPeopleForm
          isProcessing={isProcessing}
          person={personId}
          addNewPerson={addNewPerson}
          updatePerson={updatePerson}
        />
      </FormModal>
      <PageHeader
        title={t("DICTIONARY_PEOPLE_TITLE")}
        extra={headerButtons}
        breadcrumb={getBreadcrumbProps()}
      />
      <PageContent footer={<Pagination {...pagination.props} />}>
        <Table<IAssetPeopleModel>
          locale={{
            emptyText: (
              <>
                <Empty />
                {filters.anyActive && (
                  <Button
                    icon={<Icon component={FilterCleanIcon} />}
                    onClick={filters.clear}
                  >
                    {t("MENU_OPTION_CLEAR_FILTERS")}
                  </Button>
                )}
              </>
            ),
          }}
          onChange={onTableChange}
          rowKey={(rowKey) => rowKey.Id}
          dataSource={peopleLoader.data?.Entities}
          loading={peopleLoader.loading}
          columns={getColumnNames}
          pagination={false}
        />
      </PageContent>
    </div>
  );
};
