import {
  Button,
  DomainTag,
  Empty,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  Link,
  NotificationService,
  PageContent,
  PageHeader,
  Pagination,
  setTableColumnSearchProps,
  Table,
  IBreadcrumbProps,
} from "@bms/common-ui";
import { generateBreadcrumb, useTableDataProvider } from "../../../../helpers";
import { ROUTES } from "./../../constants/routes";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormModal } from "../../../../components";
import { useHistory } from "react-router";
import {
  GuidHelper,
  INotificationModel,
  IUserNotificationSearchFilterModel,
  NotificationRecipientTypesService,
  NotificationsService,
  NotificationStatusesService,
  RecordStatus,
  TimeHelper,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import { NotificationForm } from "../NotificationForm";
import { FilterCleanIcon } from "../../../../resources/icons";

const userNotificationsService = new NotificationsService().promisify();
const userNotificationsStatusService = new NotificationStatusesService().promisify();
const userNotificationsRecipienTypeService = new NotificationRecipientTypesService().promisify();
const notificationService = NotificationService.getInstance();

export const NotificationList = () => {
  const { t } = useTranslation();
  const [showNotificationModal, setNotificationModal] = useState<boolean>(
    false
  );
  const history = useHistory();

  const {
    dataLoader: notificationLoader,
    pagination,
    filters,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      Name: "string",
      Statuses: "strings",
      RecipientTypes: "strings",
      FullTextSearch: "string",
    },
    loader: (filters, pagination) => {
      return userNotificationsService.search({
        ...filters,
        ...pagination,
      });
    },
    deps: [],
    onError: (error) =>
      notificationService.error({
        message: t(
          "LOADING_DATA_ERROR_MESSAGE",
          "There was an error while loading data."
        ),
        description: error.Message,
      }),
  });

  const userNotificationRecipientTypesLoader = useDataLoader({
    loader: () => userNotificationsRecipienTypeService.select(),
    deps: [],
  });

  const userNotificationRecipientTypes = useMemo(
    () => userNotificationRecipientTypesLoader.data ?? [],
    [userNotificationRecipientTypesLoader.data]
  );

  const userNotificationStatusesLoader = useDataLoader({
    loader: () => userNotificationsStatusService.select(),
    deps: [],
  });

  const userNotificationTypes = useMemo(
    () => userNotificationStatusesLoader.data ?? [],
    [userNotificationStatusesLoader.data]
  );

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.NOTIFICATION_DETAILS}`,
        breadcrumbName: t("NOTIFICATION_LIST"),
      },
    ]);
  };

  const getColumnsProps = (): Array<ITableColumnProps<INotificationModel>> => [
    {
      key: "Name",
      dataIndex: "Name",
      title: t("MODEL_NAME", "Name"),
      width: "300px",
      filteredValue: filters.current.Name ? [filters.current.Name] : null,
      render: (_: string, row: INotificationModel) => {
        return (
          <Link to={`${ROUTES.NOTIFICATION_DETAILS}/${row.Id}`}>
            {row.NamePreview}
          </Link>
        );
      },
      ...setTableColumnSearchProps("Name"),
    },
    {
      key: "Created",
      dataIndex: "Created",
      align: "center",
      width: "140px",
      title: t("MODEL_CREATION_DATE"),
      render: (created?: string) =>
        created ? TimeHelper.format(created) : null,
    },
    {
      key: "ScheduledOn",
      dataIndex: "ScheduledOn",
      align: "center",
      width: "140px",
      title: t("NOTIFICATION_SCHEDULEDON_FIELD", "Scheduled On"),
      render: (created?: string) =>
        created ? TimeHelper.format(created) : null,
    },
    {
      key: "CreatorUserFullName",
      dataIndex: "CreatorUserFullName",
      title: t("MODEL_CREATED_BY", "Created by"),
      align: "center",
      width: "200px",
    },
    {
      key: "RecipientTypes",
      dataIndex: "RecipientType",
      title: t("MODEL_RECIPIENT_TYPE", "Recipient"),
      align: "center",
      width: "200px",
      filters: userNotificationRecipientTypes.map(
        (userNotificationRecipientType) => ({
          text: userNotificationRecipientType.DisplayName,
          value: userNotificationRecipientType.Code,
        })
      ),
      render: (_: string, row) => (
        <DomainTag
          key={row.RecipientType}
          domain="notification-recipients"
          noMargin
          colorRotate={row.RecipientType}
          value={
            userNotificationRecipientTypes.find(
              (it) => it.Code === row.RecipientType
            )?.DisplayName || row.RecipientType
          }
        />
      ),
      filteredValue: filters.asTableArray.RecipientTypes,
    },
    {
      key: "Statuses",
      dataIndex: "Status",
      title: t("MODEL_STATUS", "Status"),
      align: "center",
      width: "250px",
      filters: userNotificationTypes.map((userNotificationType) => ({
        text: userNotificationType.DisplayName,
        value: userNotificationType.Code,
      })),
      filteredValue: filters.asTableArray.Statuses,
      render: (_: string, row) => (
        <DomainTag
          key={row.Status}
          domain="notification-status"
          noMargin
          colorRotate={row.Status}
          value={
            userNotificationTypes.find((it) => it.Code === row.Status)
              ?.DisplayName || row.Status
          }
        />
      ),
    },
  ];

  const handleTableChange = (
    _: ITablePaginationConfig,
    tableFilters: ITableFilter
  ) => {
    const newFilters: IUserNotificationSearchFilterModel = {
      RecipientTypes: (tableFilters.RecipientTypes || []) as string[],
      Statuses: (tableFilters.Statuses || []) as string[],
    };
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...newFilters,
    }));
  };

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value || undefined,
    }));

  const columns = getColumnsProps();

  const [createNotification, createNotificationState] = useServiceCaller(
    async (data: INotificationModel) => {
      const result = await userNotificationsService.insert({
        ...data,
        Guid: GuidHelper.newGuid(),
        RecordStatus: RecordStatus.Inserted,
      });
      if (result.ok) {
        setNotificationModal(false);
        notificationService.success({
          message: t("NOTIFICATION_CREATE_SUCCESSFULLY"),
        });
        history.push(`${ROUTES.NOTIFICATION_DETAILS}/${result.data.Id}`);
      } else {
        notificationService.error({
          message: t("NOTIFICATION_CREATE_FAILURE"),
        });
      }
      return result.ok;
    },
    []
  );

  const getExtraButtons = (
    <>
      <InputSearch
        key="search"
        placeholder={t("SEARCH_PLACEHOLDER")}
        value={fullTextSearch}
        onChange={({ target: { value } }) => setFullTextSearch(value)}
        onSearch={onSearch}
        style={{ width: 250 }}
        allowClear
      />
      <Button
        shape="circle"
        disabled={!filters.anyActive}
        icon={<FilterCleanIcon />}
        onClick={filters.clear}
        title={t("MENU_OPTION_CLEAR_FILTERS")}
      />
      <Button
        shape="circle"
        icon={<Icon type="reload" />}
        title={t("BUTTON_REFRESH_TITLE")}
        onClick={notificationLoader.refresh}
      />
      <Button
        onClick={() => setNotificationModal(true)}
        title={t("NOTIFICATION_ADD")}
        shape="circle"
        type="primary"
        icon={<Icon type="plus" />}
      />
    </>
  );

  const AddNotificationModal = (
    <FormModal
      isVisible={showNotificationModal}
      isDeleteButtonEnabled={false}
      createFormTitle={t("NOTIFICATION_ADD")}
      editFormTitle={t("NOTIFICATION_ADD")}
      modalClassName="AddNotificationModal"
      isNewForm
      submitFormName="NotificationForm"
      onCloseModal={() => setNotificationModal(false)}
      isLoading={createNotificationState.processing}
    >
      <NotificationForm
        onSubmit={createNotification}
        hiddenFields={[
          "DataType",
          "Type",
          "ImageUrl",
          "UrlContent",
          "DataContent",
          "DataType",
          "TextContent",
          "Status",
          "CreatorUserFullName",
          "UrlContent",
          "UrlDescription",
          "RecipientType",
        ]}
      />
    </FormModal>
  );

  return (
    <>
      {AddNotificationModal}
      <PageContent footer={<Pagination {...pagination.props} />}>
        <PageHeader
          title={t("NOTIFICATION_LIST")}
          breadcrumb={getBreadcrumbProps()}
          extra={getExtraButtons}
        />
        <Table<INotificationModel>
          locale={{
            emptyText: (
              <>
                <Empty />
                {filters.anyActive && (
                  <Button icon={<FilterCleanIcon />} onClick={filters.clear}>
                    {t("MENU_OPTION_CLEAR_FILTERS")}
                  </Button>
                )}
              </>
            ),
          }}
          key="Id"
          columns={columns}
          dataSource={notificationLoader.data?.Entities || []}
          loading={notificationLoader.loading}
          pagination={false}
          onChange={handleTableChange}
        />
      </PageContent>
    </>
  );
};
