import React from "react";

export const Settings = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.5022 10C17.5022 10.34 17.4722 10.66 17.4322 10.98L19.5422 12.63C19.7322 12.78 19.7822 13.05 19.6622 13.27L17.6622 16.73C17.5422 16.95 17.2822 17.04 17.0522 16.95L14.5622 15.95C14.0422 16.34 13.4822 16.68 12.8722 16.93L12.4922 19.58C12.4622 19.82 12.2522 20 12.0022 20H8.00222C7.75222 20 7.54222 19.82 7.51222 19.58L7.13222 16.93C6.52222 16.68 5.96222 16.35 5.44222 15.95L2.95222 16.95C2.73222 17.03 2.46222 16.95 2.34222 16.73L0.34222 13.27C0.22222 13.05 0.27222 12.78 0.46222 12.63L2.57222 10.98C2.53222 10.66 2.50222 10.33 2.50222 10C2.50222 9.67 2.53222 9.34 2.57222 9.02L0.46222 7.37C0.27222 7.22 0.21222 6.95 0.34222 6.73L2.34222 3.27C2.46222 3.05 2.72222 2.96 2.95222 3.05L5.44222 4.05C5.96222 3.66 6.52222 3.32 7.13222 3.07L7.51222 0.42C7.54222 0.18 7.75222 0 8.00222 0H12.0022C12.2522 0 12.4622 0.18 12.4922 0.42L12.8722 3.07C13.4822 3.32 14.0422 3.65 14.5622 4.05L17.0522 3.05C17.2722 2.97 17.5422 3.05 17.6622 3.27L19.6622 6.73C19.7822 6.95 19.7322 7.22 19.5422 7.37L17.4322 9.02C17.4722 9.34 17.5022 9.66 17.5022 10ZM6.50222 10C6.50222 11.93 8.07222 13.5 10.0022 13.5C11.9322 13.5 13.5022 11.93 13.5022 10C13.5022 8.07 11.9322 6.5 10.0022 6.5C8.07222 6.5 6.50222 8.07 6.50222 10Z" />
  </svg>
);
