import { ICommonAppState } from "..";

export const profileSelector = (state: ICommonAppState) => {
  return state.user.profile;
};

export const usersSelector = (state: ICommonAppState) => {
  return state.user.users;
};

export const usersBrowserSelector = (state: ICommonAppState) => {
  return state.user.usersBrowser;
};
