import React from "react";
import {
  CellType,
  CellTypeHelper as CommonCellTypeHelper,
} from "@bms/common-services";
import { ChooseOption } from "@bms/common-ui";

export class CellTypeHelper extends CommonCellTypeHelper {
  static getOptions(): React.ReactElement[] {
    return [
      <ChooseOption value={CellType.Default}>
        {CellTypeHelper.getDescription(CellType.Default)}
      </ChooseOption>,
      <ChooseOption value={CellType.Highlights}>
        {CellTypeHelper.getDescription(CellType.Highlights)}
      </ChooseOption>,
      <ChooseOption value={CellType.HighlightsWidescreen}>
        {CellTypeHelper.getDescription(CellType.HighlightsWidescreen)}
      </ChooseOption>,
      <ChooseOption value={CellType.HeroLandscape}>
        {CellTypeHelper.getDescription(CellType.HeroLandscape)}
      </ChooseOption>,
      <ChooseOption value={CellType.Promo}>
        {CellTypeHelper.getDescription(CellType.Promo)}
      </ChooseOption>,
      <ChooseOption value={CellType.Cover}>
        {CellTypeHelper.getDescription(CellType.Cover)}
      </ChooseOption>,
      <ChooseOption value={CellType.Frame}>
        {CellTypeHelper.getDescription(CellType.Frame)}
      </ChooseOption>,
      <ChooseOption value={CellType.Square}>
        {CellTypeHelper.getDescription(CellType.Square)}
      </ChooseOption>,
      <ChooseOption value={CellType.Round}>
        {CellTypeHelper.getDescription(CellType.Round)}
      </ChooseOption>,
    ];
  }
}
