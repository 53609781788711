import {
  AssetContentType,
  AssetService,
  AssetType,
  ContentStatus,
  FileHelper,
  GuidHelper,
  IAssetContentModel,
  IAssetModel,
  ICommonAppState,
  ICompleteMultipartUploadFileModel,
  IErrorModel,
  OperationResultType,
  STORAGE,
  StorageService,
  StreamType,
  UploadType,
  UploadTypeHelper,
} from "@bms/common-services";
import {
  Choose,
  ChooseOption,
  Dragger,
  Form,
  Icon,
  IFormValues,
  IUploadChangeEvent,
  IUploadFile,
  Modal,
  NotificationService,
  required,
} from "@bms/common-ui";
import { BooleanHelper } from "@bms/studio/src/helpers";
import { takeRight } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormModal } from "../../../../components";

const assetService = new AssetService();
const storageService: StorageService = StorageService.getInstance();
const notificationService = NotificationService.getInstance();
const assetSelector = (state: ICommonAppState) => state.asset;

const MIN_MULTIPART_FILE_SIZE = 5_000_000;

const CONTENT_UPLOAD_DIRECT_ENABLED = BooleanHelper.toBool(
  process.env.REACT_APP_CONTENT_UPLOAD_DIRECT_ENABLED,
  true
);

const CONTENT_UPLOAD_TRANSCODING_ENABLED = BooleanHelper.toBool(
  process.env.REACT_APP_CONTENT_UPLOAD_TRANSCODING_ENABLED,
  true
);

const CREATE_ASSET_WITH_EXTERNAL_SOURCE =
  process.env.REACT_APP_CREATE_ASSET_WITH_EXTERNAL_SOURCE;

const anyUploadTypeDisabled =
  !CONTENT_UPLOAD_DIRECT_ENABLED || !CONTENT_UPLOAD_TRANSCODING_ENABLED;

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};
interface IAssetContentUploadModalProps {
  visible: boolean;
  asset?: IAssetModel;
  assetContent?: IAssetContentModel;
  onCancel?: () => void;
  onSuccess?: () => void;
}
interface IAssetContentUploadModalState {
  contentFile?: File;
  fileList: IUploadFile[];
  assetContentGuid?: string;
  ContentTypeCode?: AssetContentType;
  ContentStreamTypeCode?: string;
}

interface IProgressEvent {
  percent: number;
}

export const AssetContentUploadModal = ({
  visible,
  asset,
  assetContent,
  onCancel,
  onSuccess,
}: IAssetContentUploadModalProps) => {
  const { t } = useTranslation();
  const { assetContentTypes, assetContentStreamTypes } = useSelector(
    assetSelector
  );
  const [processing, setProcessing] = useState<boolean>(false);
  const [state, setState] = useState<IAssetContentUploadModalState>({
    contentFile: undefined,
    fileList: [],
    assetContentGuid: undefined,
  });
  const assetType = asset?.AssetTypeCode;
  const isAlbumOrPodcast =
    assetType === AssetType.Podcast || assetType === AssetType.Album;

  const [contentTypeState, setContentTypeState] = useState<string>(() => {
    if (isAlbumOrPodcast) {
      return AssetContentType.MP3;
    }

    if (CREATE_ASSET_WITH_EXTERNAL_SOURCE === "Vestigit") {
      return AssetContentType.MPD;
    }

    return AssetContentType.HLS;
  });

  useEffect(() => {
    form.setFieldsValue({
      AssetContentTypeCode: contentTypeState,
    });
  }, [contentTypeState]);

  const [uploadTypeState, setUploadTypeState] = useState<UploadType>(() => {
    if (isAlbumOrPodcast) {
      return UploadType.Direct;
    }

    if (CONTENT_UPLOAD_TRANSCODING_ENABLED && !CONTENT_UPLOAD_DIRECT_ENABLED) {
      return UploadType.Transcoding;
    }

    if (!CONTENT_UPLOAD_TRANSCODING_ENABLED && CONTENT_UPLOAD_DIRECT_ENABLED) {
      return UploadType.Direct;
    }

    return UploadType.Transcoding;
  });

  const [contentStreamTypeState, setContentStreamTypeState] = useState<
    string
  >();

  const abortControllerRef = useRef<AbortController>();
  const [form] = Form.useForm();

  const isAudio =
    asset?.AssetTypeCode === AssetType.Podcast ||
    asset?.AssetTypeCode === AssetType.Album;

  const filteredContentType = assetContentTypes.data?.filter((type) => {
    switch (asset?.AssetTypeCode) {
      case AssetType.Album:
      case AssetType.Podcast:
        return type.Code.includes("audio");
      case AssetType.Article:
        return type.Code.includes("text");
      default:
        return !type.Code.startsWith("text") && !type.Code.startsWith("audio");
    }
  });

  const contentExistsForSteamType =
    contentTypeState &&
    contentStreamTypeState &&
    (asset?.Contents?.find(
      (content) =>
        content.ContentTypeCode == contentTypeState &&
        content.StreamTypeCode == contentStreamTypeState
    ) ??
      false);

  const validateContentStreamTypeUniqueness = (
    _: any,
    value: string | number,
    callback: (error?: string) => void
  ) =>
    contentExistsForSteamType
      ? callback(t("CONTENT_STREAM_TYPE_EXISTS_FOR_CONTENT_TYPE"))
      : callback();

  useEffect(() => {
    if (contentTypeState && contentStreamTypeState) {
      form.validateFields(["AssetContentStreamTypeCode"]);
    }
  }, [contentTypeState, contentStreamTypeState]);

  const onChangeContent = (event: IUploadChangeEvent) => {
    const { fileList } = event;
    const latestFiles = takeRight(fileList);

    const fileType = isAudio ? "audio" : "video";
    const regex = new RegExp(`${fileType}\/.*`);
    if (latestFiles[0]?.type && !regex.test(latestFiles[0].type)) {
      notificationService.error({
        message: t("UPLOAD_ASSET_CONTENT_INVALID_TYPE"),
      });
      return;
    }

    if (latestFiles?.length > 0) {
      latestFiles[0].status = "done";
      if (latestFiles[0].originFileObj?.type) {
        setContentTypeState(latestFiles[0].originFileObj?.type);
      }
    }

    setState((oldState) => ({
      ...oldState,
      fileList: latestFiles,
    }));
  };

  const onUploadProgress = (event: IProgressEvent) => {
    const { fileList } = state;
    const latestFiles = takeRight(fileList);

    if (latestFiles?.length > 0) {
      latestFiles[0].percent = event.percent || 0;
      latestFiles[0].status =
        latestFiles[0].percent === 100 ? "success" : "uploading";
    }

    setState((oldState) => ({
      ...oldState,
      fileList: latestFiles,
    }));
  };

  const onUploadFailed = (error: any) => {
    console.log("Something went wron during uploading file.", error);
    const appError = error as IErrorModel;
    notificationService.error({
      message: t("UPLOAD_ASSET_CONTENT_FAILURE"),
      description: appError?.Message,
    });
    setProcessing(false);
  };

  const onUploadSuccess = () => {
    notificationService.success({
      message: t("UPLOAD_ASSET_CONTENT_SUCCESS"),
    });
    setProcessing(false);
    setState((oldState) => ({
      ...oldState,
      fileList: [],
      contentFile: undefined,
      assetContentGuid: undefined,
    }));
    onSuccess?.();
  };

  const uploadAssetContent = async (
    file: File,
    assetContent: IAssetContentModel
  ) => {
    try {
      if (!assetContent.AssetId || !assetContent.Guid) {
        return;
      }

      const assetId: number = assetContent.AssetId;
      const assetContentGuid: string = assetContent.Guid;
      const contentType = file.type;

      const uploadFileInfo = await assetService
        .getAssetContentUploadFileInfo({
          AssetId: assetId,
          AssetContentGuid: assetContentGuid,
          ContentType: contentType,
          UploadType: uploadTypeState,
        })
        .toPromise();

      assetContent.Path = uploadFileInfo.Path;

      abortControllerRef.current = new AbortController();

      storageService
        .uploadFile(
          file,
          uploadFileInfo,
          assetContentGuid,
          onUploadProgress,
          abortControllerRef.current
        )
        .then((result) => {
          if (result.ResultType === OperationResultType.Ok) {
            return assetService.addAssetContent(assetContent).toPromise();
          }
        })
        .then(() => {
          onUploadSuccess();
        })
        .catch((error) => {
          onUploadFailed(error);
        });
    } catch (error) {
      onUploadFailed(error);
    }
  };

  const uploadAssetContentMultipart = async (
    file: File,
    assetContent: IAssetContentModel
  ) => {
    try {
      if (!assetContent.AssetId || !assetContent.Guid) {
        return;
      }

      const assetId: number = assetContent.AssetId;
      const assetContentGuid: string = assetContent.Guid;
      const contentType = file.type; //"application/octet-stream",
      const chunkQuantity = storageService.getFileUploadChunksNumber(file);
      const uploadMultipartFileInfo = await assetService
        .getContentMultipartUploadFileInfo({
          AssetId: assetId,
          AssetContentGuid: assetContentGuid,
          ChunkQuantity: chunkQuantity,
          ContentType: contentType,
          UploadType: uploadTypeState,
        })
        .toPromise();

      assetContent.Path = uploadMultipartFileInfo.Path;

      abortControllerRef.current = new AbortController();

      storageService
        .uploadFileMultipart(
          file,
          uploadMultipartFileInfo,
          assetContentGuid,
          onUploadProgress,
          abortControllerRef.current
        )
        .then((result) => {
          if (result.ResultType === OperationResultType.Ok) {
            const completeMultipartUpload: ICompleteMultipartUploadFileModel = {
              AssetId: assetId,
              AssetContentGuid: assetContentGuid,
              UploadType: uploadTypeState,
              UploadId: uploadMultipartFileInfo.UploadId,
              ContentType: contentType,
              Parts: result.Result
                ? result.Result?.Parts.map((part) => {
                    return {
                      ETag: part.ETag,
                      Number: part.Number,
                    };
                  })
                : [],
            };

            return assetService
              .completeMultipartUploadFile(completeMultipartUpload)
              .toPromise();
          }
        })
        .then(() => {
          return assetService.addAssetContent(assetContent).toPromise();
        })
        .then(() => {
          onUploadSuccess();
        })
        .catch((error) => {
          const appError = error as IErrorModel;

          if (appError?.Code === STORAGE.UPLOAD_ABORT_ERROR_CODE) {
            assetService
              .abortMultipartUploadFile({
                AssetId: assetId,
                AssetContentGuid: assetContentGuid,
                UploadId: uploadMultipartFileInfo.UploadId,
                ContentType: contentType,
              })
              .toPromise();
          }

          onUploadFailed(error);
        });
    } catch (error) {
      onUploadFailed(error);
    }
  };

  const onFinish = async (values: IFormValues) => {
    const { contentFile, fileList, assetContentGuid } = state;
    const { AssetContentTypeCode, AssetContentStreamTypeCode } = values;

    if (!asset || !contentFile || !assetContentGuid) {
      return;
    }

    const duration = await FileHelper.getMediaDurationFile(contentFile);

    const assetContent: IAssetContentModel = {
      Guid: state.assetContentGuid,
      AssetId: asset?.Id,
      ContentTypeCode: AssetContentTypeCode,
      StreamTypeCode: AssetContentStreamTypeCode,
      ContentStatusCode:
        uploadTypeState === UploadType.Direct
          ? ContentStatus.Ready
          : ContentStatus.Queued,
      DurationMiliseconds: Math.floor(duration * 1000),
    };

    if (contentFile.size > MIN_MULTIPART_FILE_SIZE) {
      uploadAssetContentMultipart(contentFile, assetContent);
    } else {
      uploadAssetContent(contentFile, assetContent);
    }

    if (fileList.length) {
      setState((oldState) => ({
        ...oldState,
        ContentTypeCode: AssetContentTypeCode,
        ContentStreamTypeCode: AssetContentStreamTypeCode,
      }));
    }

    setProcessing(true);
  };
  const onBeforeContentFileUpload = (file: File) => {
    setState((oldState) => ({
      ...oldState,
      contentFile: file,
      assetContentGuid: GuidHelper.newGuid(),
    }));

    return true;
  };

  const onContentFileChange = (e: IUploadChangeEvent) => {
    return e && e.file && e.file.originFileObj;
  };

  const renderUpload = () => {
    return (
      <Form.Item
        key="ContentFile"
        name="ContentFile"
        valuePropName="ContentFile"
        getValueFromEvent={onContentFileChange}
      >
        <Dragger
          className="AssetContentModal__Dragger"
          name="Upload"
          multiple={false}
          showUploadList={{
            showRemoveIcon: true,
            showPreviewIcon: false,
            showDownloadIcon: false,
          }}
          accept={`${isAudio ? "audio" : "video"}/*`}
          beforeUpload={onBeforeContentFileUpload}
          fileList={state.fileList}
          onChange={onChangeContent}
          disabled={processing}
          progress={{
            strokeWidth: 10,
            format: (percent) =>
              percent && `${parseFloat(percent.toFixed(2))}%`,
          }}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="Inbox" />
          </p>
          <p className="ant-upload-text">{t("DRAG_AND_DROP_INFO")}</p>
        </Dragger>
      </Form.Item>
    );
  };

  const renderContentTypeField = () => {
    return (
      <Form.Item
        name="AssetContentTypeCode"
        label={t("ASSET_CONTENT_TYPE_LABEL")}
        key="contentType"
        initialValue={contentTypeState}
        {...formLayout}
      >
        <Choose
          placeholder={t("ASSET_CONTENT_TYPE_PLACEHOLDER")}
          loading={assetContentTypes.isFetching}
          disabled={
            isAlbumOrPodcast ||
            uploadTypeState === UploadType.Transcoding ||
            processing
          }
        >
          {filteredContentType &&
            filteredContentType.map((assetContentType) => (
              <ChooseOption
                key={assetContentType.Code}
                value={assetContentType.Code}
              >
                {assetContentType.DisplayName}
              </ChooseOption>
            ))}
        </Choose>
      </Form.Item>
    );
  };

  const renderUploadTypeField = () => {
    return (
      <Form.Item
        name="AssetUploadTypeCode"
        label={t("ASSET_UPLOAD_TYPE_LABEL")}
        key="uploadType"
        initialValue={uploadTypeState}
        {...formLayout}
      >
        <Choose
          placeholder={t("ASSET_UPLOAD_TYPE_PLACEHOLDER")}
          loading={assetContentTypes.isFetching}
          disabled={isAlbumOrPodcast || processing || anyUploadTypeDisabled}
          onChange={(value) => {
            const uploadType = UploadTypeHelper.getValue(value as string);

            switch (uploadType) {
              case UploadType.Transcoding:
                setContentTypeState(AssetContentType.HLS);
                setUploadTypeState(uploadType);
                break;
              case UploadType.Direct:
                setUploadTypeState(uploadType);
                break;
            }
          }}
        >
          {UploadTypeHelper.getOptions().map((option) => (
            <ChooseOption key={option?.value} value={option?.value ?? ""}>
              {option?.text}
            </ChooseOption>
          ))}
        </Choose>
      </Form.Item>
    );
  };

  const onCancelUploading = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setState((oldState) => ({
      ...oldState,
      fileList: [],
      contentFile: undefined,
      assetContentGuid: undefined,
    }));
    onCancel?.();
  };

  const onCloseModal = () => {
    if (processing) {
      Modal.confirm({
        title: t(
          "ASSET_CONTENT_UPLOAD_MODAL_CANCEL_UPLOADING_TITLE",
          "Cancel uploading"
        ),
        content: t(
          "ASSET_CONTENT_UPLOAD_MODAL_CANCEL_UPLOADING_MESSAGE",
          "Are you sure you want to cancel uploading?"
        ),
        okText: t("BUTTON_YES"),
        cancelText: t("BUTTON_NO"),
        onOk: onCancelUploading,
      });
    } else {
      onCancelUploading();
    }
  };

  const isContainingAssets =
    asset?.AssetTypeCode === AssetType.Album ||
    asset?.AssetTypeCode === AssetType.Season ||
    asset?.AssetTypeCode === AssetType.Series;

  const renderContentStreamTypeField = () => {
    return (
      <Form.Item
        name="AssetContentStreamTypeCode"
        label={t("ASSET_CONTENT_STREAM_TYPE_LABEL")}
        key="streamType"
        initialValue={
          isContainingAssets ? StreamType.Trial : assetContent?.StreamTypeCode
        }
        rules={[required(), { validator: validateContentStreamTypeUniqueness }]}
        {...formLayout}
      >
        <Choose
          placeholder={t("ASSET_CONTENT_STREAM_TYPE_PLACEHOLDER")}
          loading={assetContentStreamTypes.isFetching}
          disabled={processing || isContainingAssets}
          onChange={(value) => setContentStreamTypeState(value as string)}
        >
          {assetContentStreamTypes.data?.map((assetContentStreamType) => (
            <ChooseOption
              key={assetContentStreamType.Code}
              value={assetContentStreamType.Code}
            >
              {assetContentStreamType.DisplayName}
            </ChooseOption>
          ))}
        </Choose>
      </Form.Item>
    );
  };

  return (
    <FormModal
      isVisible={visible}
      isLoading={false}
      isNewForm={true}
      isDisabled={processing}
      isDeleteButtonEnabled={false}
      createFormTitle={t(
        "ASSET_CONTENT_UPLOAD_MODAL_TITLE",
        "Upload asset content"
      )}
      editFormTitle={t(
        "ASSET_CONTENT_UPLOAD_MODAL_TITLE",
        "Upload asset content"
      )}
      modalClassName="AssetContentModal"
      submitFormName="AssetContentUploadForm"
      onCloseModal={onCloseModal}
    >
      <Form
        form={form}
        name="AssetContentUploadForm"
        className="AssetContentForm"
        onFinish={onFinish}
      >
        {renderContentStreamTypeField()}
        {renderUploadTypeField()}
        {renderContentTypeField()}
        {renderUpload()}
      </Form>
    </FormModal>
  );
};
