import {
  ApplicationConfigurationStore,
  GuidHelper,
  IApplicationScreenModel,
  IApplicationScreenTypeModel,
  IErrorModel,
  IPlatformModel,
  PlatformTypeHelper,
  RecordStatus,
  ScreenType,
  ScreenTypeHelper,
} from "@bms/common-services";
import {
  Alert,
  Button,
  Choose,
  ChooseOption,
  Col,
  Form,
  IChooseValue,
  IFormInstance,
  IFormValues,
  Input,
  Modal,
  NotificationService,
  required,
  Row,
  TextArea,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { Action, ActionCreator } from "redux";
import { ROUTES } from "../../constants";
import "./ApplicationScreenAddModal.scss";

const notificationService = NotificationService.getInstance();

export interface IApplicationScreenAddModalStateProps {
  screen?: IApplicationScreenModel;
  actionType?: string;
  error?: IErrorModel;
  platforms: IPlatformModel[];
  screensTypes: IApplicationScreenTypeModel[];
  isProcessingData: boolean;
}

export interface IApplicationScreenAddModalDispatchProps {
  insertScreen: ActionCreator<Action>;
  selectScreensTypes: ActionCreator<Action>;
  selectPlatforms: ActionCreator<Action>;
}

export interface IApplicationScreenAddModalOwnProps {
  configurationId: number;
  visible: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export interface IApplicationScreenAddModalProps
  extends IApplicationScreenAddModalStateProps,
    IApplicationScreenAddModalDispatchProps,
    IApplicationScreenAddModalOwnProps,
    WithTranslation {}

export interface IApplicationScreenAddModalState {
  selectedComponentTypeCode: string | undefined;
}

export class ApplicationScreenAddModal extends React.PureComponent<
  IApplicationScreenAddModalProps,
  IApplicationScreenAddModalState
> {
  public static defaultProps = {
    visible: false,
  };

  formRef = React.createRef<IFormInstance>();

  public componentDidMount() {
    const {
      screensTypes,
      platforms,
      selectScreensTypes,
      selectPlatforms,
    } = this.props;

    if (!screensTypes || screensTypes.length === 0) {
      selectScreensTypes();
    }

    if (!platforms || platforms.length === 0) {
      selectPlatforms();
    }
  }

  componentDidUpdate(prevProps: IApplicationScreenAddModalProps) {
    const { actionType, error, t } = this.props;

    if (prevProps.actionType === actionType) {
      return;
    }

    switch (actionType) {
      case ApplicationConfigurationStore.Consts
        .INSERT_APPLICATION_SCREEN_FAILURE:
        return notificationService.error({
          message: t("APPLICATION_SCREEN__ADD_SCREEN_FAILURE"),
          description: error?.Message,
        });
      case ApplicationConfigurationStore.Consts
        .INSERT_APPLICATION_SCREEN_SUCCESS:
        return notificationService.success({
          message: t("APPLICATION_SCREEN__ADD_SCREEN_SUCCESS"),
        });
      default:
        break;
    }
  }

  public onCancelClick = () => {
    const { onCancel } = this.props;

    this.formRef?.current?.resetFields();

    if (onCancel) {
      onCancel();
    }
  };

  public onFinish = (values: IFormValues) => {
    const { insertScreen, configurationId, platforms } = this.props;
    const screenTypeCode =
      ScreenTypeHelper.getValue(values.ScreenTypeCode.value) ??
      ScreenType.Custom;

    const screen: IApplicationScreenModel = {
      Id: -1,
      ApplicationConfigurationId: configurationId,
      Guid: GuidHelper.newGuid(),
      Name: values.Name,
      Description: values.Description,
      ScreenTypeCode: screenTypeCode,
      RecordStatus: RecordStatus.Inserted,
    };

    if (values.Platforms) {
      const selectedPlatforms = platforms.filter((row) =>
        values.Platforms.includes(row.Code)
      );

      if (selectedPlatforms && selectedPlatforms.length > 0) {
        screen.Platforms = selectedPlatforms.map((row) => {
          return {
            ApplicationScreenId: screen.Id,
            PlatformCode: PlatformTypeHelper.getValue(row.Code),
            RecordStatus: RecordStatus.Inserted,
          };
        });
      }
    }

    insertScreen(screen);
  };

  public onScreenTypeChange = (value: IChooseValue) => {
    // @ts-ignore
    const { key, label } = value;

    if (label) {
      this.formRef?.current?.setFieldsValue({
        Name: label,
      });
    }
  };

  public renderScreenTypeField = () => {
    const { isProcessingData, t, screensTypes } = this.props;

    let options: React.ReactElement[] = [];

    if (screensTypes) {
      options = screensTypes
        .filter((screenType) => ScreenTypeHelper.instanceable(screenType.Code))
        .map((screenType) => (
          <ChooseOption key={screenType.Code} value={screenType.Code}>
            {screenType.Name}
          </ChooseOption>
        ));
    }

    return (
      <Form.Item
        name="ScreenTypeCode"
        label={t("MODEL_TYPE_CODE")}
        key="ScreenTypeCode"
        rules={[{ required: true, message: t("SELECT_TYPE_MESSAGE") }]}
      >
        <Choose
          labelInValue={true}
          defaultActiveFirstOption={false}
          filterOption={false}
          placeholder={t("MODEL_TYPE_PLACEHOLDER")}
          loading={isProcessingData}
          onChange={this.onScreenTypeChange}
        >
          {options}
        </Choose>
      </Form.Item>
    );
  };

  public renderNameField = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item name="Name" label={t("MODEL_NAME")} rules={[required()]}>
        <Input
          disabled={isProcessingData}
          placeholder={t("MODEL_NAME_PLACEHOLDER")}
        />
      </Form.Item>
    );
  };

  public renderDescriptionField = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item name="Description" label={t("MODEL_DESCRIPTION")}>
        <TextArea
          disabled={isProcessingData}
          placeholder={t("MODEL_DESCRIPTION_PLACEHOLDER")}
        />
      </Form.Item>
    );
  };

  public renderPlatformsField = () => {
    const { isProcessingData, t, platforms } = this.props;

    const options: React.ReactElement[] = [];

    for (const platform of platforms) {
      options.push(
        <ChooseOption key={platform.Code} value={platform.Code}>
          {platform.DisplayName}
        </ChooseOption>
      );
    }

    return (
      <Form.Item name="Platforms" label={t("MODEL_PLATFORMS")} key="Director">
        <Choose
          mode="multiple"
          placeholder={t("MODEL_PLATFORMS_PLACEHOLDER")}
          loading={isProcessingData}
        >
          {options}
        </Choose>
      </Form.Item>
    );
  };

  public renderError = () => {
    const { error, actionType, t } = this.props;

    const formItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
        md: {
          span: 16,
          offset: 8,
        },
        lg: {
          span: 16,
          offset: 8,
        },
      },
    };

    if (
      actionType ===
        ApplicationConfigurationStore.Consts
          .INSERT_APPLICATION_SCREEN_FAILURE &&
      error
    ) {
      return (
        <Form.Item {...formItemLayout}>
          <Alert
            message={t("APPLICATION_SCREEN__ADD_SCREEN_FAILURE")}
            description={error.Message}
            type="error"
            closable
            showIcon
          />
        </Form.Item>
      );
    }

    return null;
  };

  public render() {
    const { t, visible, actionType, onSuccess, screen } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 8 },
        lg: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 16 },
        lg: { span: 16 },
      },
    };

    if (
      actionType ===
      ApplicationConfigurationStore.Consts.INSERT_APPLICATION_SCREEN_SUCCESS
    ) {
      this.formRef?.current?.resetFields();

      if (onSuccess) {
        onSuccess();
      }

      if (screen) {
        return (
          <Redirect
            push={true}
            to={`${ROUTES.CONFIGURATION_SCREEN_DETAILS}/${screen.Id}`}
          />
        );
      }
    }

    return (
      <Modal
        title={t("APPLICATION_CONFIGURATION_SCREENS__ADD_SCREEN")}
        onCancel={this.onCancelClick}
        visible={visible}
        footer={
          <>
            <Button key="cancel" onClick={this.onCancelClick}>
              {t("BUTTON_CANCEL")}
            </Button>
            <Button
              form="ApplicationScreenAddForm"
              key="submit"
              htmlType="submit"
              type="primary"
            >
              {t("BUTTON_SAVE")}
            </Button>
          </>
        }
      >
        <Form
          name="ApplicationScreenAddModal"
          id="ApplicationScreenAddForm"
          {...formItemLayout}
          className="ApplicationScreenAddModal"
          onFinish={this.onFinish}
          ref={this.formRef}
        >
          <Row
            direction="column"
            justify="space-between"
            className="full-height"
          >
            <Col>
              {this.renderScreenTypeField()}
              {this.renderNameField()}
              {this.renderDescriptionField()}
              {this.renderPlatformsField()}
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}
