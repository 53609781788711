import React, { useContext } from "react";
import { Row } from "../Row";
import { Col } from "../Col";
import cx from "classnames";
import { CardGridContext } from "./CardGridContext";
import { Button, ButtonType } from "../Button";
import { Link } from "../Link";

interface DetailsGridItemProps {
  header?: string;
  headerAction?: React.ReactNode;
  highlighted?: boolean;
  span?: number;
  footer?: React.ReactElement;
  footerLink?: {
    icon?: React.ReactNode;
    href: string;
    title: React.ReactNode;
    type?: ButtonType;
  };
}

export const CardGridItem: React.FC<DetailsGridItemProps> = ({
  children,
  header,
  headerAction,
  footer,
  footerLink,
  highlighted,
  span = 1,
}) => {
  const { cardHeight, cardWidth } = useContext(CardGridContext);
  const width = span * cardWidth + span * 32;
  return (
    <Col
      style={{
        minWidth: `${width}px`,
        width: `${width}px`,
        height: `${cardHeight}px`,
      }}
    >
      <div
        className={cx(
          "card-grid-item",
          highlighted && "card-grid-item-highlighted"
        )}
      >
        <Row direction="column" style={{ height: "100%", flexWrap: "nowrap" }}>
          {header && (
            <Col flex="0 0 auto" className="card-grid-item-headerbox">
              <Row justify="space-between" align="middle">
                <Col>
                  <h2 className="card-grid-item-headerbox-header">{header}</h2>
                </Col>
                <Col>{headerAction}</Col>
              </Row>
            </Col>
          )}
          <Col flex="1 1 auto" className="card-grid-item-content">
            {children}
          </Col>
          {footer && (
            <Col flex="0 0 auto" className="card-grid-item-footer">
              {footer}
            </Col>
          )}
          {footerLink && (
            <Col flex="0 0 auto" className="card-grid-item-footer">
              <Link to={footerLink.href}>
                <Button
                  type={highlighted ? "primary" : footerLink.type ?? "default"}
                >
                  <Row gutter={8}>
                    <Col>{footerLink.title}</Col>
                    <Col>{footerLink.icon}</Col>
                  </Row>
                </Button>
              </Link>
            </Col>
          )}
        </Row>
      </div>
    </Col>
  );
};
