import {
  IPaymentModel,
  IPaymentErrorStatusModel,
  IPaymentSearchFilterModel,
  IErrorModel,
  IPaymentListModel,
} from "../../models";
import { PaymentActionsTypes } from "./types";
import { Types, Consts } from ".";

export const getPayments = (key: string): PaymentActionsTypes => {
  return {
    key,
    type: Consts.GET_PAYMENT_STATUS,
  };
};

export const getPaymentStatusSuccess = (
  data: IPaymentModel
): PaymentActionsTypes => {
  return {
    payload: data,
    type: Consts.GET_PAYMENT_STATUS_SUCCESS,
  };
};

export const getPaymentsStatusFailure = (
  error?: IPaymentErrorStatusModel
): PaymentActionsTypes => {
  return {
    error,
    type: Consts.GET_PAYMENT_STATUS_FAILURE,
  };
};

export const searchPayment = (
  filter: IPaymentSearchFilterModel
): Types.ISearchPaymentAction => {
  return {
    filter,
    type: Consts.SEARCH_PAYMENT,
  };
};

export const searchPaymentSuccess = (
  data: IPaymentListModel
): Types.ISearchPaymentSuccessAction => {
  return {
    payload: data,
    type: Consts.SEARCH_PAYMENT_SUCCESS,
  };
};

export const searchPaymentFailure = (
  error?: IErrorModel
): Types.ISearchPaymentErrorAction => {
  return {
    error,
    type: Consts.SEARCH_PAYMENT_FAILURE,
  };
};

export const PaymentActions = {
  getPaymentStatusSuccess,
  getPayments,
  getPaymentsStatusFailure,
  searchPayment,
};
