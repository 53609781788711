import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Choose,
  ChooseOption,
  Col,
  Dragger,
  Form,
  Icon,
  IFormValues,
  Input,
  IUploadChangeEvent,
  IUploadFile,
  Link,
  NotificationService,
  required,
  Row,
  Spin,
  Switch,
  TextArea,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import {
  AssetContentType,
  ConsentService,
  GuidHelper,
  ICompleteMultipartUploadConsentModel,
  IConsentModel,
  IErrorModel,
  IResourceModel,
  OperationResultType,
  STORAGE,
  StorageService,
} from "@bms/common-services";
import * as TranslationsModule from "../../../Translations";
import { takeRight } from "lodash";

export interface IDictionaryConsentFormProps {
  isNewConsent: boolean;
  isProcessing: boolean;
  consent: IConsentModel;
  onSuccess: () => void;
}

interface IProgressEvent {
  percent: number;
}

interface IAssetContentUploadModalState {
  consentFile?: File;
  fileList: IUploadFile[];
  assetContentGuid?: string;
  ContentTypeCode?: AssetContentType;
  ContentStreamTypeCode?: string;
}

const storageService: StorageService = StorageService.getInstance();
const notificationService = NotificationService.getInstance();
const consentService = new ConsentService();

export const DictionaryConsentForm: React.FC<IDictionaryConsentFormProps> = (
  props
) => {
  const { consent, isNewConsent, isProcessing } = props;
  const [translationsBrowserVisible, setTranslationsBrowserVisible] = useState<
    boolean
  >(false);
  const [nameTranslationKey, setNameTranslationKey] = useState<string>(
    consent.NameTranslationKey || ""
  );
  const [processing, setProcessing] = useState<boolean>(false);
  const abortControllerRef = useRef<AbortController>();
  const [state, setState] = useState<IAssetContentUploadModalState>({
    consentFile: undefined,
    fileList: [],
    assetContentGuid: undefined,
  });
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
  }, []);

  const onTitleTranslationKeyCancel = () =>
    setTranslationsBrowserVisible(false);

  const onTitleTranslationKeySelect = (row: IResourceModel) => {
    setNameTranslationKey(row.ResourceKey);
    setTranslationsBrowserVisible(false);
  };

  const onContentFileChange = (e: IUploadChangeEvent) => {
    return e && e.file && e.file.originFileObj;
  };

  const onBeforeContentFileUpload = (file: File) => {
    setState((oldState) => ({
      ...oldState,
      consentFile: file,
      assetContentGuid: GuidHelper.newGuid(),
    }));

    return true;
  };

  const onUploadProgress = (event: IProgressEvent) => {
    const { fileList } = state;
    const latestFiles = takeRight(fileList);

    if (latestFiles?.length > 0) {
      latestFiles[0].percent = event.percent || 0;
      latestFiles[0].status =
        latestFiles[0].percent === 100 ? "success" : "uploading";
    }

    setState((oldState) => ({
      ...oldState,
      fileList: latestFiles,
    }));
  };

  const onUploadFailed = async (error: any, consentContent: IConsentModel) => {
    const appError = error as IErrorModel;
    notificationService.error({
      message: t("UPLOAD_CONSENT_CONTENT_FAILURE"),
      description: appError?.Message,
    });

    if (isNewConsent) {
      await consentService.delete(consentContent).toPromise();
    }

    setProcessing(false);
  };

  const onUploadSuccess = async (consentContent: IConsentModel) => {
    setProcessing(false);
    setState((oldState) => ({
      ...oldState,
      fileList: [],
      contentFile: undefined,
      assetContentGuid: undefined,
    }));
    await consentService.update(consentContent).toPromise();
    props.onSuccess();
    notificationService.success({
      message: t("UPLOAD_CONSENT_CONTENT_SUCCESS"),
    });
  };

  const onChangeContent = (event: IUploadChangeEvent) => {
    const { fileList, file } = event;
    const latestFiles = takeRight(fileList);

    if (latestFiles?.length > 0) {
      latestFiles[0].status = "done";
    }

    form.setFieldsValue({ ContentType: file.type });

    setState((oldState) => ({
      ...oldState,
      fileList: latestFiles,
    }));
  };

  const uploadAssetContentMultipart = async (
    file: File,
    consentContent: IConsentModel
  ) => {
    try {
      if (!consentContent.RecordStatus) {
        consentContent = await consentService
          .insert(consentContent)
          .toPromise();
      }
      const consentId: number = consentContent.Id;
      const consentContentGuid = consentContent.Guid;
      const contentType = file.type;
      const chunkQuantity = storageService.getFileUploadChunksNumber(file);
      const uploadMultipartFileInfo = await consentService
        .getContentMultipartUploadConsentInfo({
          ConsentId: consentId,
          ChunkQuantity: chunkQuantity,
          ContentType: contentType,
        })
        .toPromise();

      consentContent.ContentUrl = uploadMultipartFileInfo.Path;

      abortControllerRef.current = new AbortController();

      storageService
        .uploadFileMultipart(
          file,
          uploadMultipartFileInfo,
          consentContentGuid,
          onUploadProgress,
          abortControllerRef.current
        )
        .then((result) => {
          if (result.ResultType === OperationResultType.Ok) {
            const completeMultipartUpload: ICompleteMultipartUploadConsentModel = {
              ConsentId: consentId,
              UploadId: uploadMultipartFileInfo.UploadId,
              ContentType: contentType,
              Parts: result.Result
                ? result.Result?.Parts.map((part) => {
                    return {
                      ETag: part.ETag,
                      Number: part.Number,
                    };
                  })
                : [],
            };

            return consentService
              .completeMultipartUploadConsent(completeMultipartUpload)
              .toPromise();
          }
        })
        .then(() => {
          onUploadSuccess(consentContent);
        })
        .catch((error) => {
          const appError = error as IErrorModel;

          if (appError?.Code === STORAGE.UPLOAD_ABORT_ERROR_CODE) {
            consentService
              .abortMultipartUploadConsent({
                ConsentId: consentId,
                UploadId: uploadMultipartFileInfo.UploadId,
              })
              .toPromise();
          }

          onUploadFailed(error, consentContent);
        });
    } catch (error) {
      onUploadFailed(error, consentContent);
    }
  };

  const updateConsent = async (consentContent: IConsentModel) => {
    try {
      await consentService.update(consentContent).toPromise();
      notificationService.success({
        message: t("CONSENT_UPDATE_SUCCESS"),
      });
      props.onSuccess();
    } catch (error) {
      notificationService.error({
        message: t("CONSENT_UPDATE_FAILURE"),
      });
    }
    setProcessing(false);
  };

  const insertWithoutUpload = async (consentContent: IConsentModel) => {
    try {
      await consentService.insert(consentContent).toPromise();
      notificationService.success({
        message: t("CONSENT_INSERT_SUCCESS"),
      });
      props.onSuccess();
    } catch (error) {
      notificationService.error({
        message: t("CONSENT_INSERT_FAILURE"),
      });
    }
    setProcessing(false);
  };

  const onFinish = async (values: IFormValues) => {
    delete values.ConsentFile;
    const { consentFile, assetContentGuid } = state;

    const consentContent: IConsentModel = {
      ...consent,
      ...values,
      Guid: assetContentGuid,
      NameTranslationKey: nameTranslationKey || undefined,
      Sequence: parseInt(values.Sequence, 10),
    };

    setProcessing(true);

    if (isNewConsent) {
      delete consentContent.Sequence;
      if (consentFile) {
        await uploadAssetContentMultipart(consentFile, consentContent);
      } else {
        await insertWithoutUpload(consentContent);
      }
    } else {
      if (consentFile) {
        await uploadAssetContentMultipart(consentFile, consentContent);
      } else {
        await updateConsent(consentContent);
      }
    }
  };

  const renderSequenceField = () => {
    if (!isNewConsent) {
      return (
        <Form.Item
          name="Sequence"
          label={t("MODEL_SEQUENCE")}
          rules={[required()]}
        >
          <Input placeholder={t("MODEL_SEQUENCE")} />
        </Form.Item>
      );
    }
  };

  const renderNameTranslationKey = () => {
    let titleTranslationKeyView: React.ReactElement = nameTranslationKey ? (
      <label>
        <Link
          to={`${TranslationsModule.ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${nameTranslationKey}`}
        >
          {nameTranslationKey}
        </Link>
      </label>
    ) : (
      <label>{t("MODEL_TRANSLATION_KEY_PLACEHOLDER")}</label>
    );

    return (
      <Form.Item
        name="NameTranslationKey"
        label={t("DICTIONARY_CONSENT_FORM__NAME_TRANSLATION_KEY")}
        style={{ marginBottom: 0 }}
      >
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item>
              {titleTranslationKeyView}
              <Input
                hidden
                value={nameTranslationKey}
                placeholder={t(
                  "DICTIONARY_CONSENT_FORM__TRANSLATION_KEY_PLACEHOLDER"
                )}
              />
            </Form.Item>
            <TranslationsModule.Components.TranslationsBrowserModal
              key={`TranslationsBrowser-${consent.Id}`}
              visible={translationsBrowserVisible}
              onCancel={onTitleTranslationKeyCancel}
              onSelect={onTitleTranslationKeySelect}
            />
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {nameTranslationKey && (
              <Button
                icon={<Icon type="delete" />}
                onClick={() => {
                  setNameTranslationKey("");
                }}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                setTranslationsBrowserVisible(true);
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  const renderTypeSelector = () => {
    const types = {
      "text/html": "HTML",
      "application/pdf": "PDF",
    };

    return (
      <Form.Item
        name="ContentType"
        key="ContentType"
        label={t("MODEL_CONTENT_TYPE", "Content Type")}
      >
        <Choose
          placeholder={t("MODEL_CONTENT_TYPE", "Enter content type")}
          allowClear
        >
          {Object.entries(types).map((type) => (
            <ChooseOption key={type[0]} value={type[0]}>
              {type[1]}
            </ChooseOption>
          ))}
        </Choose>
      </Form.Item>
    );
  };

  const renderUpload = () => {
    return (
      <Form.Item
        key="ConsentFile"
        name="ConsentFile"
        valuePropName="ConsentFile"
        getValueFromEvent={onContentFileChange}
      >
        <Dragger
          className="AssetContentModal__Dragger"
          name="Upload"
          multiple={false}
          showUploadList={{
            showRemoveIcon: true,
            showPreviewIcon: false,
            showDownloadIcon: false,
          }}
          accept={".pdf,.html"}
          beforeUpload={onBeforeContentFileUpload}
          fileList={state.fileList}
          onChange={onChangeContent}
          disabled={processing}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="Inbox" />
          </p>
          <p className="ant-upload-text">{t("DRAG_AND_DROP_INFO")}</p>
        </Dragger>
      </Form.Item>
    );
  };

  return (
    <Spin spinning={isProcessing || processing}>
      <Form
        name="DictionaryConsentForm"
        className="DictionaryConsentForm"
        form={form}
        initialValues={{ ...consent, Sequence: `${consent.Sequence}` }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            <Form.Item
              name="Code"
              label={t("MODEL_CODE", "Code")}
              rules={[required()]}
            >
              <Input placeholder={t("MODEL_CODE", "Code")} />
            </Form.Item>

            <Form.Item
              name="Name"
              label={t("MODEL_NAME", "Name")}
              rules={[required()]}
            >
              <Input placeholder={t("MODEL_NAME", "Name")} />
            </Form.Item>

            {renderNameTranslationKey()}

            <Form.Item
              name="ContentUrl"
              label={t("MODEL_CONTENT_URL", "Content Url")}
              key="ContentUrl"
            >
              <Input
                placeholder={t("MODEL_CONTENT_URL", "Content Url")}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  form.setFieldsValue({
                    ContentType: event.target.value ? "text/html" : undefined,
                  })
                }
              />
            </Form.Item>

            {renderTypeSelector()}

            {renderUpload()}

            <Form.Item
              name="Description"
              label={t("MODEL_DESCRIPTION", "Description")}
            >
              <TextArea
                placeholder={t("MODEL_DESCRIPTION", "Description")}
                autoSize={{ minRows: 4 }}
              />
            </Form.Item>

            {renderSequenceField()}

            <Form.Item>
              <Row gutter={8}>
                <Col>
                  <Form.Item name="Required" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="Required">
                    <label>{t("MODEL_REQUIRED", "Required")}</label>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item>
              <Row gutter={8}>
                <Col>
                  <Form.Item name="UpToDate" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="UpToDate">
                    <label>{t("MODEL_UP_TO_DATE", "Up to date")}</label>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
