import {
  AssetContentType,
  AssetImageType,
  AssetService,
  ContentStatus,
  GuidHelper,
  IAssetContentModel,
  IAssetImageModel,
  IAssetModel,
  IErrorModel,
  PlatformType,
  RecordStatus,
  StreamType,
  UploadFileInfoModel,
} from "@bms/common-services";
import {
  Button,
  Heading,
  NotificationService,
  TextEditor,
} from "@bms/common-ui";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import "./AssetContentHtml.scss";

interface IAssetContentHtmlProps {
  asset?: IAssetModel;
  refreshAsset: () => void;
}

const assetService = new AssetService();
const notificationService = NotificationService.getInstance();

export const AssetContentHtml = ({
  asset,
  refreshAsset,
}: IAssetContentHtmlProps) => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean | undefined>(undefined);
  const contentHtml = useMemo(() => {
    if (asset?.Contents && asset?.Contents?.length > 0) {
      return asset.Contents.find(
        (content) => content.ContentTypeCode === AssetContentType.HTML
      );
    }

    return null;
  }, [asset]);

  const [textEditorValue, setTextEditorValue] = useState<string>(
    contentHtml?.Content || ""
  );

  const onFinish = async () => {
    if (!asset) {
      return;
    }
    try {
      setProcessing(true);
      const assetContent: IAssetContentModel = contentHtml
        ? {
            ...contentHtml,
            Content: textEditorValue,
            RecordStatus: RecordStatus.Updated,
          }
        : {
            Guid: GuidHelper.newGuid(),
            AssetId: asset?.Id,
            ContentTypeCode: AssetContentType.HTML,
            StreamTypeCode: StreamType.Main,
            ContentStatusCode: ContentStatus.Ready,
            Content: textEditorValue,
            RecordStatus: RecordStatus.Inserted,
          };

      if (assetContent.RecordStatus === RecordStatus.Inserted) {
        await assetService.addAssetContent(assetContent).toPromise();
      } else {
        await assetService.updateAssetContent(assetContent).toPromise();
      }

      setProcessing(false);
      setHasChanges(false);
      notificationService.success({
        message: t("ASSET_CONTENT_SAVE_SUCCESS"),
      });
      refreshAsset();
    } catch (err) {
      const error = err as IErrorModel;
      notificationService.error({
        message: t("ASSET_CONTENT_SAVE_FAILURE"),
        description: error?.Message,
      });
      setProcessing(false);
    }
  };

  const onUploadImageSucess = () => {
    notificationService.success({
      message: t("ASSET_IMAGE_UPLOAD_SUCESS"),
    });
  };

  const onUploadImageFailed = (error: any) => {
    const err = error as IErrorModel;

    notificationService.error({
      message: t("ASSET_IMAGE_UPLOAD_FAILURE"),
      description: err.Message,
    });
  };

  const onImageUpload = async (file: File) => {
    if (asset) {
      try {
        const uploadFileInfo: UploadFileInfoModel = await assetService
          .getUploadFileInfo(asset.Id)
          .toPromise();

        const image = new Image();
        image.src = URL.createObjectURL(file);

        await assetService
          .uploadAssetContentFile(uploadFileInfo!, file)
          .toPromise();

        const assetImageToSave: IAssetImageModel = {
          AssetId: asset?.Id,
          PlatformCode: PlatformType.Any,
          PlatformDisplayName: PlatformType.Any,
          Path: uploadFileInfo!.AbsolutePath,
          AssetImageTypeCode: AssetImageType.Content,
          AssetImageTypeDisplayName: AssetImageType.Content,
          Width: image.width,
          Height: image.height,
        };

        await assetService.addAssetImage(assetImageToSave).toPromise();

        onUploadImageSucess();

        return uploadFileInfo!.AbsolutePath;
      } catch (error) {
        onUploadImageFailed(error);
      }
    }
    return;
  };

  return (
    <div className="AssetContentHtml">
      <Heading
        title={t("ASSET_CONTENT_HTML_TITLE")}
        actions={
          <Button
            type="primary"
            htmlType="submit"
            disabled={processing || !hasChanges}
            onClick={onFinish}
          >
            {t("BUTTON_SAVE")}
          </Button>
        }
      />
      <TextEditor
        key={`text-editor-content-${asset?.Id}`}
        value={contentHtml?.Content || ""}
        onImageUpload={onImageUpload}
        className="AssetContentHtml__textEditor"
        addImage={true}
        onChange={(value) => {
          if (!hasChanges) {
            setHasChanges(hasChanges === undefined ? false : true);
          }
          setTextEditorValue(value);
        }}
        addTable={true}
      />
    </div>
  );
};
