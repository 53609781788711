import React from "react";

export const GradientInputBorder = () => (
  <svg className="gm-gradient-input-border" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="border" x1="0%" y1="54%" x2="100%" y2="46%">
        <stop stopColor="#54297B" offset="0%" />
        <stop stopColor="#00FBFB" offset="40%" />
        <stop stopColor="#FD00FF" offset="100%" />
      </linearGradient>
    </defs>
    <rect
      x="1"
      y="1"
      height="100%"
      width="100%"
      style={{ width: "calc(100% - 2px)", height: "calc(100% - 2px)" }}
      rx="25"
      ry="25"
      strokeWidth="2"
      fill="transparent"
      stroke="url(#border)"
    />
  </svg>
);
