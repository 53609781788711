import React from "react";
import {
  AssetPurchasePeriodTypeService,
  IAssetPurchasePeriodTypeModel,
  useDataLoader,
} from "@bms/common-services";
import {
  Checkbox,
  IBreadcrumbProps,
  ITableColumnProps,
  Link,
  NotificationService,
  PageContent,
  PageHeader,
  Table,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import * as TranslationsModule from "../../../Translations";
import { generateBreadcrumb } from "../../../../helpers";

const notificationService = NotificationService.getInstance();
const purchasePeriodTypeService = new AssetPurchasePeriodTypeService().promisify();
import { ROUTES } from "../../../../modules/Dictionaries/constants";

export const DictionaryAssetPurchasePeriodTypeList: React.FC = () => {
  const { t } = useTranslation();

  const assetsInCollectionLoader = useDataLoader({
    loader: () => purchasePeriodTypeService.select(),
    onError: (error) => {
      notificationService.error({
        message: t("DATA_LOADING_FAILURE"),
        description: error?.Message,
      });
    },
    deps: [],
  });

  const columns: Array<ITableColumnProps<IAssetPurchasePeriodTypeModel>> = [
    {
      key: "Code",
      dataIndex: "Code",
      title: t("MODEL_CODE"),
    },
    {
      key: "DisplayName",
      dataIndex: "DisplayName",
      title: t("MODEL_DISPLAY_NAME"),
    },
    {
      key: "TranslationKey",
      dataIndex: "TranslationKey",
      title: t("MODEL_TRANSLATION_KEY"),
      render: (text: any, row: IAssetPurchasePeriodTypeModel) => {
        const route = `${TranslationsModule.ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${row.TranslationKey}`;

        return row.TranslationKey ? (
          <label>
            <Link to={route}>{row.TranslationKey}</Link>
          </label>
        ) : (
          ""
        );
      },
    },
    {
      key: "Renewal",
      dataIndex: "Renewal",
      title: t("MODEL_RENEWAL"),
      render: (text, record) => (
        <Checkbox checked={record.Renewal} disabled={true} />
      ),
    },
  ];

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.DICTIONARY_BASE}`,
        breadcrumbName: t("MENU_ADMINISTRATION_DICTIONARIES"),
      },
      {
        path: `${ROUTES.DICTIONARY_ASSET_PURCHASE_PERIOD_TYPE_LIST}`,
        breadcrumbName: t("PURCHASE_PERIOD_TYPE_TABLE_TITLE"),
      },
    ]);
  };

  return (
    <PageContent>
      <PageHeader
        title={t("PURCHASE_PERIOD_TYPE_TABLE_TITLE")}
        breadcrumb={getBreadcrumbProps()}
      />
      <Table<IAssetPurchasePeriodTypeModel>
        rowKey="Sequence"
        columns={columns}
        dataSource={assetsInCollectionLoader.data}
        loading={assetsInCollectionLoader.loading}
        pagination={false}
      />
    </PageContent>
  );
};
