import {
  ComponentType,
  IApplicationComponentLayoutModel,
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  PropertyType,
  RecordStatus,
} from "@bms/common-services";
import { ComponentLayoutPropertyModel } from "./ComponentLayoutPropertyModel";
import { ComponentPropertyModel } from "./ComponentPropertyModel";
import { LayoutOptionsModel } from "./LayoutOptionsModel";

export class ApplicationFooterItemComponentPropertyModel extends ComponentPropertyModel {
  Title?: string;
  IconUrl?: string;
  BackgroundUrl?: string;
  Columns?: number;
  ItemsAlignment?: string;

  constructor(component?: IApplicationComponentModel) {
    super();

    if (component) {
      this.init(component);
    }
  }

  public getLayoutProperty(
    component: IApplicationComponentModel,
    layoutOptions: LayoutOptionsModel
  ): ComponentLayoutPropertyModel {
    if (!component.Layouts) {
      component.Layouts = [];
    }

    let componentLayout = component.Layouts.find(
      (row: IApplicationComponentLayoutModel) =>
        row.PlatformCode === layoutOptions.Platform
    );

    if (!componentLayout) {
      componentLayout = {
        RecordStatus: RecordStatus.Inserted,
        ApplicationComponentId: component.Id,
        PlatformCode: layoutOptions.Platform,
        PositionX: layoutOptions.CurrentPositionX,
        PositionY: 0,
        Width: 3,
        Height: 2,
      };

      component.Layouts.push(componentLayout);
    } else {
      componentLayout = {
        ...componentLayout,
        ...{
          Width: 3,
          Height: 2,
          PositionY: 0,
          PositionX: layoutOptions.CurrentPositionX,
        },
      };
    }

    return {
      PositionX: componentLayout.PositionX,
      PositionY: componentLayout.PositionY,
      Width: componentLayout.Width,
      Height: componentLayout.Height,
      IsResizable: true,
      IsDraggable: false,
    };
  }

  initProperties(component: IApplicationComponentModel): void {
    if (!component.Properties) {
      component.Properties = [];
    }
  }

  setProperties(component: IApplicationComponentModel): void {
    if (component.ComponentTypeCode === ComponentType.ApplicationFooterItem) {
      let titleProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) => row.Name === "Title"
      );

      if (titleProperty && titleProperty.Value) {
        this.Title = titleProperty.Value.StringValue;
      }

      let iconProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) => row.Name === "IconUrl"
      );

      if (iconProperty && iconProperty.Value) {
        this.IconUrl = iconProperty.Value.UrlValue;
      }
    }

    if (component.ComponentTypeCode === ComponentType.ScreenProperties) {
      let itemsAlignmentProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) =>
          row.Name === "ItemsAlignment"
      );

      if (itemsAlignmentProperty && itemsAlignmentProperty.Value) {
        this.ItemsAlignment = itemsAlignmentProperty.Value.StringValue;
      }

      let bakcgroundUrlProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) =>
          row.Name === "BackgroundUrl"
      );

      if (bakcgroundUrlProperty && bakcgroundUrlProperty.Value) {
        this.BackgroundUrl = bakcgroundUrlProperty.Value.UrlValue;
      }

      let columnsProperty = component.Properties?.find(
        (row: IApplicationComponentPropertyModel) => row.Name === "Columns"
      );

      if (columnsProperty && columnsProperty.Value) {
        this.Columns = columnsProperty.Value.NumericValue;
      }
    }
  }

  setProperty(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Title":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "IconUrl":
        property.PropertyType = PropertyType.Url;
        property.IsEnumerable = false;
        break;
      case "ItemsAlignment":
        property.PropertyType = PropertyType.String;
        property.IsEnumerable = false;
        break;
      case "BackgroundUrl":
        property.PropertyType = PropertyType.Url;
        property.IsEnumerable = false;
        break;
      case "Columns":
        property.PropertyType = PropertyType.Numeric;
        property.IsEnumerable = false;
        break;
    }
  }

  setPropertyValue(property: IApplicationComponentPropertyModel) {
    switch (property.Name) {
      case "Title":
        if (property.Value) {
          property.Value.StringValue = this.Title;
        }
        break;
      case "IconUrl":
        if (property.Value) {
          property.Value.UrlValue = this.IconUrl;
        }
        break;
      case "ItemsAlignment":
        if (property.Value) {
          property.Value.StringValue = this.ItemsAlignment;
        }
        break;
      case "BackgroundUrl":
        if (property.Value) {
          property.Value.UrlValue = this.BackgroundUrl;
        }
        break;
      case "Columns":
        if (property.Value) {
          property.Value.NumericValue = this.Columns;
        }
        break;
    }
  }
}
