import React, { memo, useMemo } from "react";
import {
  IAnalyticsSalesRevenueFilterModel,
  UserService,
  useDataLoader,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import {
  Choose,
  ChooseOption,
  NotificationService,
  Spin,
} from "@bms/common-ui";

interface ISalesRevenueCreatorSelectProps {
  filter: IAnalyticsSalesRevenueFilterModel;
  onChange?: (newValue: number[]) => void;
}

const notificationService = NotificationService.getInstance();
const userService = new UserService().promisify();

const useSalesRevenueCreatorsDataProvider = () => {
  const { data: creatorsData, loading } = useDataLoader({
    loader: () =>
      userService.search({
        PageNumber: 1,
        PageSize: 10000,
        IncludeCount: false,
        Profiles: ["CREATOR"],
      }),
    onError: (error) =>
      notificationService.error({
        message: error.Title,
        description: error.Message,
      }),
    deps: [],
  });
  return { creators: creatorsData?.Entities || [], loading };
};

export const SalesRevenueCreatorSelect = memo(
  ({ filter, onChange }: ISalesRevenueCreatorSelectProps) => {
    const { t } = useTranslation();
    const { creators, loading } = useSalesRevenueCreatorsDataProvider();
    const value: any = useMemo(
      () =>
        creators
          ?.filter((it) => filter.CreatorUsers?.find((cu) => cu === it.Id))
          .map((it) => ({ key: it.Id, value: it.Id, label: it.FullName })) ||
        [],
      [filter.CreatorUsers, creators]
    );
    const onValueChange = (newValue: any) => {
      if (onChange) {
        console.log(newValue);
        onChange(newValue.map((it: any) => it.value));
      }
    };

    return (
      <Choose
        mode="multiple"
        labelInValue={true}
        defaultActiveFirstOption={false}
        filterOption={(inputValue, option) =>
          option?.children?.toLowerCase()?.includes(inputValue)
        }
        value={value}
        getPopupContainer={(trigger) => trigger.parentNode}
        style={{ width: "100%" }}
        placeholder={t("SALES_REVENUE_CREATORS_PLACEHOLDER")}
        onChange={onValueChange}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {creators.map((it) => (
          <ChooseOption key={`choose-creators--${it.Id}`} value={it.Id}>
            {it.FullName}
          </ChooseOption>
        ))}
      </Choose>
    );
  }
);
