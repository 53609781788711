import {
  AssetImageTypeHelper,
  AssetStore,
  CommonStore,
  IAssetImageModel,
  ICommonAppState,
  PlatformTypeHelper,
  UploadFileInfoModel,
} from "@bms/common-services";
import {
  Choose,
  ChooseOption,
  Col,
  Dragger,
  Form,
  Icon,
  IFormValues,
  Input,
  IUploadChangeEvent,
  IUploadFile,
  NotificationService,
  required,
  Row,
} from "@bms/common-ui";
import { assign, filter, get, noop, takeRight } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IAssetImagesTypesInUse } from "../AssetImages";

export interface IAssetImageModalProps {
  assetId: number;
  assetImage: IAssetImageModel;
  assetImagesTypesInUse: IAssetImagesTypesInUse;
}

const notificationService = NotificationService.getInstance();

const assetSelector = (state: ICommonAppState) => state.asset;
const commonSelector = (state: ICommonAppState) => state.common;

export const AssetImageModal: React.FC<IAssetImageModalProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState<IUploadFile[]>([]);
  const [file, setFile] = useState<File>();
  const [suggestedResolution, setSuggestedResolution] = useState({
    width: 0,
    height: 0,
    ratioW: 0,
    ratioH: 0,
  });
  const [imageResolution, setImageResolution] = useState({
    width: 0,
    height: 0,
  });
  const [imageType, setImageType] = useState<string | undefined>();
  const [imagePlfatform, setImagePlatform] = useState<string | undefined>();
  const { assetImage, assetId, assetImagesTypesInUse } = props;
  const [assetImageState, setAssetImageState] = useState<IAssetImageModel>(
    assetImage
  );
  const { assetImageTypes, uploadFileInfo, action } = useSelector(
    assetSelector
  );
  const { platforms, isLoadingData } = useSelector(commonSelector);
  const {
    data: assetTypesData = [],
    isFetching: assetTypesFetching,
  } = assetImageTypes;
  const getPlatformTypes = useCallback(
    () => dispatch(CommonStore.Actions.selectPlatforms()),
    [dispatch]
  );
  const getAssetImageTypes = useCallback(
    () => dispatch(AssetStore.Actions.getAssetImageTypes()),
    [dispatch]
  );

  const getAssetContentUploadPath = useCallback(
    () => dispatch(AssetStore.Actions.getUploadFileInfo(assetId)),
    [dispatch, assetId]
  );

  const uploadAssetImage = useCallback(
    (
      fileUploadInfo: UploadFileInfoModel,
      file: File,
      onProgress: any,
      onSuccess: () => void
    ) =>
      dispatch(
        AssetStore.Actions.uploadAssetContentFile(
          fileUploadInfo,
          file,
          onProgress,
          onSuccess
        )
      ),
    [dispatch]
  );

  const updateAssetImage = useCallback(
    (data: IAssetImageModel) =>
      dispatch(AssetStore.Actions.updateAssetImage(data)),
    [dispatch]
  );

  const addAssetImage = useCallback(
    (data: IAssetImageModel) =>
      dispatch(AssetStore.Actions.addAssetImage(data)),
    [dispatch]
  );

  useEffect(() => {
    getPlatformTypes();
    getAssetImageTypes();

    const selectedResolution = assetImageState.AssetImageTypeCode || "";
    changeImageType(selectedResolution);
  }, [getPlatformTypes, getAssetImageTypes]);

  useEffect(() => {
    switch (action?.type) {
      case "GET_UPLOAD_FILE_INFO_SUCCESS":
        uploadAssetImage(uploadFileInfo.data!, file!, noop, noop);
        break;
      case "UPLOAD_ASSET_CONTENT_FILE_SUCCESS":
        if (assetImage.Id) {
          updateAssetImage({
            ...assetImageState,
            Path: uploadFileInfo.data?.Path,
          });
        } else {
          addAssetImage({
            ...assetImageState,
            Path: uploadFileInfo.data?.Path,
          });
        }
        break;
      case "GET_UPLOAD_FILE_INFO_FAILURE":
        return notificationService.error({
          message: t("ASSET_IMAGE_UPLOAD_FAILURE"),
          description: action?.error?.Message,
        });
    }
  }, [action]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
      md: { span: 6 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
      md: { span: 18 },
      lg: { span: 18 },
    },
  };

  const onImageChange = (e: IUploadChangeEvent) => {
    const { fileList } = e;
    const image = takeRight(fileList);

    if (image.length) {
      image[0].status = "done";
    }

    setFileList(image);
  };

  const changeImageType = (value: string) => {
    const platform = PlatformTypeHelper.getValue(imagePlfatform);
    const assetImageType = AssetImageTypeHelper.getValue(value);

    setSuggestedResolution(
      AssetImageTypeHelper.getSuggestedResolution(platform, assetImageType)
    );
    setImageType(value);
  };

  const changePlatformType = (value: string) => {
    const platform = PlatformTypeHelper.getValue(value);
    const assetImageType = AssetImageTypeHelper.getValue(imageType);

    setSuggestedResolution(
      AssetImageTypeHelper.getSuggestedResolution(platform, assetImageType)
    );
    setImagePlatform(value);
  };

  const renderTypeField = () => (
    <Form.Item
      name="AssetTypeCode"
      label={t("ASSET_IMAGE_TYPE")}
      key="type"
      initialValue={get(assetImage, "AssetImageTypeCode", "")}
      rules={[required()]}
    >
      <Choose
        placeholder={t("MODEL_TYPE_PLACEHOLDER")}
        loading={assetTypesFetching}
        disabled={assetTypesFetching}
        onChange={changeImageType}
      >
        {assetTypesData.map((assetType) => (
          <ChooseOption key={assetType.Code} value={assetType.Code}>
            {assetType.DisplayName}
          </ChooseOption>
        ))}
      </Choose>
    </Form.Item>
  );

  const renderRecomendedResolution = () => (
    <Form.Item name="Ratio" label={t("ASSET_IMAGE_FORM_RATIO")} key="Ratio">
      {t("ASSET_IMAGE_FORM_RECOMMENDED_RATIO", {
        ratioW: suggestedResolution?.ratioW,
        ratioH: suggestedResolution?.ratioH,
      })}
      {suggestedResolution?.width !== 0 && suggestedResolution?.height !== 0 && (
        <p>
          {t("ASSET_IMAGE_FORM_RECOMMENDED_RESOLUTION", {
            width: suggestedResolution?.width,
            height: suggestedResolution?.height,
          })}
        </p>
      )}
    </Form.Item>
  );

  const renderPlatformField = () => (
    <Form.Item
      name="Platform"
      label={t("ASSET_IMAGE_PLATFORM")}
      key="Platform"
      initialValue={get(assetImage, "PlatformCode", null)}
      rules={[required()]}
    >
      <Choose
        placeholder={t("ASSET_IMAGE_FORM_PLATFORM_PLACEHOLDER")}
        loading={isLoadingData}
        disabled={isLoadingData}
        onChange={changePlatformType}
      >
        {platforms.map((platform) => (
          <ChooseOption key={platform.Code} value={platform.Code}>
            {platform.DisplayName}
          </ChooseOption>
        ))}
      </Choose>
    </Form.Item>
  );

  const renderPathField = () => (
    <Form.Item
      name="Path"
      label={t("ASSET_IMAGE_FORM_PATH")}
      key="Path"
      initialValue={get(assetImage, "Path", "")}
    >
      <Input placeholder={t("ASSET_IMAGE_FORM_PATH_PLACEHOLDER")} />
    </Form.Item>
  );

  const beforeUpload = (file: File) => {
    setUpWidthAndHeightFromFile(file);

    setFile(file);
    return true;
  };

  const setUpWidthAndHeightFromFile = (file: File) => {
    const image = new Image();
    image.onload = function () {
      setImageResolution({
        width: image.width,
        height: image.height,
      });
    };
    image.src = URL.createObjectURL(file);
  };

  const renderUploadField = () => (
    <Form.Item
      name="Upload"
      label={t("ASSET_IMAGE_FORM_UPLOAD")}
      key="Upload"
      getValueFromEvent={onImageChange}
      valuePropName="file"
    >
      <Dragger
        name="Upload"
        accept="image/*"
        multiple={false}
        showUploadList={true}
        beforeUpload={beforeUpload}
        fileList={fileList}
      >
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">{t("DRAG_AND_DROP_INFO")}</p>
      </Dragger>
    </Form.Item>
  );

  const onFinish = (values: IFormValues) => {
    const { AssetTypeCode, Platform, Path } = values;

    if (fileList.length === 0 && !Path) {
      return notificationService.error({
        message: t("ADD_ASSET_IMAGE_VALIDATION_PROVIDE_IMAGE"),
      });
    }

    if (
      assetImagesTypesInUse[Platform].includes(AssetTypeCode) &&
      assetImage.AssetImageTypeCode !== AssetTypeCode
    ) {
      return notificationService.error({
        message: t("ADD_ASSET_IMAGE_VALIDATION_TYPE"),
      });
    }

    const assetImageToSave: IAssetImageModel = assign({}, assetImage, {
      AssetId: assetId,
      PlatformCode: Platform,
      PlatformDisplayName: filter(platforms, { Code: Platform })[0].DisplayName,
      Path,
      AssetImageTypeCode: AssetTypeCode,
      AssetImageTypeDisplayName: filter(assetTypesData, {
        Code: AssetTypeCode,
      })[0].DisplayName,
      Width: imageResolution.width || assetImage.Width,
      Height: imageResolution.height || assetImage.Height,
    });

    setAssetImageState(assetImageToSave);

    if (fileList.length) {
      return getAssetContentUploadPath();
    }

    if (assetImage.Id) {
      updateAssetImage(assetImageToSave);
    } else {
      addAssetImage(assetImageToSave);
    }
  };

  return (
    <Form
      id="AssetImageModal"
      name="AssetImageModal"
      {...formItemLayout}
      onFinish={onFinish}
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          {renderTypeField()}
          {renderRecomendedResolution()}
          {renderPlatformField()}
          {renderPathField()}
          {renderUploadField()}
        </Col>
      </Row>
    </Form>
  );
};
