import { ViewModelBase } from "./Base";

export class AgeRestrictionModel extends ViewModelBase {
  public Code?: string;

  public Guid?: string;

  public Id?: number;

  public Name?: File;
}
