import {
  ApplicationConfigurationStore,
  IApplicationScreenModel,
  IApplicationScreenTypeModel,
  IApplicationsScreensSearchFilterModel,
  IErrorModel,
  IPlatformModel,
} from "@bms/common-services";
import {
  Button,
  Icon,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  Link,
  NotificationService,
  PageContent,
  PageHeader,
  Popconfirm,
  Table,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ActionCreator } from "redux";
import { PlatformTypeHelper } from "../../../../helpers";
import { ROUTES } from "../../constants";
import { ScreenTypeHelper } from "../../helpers";
import { ApplicationScreenAddModal } from "../ApplicationScreenAddModal";
import "./ApplicationConfigurationScreensList.scss";

const notificationService = NotificationService.getInstance();

export interface IApplicationConfigurationScreensListProps
  extends WithTranslation {
  configurationId?: number;
  platforms: IPlatformModel[];
  screensTypes: IApplicationScreenTypeModel[];
  screens?: IApplicationScreenModel[];
  isLoadingData: boolean;
  actionType?: string;
  error?: IErrorModel;
  getConfiguration: ActionCreator<
    ApplicationConfigurationStore.Types.IGetApplicationConfigurationAction
  >;
  deleteScreen: ActionCreator<
    ApplicationConfigurationStore.Types.IDeleteApplicationScreenAction
  >;
}

export interface ILabeledValue {
  key?: string;
  value: string;
  label: React.ReactNode;
}
interface IApplicationConfigurationScreensListState {
  addScreenModalVisible: boolean;
  filters: IApplicationsScreensSearchFilterModel;
}
export class ApplicationConfigurationScreensList extends React.PureComponent<
  IApplicationConfigurationScreensListProps,
  IApplicationConfigurationScreensListState
> {
  public state: Readonly<IApplicationConfigurationScreensListState> = {
    addScreenModalVisible: false,
    filters: {},
  };

  componentDidUpdate(prevProps: IApplicationConfigurationScreensListProps) {
    const { actionType, error, t } = this.props;
    if (prevProps.actionType === actionType) {
      return;
    }

    switch (actionType) {
      case ApplicationConfigurationStore.Consts
        .DELETE_APPLICATION_SCREEN_FAILURE:
        return notificationService.error({
          message: t("APPLICATION_SCREEN_DETAILS__DELETE_SCREEN_FAILURE"),
          description: error ? error.Message : undefined,
        });
      case ApplicationConfigurationStore.Consts
        .DELETE_APPLICATION_SCREEN_SUCCESS:
        notificationService.success({
          message: t("APPLICATION_SCREEN_DETAILS__DELETE_SCREEN_SUCCESS"),
        });
        const paramId = location.pathname.substring(
          location.pathname.lastIndexOf("/") + 1
        );
        this.props.getConfiguration(paramId);
        break;
    }
  }

  private getColumnsProps(): Array<ITableColumnProps<IApplicationScreenModel>> {
    const { t, configurationId, screens, platforms, screensTypes } = this.props;
    const { filters } = this.state;

    return [
      {
        key: "Name",
        dataIndex: "Name",
        title: t("MODEL_NAME"),
        render: (text: any, row: IApplicationScreenModel) => {
          return (
            <Link
              to={{
                pathname: `${ROUTES.CONFIGURATION_SCREEN_DETAILS}/${row.Id}`,
                state: { prevId: configurationId },
              }}
            >
              {row.Name}
            </Link>
          );
        },
      },
      {
        key: "ScreenTypeCode",
        dataIndex: "ScreenTypeCode",
        title: t("MODEL_TYPE_CODE"),
        filterMultiple: false,
        filters: screensTypes?.map((screenType) => ({
          text: screenType.Name,
          value: screenType.Code,
        })),
        filteredValue: filters.ScreenTypeCode ? [filters.ScreenTypeCode] : null,
        onFilter: (value, record) =>
          record.ScreenTypeCode!.includes(`${value}`),
        render: (text: any, row: IApplicationScreenModel) =>
          ScreenTypeHelper.getTag(row.ScreenTypeCode),
      },
      {
        key: "Platforms",
        dataIndex: "Platforms",
        title: t("MODEL_PLATFORMS"),
        filters: platforms?.map((platform) => ({
          text: platform.DisplayName,
          value: platform.Code,
        })),
        filteredValue: filters.Platforms || null,
        onFilter: (value, record) => {
          if (!record.Platforms) {
            return false;
          }
          const isPlatformAvaiable = screens
            ?.filter(
              (screen) => screen.ScreenTypeCode === record.ScreenTypeCode
            )
            .some((screen) =>
              screen.Platforms?.some(
                (platform) => platform.PlatformCode === value
              )
            );

          if (!isPlatformAvaiable) {
            return record.Platforms.some((platform) => {
              return platform.PlatformCode === "ANY";
            });
          }

          return record.Platforms.some((platform) => {
            return platform.PlatformCode === value;
          });
        },
        render: (text: any, row: IApplicationScreenModel) => {
          const platformsView: React.ReactNode[] = [];

          if (row.Platforms && row.Platforms.length > 0) {
            for (const platform of row.Platforms) {
              platformsView.push(
                PlatformTypeHelper.getTag(platform.PlatformCode)
              );
            }
          }

          return platformsView;
        },
      },
      {
        dataIndex: "Description",
        title: t("MODEL_DESCRIPTION"),
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN"),
        render: (_: any, row: IApplicationScreenModel) => (
          <Popconfirm
            title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              this.props.deleteScreen(row);
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT")}
            />
          </Popconfirm>
        ),
      },
    ];
  }

  public onAddScreenClick = () => {
    const { configurationId } = this.props;

    if (!configurationId) {
      return;
    }

    this.setState({ addScreenModalVisible: true });
  };

  public onAddScreenCancel = () => {
    this.setState({ addScreenModalVisible: false });
  };

  public onAddScreenSuccess = () => {
    this.setState({ addScreenModalVisible: false });
  };

  public onTableChange = (_: ITablePaginationConfig, filters: ITableFilter) => {
    const filter: IApplicationsScreensSearchFilterModel = {
      ...this.state.filters,
    };

    filter.Platforms = filters.Platforms?.length
      ? filters.Platforms.map((row) => `${row}`)
      : undefined;

    filter.ScreenTypeCode = filters.ScreenTypeCode?.length
      ? `${filters.ScreenTypeCode[0]}`
      : undefined;

    this.setState({
      filters: filter,
    });
  };

  public render() {
    const { screens, isLoadingData, t, configurationId } = this.props;
    const { addScreenModalVisible } = this.state;
    const columns = this.getColumnsProps();

    return (
      <PageContent>
        <PageHeader
          title={t("APPLICATION_CONFIGURATION__SCREENS_TAB")}
          extra={
            <>
              <Button
                shape="circle"
                type="primary"
                icon={<Icon type="plus" />}
                onClick={this.onAddScreenClick}
                title={t("APPLICATION_CONFIGURATION_SCREENS__ADD_SCREEN")}
              />
            </>
          }
        />
        <Table<IApplicationScreenModel>
          pagination={false}
          rowKey="Guid"
          columns={columns}
          dataSource={screens}
          loading={isLoadingData}
          onChange={this.onTableChange}
        />
        <ApplicationScreenAddModal
          configurationId={configurationId!}
          visible={addScreenModalVisible}
          onCancel={this.onAddScreenCancel}
          onSuccess={this.onAddScreenSuccess}
        />
      </PageContent>
    );
  }
}
