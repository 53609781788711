import { AuthStore, IAuthRequestModel } from "@bms/common-services";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Login as LoginDefinition } from "./Login";

const mapDispatchToProps = (dispatch: Dispatch) => ({
  signIn: (data: IAuthRequestModel) => {
    return dispatch(AuthStore.Actions.signIn(data));
  },
});

export const Login = connect(
  undefined,
  mapDispatchToProps
)(withTranslation()(LoginDefinition));
