import React, { memo, useMemo } from "react";
import {
  useDataLoader,
  IAnalyticsAssetImpressionsFilterModel,
  AssetService,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import {
  Choose,
  ChooseOption,
  NotificationService,
  Spin,
} from "@bms/common-ui";

interface IAssetImpressionsSelectProps {
  filter: IAnalyticsAssetImpressionsFilterModel;
  onChange?: (newValue: number[]) => void;
}

const notificationService = NotificationService.getInstance();
const assetService = new AssetService().promisify();

const useAssetImpressionsAssetsDataProvider = () => {
  const { data: assetsData, loading } = useDataLoader({
    loader: () =>
      assetService.search({
        PageNumber: 1,
        PageSize: 10000,
        IncludeCount: false,
      }),
    onError: (error) =>
      notificationService.error({
        message: error.Title,
        description: error.Message,
      }),
    deps: [],
  });
  return { assets: assetsData?.Entities || [], loading };
};

export const AssetImpressionsAssetSelect = memo(
  ({ filter, onChange }: IAssetImpressionsSelectProps) => {
    const { t } = useTranslation();
    const { assets, loading } = useAssetImpressionsAssetsDataProvider();

    const value: any = useMemo(
      () =>
        assets
          ?.filter((it) => filter.Assets?.find((cu) => cu === it.Id))
          .map((it) => ({ key: it.Id, value: it.Id, label: it.Title })) || [],
      [filter.Assets, assets]
    );
    const onValueChange = (newValue: any) => {
      if (onChange) {
        onChange(newValue.map((it: any) => it.value));
      }
    };

    return (
      <Choose
        mode="multiple"
        labelInValue={true}
        defaultActiveFirstOption={false}
        filterOption={(inputValue, option) =>
          option?.children?.toLowerCase()?.includes(inputValue)
        }
        getPopupContainer={(trigger) => trigger.parentNode}
        value={value}
        style={{ width: "100%" }}
        placeholder={t("ASSET_IMPRESSIONS_ASSETS_PLACEHOLDER")}
        onChange={onValueChange}
        loading={loading}
        notFoundContent={loading ? <Spin size="small" /> : undefined}
      >
        {assets.map((it) => (
          <ChooseOption key={`choose-creators--${it.Id}`} value={it.Id}>
            {it.Title}
          </ChooseOption>
        ))}
      </Choose>
    );
  }
);
