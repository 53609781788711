import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import React from "react";
import { IBreadcrumbProps, Link } from "@bms/common-ui";
import { Trans } from "react-i18next";
import ReactDOMServer from "react-dom/server";

export function generateBreadcrumb(routes: Route[]): IBreadcrumbProps {
  return {
    itemRender: (route, params, routes) => {
      const last = routes.indexOf(route) === routes.length - 1;
      return last ? (
        <span>{route.breadcrumbName}</span>
      ) : (
        <Link to={route.path}>{route.breadcrumbName}</Link>
      );
    },
    routes: [
      {
        path: "",
        breadcrumbName: ReactDOMServer.renderToString(
          <Trans i18nKey="BREADCRUMBS_HOME" />
        ),
      },
      ...routes,
    ],
  };
}
