import React from "react";
import { Spin as AntSpin } from "antd";
import { SpinProps } from ".";

export class Spin extends React.PureComponent<SpinProps> {
  public render() {
    const { children, ...rest } = this.props;
    return <AntSpin {...rest}>{children}</AntSpin>;
  }
}
