import React, { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Choose,
  ChooseOption,
  NotificationService,
  Spin,
} from "@bms/common-ui";
import {
  AssetService,
  IAnalyticsSalesRevenueFilterModel,
  useDataLoader,
} from "@bms/common-services";

interface IPaymentAssetSelectProps {
  onChange?: (newValue: number[]) => void;
  filter: IAnalyticsSalesRevenueFilterModel;
  style?: any;
}

const notificationService = NotificationService.getInstance();
const assetService = new AssetService().promisify();

const useSalesRevenueAssetsDataProvider = () => {
  const { data: assetsData, loading } = useDataLoader({
    loader: () =>
      assetService.search({
        PageNumber: 1,
        PageSize: 10000,
        IncludeCount: false,
      }),
    onError: (error) =>
      notificationService.error({
        message: error.Title,
        description: error.Message,
      }),
    deps: [],
  });
  return { assets: assetsData?.Entities || [], loading };
};

export const PaymentAssetSelect = memo(
  ({ filter, onChange }: IPaymentAssetSelectProps) => {
    // const [value, setValue] = useState<any>([]);
    const { t } = useTranslation();
    const { assets, loading } = useSalesRevenueAssetsDataProvider();

    const value: any = useMemo(
      () =>
        assets
          ?.filter((it) => filter.Assets?.find((cu) => cu === it.Id))
          .map((it) => ({ key: it.Id, value: it.Id, label: it.Title })) || [],
      [filter.Assets, assets]
    );

    const onValueChange = (newValue: any) => {
      if (onChange) {
        onChange(newValue.map((it: any) => it.value));
      }
    };

    return (
      <Choose
        mode="multiple"
        labelInValue={true}
        defaultActiveFirstOption={false}
        filterOption={(inputValue, option) =>
          option?.children?.toLowerCase()?.includes(inputValue)
        }
        value={value}
        style={{ width: "100%" }}
        placeholder={t("SALES_REVENUE_ASSETS_PLACEHOLDER")}
        getPopupContainer={(trigger) => trigger.parentNode}
        onChange={onValueChange}
        loading={loading}
        notFoundContent={loading ? <Spin size="small" /> : undefined}
      >
        {assets.map((it) => (
          <ChooseOption key={`choose-creators--${it.Id}`} value={it.Id}>
            {it.Title}
          </ChooseOption>
        ))}
      </Choose>
    );
  }
);
