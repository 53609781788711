import {
  IApplicationConfigurationBrandingKeyTypeModel,
  IApplicationConfigurationBrandingValueModel,
} from "@bms/common-services";
import {
  Checkbox,
  Choose,
  ChooseOption,
  ColorPicker,
  Input,
  TextArea,
  ITableColumnProps,
  Table,
  Form,
} from "@bms/common-ui";
import React, { useEffect, useState } from "react";
import { useTranslation, UseTranslationResponse } from "react-i18next";
import {
  allowedFonts,
  allowedFontSizes,
  fontKey2NameMap,
  getDefaultValByKeyOrType,
} from "../../../../../../helpers";

type ComponentResolver = ({
  Key,
  Name,
  Type,
  Value,
}: IApplicationConfigurationBrandingValueModel) => JSX.Element;

interface IValuesHandler {
  [key: string]: ComponentResolver;
}

const createValuesHandler = (
  t: UseTranslationResponse["t"]
): IValuesHandler => {
  return {
    BOOL: ({
      Key,
      Name,
      Type,
      Value: propsValue = getDefaultValByKeyOrType({ Key, Type }),
    }: IApplicationConfigurationBrandingValueModel) => {
      const Value = propsValue === "true";

      return (
        <Form.Item
          key={Key}
          name={Key}
          initialValue={Value}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      );
    },
    COLOR: ({
      Key,
      Name,
      Type,
      Value = getDefaultValByKeyOrType({ Key, Type }),
    }: IApplicationConfigurationBrandingValueModel) => {
      return (
        <Form.Item
          key={Key}
          name={Key}
          initialValue={Value}
          valuePropName="hex"
        >
          <ColorPicker
            color={Value as string}
            pickerStyle={{ right: "-55px" }}
          />
        </Form.Item>
      );
    },
    FONT: ({
      Key,
      Name,
      Type,
      Value = getDefaultValByKeyOrType({ Key, Type }),
    }: IApplicationConfigurationBrandingValueModel) => (
      <Form.Item key={Key} name={Key} initialValue={Value}>
        <Choose
          allowClear={false}
          placeholder={t("BRANDING_FIELDS_GROUP_CHOOSE_FONT")}
        >
          {allowedFonts.map((fontName) => (
            <ChooseOption key={fontName} value={fontName}>
              {fontKey2NameMap[fontName]}
            </ChooseOption>
          ))}
        </Choose>
      </Form.Item>
    ),
    TEXT: ({
      Key,
      Name,
      Type,
      Value = getDefaultValByKeyOrType({ Key, Type }),
    }: IApplicationConfigurationBrandingValueModel) => {
      return (
        <Form.Item
          key={Key}
          name={Key}
          initialValue={Value}
          wrapperCol={{
            lg: { span: 24 },
            md: { span: 24 },
            sm: { span: 24 },
            xs: { span: 24 },
          }}
        >
          <TextArea rows={1} placeholder={t("BRANDING_FORM_ENTER_VALUE")} />
        </Form.Item>
      );
    },
    NUMBER: ({
      Key,
      Name,
      Type,
      Value = getDefaultValByKeyOrType({ Key, Type }),
    }: IApplicationConfigurationBrandingValueModel) => {
      return (
        <Form.Item key={Key} name={Key} initialValue={Value}>
          <Choose
            allowClear={false}
            placeholder={t("BRANDING_FIELDS_GROUP_CHOOSE_FONT_SIZE")}
          >
            {allowedFontSizes.map((fontSize) => (
              <ChooseOption key={fontSize} value={fontSize}>
                {fontSize}
              </ChooseOption>
            ))}
          </Choose>
        </Form.Item>
      );
    },
    URL: ({
      Key,
      Name,
      Type,
      Url = getDefaultValByKeyOrType({ Key, Type }),
    }: IApplicationConfigurationBrandingValueModel) => (
      <img src={`${Url}`} alt="" className="imagePreview" />
    ),
    DEFAULT: ({
      Key,
      Name,
      Type,
      Value = getDefaultValByKeyOrType({ Key, Type }),
    }: IApplicationConfigurationBrandingValueModel) => {
      return (
        <Form.Item key={Key} name={Key} initialValue={Value}>
          <Input placeholder={t("BRANDING_FORM_ENTER_VALUE")} />
        </Form.Item>
      );
    },
  };
};

interface IGetColumnsProps {
  t: UseTranslationResponse["t"];
  valuesHandler: IValuesHandler;
  setImageModalData?: (
    imageData: IApplicationConfigurationBrandingValueModel
  ) => any;
}

const getColumnsProps = ({
  t,
  valuesHandler,
  setImageModalData,
}: IGetColumnsProps): Array<
  ITableColumnProps<IApplicationConfigurationBrandingValueModel>
> => [
  {
    key: "Key",
    dataIndex: "Key",
    title: t("MODEL_KEY"),
    render: (text, row) => {
      const { Type } = row;
      if (Type !== "URL" || typeof setImageModalData !== "function") {
        return <span>{text}</span>;
      }

      const imageData = {
        ...row,
      };

      const handleImageNameClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setImageModalData(imageData);
      };
      return <a onClick={handleImageNameClick}>{text}</a>;
    },
  },
  {
    key: "Name",
    dataIndex: "Name",
    title: t("MODEL_NAME"),
  },
  {
    key: "Value",
    dataIndex: "Value",
    title: t("MODEL_VALUE"),
    render: (text, { Value, Key, Name, Type, Url }) => {
      let valueHandler = valuesHandler[Type];

      if (!valueHandler) {
        valueHandler = valuesHandler["DEFAULT"];
      }

      return valueHandler({
        Key,
        Name,
        Type,
        Value,
        Url,
      });
    },
  },
];

interface IApplicationConfigurationBrandingValueModelsGroup {
  label: string;
  dataSource: IApplicationConfigurationBrandingValueModel[];
  setImageModalData?: (
    imageData: IApplicationConfigurationBrandingValueModel
  ) => any;
}

const getRowKey = ({ Key }: IApplicationConfigurationBrandingKeyTypeModel) =>
  `${Key}`;

const FieldsGroupDef: React.FC<IApplicationConfigurationBrandingValueModelsGroup> = (
  props
) => {
  const { label, dataSource, setImageModalData } = props;

  const [columns, setColumns] = useState<
    Array<ITableColumnProps<IApplicationConfigurationBrandingValueModel>>
  >([]);

  const { t } = useTranslation();

  useEffect(() => {
    const valuesHandler = createValuesHandler(t);

    setColumns(getColumnsProps({ t, valuesHandler, setImageModalData }));
  }, []);

  return !!columns.length ? (
    <>
      <h3 className="branding-value-group-title">{label}</h3>
      {!!columns.length && (
        <Table<IApplicationConfigurationBrandingValueModel>
          className="branding-value-group-table"
          columns={columns}
          dataSource={dataSource}
          loading={false}
          rowKey={getRowKey}
          pagination={false}
        />
      )}
    </>
  ) : null;
};

export const FieldsGroup = React.memo(FieldsGroupDef);
