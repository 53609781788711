import {
  AnalyticsService,
  IAnalyticsSalesRevenueFilterModel,
  useDataLoader,
} from "@bms/common-services";
import { NotificationService } from "@bms/common-ui";
import { useEffect, useState } from "react";

const notificationService = NotificationService.getInstance();
const analyticsService = new AnalyticsService().promisify();

export const useSalesRevenueDataProvider = () => {
  const [filter, __setFilter] = useState<IAnalyticsSalesRevenueFilterModel>({});

  const {
    debounceAwaiting,
    refresh: refreshData,
    loading,
    data,
  } = useDataLoader({
    loader: () => analyticsService.getSalesRevenue(filter),
    onError: (error) =>
      notificationService.error({
        message: error.Title,
        description: error.Message,
      }),
    deps: [filter],
  });

  const setFilter = <T>(
    property: keyof IAnalyticsSalesRevenueFilterModel,
    value: T
  ) => __setFilter({ ...filter, [property]: value });

  const resetFilter = () => __setFilter({});

  return {
    data,
    filter,
    loading,
    debounceAwaiting,
    refreshData,
    setFilter,
    resetFilter,
  };
};
