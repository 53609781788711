import React from "react";
import { Switch as AntSwitch } from "antd";
import { SwitchProps as AntSwitchProps } from "antd/lib/switch";

export class Switch extends React.PureComponent<AntSwitchProps> {
  public render() {
    const { children, ...rest } = this.props;
    return <AntSwitch {...rest}>{children}</AntSwitch>;
  }
}
