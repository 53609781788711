import { Modal as AntModal } from "antd";
import {
  ModalFuncProps as AntModalFuncProps,
  ModalProps as AntModalProps,
} from "antd/lib/modal";
import React from "react";
import cx from "classnames";
import "./Modal.scss";

export interface IModalFunctionProps extends AntModalFuncProps {}

export interface IModalProps extends AntModalProps {
  children: React.ReactNode;
  processing?: boolean;
  preview?: boolean;
}

export declare type IModalFunction = (props: IModalFunctionProps) => void;

export class Modal extends React.PureComponent<IModalProps> {
  static info: IModalFunction = (props: IModalFunctionProps) => {
    return AntModal.info(props);
  };

  static success: IModalFunction = (props: IModalFunctionProps) => {
    return AntModal.success(props);
  };

  static error: IModalFunction = (props: IModalFunctionProps) => {
    return AntModal.error(props);
  };

  static warning: IModalFunction = (props: IModalFunctionProps) => {
    return AntModal.warning(props);
  };

  static confirm: IModalFunction = (props: IModalFunctionProps) => {
    return AntModal.confirm(props);
  };

  static destroyAll: () => void = () => {
    return AntModal.destroyAll();
  };

  public render() {
    const {
      children,
      processing,
      visible,
      preview,
      centered,
      style,
      width,
      maskStyle,
      ...rest
    } = this.props;
    return (
      <>
        {visible && processing && <div className="processing-overlay-mask" />}
        <AntModal
          {...rest}
          visible={visible}
          className={cx(
            processing && "modal-processing-overlay",
            preview && "modal-preview"
          )}
          maskStyle={{
            ...(preview ? { backgroundColor: "#000000dd" } : {}),
            ...maskStyle,
          }}
          width={preview ? "auto" : width}
          style={{ ...(preview ? { padding: "8px" } : {}), ...style }}
          bodyStyle={{ ...(preview ? { padding: 0 } : {}), ...style }}
          centered={preview ? true : centered}
        >
          {children}
        </AntModal>
      </>
    );
  }
}
