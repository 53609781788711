import { IErrorModel, IUserModel, TimeHelper } from "@bms/common-services";
import {
  Avatar,
  Button,
  Col,
  Form,
  Icon,
  IDaggerChangeEvent,
  IFormValues,
  Input,
  NotificationService,
  Row,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import { UserAvatarDragger } from "../UserAvatarDragger";
import "./UserPersonalInformationForm.scss";

const notificationService = NotificationService.getInstance();

export interface IUserPersonalInformationFormStateProps {
  actionType?: string;
  isProcessingData: boolean;
  error?: IErrorModel;
}

export interface IUserPersonalInformationFormDispatchProps {
  updateUser: ActionCreator<Action>;
}

export interface IUserPersonalInformationFormOwnProps {
  user: IUserModel;
  isActive: boolean;
}

export interface IUserPersonalInformationFormProps
  extends IUserPersonalInformationFormStateProps,
    IUserPersonalInformationFormDispatchProps,
    IUserPersonalInformationFormOwnProps,
    WithTranslation {}

export class UserPersonalInformationForm extends React.PureComponent<
  IUserPersonalInformationFormProps
> {
  public static defaultProps = {
    isActive: false,
    isProcessingData: false,
  };

  public formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 8 },
      lg: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 16 },
      lg: { span: 20 },
    },
  };

  public onFinish = (values: IFormValues) => {
    const { user, updateUser } = this.props;

    user.UserName = values.UserName;
    user.Initials = values.Initials;

    if (values.AvatarFile) {
      user.AvatarFile = values.AvatarFile;
    }

    updateUser(user);
  };

  public onAvatarImageChange = (e: IDaggerChangeEvent) => {
    return e && e.file && e.file.originFileObj;
  };

  public renderAvatar = (preview: string) => {
    const { user } = this.props;
    const avatarSrc = preview || user.AvatarUrl || "";

    return (
      <Avatar
        src={avatarSrc}
        icon={<Icon type="user" className="icon-user" />}
        className="UserPersonalInformationForm__Avatar"
      />
    );
  };

  public renderAvatarField = () => {
    const { isProcessingData, user } = this.props;
    return (
      <Form.Item
        name="AvatarFile"
        getValueFromEvent={this.onAvatarImageChange}
        valuePropName="file"
        style={{ textAlign: "center" }}
        wrapperCol={{ span: 24 }}
      >
        <UserAvatarDragger name="Avatar" src={user.AvatarUrl} />
      </Form.Item>
    );
  };

  public renderInitialsField = () => {
    const { t, user } = this.props;

    return (
      <Form.Item
        name="Initials"
        initialValue={user.Initials}
        label={t("USER_ACCOUNT_FORM_INITIALS_LABEL", "Initials")}
      >
        <Input
          placeholder={t(
            "USER_ACCOUNT_FORM_INITIALS_PLACEHOLDER",
            "Enter initials"
          )}
        />
      </Form.Item>
    );
  };

  public renderUsernameField = () => {
    const { t, user } = this.props;

    return (
      <Form.Item
        name="UserName"
        label={t("USER_ACCOUNT_FORM_USERNAME_LABEL", "Username")}
        initialValue={user.UserName}
      >
        <Input
          placeholder={t(
            "USER_ACCOUNT_FORM_USERNAME_PLACEHOLDER",
            "Enter username"
          )}
        />
      </Form.Item>
    );
  };

  renderLastLoginDateField = () => {
    const { user, t } = this.props;

    const date = user.LastLoginDate && TimeHelper.format(user.LastLoginDate);

    return (
      date && (
        <Form.Item label={t("USER_ACCOUNT_FORM_LAST_LOGIN_DATE_LABEL")}>
          <label>{date}</label>
        </Form.Item>
      )
    );
  };

  public render() {
    const { children, t } = this.props;

    return (
      <Form
        name="UserPersonalInformationForm"
        {...this.formItemLayout}
        className="UserPersonalInformationForm"
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderAvatarField()}
            {this.renderUsernameField()}
            {this.renderInitialsField()}
            {this.renderLastLoginDateField()}
          </Col>
          <Col>{children}</Col>
          <Col>
            <Form.Item wrapperCol={{ style: { textAlign: "right" } }}>
              <Button type="primary" htmlType="submit">
                {t("BUTTON_SAVE", "Save")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}
