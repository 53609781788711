import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Modal, Popconfirm } from "@bms/common-ui";

interface IFormModalModalProps {
  isLoading: boolean;
  isVisible: boolean;
  isNewForm: boolean;
  isDisabled?: boolean;
  isDeleteButtonEnabled: boolean;
  createFormTitle: string;
  editFormTitle: string;
  modalClassName: string;
  submitFormName: string;
  cancelButtonTransKey?: string;
  confirmButtonTransKey?: string;
  onCloseModal: () => void;
  onDeleteButtonClick?: () => void;
}

export const FormModal: React.FC<IFormModalModalProps> = (props) => {
  const {
    isVisible,
    isNewForm,
    isLoading,
    isDisabled,
    isDeleteButtonEnabled,
    createFormTitle,
    editFormTitle,
    submitFormName,
    modalClassName,
    cancelButtonTransKey = "BUTTON_CANCEL",
    confirmButtonTransKey = "BUTTON_SAVE",
    onDeleteButtonClick,
    onCloseModal,
    children,
  } = props;

  const { t } = useTranslation();
  const title = isNewForm ? createFormTitle : editFormTitle;

  const renderFooter = () => {
    return (
      <>
        {renderDeleteButton()}
        <Button key="cancel" onClick={onCloseModal} type="link">
          {t(cancelButtonTransKey, "Cancel")}
        </Button>
        <Button
          form={submitFormName}
          key="submit"
          htmlType="submit"
          type="primary"
          loading={isLoading}
          disabled={isDisabled}
        >
          {t(confirmButtonTransKey, "Save")}
        </Button>
      </>
    );
  };

  const renderDeleteButton = () => {
    if (isDeleteButtonEnabled && !isNewForm) {
      return (
        <Popconfirm
          key="popconfirm"
          title={t("FORM_MODAL__DELETE_MESSAGE")}
          onConfirm={onDeleteButtonClick}
          onCancel={() => {}}
          okText={t("BUTTON_YES")}
          cancelText={t("BUTTON_NO")}
        >
          <Button
            disabled={isLoading || isDisabled}
            key="delete"
            shape="circle"
            danger={true}
            icon={<Icon type="delete" />}
            style={{ float: "left" }}
          ></Button>
        </Popconfirm>
      );
    }
  };

  return (
    <div className={modalClassName}>
      <Modal
        title={title}
        footer={renderFooter()}
        processing={isLoading}
        visible={isVisible}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCloseModal}
      >
        {children}
      </Modal>
    </div>
  );
};
