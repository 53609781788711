import { notification as AntNotification } from "antd";
import {
  ArgsProps as AntArgsProps,
  ConfigProps as AntConfigProps,
} from "antd/lib/notification";

export interface NotificationServiceConfigProps extends AntConfigProps {}

export interface NotificationServiceProps extends AntArgsProps {}

export class NotificationService {
  static instance: NotificationService;

  static getInstance() {
    if (NotificationService.instance) {
      return NotificationService.instance;
    }

    NotificationService.instance = new NotificationService();

    return NotificationService.instance;
  }

  success(args: NotificationServiceProps): void {
    AntNotification.success(args);
  }

  error(args: NotificationServiceProps): void {
    AntNotification.error(args);
  }

  info(args: NotificationServiceProps): void {
    AntNotification.info(args);
  }

  warning(args: NotificationServiceProps): void {
    AntNotification.warning(args);
  }

  open(args: NotificationServiceProps): void {
    AntNotification.open(args);
  }

  config(options: NotificationServiceConfigProps): void {
    AntNotification.config(options);
  }

  destroy(): void {
    AntNotification.destroy();
  }

  close(key: string): void {
    AntNotification.close(key);
  }
}
