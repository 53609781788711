import { IAssetCollectionModel, RecordStatus } from "@bms/common-services";
import {
  Button,
  Col,
  Form,
  IFormValues,
  Input,
  InputNumber,
  required,
  Row,
  Spin,
  TextArea,
  useSendable,
} from "@bms/common-ui";
import React from "react";
import { useTranslation } from "react-i18next";

export interface IAssetCollectionFormProps {
  assetCollection?: IAssetCollectionModel;
  onSubmit: (collection: IAssetCollectionModel) => Promise<boolean>;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    xl: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    xl: { span: 18 },
  },
};

const formItemLayoutModal = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    lg: { span: 18 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: { offset: 0, span: 24 },
    sm: { offset: 8, span: 16 },
    lg: { offset: 6, span: 18 },
  },
};

export const AssetCollectionForm: React.FC<IAssetCollectionFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const { onSubmit, assetCollection } = props;
  const isEditMode = assetCollection?.RecordStatus !== RecordStatus.Inserted;
  const [form] = Form.useForm();
  const sendable = useSendable();

  const formLayout = isEditMode ? formItemLayout : formItemLayoutModal;

  const onFinish = async (values: IFormValues) => {
    const data: IAssetCollectionModel = {
      ...assetCollection,
      Id: assetCollection?.Id ?? -1,
      Name: values.Name,
      Description: values.Description,
      CacheDataInterval: parseInt(values.CacheDataInterval) || 0,
    };

    const ok = await onSubmit(data);
    if (ok) {
      sendable.resetSendable();
    }

    form.resetFields();
  };

  const renderNameField = () => (
    <Form.Item
      name="Name"
      label={t("MODEL_NAME")}
      initialValue={isEditMode ? assetCollection?.Name : ""}
      rules={[required()]}
    >
      <Input placeholder={t("MODEL_NAME_PLACEHOLDER")} />
    </Form.Item>
  );

  const renderDescriptionField = () => (
    <Form.Item
      name="Description"
      label={t("MODEL_DESCRIPTION")}
      initialValue={isEditMode ? assetCollection?.Description : ""}
    >
      <TextArea
        maxLength={250}
        placeholder={t("MODEL_DESCRIPTION_PLACEHOLDER")}
      />
    </Form.Item>
  );

  const renderCacheDataIntervalField = () => (
    <Form.Item
      name="CacheDataInterval"
      label={t("PLAY_LIST_CACHE_RETENTION_LABEL")}
      initialValue={assetCollection?.CacheDataInterval ?? 0}
    >
      <InputNumber
        formatter={(value) => `${`${value}`?.replace?.(/[^\d]/, "") || 0} s`}
        parser={(value?: string) => value?.replace(/[^\d]/, "") || 0}
        min={0}
        max={3600}
        defaultValue={0}
        placeholder={t("PLAY_LIST_CACHE_PLACEHOLDER")}
      />
    </Form.Item>
  );

  if (!assetCollection) {
    return <Spin />;
  }

  return (
    <Form
      id="AssetCollectionForm"
      name="AssetCollectionForm"
      {...formLayout}
      onFinish={onFinish}
      onFieldsChange={sendable.onFieldsChange}
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          {renderNameField()}
          {renderDescriptionField()}
          {renderCacheDataIntervalField()}
        </Col>
        {isEditMode && (
          <Col>
            <Form.Item {...tailFormItemLayout}>
              <Button
                disabled={!sendable.sendable}
                style={{ float: "right" }}
                type="primary"
                htmlType="submit"
              >
                {t("BUTTON_SAVE")}
              </Button>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};
