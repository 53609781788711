import { SectionGrid, SectionGridItem } from "@bms/common-ui";
import React from "react";
import { NotificationForm } from "../../NotificationForm";
import { INotificationModel } from "@bms/common-services";
import { ITabProps } from "./ITabProps";

export const TabGeneral = ({ notification, onSubmit }: ITabProps) => {
  return (
    <SectionGrid style={{ maxWidth: "1200px" }}>
      <SectionGridItem>
        <NotificationForm
          notification={notification}
          onSubmit={onSubmit}
          isEditMode
          hiddenFields={[
            "RecipientType",
            "ImageUrl",
            "UrlContent",
            "DataContent",
            "DataType",
            "TextContent",
            "UrlDescription",
          ]}
        />
      </SectionGridItem>
    </SectionGrid>
  );
};
