import { Icon } from "@ant-design/compatible";
import React from "react";
import { useTranslation } from "react-i18next";
import "./TileList.scss";

interface ITileListProps {
  header: React.ReactNode;
  subheader: React.ReactNode;
  headerValue: React.ReactNode;
  refresh?: () => void;
}

export const TileList: React.FC<ITileListProps> = ({
  header,
  subheader,
  headerValue,
  children,
  refresh,
}) => {
  const {t} = useTranslation();

  return (
    <div className="tile-list">
      {refresh && (
        <button onClick={refresh} className="tile-list-refresh">
          <Icon type="reload" />
        </button>
      )}
      <div className="tile-list-header">{header}</div>
      <div className="tile-list-header-value">{headerValue}</div>
      <div className="tile-list-subheader">{subheader}</div>
      {children}
    </div>
  );
};
