import React, { useCallback } from "react";
import { Form, IFormInstance, Link } from "@bms/common-ui";
import { AssetService, AssetType, IAssetModel } from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { ROUTES } from "../../../constants";
import { ILazyChooseLoader, LazyChoose } from "../../../../../components";

interface IParentFieldProps extends IAssetFormFieldCommonProps {
  parent?: IAssetModel;
  parentId?: number;
  parentTitle?: string;
  type?: AssetType;
  form: IFormInstance;
  onChange?: (parentId?: number) => void;
}

const inferParentAssetType = (type?: AssetType) => {
  switch (type) {
    case AssetType.Season:
      return [AssetType.Series];
    case AssetType.Episode:
      return [AssetType.Season, AssetType.Series];
    case AssetType.Podcast:
      return [AssetType.Album];
    case AssetType.Program:
      return [AssetType.Channel];
    default:
      return undefined;
  }
};

const assetService = new AssetService().promisify();

export const ParentField = ({
  isEditMode,
  isHidden = false,
  parent,
  parentId,
  parentTitle,
  type,
  form,
  onChange,
}: IParentFieldProps) => {
  const { t } = useTranslation();
  if (isHidden || (isEditMode && !parent && !parentTitle)) {
    return null;
  }

  if (isEditMode) {
    return (
      <Form.Item label={t("MODEL_PARENT")} key="Parent">
        {parent?.ParentAssetTitle && parent?.ParentAssetId && (
          <>
            <Link to={`${ROUTES.ASSET_DETAILS}/${parent.ParentAssetId}`}>
              {parent.ParentAssetTitle}
            </Link>
            <span> / </span>
          </>
        )}
        <Link to={`${ROUTES.ASSET_DETAILS}/${parentId}`}>{parentTitle}</Link>
      </Form.Item>
    );
  }

  const parentType = inferParentAssetType(type);
  const isRequired = type === AssetType.Podcast ? false : !isEditMode;

  const onParentChange = useCallback((value?: IAssetModel) => {
    const parentId = value?.Id;

    if (!parentId) {
      form.setFieldsValue({ OrderInParent: undefined });
    }
    onChange?.(parentId);
  }, []);

  const loadCandidates: ILazyChooseLoader<IAssetModel> = async (
    search: string
  ) => {
    return assetService.search({
      PageNumber: 1,
      PageSize: 100,
      Types: parentType,
      Title: search,
    });
  };

  return (
    <Form.Item
      name="ParentAssetId"
      label={t("MODEL_PARENT")}
      key="Parent"
      initialValue={
        parentId && {
          key: parentId,
          label: parentTitle,
          value: parentId,
        }
      }
      rules={[{ required: isRequired }]}
    >
      <LazyChoose<IAssetModel, IAssetModel>
        disabled={!!parent}
        multiple={false}
        allowClear={!isRequired}
        showSearch
        placeholder={t("MODEL_PARENT_PLACEHOLDER")}
        loader={loadCandidates}
        loaderDeps={[parentType]}
        candidateToOption={(item) => ({
          label: item.ParentAssetTitle
            ? `${item.ParentAssetTitle} / ${item.Title}`
            : item.Title!,
          value: `${item.Id}`,
          item,
        })}
        selectedToOption={(item) => ({
          label: item.Title!,
          value: `${item.Id!}`,
          item,
        })}
        onValueChange={onParentChange}
      />
    </Form.Item>
  );
};
