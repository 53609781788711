import React from "react";
import { Row } from "../Row";
import { Col } from "../Col";
import cx from "classnames";

interface DetailsGridItemProps {
  header?: string;
  description?: React.ReactNode;
  processing?: boolean;
}

export const SectionGridItem: React.FC<DetailsGridItemProps> = ({
  children,
  header,
  description,
  processing,
}) => (
  <Row className="section-grid-item">
    {(header || description) && (
      <Col flex="150px 1 1" className="section-grid-item-header-desc">
        <h3>{header}</h3>
        {description && <p>{description}</p>}
      </Col>
    )}
    <Col
      flex="350px 1 0"
      className={cx(
        "section-grid-item-content",
        processing ? "section-grid-item-processing" : ""
      )}
    >
      {children}
      <div className="section-grid-item-opaque-margin" />
    </Col>
  </Row>
);
