import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Input,
  InputPassword,
  Form,
  IFormValues,
  useValidate,
} from "@bms/common-ui";
import { useSelector } from "react-redux";
import { IAuthState } from "@bms/common-services";
import { IAppState } from "../../../../store";

interface ILoginFormValues {
  Username: string;
  Password: string;
}

export interface ILoginFormProps {
  onSubmit: (values: ILoginFormValues) => void;
}

export const LoginForm: React.FC<ILoginFormProps> = ({ onSubmit }) => {
  const [t] = useTranslation();
  const validate = useValidate();

  const onFinish = React.useCallback(
    (values: IFormValues) => {
      const payload: ILoginFormValues = {
        Username: values.Username,
        Password: values.Password,
      };
      onSubmit(payload);
    },
    [onSubmit]
  );

  const { isFetching } = useSelector<IAppState, IAuthState>(
    (state) => state.auth
  );

  return (
    <Form name="LoginForm" onFinish={onFinish} labelCol={{ span: 7 }}>
      <Form.Item
        name="Username"
        rules={validate.required({
          message: t("LOGIN_USERNAME_VALIDATION_REQUIRED_MESSAGE"),
        })}
        label={t("EMAIL_OR_LOGIN_LABEL")}
      >
        <Input
          autoFocus
          className="form-field"
          placeholder={t("EMAIL_OR_LOGIN_PLACEHOLDER")}
        />
      </Form.Item>
      <Form.Item
        name="Password"
        rules={validate.required({
          message: t("LOGIN_PASSWORD_VALIDATION_REQUIRED_MESSAGE"),
        })}
        label={t("PASSWORD_LABEL")}
      >
        <InputPassword
          visibilityToggle={false}
          placeholder={t("PASSWORD_PLACEHOLDER")}
        />
      </Form.Item>
      <Form.Item style={{ textAlign: "right", paddingTop: "16px" }}>
        <Button
          loading={isFetching}
          type="primary"
          htmlType="submit"
          block={false}
        >
          {t("LOGIN_BUTTON")}
        </Button>
      </Form.Item>
    </Form>
  );
};
