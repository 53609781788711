import { AxiosResponse } from "axios";
import { Observable, Observer, BehaviorSubject } from "rxjs";
import PQueue, { AbortError } from "p-queue/dist";
import { HTTP_METHOD, STORAGE } from "../constants";
import {
  OperationResultType,
  StorageProvider,
  FileUploadStatus,
} from "../enums";
import {
  IErrorModel,
  IUploadFileInfoHeaderModel,
  IUploadMultipartFileInfoModel,
  IUploadPartFileInfoModel,
  OperationResult,
  StorageOptions,
  StorageUploadFileInfo,
  UploadFileInfoModel,
} from "../models";
import { AWSStorageProvider } from "./AWSStorageProvider";
import { AxiosSubscriber } from "./AxiosSubscriber";
import { InternalStorageProvider } from "./InternalStorageProvider";
import { IStorageProvider } from "./IStorageProvider";
import { GuidHelper } from "../helpers";

const FILE_CHUNK_SIZE = 10_000_000;
export interface IFileStorage {
  [key: string]: {
    file?: File | Blob;
    status?: FileUploadStatus;
    progress?: number;
    parts?: {
      [key: number]: {
        status?: FileUploadStatus;
        progress?: number;
      };
    };
  };
}

export class StorageService {
  public static instance: StorageService;

  public _provider: IStorageProvider = new InternalStorageProvider();
  private _files = new BehaviorSubject<IFileStorage>({});
  public files$ = this._files;

  public static getInstance() {
    if (StorageService.instance) {
      return StorageService.instance;
    }

    StorageService.instance = new StorageService();

    return StorageService.instance;
  }

  public init = (options: StorageOptions): void => {
    switch (options.Provider) {
      case StorageProvider.AWS:
        this._provider = new AWSStorageProvider(options);
        break;
      case StorageProvider.Azure:
        break;
      default:
        this._provider = new InternalStorageProvider();
        break;
    }
  };

  public getFileUploadChunksNumber = (file: File | Blob): number => {
    if (file.size < FILE_CHUNK_SIZE) {
      return 1;
    }

    let chunks = Math.floor(file.size / FILE_CHUNK_SIZE);

    if (file.size % FILE_CHUNK_SIZE > 0) {
      chunks++;
    }

    return chunks;
  };

  public uploadFilePart = (
    filePart: Blob,
    filePartInfo: IUploadPartFileInfoModel,
    fileUploadMethod: string = HTTP_METHOD.PUT,
    fileKey?: string | number,
    onProgress?: (
      e: ProgressEvent,
      filePartInfo: IUploadPartFileInfoModel
    ) => void,
    abortSignal?: AbortSignal
  ): Promise<OperationResult<IUploadPartFileInfoModel>> => {
    return new Promise((resolve, reject) => {
      const observable = new Observable((observer: Observer<AxiosResponse>) => {
        return new AxiosSubscriber(observer, {
          axiosConfig: {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            transformRequest: (data: any, headers?: any) => {
              delete headers["Authorization"];

              return data;
            },
            onUploadProgress: (e: ProgressEvent) => {
              onProgress?.(e, filePartInfo);
            },
            signal: abortSignal,
          },
          data: filePart,
          method: fileUploadMethod,
          url: filePartInfo.Url,
          entireResponse: true,
        });
      });

      if (fileKey) {
        let files = this._files.getValue();
        let fileStatus = files[fileKey];

        if (!fileStatus.parts) {
          fileStatus.parts = {};
        }

        fileStatus.parts[filePartInfo.Number] = {
          progress: 0,
          status: FileUploadStatus.Uploading,
        };

        files[fileKey] = fileStatus;

        this._files.next(files);
      }

      return observable
        .toPromise()
        .then((response: AxiosResponse) => {
          if (response && response.headers) {
            filePartInfo.ETag = response.headers["etag"];
          }

          if (fileKey) {
            let files = this._files.getValue();
            let fileStatus = files[fileKey];

            if (!fileStatus.parts) {
              fileStatus.parts = {};
            }

            let filePartStatus = fileStatus.parts[filePartInfo.Number];
            (filePartStatus.status = FileUploadStatus.Completed),
              (filePartStatus.progress = 100);
            files[fileKey] = fileStatus;
            this._files.next(files);
          }

          resolve({
            ResultType: OperationResultType.Ok,
            Message: `Successfully uploaded file part ${filePartInfo.Number}.`,
            Result: filePartInfo,
          });
        })
        .catch((error: IErrorModel) => {
          if (fileKey) {
            let files = this._files.getValue();
            let fileStatus = files[fileKey] ?? {};

            if (!fileStatus.parts) {
              fileStatus.parts = {};
            }

            let filePartStatus = fileStatus.parts[filePartInfo.Number];
            (filePartStatus.status = FileUploadStatus.Failed),
              (files[fileKey] = fileStatus);
            this._files.next(files);
          }
          reject({
            ResultType: OperationResultType.Error,
            Message:
              `There was an error uploading file part ${filePartInfo.Number}: ` +
              error.Message,
          });
        });
    });
  };

  public uploadFileMultipart = async (
    file: File | Blob,
    fileMultipartUploadInfo: IUploadMultipartFileInfoModel,
    fileKey?: string,
    onProgress?: (e: { percent: number }) => void,
    abortController?: AbortController
  ): Promise<OperationResult<IUploadMultipartFileInfoModel>> => {
    const self = this;
    const fileStatusKey = fileKey || GuidHelper.newGuid();

    if (
      !fileMultipartUploadInfo.Parts ||
      fileMultipartUploadInfo.Parts.length === 0
    ) {
      const error: IErrorModel = {
        Message: "Empty parts info",
      };
      return Promise.reject(error);
    }

    const onAbortUploadFile = () => {
      if (abortController) {
        abortController.signal.removeEventListener("abort", onAbortUploadFile);
      }
      const error: IErrorModel = {
        Message: "Abort",
        Code: STORAGE.UPLOAD_ABORT_ERROR_CODE,
      };
      return Promise.reject(error);
    };

    if (abortController) {
      abortController.signal.addEventListener("abort", onAbortUploadFile);
    }

    // Init file status
    let files = this._files.getValue();
    let fileStatus = files[fileStatusKey] || {};

    if (!fileStatus.parts) {
      fileStatus.parts = {};
    }

    (fileStatus.status = FileUploadStatus.Uploading), (fileStatus.progress = 0);

    const uploadFilePartsPromises: ((options: {
      signal?: AbortSignal;
    }) => Promise<OperationResult<IUploadPartFileInfoModel>>)[] = [];

    for (const filePartInfo of fileMultipartUploadInfo.Parts) {
      const start = (filePartInfo.Number - 1) * FILE_CHUNK_SIZE;
      const end = filePartInfo.Number * FILE_CHUNK_SIZE;
      const filePart =
        filePartInfo.Number < fileMultipartUploadInfo.Parts.length
          ? file.slice(start, end)
          : file.slice(start);

      fileStatus.parts[filePartInfo.Number] = { progress: 0 };

      uploadFilePartsPromises.push(({ signal }: { signal?: AbortSignal }) =>
        self.uploadFilePart(
          filePart,
          filePartInfo,
          fileMultipartUploadInfo.Method,
          fileStatusKey,
          (e: ProgressEvent, filePartInfo: IUploadPartFileInfoModel) => {
            let files = this._files.getValue();
            let fileStatus = files[fileStatusKey];

            if (!fileStatus.parts) {
              fileStatus.parts = {};
            }

            let filePartStatus = fileStatus.parts[filePartInfo.Number];
            filePartStatus.progress = (e.loaded / e.total) * 100;
            files[fileStatusKey] = fileStatus;
            this._files.next(files);

            let progress = 0;
            let count = 0;

            for (let filePartKey in fileStatus.parts) {
              progress += fileStatus.parts[filePartKey].progress || 0;
              count++;
            }

            onProgress?.({ percent: progress / count });
          },
          signal
        )
      );
    }

    files[fileStatusKey] = fileStatus;
    this._files.next(files);

    const queue = new PQueue({ concurrency: 50 });

    try {
      await queue.addAll(uploadFilePartsPromises, {
        signal: abortController?.signal,
      });

      return Promise.resolve({
        ResultType: OperationResultType.Ok,
        Message: "Successfully uploaded file.",
        Result: fileMultipartUploadInfo,
      });
    } catch (err) {
      if (!(err instanceof AbortError)) {
        const error = err as IErrorModel;
        return Promise.reject(error);
      }

      const error: IErrorModel = {
        Message: "Abort",
        Code: STORAGE.UPLOAD_ABORT_ERROR_CODE,
      };
      return Promise.reject(error);
    }
  };

  public uploadFile = (
    file: File | Blob,
    fileUploadInfo: UploadFileInfoModel,
    fileKey?: string,
    onProgress?: (e: { percent: number }) => void,
    abortController?: AbortController
  ): Promise<OperationResult<UploadFileInfoModel>> => {
    const fileStatusKey = fileKey || GuidHelper.newGuid();

    return new Promise((resolve, reject) => {
      const onAbortUploadFile = () => {
        if (abortController) {
          abortController.signal.removeEventListener(
            "abort",
            onAbortUploadFile
          );
        }
        const error: IErrorModel = {
          Message: "Abort",
          Code: STORAGE.UPLOAD_ABORT_ERROR_CODE,
        };
        reject(error);
      };

      if (abortController) {
        abortController.signal.addEventListener("abort", onAbortUploadFile);
      }

      // Init file status
      let files = this._files.getValue();

      files[fileStatusKey] = {
        status: FileUploadStatus.Uploading,
        progress: 0,
      };
      this._files.next(files);

      const observable = new Observable(
        (observer: Observer<OperationResult<UploadFileInfoModel>>) => {
          const _headers =
            fileUploadInfo.Headers &&
            fileUploadInfo.Headers.reduce(
              (
                headers: Record<string, string>,
                header: IUploadFileInfoHeaderModel
              ) => {
                headers[header.Key] = header.Value;
                return headers;
              },
              {}
            );

          return new AxiosSubscriber(observer, {
            axiosConfig: {
              headers: {
                ..._headers,
                "Content-Type": file.type ?? "application/octet-stream",
              },
              transformRequest: (data: any, headers?: any) => {
                delete headers["Authorization"];

                return data;
              },
              onUploadProgress: (e: ProgressEvent) => {
                onProgress?.({ percent: (e.loaded / e.total) * 100 });
              },
              signal: abortController?.signal,
            },
            data: file,
            method: fileUploadInfo.Method,
            url: fileUploadInfo.Url,
          });
        }
      );

      return observable
        .toPromise()
        .then(() => {
          let files = this._files.getValue();
          let fileStatus = files[fileStatusKey] ?? {};
          (fileStatus.status = FileUploadStatus.Completed),
            (fileStatus.progress = 100);
          files[fileStatusKey] = fileStatus;
          this._files.next(files);

          onProgress?.({ percent: 100 });

          resolve({
            ResultType: OperationResultType.Ok,
            Message: "Successfully uploaded file.",
            Result: fileUploadInfo,
          });
        })
        .catch((error: IErrorModel) => {
          let files = this._files.getValue();
          let fileStatus = files[fileStatusKey] ?? {};
          (fileStatus.status = FileUploadStatus.Failed),
            (files[fileStatusKey] = fileStatus);
          this._files.next(files);

          reject({
            ResultType: OperationResultType.Error,
            Message: "There was an error uploading your file: " + error.Message,
          });
        });
    });
  };

  public getFile = (
    fileUploadInfo: UploadFileInfoModel
  ): Promise<OperationResult<Blob>> => {
    return new Promise((resolve, reject) => {
      const observable = new Observable((observer: Observer<Blob>) => {
        const _headers =
          fileUploadInfo.Headers &&
          fileUploadInfo.Headers.reduce(
            (
              headers: Record<string, string>,
              header: IUploadFileInfoHeaderModel
            ) => {
              headers[header.Key] = header.Value;
              return headers;
            },
            {}
          );

        return new AxiosSubscriber(observer, {
          axiosConfig: {
            headers: {
              ..._headers,
              Authorization: "",
            },
            responseType: "blob",
          },
          method: fileUploadInfo.Method,
          url: fileUploadInfo.Url,
        });
      });

      return observable
        .toPromise()
        .then((file) => {
          resolve({
            Message: "Successfully downloaded file.",
            Result: file,
            ResultType: OperationResultType.Ok,
          });
        })
        .catch((error: IErrorModel) => {
          reject({
            Message:
              "There was an error downloading your file: " + error.Message,
            ResultType: OperationResultType.Error,
          });
        });
    });
  };

  public deleteFile(
    filePath: string,
    fileKey?: string
  ): Promise<OperationResult<StorageUploadFileInfo>> {
    return this._provider.deleteFile(filePath, fileKey);
  }
}
