import { Menu as AntMenu } from "antd";
import { SubMenuProps as AntSubMenuProps } from "antd/lib/menu/SubMenu";
import React from "react";

export interface SubMenuProps extends AntSubMenuProps {}

export class SubMenu extends React.PureComponent<SubMenuProps> {
  public render() {
    const { children, ...rest } = this.props;
    return <AntMenu.SubMenu {...rest}>{children}</AntMenu.SubMenu>;
  }
}
