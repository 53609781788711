import React from "react";
import {
  HorizontalGridLines,
  YAxis,
  XAxis,
  VerticalBarSeries,
  XYPlot,
  Crosshair,
} from "react-vis";
import "../../../../../node_modules/react-vis/dist/style.css";
import { getColor } from "./colors";
import { CrosshairContent } from "./CrosshairContent";
import { tickFormat, useChartHover, useSeriesRank } from "./helpers";

export interface SeriesPoint {
  x: number | string;
  y: number;
}

export interface IStackedBarChartProps {
  height: number;
  width: number;
  series: SeriesPoint[][];
  seriesNames?: string[];
  xAxis?: boolean;
  xDate?: boolean;
}

export const StackedBarChart = React.forwardRef<XYPlot, IStackedBarChartProps>(
  (
    { height, width, series, seriesNames, xAxis, xDate }: IStackedBarChartProps,
    ref
  ) => {
    const {
      highlightedIndex,
      nearestItem,
      onNearestX,
      onSeriesMouseOver,
      onPlotMouseLeave,
    } = useChartHover(series);

    const maxValueRank = useSeriesRank(series);

    return (
      <XYPlot
        ref={ref}
        stackBy="y"
        height={height}
        width={width}
        onMouseLeave={onPlotMouseLeave}
        margin={{
          bottom: xAxis ? 28 : 8,
          top: 8,
          left: 2,
          right: Math.max(48, 16 + 8 * maxValueRank),
        }}
      >
        <HorizontalGridLines style={{ opacity: 0.25 }} />

        {[...series].map((serie, index) => (
          <VerticalBarSeries
            key={index}
            barWidth={0.9}
            color={getColor(index)}
            opacity={
              highlightedIndex === undefined
                ? 0.5
                : highlightedIndex === index
                ? 1
                : 0.3
            }
            onSeriesMouseOver={() => onSeriesMouseOver(index)}
            onNearestX={index === 0 ? onNearestX : undefined}
            data={serie}
          />
        ))}

        {xAxis && (
          <XAxis
            tickValues={series[0].map(({ x }) => x)}
            tickSize={0}
            hideLine
            tickFormat={tickFormat(xDate)}
          />
        )}

        <YAxis orientation="right" hideLine tickSize={4} />

        {nearestItem && (
          <Crosshair
            values={nearestItem}
            style={{
              line: {
                backgroundColor: "#FFF",
              },
            }}
          >
            <CrosshairContent
              xDate={xDate}
              values={nearestItem}
              seriesNames={seriesNames}
              highlighted={highlightedIndex}
            />
          </Crosshair>
        )}
      </XYPlot>
    );
  }
);
