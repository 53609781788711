import React, { memo, useState } from "react";
import {
  IBreadcrumbProps,
  Icon,
  PageHeader,
  Button,
  IFiltersUpdater,
  Input,
  NotificationService,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import {
  AnalyticsService,
  IAnalyticsPaymentListFilterModel,
} from "@bms/common-services";

import "./PaymentListHeader.scss";
import { PaymentListTableDataProviderFilterModel } from "../../../types";
import { ROUTES } from "../../../../../modules/Payment";
import { generateBreadcrumb } from "../../../../../helpers";
import {
  ArrowDownIcon,
  FilterCleanIcon,
  LoadingSpinnerIcon,
} from "../../../../../resources/icons";

const analyticsService = new AnalyticsService().promisify();
const notificationService = NotificationService.getInstance();
const isPaymentImportAvailable =
  process.env.REACT_APP_SHOW_PAYMENT_IMPORT === "true";

interface IPaymentListHeaderProps {
  fullTextSearch: string;
  setFullTextSearch: (newVal: string) => void;
  onRefresh: () => void;
  onInport?: () => void;
  filters: {
    current: IAnalyticsPaymentListFilterModel;
    clear: () => void;
    update: (
      updater: IFiltersUpdater<PaymentListTableDataProviderFilterModel>
    ) => void;
  };
}

export const PaymentListHeader = memo(
  ({ filters, onRefresh, onInport }: IPaymentListHeaderProps) => {
    const { t } = useTranslation();
    const [downloadInProgress, setDownloadInProgress] = useState(false);

    const onFilterChange = (
      key: keyof IAnalyticsPaymentListFilterModel,
      value: any,
      setLocalValue?: (val: any) => void
    ) => {
      filters.update((oldFilters) => ({
        ...oldFilters,
        [key]: value,
      }));
      setLocalValue?.(value);
    };

    const onExport = () => {
      const downloadAnchor = document.createElement("a");
      let urlObject: string;
      setDownloadInProgress(true);
      analyticsService
        .downloadPaymentListReport(filters.current)
        .then((response: any) => {
          urlObject = URL.createObjectURL(
            new Blob([response.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          downloadAnchor.href = urlObject;
          downloadAnchor.download = `payments_list_report__${new Date().getTime()}.xlsx`;
          document.body.appendChild(downloadAnchor);
          downloadAnchor.click();
        })
        .catch((err) => {
          notificationService.error({
            message: t("LOADING_DATA_ERROR_MESSAGE"),
            description: err.Message,
          });
        })
        .finally(() => {
          setDownloadInProgress(false);
          document.body.removeChild(downloadAnchor);
          if (urlObject) {
            URL.revokeObjectURL(urlObject);
          }
        });
    };

    const getBreadcrumbProps = (): IBreadcrumbProps => {
      return generateBreadcrumb([
        {
          path: `${ROUTES.PAYMENT_LIST}`,
          breadcrumbName: t("PAYMENT_LIST_TITLE"),
        },
      ]);
    };

    return (
      <PageHeader
        title={t("PAYMENT_LIST_TITLE")}
        breadcrumb={getBreadcrumbProps()}
        extra={
          <div className="PaymentListHeader__container">
            <div className="PaymentListHeader__container-row PaymentListHeader__container-row--reverse">
              <Button
                shape="circle"
                icon={<FilterCleanIcon />}
                onClick={filters.clear}
                title={t("BUTTON_RESET")}
              />
              <Button
                shape="circle"
                icon={<Icon type="reload" />}
                onClick={onRefresh}
                title={t("BUTTON_REFRESH_TITLE")}
              />
              <Button
                key="export"
                shape="circle"
                onClick={onExport}
                icon={<Icon type="export" />}
                loading={downloadInProgress}
                disabled={downloadInProgress}
                title={t("PAYMENT_LIST_BUTTON_EXPORT")}
              />
              {isPaymentImportAvailable && (
                <Button
                  key="import"
                  shape="circle"
                  icon={<Icon type="import" />}
                  onClick={() => onInport?.()}
                  title={t("PAYMENT_LIST_BUTTON_IMPORT")}
                />
              )}
              <Input
                placeholder={t("SEARCH_PLACEHOLDER")}
                value={filters.current.FullTextSearch}
                onChange={(val) =>
                  onFilterChange("FullTextSearch", val.target.value)
                }
                style={{ minWidth: 250, width: "50%" }}
                allowClear
              />
            </div>
          </div>
        }
      />
    );
  }
);
