import React from "react";
import { Upload as AntUpload } from "antd";
import {
  DraggerProps as AntDraggerProps,
  UploadChangeParam,
} from "antd/lib/upload";
import { FileHelper } from "../../helpers";

const AntDragger = AntUpload.Dragger;

export interface IDraggerProps extends AntDraggerProps {
  withPreview?: boolean;
  maxCount?: number
}

interface IDraggerState {
  preview: string;
}

export interface IDaggerChangeEvent extends UploadChangeParam {}

export class Dragger extends React.PureComponent<IDraggerProps, IDraggerState> {
  public state = {
    preview: "",
  };

  public componentDidUpdate = (prevProps: IDraggerProps) => {
    if (prevProps.withPreview && !this.props.withPreview) {
      this.setState({ preview: "" });
    }
  };

  private disableAjaxRequest = () => {
    return;
  };

  private onChange = (e: IDaggerChangeEvent) => {
    if (this.props.withPreview) {
      FileHelper.fileToBase64(e.file.originFileObj as File).then(
        (base64image: string) => {
          this.setState({ preview: base64image });
        }
      );
    }

    return this.props.onChange && this.props.onChange(e);
  };

  public render() {
    const { children, customRequest, ...rest } = this.props;

    return (
      <AntDragger
        {...rest}
        customRequest={customRequest || this.disableAjaxRequest}
        onChange={this.onChange}
        openFileDialogOnClick={true}
      >
        {typeof children === "function"
          ? children(this.state.preview)
          : children}
      </AntDragger>
    );
  }
}
