import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  AssetStore,
  ICommonAppState,
  IProductModel,
  ProductsService,
} from "@bms/common-services";
import {
  AgeRestrictionTag,
  Button,
  IBreadcrumbProps,
  Icon,
  InputSearch,
  ITableColumnFilterDropdownProps,
  ITableColumnProps,
  ITableFilter,
  Link,
  NotificationService,
  PageContent,
  PageHeader,
  Pagination,
  setTableColumnSearchProps,
  Slider,
  Table,
  Tag,
} from "@bms/common-ui";
import { generateBreadcrumb, useTableDataProvider } from "../../../../helpers";
import { ROUTES } from "../../constants";

const notificationService = NotificationService.getInstance();
const productsService = new ProductsService().promisify();

const assetSelector = (state: ICommonAppState) => state.asset;

export const ProductsList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    assetTypes: { data: assetTypesData = [] },
  } = useSelector(assetSelector);

  useEffect(() => {
    dispatch(AssetStore.Actions.getAssetTypes());
  }, [dispatch]);

  const {
    dataLoader: productSearchLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Title: "string",
      Types: "strings",
      AgeRestrictionFrom: "number",
    },
    loader: (filters, pagination) =>
      productsService.search({
        ...filters,
        ...pagination,
      }),
    deps: [],
    onError: (error) =>
      notificationService.error({
        message: t(
          "LOADING_DATA_ERROR_MESSAGE",
          "There was an error while loading data."
        ),
        description: error.Message,
      }),
  });

  const columns: Array<ITableColumnProps<IProductModel>> = [
    {
      key: "Title",
      dataIndex: "Title",
      title: t("MODEL_TITLE"),
      width: "300px",
      ellipsis: true,
      filteredValue: filters.asTableArray.Title || null,
      render: (_, row) => {
        return (
          <Link to={`${ROUTES.PRODUCTS_DETAILS}/${row.Id}`}>{row.Title}</Link>
        );
      },
      ...setTableColumnSearchProps("Title", t("MODEL_TITLE")),
    },
    {
      key: "Description",
      dataIndex: "Description",
      align: "left",
      ellipsis: true,
      title: t("MODEL_DESCRIPTION"),
    },
    {
      key: "Types",
      dataIndex: "Types",
      align: "center",
      width: "180px",
      title: t("MODEL_TYPE_CODE"),
      filters: assetTypesData.map((assetType) => ({
        text: assetType.DisplayName,
        value: assetType.Code,
      })),
      filteredValue: filters.asTableArray.Types || null,
      render: (_, row) => (
        <Tag
          colorRotate={assetTypesData.findIndex(
            ({ Code }) => Code === row.TypeCode
          )}
        >
          {row.TypeCode}
        </Tag>
      ),
    },
    {
      key: "AgeRestrictionFrom",
      dataIndex: "AgeRestrictionFrom",
      title: t("MODEL_MIN_AGE"),
      align: "center",
      width: "180px",
      filteredValue: filters.asTableArray.AgeRestrictionFrom || null,
      filterDropdown: (event: ITableColumnFilterDropdownProps) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = event;
        const filterValue: [number] | undefined =
          selectedKeys && selectedKeys.length > 0
            ? [+selectedKeys[0]]
            : undefined;

        return (
          <div style={{ padding: 8 }}>
            <Slider
              min={0}
              max={18}
              value={filterValue?.[0] || 0}
              onChange={(value: number) => {
                if (setSelectedKeys) {
                  setSelectedKeys([value]);
                }
              }}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="link"
                size="small"
                disabled={!filterValue}
                onClick={() => {
                  if (clearFilters) {
                    if (setSelectedKeys) {
                      setSelectedKeys([]);
                    }
                    clearFilters();
                  }
                }}
              >
                <Trans i18nKey="BUTTON_RESET">Reset</Trans>
              </Button>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  filters.update((oldFilters) => ({
                    ...oldFilters,
                    AgeRestrictionFrom: filterValue?.[0],
                  }));
                  confirm();
                }}
              >
                <Trans i18nKey="BUTTON_OK">OK</Trans>
              </Button>
            </div>
          </div>
        );
      },
      render: (_, row) => <AgeRestrictionTag age={row.AgeRestrictionFrom} />,
    },
  ];

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value || undefined,
    }));

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
    }));
  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.PRODUCTS_LIST}`,
        breadcrumbName: t("PRODUCT_LIST_TITLE"),
      },
    ]);
  };

  return (
    <PageContent footer={<Pagination {...pagination.props} />}>
      <PageHeader
        title={t("PRODUCT_LIST_TITLE")}
        breadcrumb={getBreadcrumbProps()}
        extra={
          <>
            <InputSearch
              placeholder={t("SEARCH_PLACEHOLDER")}
              defaultValue={fullTextSearch}
              onChange={({ target: { value } }) => setFullTextSearch(value)}
              onSearch={onSearch}
              style={{ width: 250 }}
              allowClear
            />
            <Button
              shape="circle"
              icon={<Icon type="reload" />}
              onClick={productSearchLoader.refresh}
              title={t("BUTTON_REFRESH_TITLE")}
            />
          </>
        }
      />
      <Table<IProductModel>
        rowKey="Id"
        columns={columns}
        dataSource={productSearchLoader.data?.Entities}
        loading={productSearchLoader.loading}
        pagination={false}
        onChange={onTableChange}
      />
    </PageContent>
  );
};
