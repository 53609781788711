/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "./AxiosSubscriber";

import { HTTP_METHOD } from "../../../../constants";
import {
  IPlaylistFilterModel,
  IPlaylistListModel,
  IPlaylistModel,
} from "../models";

export class PlayListService {
  get url(): string {
    return "/playlists";
  }

  public get = (id: string): Observable<IPlaylistModel> => {
    return new Observable(
      (observer: Observer<IPlaylistModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/${id}`,
        })
    );
  };

  public search = (
    filter: IPlaylistFilterModel
  ): Observable<IPlaylistListModel> => {
    const { page = 1, page_length = 10, sort = "created:dsc", title } = filter;
    const q = "playlist_type:+NOT+watchlist";

    const params = {
      page,
      page_length,
      sort,
    };
    return new Observable(
      (observer: Observer<IPlaylistListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}`,
        })
    );
  };
}
