import {
  INotificationModel,
  INotificationListModel,
  INotificationSearchFilterModel,
  IUserNotificationSearchFilterModel,
  IUserNotificationListModel,
} from "../../models";
import { SimpleServiceBase } from "../Base";
import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants/http";
import { AxiosSubscriber } from "../AxiosSubscriber";

export class NotificationsService extends SimpleServiceBase<
  INotificationModel,
  INotificationListModel,
  number,
  INotificationSearchFilterModel
> {
  get url(): string {
    return "/Notifications";
  }

  public searchUserNotifications = (
    filter: IUserNotificationSearchFilterModel
  ): Observable<IUserNotificationListModel> =>
    new Observable(
      (observer: Observer<IUserNotificationListModel>) =>
        new AxiosSubscriber(observer, {
          data: filter,
          method: HTTP_METHOD.POST,
          url: `${this.url}/SearchUserNotifications`,
        })
    );
}
