import React from "react";
import { Result as AntResult } from "antd";
import { ResultProps as AntResultProps } from "antd/lib/result";

export interface IResultProps extends AntResultProps {}

export class Result extends React.PureComponent<IResultProps> {
  public render() {
    let { children, ...rest } = this.props;

    return <AntResult {...rest}>{children}</AntResult>;
  }
}
