import React from "react";
import { Input, InputProps } from "../Input";
import { GradientInputContainer } from "./GradientInputContainer";
import "./GradientInput.scss";

export const GradientInput = React.forwardRef<Input>((props, ref) => {
  return (
    <GradientInputContainer>
      <Input className="form-field" ref={ref} {...props} />
    </GradientInputContainer>
  );
});
