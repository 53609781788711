import * as TextComponentsStore from "./textComponents";
import * as AssetStore from "./asset";
import * as CommonStore from "./common";
import * as ConfigStore from "./config";
import * as QuizStore from "./quiz";
import * as AuthStore from "./auth";
import * as AdvertisementStore from "./advertisement";
import * as ApplicationConfigurationStore from "./applicationConfiguration";
import * as NotificationStore from "./notifications";
import * as SignalRStore from "./signalR";
import * as UserStore from "./user";
import * as CurrencyStore from "./currencies";
import * as PaymentStore from "./payments";
import * as SourceEntitiesStore from "./sourceEntities";

export {
  AdvertisementStore,
  AssetStore,
  AuthStore,
  ApplicationConfigurationStore,
  CommonStore,
  ConfigStore,
  NotificationStore,
  SignalRStore,
  TextComponentsStore,
  QuizStore,
  UserStore,
  CurrencyStore,
  PaymentStore,
  SourceEntitiesStore,
};

export * from "./auth/types";

export * from "./types";
export * from "./actions";

declare global {
  // tslint:disable-next-line
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function; // tslint:disable-line
  }
}
