import {
  AssetStore,
  DataProvider,
  IAssetCollectionModel,
  ICommonAppState,
  IErrorModel,
  ServiceResponse,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import {
  Button,
  Icon,
  Modal,
  NotificationService,
  PageContent,
  PageHeader,
  SectionGrid,
  SectionGridItem,
  TabPane,
  Tabs,
  useSearchParamsTabs,
} from "@bms/common-ui";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { ROUTES } from "../../../../constants";
import { AssetCollectionAssetsGrid } from "../AssetCollectionAssetsGrid";
import { AssetCollectionAssetsTransfer } from "../AssetCollectionAssetsTransfer";
import { AssetCollectionFiltersForm } from "../AssetCollectionFiltersForm";
import { AssetCollectionForm } from "../AssetCollectionForm";
import "./AssetCollectionDetails.scss";
import { generateBreadcrumb } from "../../../../helpers";

const assetCollectionSelector = (state: ICommonAppState) => state.asset;
const notificationService = NotificationService.getInstance();

export const AssetCollectionDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { assetCollection, action } = useSelector(assetCollectionSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeTabKey, setActiveTabKey] = useSearchParamsTabs("DETAILS");

  const getAssetsCollection = useCallback(
    () => dispatch(AssetStore.Actions.getAssetsCollection(parseInt(id, 10))),
    [dispatch]
  );

  useEffect(() => {
    getAssetsCollection();
  }, []);

  const DeleteAssetsCollectionButton = () => (
    <Button
      icon={<Icon type="delete" />}
      shape="circle"
      title={t("DELETE_COLLECTION")}
      danger
      onClick={() => {
        Modal.confirm({
          title: t("DELETE_PLAYLIST"),
          content: t("DELETE_PLAYLIST_CONFIRMATION_MESSAGE"),
          okText: t("BUTTON_DELETE"),
          cancelText: t("BUTTON_CANCEL"),
          visible: true,
          onOk: () => {
            dispatch(
              AssetStore.Actions.deleteAssetsCollection(assetCollection.Data!)
            );
          },
        });
      }}
    />
  );

  useEffect(() => {
    switch (action?.type) {
      case "CREATE_ASSETS_COLLECTION_SUCCESS":
        notificationService.success({
          message: t("ASSET_COLLECTION__ADD_PLAYLIST_COLLECTION_SUCCESS"),
        });
        break;
      case "CREATE_ASSETS_COLLECTION_FAILURE":
        notificationService.error({
          message: t("ASSET_COLLECTION__ADD_PLAYLIST_COLLECTION_FAILURE"),
          description: action?.error?.Message,
        });
        break;
      case "DELETE_ASSETS_COLLECTION_SUCCESS":
        notificationService.success({
          message: t("ASSET_COLLECTION__DELETE_PLAYLIST_SUCCESS"),
        });
        history.push(ROUTES.ASSET_COLLECTION_LIST);
        Modal.destroyAll();
        break;
      case "DELETE_ASSETS_COLLECTION_FAILURE":
        notificationService.error({
          message: t("ASSET_COLLECTION__DELETE_PLAYLIST_FAILURE"),
          description: action?.error?.Message,
        });
        Modal.destroyAll();
        break;
    }
  }, [action]);

  const assetCollectionLoader = useDataLoader<
    IAssetCollectionModel,
    IErrorModel
  >({
    loader: () => {
      return DataProvider.AssetCollection.get(id)
        .then((data) => {
          return {
            ok: true,
            data,
          } as ServiceResponse<IAssetCollectionModel, IErrorModel>;
        })
        .catch((error) => {
          return {
            ok: false,
            error,
          };
        });
    },
    deps: [id],
  });

  const updateAssetCollection = useCallback((asset: IAssetCollectionModel) => {
    return DataProvider.AssetCollection.update(asset)
      .then((data) => {
        notificationService.success({
          message: t("ASSET_COLLECTION__UPDATE_PLAYLIST_SUCCESS"),
        });
        return assetCollectionLoader.refresh();
      })
      .then(() => {
        return true;
      })
      .catch((error) => {
        notificationService.error({
          message: t("ASSET_COLLECTION__UPDATE_PLAYLIST_FAILURE"),
          description: error?.Message,
        });
        return false;
      });
  }, []);

  const [
    updateAssetCollectionGeneral,
    { processing: generalProcessing },
  ] = useServiceCaller(updateAssetCollection, [updateAssetCollection]);

  const [
    updateAssetCollectionDefinitions,
    { processing: definitionsProcessing },
  ] = useServiceCaller(updateAssetCollection, [updateAssetCollection]);

  return (
    <>
      <PageContent>
        <PageHeader
          title={t("PLAY_LIST_DETAILS_HEADER")}
          onBack={() => history.push(ROUTES.ASSET_COLLECTION_LIST)}
          breadcrumb={generateBreadcrumb([
            {
              path: `${ROUTES.ASSET_COLLECTION_LIST}`,
              breadcrumbName: t("PLAY_LIST_HEADER"),
            },
            {
              path: `${ROUTES.ASSET_COLLECTION_DETAILS}/${id}`,
              breadcrumbName:
                assetCollection.Data?.Name ?? t("PLAY_LIST_DETAILS_HEADER"),
            },
          ])}
          extra={
            <>
              <Button
                key="action-reload"
                icon={<Icon type="reload" />}
                onClick={() => getAssetsCollection()}
                title={t("BUTTON_REFRESH_TITLE")}
                shape="circle"
              />
              {DataProvider.AssetCollection.Options.DeleteEnabled && (
                <DeleteAssetsCollectionButton />
              )}
            </>
          }
        />

        <Tabs activeKey={activeTabKey} onTabClick={setActiveTabKey}>
          <TabPane key="DETAILS" tab={t("PLAY_LIST_DETAILS_TAB_DETAILS")}>
            <SectionGrid style={{ maxWidth: "1200px" }}>
              <SectionGridItem
                processing={generalProcessing}
                header={t("PLAY_LIST_DETAILS_SECTION_GENERAL")}
              >
                <AssetCollectionForm
                  assetCollection={assetCollectionLoader.data}
                  onSubmit={updateAssetCollectionGeneral}
                />
              </SectionGridItem>
              <SectionGridItem
                processing={definitionsProcessing}
                header={t("PLAY_LIST_DETAILS_SECTION_DEFINITIONS")}
              >
                <AssetCollectionFiltersForm
                  assetCollection={assetCollectionLoader.data}
                  onSubmit={updateAssetCollectionDefinitions}
                />
              </SectionGridItem>
            </SectionGrid>
          </TabPane>
          <TabPane key="ASSETS" tab={t("PLAY_LIST_DETAILS_TAB_ASSETS")}>
            <SectionGrid>
              <SectionGridItem>
                <AssetCollectionAssetsTransfer />
              </SectionGridItem>
            </SectionGrid>
          </TabPane>
          <TabPane key="PREVIEW" tab={t("PLAY_LIST_DETAILS_TAB_PREVIEW")}>
            <SectionGrid>
              <SectionGridItem>
                <AssetCollectionAssetsGrid
                  isActive={activeTabKey === "PREVIEW"}
                />
              </SectionGridItem>
            </SectionGrid>
          </TabPane>
        </Tabs>
      </PageContent>
    </>
  );
};
