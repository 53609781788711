/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { HTTP_RESPONSE_TYPE } from "../../../../constants";
import {
  IErrorModel as ICommonErrorModel,
  IAssetCollectionModel,
} from "../../../../models";
import { IPlaylistModel } from "../models";

const EXTERNAL_SOURCE = "JWPlayer";

export class ModelsMapperHelper {
  static toAssetCollectionModel(entity: IPlaylistModel): IAssetCollectionModel {
    let result: IAssetCollectionModel = {
      Id: entity.id,
      Name: entity.metadata?.title,
      Description: entity.metadata?.description,
      Created: entity.created,
      CreatedByFullName: "Unknown",
      ExternalSource: EXTERNAL_SOURCE,
    };

    return result;
  }

  static toErrorModel(error: any): ICommonErrorModel {
    return {
      Message: error?.message,
      ResultType: HTTP_RESPONSE_TYPE.ERROR,
    };
  }
}
