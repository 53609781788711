export const GET_QUIZ_QUESTION = "GET_QUIZ_QUESTION";
export const GET_QUIZ_QUESTION_SUCCESS = "GET_QUIZ_QUESTION_SUCCESS";
export const GET_QUIZ_QUESTION_FAILURE = "GET_QUIZ_QUESTION_FAILURE";

export const DELETE_QUIZ_QUESTION = "DELETE_QUIZ_QUESTION";
export const DELETE_QUIZ_QUESTION_SUCCESS = "DELETE_QUIZ_QUESTION_SUCCESS";
export const DELETE_QUIZ_QUESTION_FAILURE = "DELETE_QUIZ_QUESTION_FAILURE";

export const UPDATE_QUIZ_QUESTION = "UPDATE_QUIZ_QUESTION";
export const UPDATE_QUIZ_QUESTION_SUCCESS = "UPDATE_QUIZ_QUESTION_SUCCESS";
export const UPDATE_QUIZ_QUESTION_FAILURE = "UPDATE_QUIZ_QUESTION_FAILURE";

export const INSERT_QUIZ_QUESTION = "INSERT_QUIZ_QUESTION";
export const INSERT_QUIZ_QUESTION_SUCCESS = "INSERT_QUIZ_QUESTION_SUCCESS";
export const INSERT_QUIZ_QUESTION_FAILURE = "INSERT_QUIZ_QUESTION_FAILURE";

export const GET_QUIZ = "GET_QUIZ";
export const GET_QUIZ_SUCCESS = "GET_QUIZ_SUCCESS";
export const GET_QUIZ_FAILURE = "GET_QUIZ_FAILURE";

export const SEND_QUIZ_TO_REVIEW = "SEND_QUIZ_TO_REVIEW";
export const SEND_QUIZ_TO_REVIEW_SUCCESS = "SEND_QUIZ_TO_REVIEW_SUCCESS";
export const SEND_QUIZ_TO_REVIEW_FAILURE = "SEND_QUIZ_TO_REVIEW_FAILURE";

export const START_QUIZ_REVIEW = "START_QUIZ_REVIEW";
export const START_QUIZ_REVIEW_SUCCESS = "START_QUIZ_REVIEW_SUCCESS";
export const START_QUIZ_REVIEW_FAILURE = "START_QUIZ_REVIEW_FAILURE";

export const CANCEL_QUIZ_REVIEW = "CANCEL_QUIZ_REVIEW";
export const CANCEL_QUIZ_REVIEW_SUCCESS = "CANCEL_QUIZ_REVIEW_SUCCESS";
export const CANCEL_QUIZ_REVIEW_FAILURE = "CANCEL_QUIZ_REVIEW_FAILURE";

export const REJECT_QUIZ = "REJECT_QUIZ";
export const REJECT_QUIZ_SUCCESS = "REJECT_QUIZ_SUCCESS";
export const REJECT_QUIZ_FAILURE = "REJECT_QUIZ_FAILURE";

export const APPROVE_QUIZ = "APPROVE_QUIZ";
export const APPROVE_QUIZ_SUCCESS = "APPROVE_QUIZ_SUCCESS";
export const APPROVE_QUIZ_FAILURE = "APPROVE_QUIZ_FAILURE";

export const BLOCK_QUIZ = "BLOCK_QUIZ";
export const BLOCK_QUIZ_SUCCESS = "BLOCK_QUIZ_SUCCESS";
export const BLOCK_QUIZ_FAILURE = "BLOCK_QUIZ_FAILURE";

export const UNBLOCK_QUIZ = "UNBLOCK_QUIZ";
export const UNBLOCK_QUIZ_SUCCESS = "UNBLOCK_QUIZ_SUCCESS";
export const UNBLOCK_QUIZ_FAILURE = "UNBLOCK_QUIZ_FAILURE";

export const SEARCH_QUIZ = "SEARCH_QUIZ ";
export const SEARCH_QUIZ_SUCCESS = "SEARCH_QUIZ_SUCCESS";
export const SEARCH_QUIZ_FAILURE = "SEARCH_QUIZ_FAILURE";

export const BROWSE_QUIZ = "BROWSE_QUIZ";
export const BROWSE_QUIZ_SUCCESS = "BROWSE_QUIZ_SUCCESS";
export const BROWSE_QUIZ_FAILURE = "BROWSE_QUIZ_FAILURE";
export const BROWSE_QUIZ_CANCEL = "BROWSE_QUIZ_CANCEL";

export const INSERT_QUIZ = "INSERT_QUIZ";
export const INSERT_QUIZ_SUCCESS = "INSERT_QUIZ_SUCCESS";
export const INSERT_QUIZ_FAILURE = "INSERT_QUIZ_FAILURE";

export const UPDATE_QUIZ = "UPDATE_QUIZ";
export const UPDATE_QUIZ_SUCCESS = "UPDATE_QUIZ_SUCCESS";
export const UPDATE_QUIZ_FAILURE = "UPDATE_QUIZ_FAILURE";

export const DELETE_QUIZ = "DELETE_QUIZ";
export const DELETE_QUIZ_SUCCESS = "DELETE_QUIZ_SUCCESS";
export const DELETE_QUIZ_FAILURE = "DELETE_QUIZ_FAILURE";

export const SELECT_CLASS = "SELECT_CLASS";
export const SELECT_CLASS_SUCCESS = "SELECT_CLASS_SUCCESS";
export const SELECT_CLASS_FAILURE = "SELECT_CLASS_FAILURE";

export const SELECT_QUIZ_STATUS = "SELECT_QUIZ_STATUS";
export const SELECT_QUIZ_STATUS_SUCCESS = "SELECT_QUIZ_STATUS_SUCCESS";
export const SELECT_QUIZ_STATUS_FAILURE = "SELECT_QUIZ_STATUS_FAILURE";

export const SELECT_QUIZ_CATEGORY = "SELECT_QUIZ_CATEGORY";
export const SELECT_QUIZ_CATEGORY_SUCCESS = "SELECT_QUIZ_CATEGORY_SUCCESS";
export const SELECT_QUIZ_CATEGORY_FAILURE = "SELECT_QUIZ_CATEGORY_FAILURE";

export const SELECT_USER_TEACHER = "SELECT_USER_TEACHER";
export const SELECT_USER_TEACHER_SUCCESS = "SELECT_USER_TEACHER_SUCCESS";
export const SELECT_USER_TEACHER_FAILURE = "SELECT_USER_TEACHER_FAILURE";

export const SELECT_USER_REVIEWER = "SELECT_USER_REVIEWER";
export const SELECT_USER_REVIEWER_SUCCESS = "SELECT_USER_REVIEWER_SUCCESS";
export const SELECT_USER_REVIEWER_FAILURE = "SELECT_USER_REVIEWER_FAILURE";

export const SEARCH_LESSON = "SEARCH_LESSON ";
export const SEARCH_LESSON_SUCCESS = "SEARCH_LESSON_SUCCESS";
export const SEARCH_LESSON_FAILURE = "SEARCH_LESSON_FAILURE";

export const UPDATE_COLLECTION_USER_CLASS_QUIZ_CATEGORY =
  "UPDATE_COLLECTION_USER_CLASS_QUIZ_CATEGORY";
export const UPDATE_COLLECTION_USER_CLASS_QUIZ_CATEGORY_SUCCESS =
  "UPDATE_COLLECTION_USER_CLASS_QUIZ_CATEGORY_SUCCESS";
export const UPDATE_COLLECTION_USER_CLASS_QUIZ_CATEGORY_FAILURE =
  "UPDATE_COLLECTION_USER_CLASS_QUIZ_CATEGORY_FAILURE";

export const SELECT_USER_CLASS_QUIZ_CATEGORY =
  "SELECT_USER_CLASS_QUIZ_CATEGORY";
export const SELECT_USER_CLASS_QUIZ_CATEGORY_SUCCESS =
  "SELECT_USER_CLASS_QUIZ_CATEGORY_SUCCESS";
export const SELECT_USER_CLASS_QUIZ_CATEGORY_FAILURE =
  "SELECT_USER_CLASS_QUIZ_CATEGORY_FAILURE";

export const SELECT_CURRENT_USER_CLASS_QUIZ_CATEGORY =
  "SELECT_CURRENT_USER_CLASS_QUIZ_CATEGORY";
export const SELECT_CURRENT_USER_CLASS_QUIZ_CATEGORY_SUCCESS =
  "SELECT_CURRENT_USER_CLASS_QUIZ_CATEGORY_SUCCESS";
export const SELECT_CURRENT_USER_CLASS_QUIZ_CATEGORY_FAILURE =
  "SELECT_CURRENT_USER_CLASS_QUIZ_CATEGORY_FAILURE";

export const SELECT_CLASS_QUIZ_CATEGORY = "SELECT_CLASS_QUIZ_CATEGORY";
export const SELECT_CLASS_QUIZ_CATEGORY_SUCCESS =
  "SELECT_CLASS_QUIZ_CATEGORY_SUCCESS";
export const SELECT_CLASS_QUIZ_CATEGORY_FAILURE =
  "SELECT_CLASS_QUIZ_CATEGORY_FAILURE";
