import {
  IProfileModel,
  IUserModel,
  RecordStatus,
  UserService,
  useServiceCaller
} from "@bms/common-services";
import {
  Choose,
  ChooseOption,
  Col,
  Form,
  Icon,
  IDaggerChangeEvent,
  Input,
  NotificationService,
  Row,
  Switch,
  useValidate
} from "@bms/common-ui";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ROUTES } from "../../constants";
import { UserAvatarDragger } from "../UserAvatarDragger";
import { FormModal } from "../../../../components";
import "./UserCreateModalForm.scss";

const notificationService = NotificationService.getInstance();
const userService = new UserService().promisify();

interface UserCreateModalFormProps {
  visible: boolean;
  onCancel: () => void;
  profiles: IProfileModel[];
  viewType: "customers" | "administrators" | "all";
}

export const UserCreateModalForm: React.FC<UserCreateModalFormProps> = ({
  visible,
  onCancel,
  profiles,
  viewType,
}) => {
  const [t] = useTranslation();
  const { push } = useHistory();
  const validate = useValidate();
  const [form] = Form.useForm();

  const [createUser, { processing }] = useServiceCaller(
    async (data: IUserModel) => {
      const result = await userService.insert(data);
      if (!result.ok) {
        notificationService.error({
          message: t("USER_CREATE_INSERT_USER_FAILURE"),
          description: result.error.Message || result.error,
        });
      } else {
        notificationService.success({
          message: t("USER_CREATE_INSERT_USER_SUCCESS"),
        });
        if (result.data.Id) {
          push(
            `${viewType === "customers" 
            ? ROUTES.CUSTOMER_DETAILS 
            : viewType === "administrators" 
            ? ROUTES.ADMINISTRATOR_DETAILS 
            : ROUTES.USER_DETAILS}/${result.data.Id}`
          );
        }
      }
    },
    []
  );

  const onFinish = useCallback(
    (values) => {
      const payload: IUserModel = {
        Id: -1,
        PhoneNumber: values.PhoneNumber,
        Email: values.Email,
        FullName: values.FullName,
        RecordStatus: RecordStatus.Inserted,
        AvatarFile: values.AvatarFile,
        EmailConfirmed: !values.EmailConfirmed,
        Profiles: values.Profiles.map((id: number) => {
          const profile = profiles.find((profile) => profile.Id === id);
          return (
            profile && {
              ProfileId: profile.Id,
              ProfileCode: profile.Code,
              ProfileName: profile.Name,
              RecordStatus: RecordStatus.Inserted,
            }
          );
        }).filter(Boolean),
      };
      createUser(payload);
    },
    [profiles]
  );

  const onAvatarImageChange = (e: IDaggerChangeEvent) => {
    return e && e.file && e.file.originFileObj;
  };

  return (
    <FormModal
      isVisible={visible}
      isLoading={processing}
      isNewForm={true}
      isDeleteButtonEnabled={false}
      createFormTitle={t("USER_CREATE_ACCOUNT_MODAL_TITLE", "Create a new user account")}
      editFormTitle={t("USER_CREATE_ACCOUNT_MODAL_TITLE", "Create a new user account")}
      modalClassName="UserCreateAccountForm"
      submitFormName="UserCreateAccountForm"
      onCloseModal={onCancel}
      onDeleteButtonClick={()=>{}}
    >
      <Form
        form={form}
        name="UserCreateAccountForm"
        className="UserCreateAccountForm"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="AvatarFile"
          getValueFromEvent={onAvatarImageChange}
          valuePropName="file"
          style={{ textAlign: "center", marginBottom: 0 }}
        >
          <UserAvatarDragger name="Avatar" />
        </Form.Item>
        <Form.Item
          name="FullName"
          label={t("USER_ACCOUNT_FORM_FULL_NAME_LABEL", "Full name")}
          rules={validate.required()}
        >
          <Input
            placeholder={t(
              "USER_ACCOUNT_FORM_FULL_NAME_PLACEHOLDER",
              "Enter full name"
            )}
          />
        </Form.Item>
        <Form.Item
          name="Email"
          label={t("USER_ACCOUNT_FORM_EMAIL_LABEL", "E-mail")}
          rules={[...validate.required(), ...validate.email()]}
        >
          <Input
            addonBefore="@"
            placeholder={t(
              "USER_ACCOUNT_FORM_EMAIL_PLACEHOLDER",
              "Enter e-mail"
            )}
          />
        </Form.Item>
        <Form.Item
          name="PhoneNumber"
          label={t("USER_ACCOUNT_FORM_PHONE_NUMBER_LABEL", "Phone number")}
          rules={[...validate.phone(), ...validate.internationalPhone()]}
        >
          <Input
            addonBefore={<Icon type="phone" style={{ color: "inherit" }} />}
            placeholder={t(
              "USER_ACCOUNT_FORM_PHONE_NUMBER_PLACEHOLDER",
              "Enter phone number"
            )}
          />
        </Form.Item>
        <Form.Item
          name="Profiles"
          label={t("USER_ACCOUNT_FORM_PROFILE_LABEL", "Profile")}
          rules={validate.required({
            choiceField: true,
            message: t(
              "USER_ACCOUNT_FORM_REQUIRE_VALIDATION_MESSAGE",
              "Please select user profile"
            ),
          })}
        >
          <Choose
            mode="multiple"
            placeholder={t(
              "USER_ACCOUNT_FORM_PROFILE_PLACEHOLDER",
              "Select a user profile"
            )}
            optionFilterProp="children"
          >
            {profiles.map((profile) => (
              <ChooseOption key={profile.Id} value={profile.Id}>
                {profile.Name}
              </ChooseOption>
            ))}
          </Choose>
        </Form.Item>
        <Form.Item>
          <Row gutter={8}>
            <Col>
              <Form.Item
                name="EmailConfirmed"
                key="EmailConfirmed"
                valuePropName="checked"
                initialValue="true"
              >
                <Switch style={{ marginRight: "8px" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="EmailConfirmed"
                key="EmailConfirmed"
                valuePropName="checked"
                initialValue="true"
              >
                <label>
                  {t(
                    "USER_ACCOUNT_FORM_SEND_LINK_TO_CREATE_PASSWORD",
                    "Send a link to create a password"
                  )}
                </label>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </FormModal>
  );
};
