import React, { memo, useState } from "react";
import {
  UserService,
  IAnalyticsAssetImpressionsFilterModel,
  IUserModel,
} from "@bms/common-services";
import { useTranslation } from "react-i18next";
import { NotificationService } from "@bms/common-ui";
import { LazyChoose } from "../../../../../components";

interface IAssetImpressionsSelectProps {
  filter: IAnalyticsAssetImpressionsFilterModel;
  onChange?: (newValue: number[]) => void;
}

const notificationService = NotificationService.getInstance();
const userService = new UserService().promisify();

export const AssetImpressionsUserSelect = memo(
  ({ onChange }: IAssetImpressionsSelectProps) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<IUserModel[]>([]);

    const onValueChange = (value: IUserModel[] | IUserModel) => {
      const newValue = Array.isArray(value) ? value : [value];
      setValue(newValue);

      if (onChange) {
        onChange(newValue.map((row) => row.Id));
      }
    };

    return (
      <LazyChoose<IUserModel, IUserModel>
        style={{ width: "100%" }}
        value={value}
        loader={(search: string) =>
          userService.search({
            PageNumber: 1,
            PageSize: 100,
            FullTextSearch: search,
          })
        }
        candidateToOption={(user) => ({
          label: `${user.FullName} (${user.Email})`,
          value: `${user.Id}`,
          item: user,
        })}
        selectedToOption={(user) => ({
          label: `${user.FullName || user.Email}`,
          value: `${user.Id}`,
          item: user,
        })}
        placeholder={t("ASSET_IMPRESSIONS_USERS_PLACEHOLDER")}
        onChange={onValueChange}
        onError={(error) =>
          notificationService.error({
            message: error.Title,
            description: error.Message,
          })
        }
        getPopupContainer={(trigger) => trigger.parentNode}
        multiple
      />
    );
  }
);
