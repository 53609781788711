import {
  ApplicationResourceType,
  ItemsAlignmentType,
} from "@bms/common-services";
import {
  Button,
  Choose,
  Col,
  Form,
  IChooseValue,
  Icon,
  IFormInstance,
  IFormValues,
  Input,
  InputNumber,
  Row,
} from "@bms/common-ui";
import { ItemsAlignmentTypeHelper } from "../../../../helpers";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ApplicationFooterPropertyModel } from "../../models";
import { ApplicationResourceUploadModal } from "../ApplicationResourceUploadModal";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import { ImagePreviewModal } from "../ImagePreviewModal";

export interface ApplicationFooterPropertiesStateProps {
  isProcessingData: boolean;
}

export interface ApplicationFooterPropertiesDispatchProps {}

export interface ApplicationFooterPropertiesOwnProps {}

export interface ApplicationFooterPropertiesProps
  extends ApplicationFooterPropertiesStateProps,
    ApplicationFooterPropertiesDispatchProps,
    ApplicationFooterPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface ApplicationFooterPropertiesState
  extends IComponentPropertiesBaseState {
  applicationResourceUploadModalVisible: boolean;
  backgroundPreviewModalVisible: boolean;
}

export class ApplicationFooterProperties extends ComponentPropertiesBase<
  ApplicationFooterPropertiesProps,
  ApplicationFooterPropertiesState,
  ApplicationFooterPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  formRef = React.createRef<IFormInstance>();

  public state: Readonly<ApplicationFooterPropertiesState> = {
    applicationResourceUploadModalVisible: false,
    backgroundPreviewModalVisible: false,
  };

  getPropertiesModel(): ApplicationFooterPropertyModel {
    const { component } = this.props;
    const properties = new ApplicationFooterPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public onApplicationResourceUploadCancel = () => {
    this.setState({ applicationResourceUploadModalVisible: false });
  };

  public onApplicationResourceUploadSuccess = (
    filePath?: string,
    fileUrl?: string
  ) => {
    this.properties.BackgroundUrl = filePath;
    this.properties.BackgroundAbsoluteUrl = fileUrl;
    this.onPropertyChange("BackgroundUrl");
    this.formRef?.current?.setFieldsValue({
      BackgroundUrl: filePath,
    });
    this.setState({ applicationResourceUploadModalVisible: false });
  };

  public onShowBackgroundPreviewModal = () => {
    const videoUrl =
      this.properties.BackgroundAbsoluteUrl ?? this.properties.BackgroundUrl;
    if (!videoUrl) {
      return;
    }

    this.setState({ backgroundPreviewModalVisible: true });
  };

  public onCloseBackgroundPreviewModal = () => {
    this.setState({ backgroundPreviewModalVisible: false });
  };

  public renderBackgroundUrlProperty = () => {
    const { component, isProcessingData, t } = this.props;
    const {
      applicationResourceUploadModalVisible,
      backgroundPreviewModalVisible,
    } = this.state;

    let style: React.CSSProperties = { marginLeft: "6px", marginRight: "6px" };

    if (this.properties.BackgroundAbsoluteUrl) {
      style = { marginLeft: "12px", marginRight: "12px" };
    }

    return (
      <Row gutter={8} style={style}>
        <Col flex="auto">
          <Form.Item
            name="BackgroundUrl"
            label={t("CONFIGURATION_PROPERTY__BACKGROUND_URL")}
            initialValue={this.properties.BackgroundUrl}
            extra={
              <p>
                {t("CONFIGURATION_IMAGE__SUGGESTED_RESOLUTION", {
                  width: 1920,
                  height: 400,
                })}
              </p>
            }
          >
            <Input
              disabled={isProcessingData}
              placeholder={"https://"}
              onChange={(e) => {
                this.properties.BackgroundUrl = e.target.value;
                this.properties.BackgroundAbsoluteUrl = e.target.value;
                this.onPropertyChange("BackgroundUrl");
              }}
            />
          </Form.Item>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <ApplicationResourceUploadModal
            key={`ApplicationResourceUploadModal-${component.Id}`}
            visible={applicationResourceUploadModalVisible}
            applicationConfigurationId={component.ApplicationConfigurationId}
            resourceType={ApplicationResourceType.Image}
            titleTransKey="CONFIGURATION_BUTTON__UPLOAD_IMAGE_TITLE"
            onCancel={this.onApplicationResourceUploadCancel}
            onSuccess={this.onApplicationResourceUploadSuccess}
          />
          {this.properties.BackgroundAbsoluteUrl && (
            <>
              <ImagePreviewModal
                visible={backgroundPreviewModalVisible}
                imageSrc={this.properties.BackgroundAbsoluteUrl}
                onCancel={this.onCloseBackgroundPreviewModal}
              />
              <Button
                title={t("CONFIGURATION_BUTTON__PREVIEW_IMAGE_TITLE")}
                style={{ marginRight: "8px" }}
                icon={<Icon type="Eye" />}
                onClick={() => this.onShowBackgroundPreviewModal()}
              />
            </>
          )}
          <Button
            icon={<Icon type="upload" />}
            title={t("CONFIGURATION_BUTTON__UPLOAD_IMAGE_TITLE")}
            onClick={() => {
              this.setState({ applicationResourceUploadModalVisible: true });
            }}
          />
        </Col>
      </Row>
    );
  };

  public renderColumnsProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="Columns"
        label={t("CONFIGURATION_FOOTER__COLUMNS_LABEL")}
        initialValue={this.properties.Columns || 5}
      >
        <InputNumber
          disabled={isProcessingData}
          placeholder={t("CONFIGURATION_FOOTER__COLUMNS_PLACEHOLDER")}
          formatter={(value) => `${`${value}`?.replace?.(/[^\d]/, "") || 0}`}
          parser={(value?: string) => value?.replace(/[^\d]/, "") || 0}
          min={0}
          max={10}
          onChange={(value) => {
            if (typeof value === "number") {
              this.properties.Columns = value;
              this.onPropertyChange("Columns");
            }
          }}
        />
      </Form.Item>
    );
  };

  public onItemsAlignmentPropertyChange = (value: IChooseValue) => {
    this.properties.ItemsAlignment = ItemsAlignmentTypeHelper.getValue(
      value as string
    );
    this.onPropertyChange("ItemsAlignment");
  };

  public renderItemsAlignmentProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="ItemsAlignment"
        label={t("CONFIGURATION_FOOTER__ITEMS_ALIGNMENT_LABEL")}
        initialValue={this.properties.ItemsAlignment}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 24 },
          md: { span: 9 },
          lg: { span: 6 },
        }}
      >
        <Choose
          disabled={isProcessingData}
          placeholder={t("CONFIGURATION_FOOTER__ITEMS_ALIGNMENT_PLACEHOLDER")}
          defaultValue={ItemsAlignmentType.FitToContent}
          onChange={this.onItemsAlignmentPropertyChange}
        >
          {ItemsAlignmentTypeHelper.getOptions()}
        </Choose>
      </Form.Item>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 20 },
      },
    };

    return (
      <Form
        name="ApplicationFooterProperties"
        ref={this.formRef}
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderColumnsProperty()}
            {this.renderItemsAlignmentProperty()}
            {this.renderBackgroundUrlProperty()}
          </Col>
        </Row>
      </Form>
    );
  }
}
