import React from "react";
import { AssetType, PersonInAssetType } from "@bms/common-services";
import { Button, Col, Form, Row } from "@bms/common-ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FieldType, formLayouts } from "./AssetFormUtils";
import {
  AdUrlField,
  AssetActionField,
  AgeRestrictionField,
  AvailableDateFields,
  CategoriesField,
  CreatorUserIdField,
  DescriptionField,
  DurationField,
  IsFreeField,
  OrderInParentField,
  ParentField,
  PeopleField,
  StartEndDatesFields,
  TitleField,
  TypeField,
  YearField,
  GeoAllowField,
  GeoBlockField,
} from "./fields";
import {
  IAssetFormProps,
  useAssetFormController,
} from "./useAssetFormController";

import "./AssetForm.scss";

export const AssetForm = (props: IAssetFormProps) => {
  const { t } = useTranslation();
  const {
    isEditMode,
    asset,
    section,
    hiddenFields,
    parentAssetsCount = 0,
  } = props;
  const {
    formLayout,
    parentId,
    parentTitle,
    formFieldNames,
    sendable,
    parent,
    typeState,
    userCreator,
    form,
    setTypeState,
    setParentId,
    onFieldsChange,
    onUserClear,
    onUserSelect,
    handleDurationChange,
    onFinish,
  } = useAssetFormController(props);

  const isHidden = (key: FieldType) =>
    !!hiddenFields && hiddenFields.includes(key);

  const isAssetTypeIn = (...types: AssetType[]) =>
    !!typeState && types.includes(typeState);

  const formFieldsMapping: { [key: string]: () => JSX.Element } = {
    AssetTypeCode: () => (
      <TypeField
        key="AssetForm--AssetTypeCodeFormField"
        typeState={typeState}
        isEditMode={isEditMode}
        parent={parent}
        onChange={setTypeState}
        isHidden={isHidden("AssetTypeCode")}
      />
    ),
    ParentAssetId: () => (
      <ParentField
        key="AssetForm--ParentAssetIdField"
        isEditMode={isEditMode}
        isHidden={isHidden("ParentAssetId")}
        parent={parent}
        parentId={parentId}
        parentTitle={parentTitle}
        type={typeState}
        onChange={setParentId}
        form={form}
      />
    ),
    OrderInParent: () => (
      <OrderInParentField
        key={`AssetForm--OrderInParentField-${parentId}`}
        isEditMode={isEditMode}
        isHidden={isHidden("OrderInParent")}
        asset={asset}
        parentAssetsCount={parentAssetsCount}
        parentId={parentId}
      />
    ),
    Title: () => (
      <TitleField
        key="AssetForm--TitleField"
        isHidden={isHidden("Title")}
        isEditMode={isEditMode}
        asset={asset}
      />
    ),
    CreatorUserId: () => (
      <CreatorUserIdField
        key="AssetForm--CreatorUserIdField"
        isEditMode={isEditMode}
        isHidden={isHidden("CreatorUserId")}
        onSelect={onUserSelect}
        onClear={onUserClear}
        userCreator={userCreator}
      />
    ),
    Categories: () => (
      <CategoriesField
        key="AssetForm--CategoriesField"
        isHidden={
          (!isEditMode && isAssetTypeIn(AssetType.Podcast)) ||
          isHidden("Categories")
        }
        isEditMode={isEditMode}
        asset={asset}
      />
    ),
    CountriesAllowed: () => (
      <GeoAllowField
        key="AssetForm--GeoAllowField"
        form={form}
        isHidden={!isEditMode || isHidden("CountriesAllowed")}
        isEditMode={isEditMode}
        asset={asset}
      />
    ),
    CountriesBlocked: () => (
      <GeoBlockField
        key="AssetForm--GeoBlockField"
        isHidden={!isEditMode || isHidden("CountriesBlocked")}
        isEditMode={isEditMode}
        asset={asset}
        form={form}
      />
    ),
    Director: () => (
      <PeopleField
        key="AssetForm--DirectorField"
        name={"Director"}
        label={t("ASSET_DIRECTOR_LABEL")}
        roleCode={PersonInAssetType.Director}
        isEditMode={isEditMode}
        asset={asset}
        isHidden={
          (!isEditMode && isAssetTypeIn(AssetType.Program)) ||
          isAssetTypeIn(AssetType.Podcast) ||
          isHidden("Director")
        }
      />
    ),
    Writer: () => (
      <PeopleField
        key="AssetForm--WriterField"
        name={"Writer"}
        label={t("ASSET_WRITER_LABEL")}
        roleCode={PersonInAssetType.Writer}
        isEditMode={isEditMode}
        asset={asset}
        isHidden={
          (!isEditMode && isAssetTypeIn(AssetType.Program)) ||
          isAssetTypeIn(AssetType.Podcast) ||
          isHidden("Writer")
        }
      />
    ),
    Cast: () => (
      <PeopleField
        key="AssetForm--CastField"
        name={"Cast"}
        label={t("ASSET_CAST_LABEL")}
        roleCode={PersonInAssetType.Cast}
        isEditMode={isEditMode}
        asset={asset}
        isHidden={
          (!isEditMode && isAssetTypeIn(AssetType.Program)) ||
          isAssetTypeIn(AssetType.Podcast) ||
          isHidden("Cast")
        }
      />
    ),
    AssetAgeRestrictionValueMin: () => (
      <AgeRestrictionField
        key="AssetForm--AgeRestrictionField"
        isHidden={
          (!isEditMode && isAssetTypeIn(AssetType.Podcast)) ||
          isHidden("AssetAgeRestrictionValueMin")
        }
        isEditMode={isEditMode}
        asset={asset}
        parent={parent}
      />
    ),
    Description: () => (
      <DescriptionField
        key="AssetForm--DescriptionField"
        asset={asset}
        isHidden={isHidden("Description")}
        isEditMode={isEditMode}
      />
    ),
    DurationMiliseconds: () => (
      <DurationField
        key="AssetForm--DurationField"
        isHidden={isHidden("DurationMiliseconds")}
        isEditMode={isEditMode}
        asset={asset}
        onDurationChange={handleDurationChange}
      />
    ),
    Year: () => (
      <YearField
        key="AssetForm--YearField"
        isEditMode={isEditMode}
        asset={asset}
        isHidden={
          (!isEditMode &&
            isAssetTypeIn(AssetType.Program, AssetType.Podcast)) ||
          isHidden("Year")
        }
      />
    ),
    AvailableDate: () => (
      <AvailableDateFields
        key="AssetForm--AvailableDateFields"
        isHidden={
          (!isEditMode && isAssetTypeIn(AssetType.Podcast)) ||
          isHidden("AvailableDate")
        }
        isEditMode={isEditMode}
        asset={asset}
        form={form}
      />
    ),
    StartEndDates: () => (
      <StartEndDatesFields
        key="AssetForm--StartEndDatesFields"
        isEditMode={isEditMode}
        isHidden={isHidden("StartEndDates")}
        asset={asset}
        form={form}
      />
    ),
    IsFree: () => (
      <IsFreeField
        key="AssetForm--IsFreeField"
        isHidden={isHidden("IsFree")}
        asset={asset}
        formLayout={formLayout}
      />
    ),
    AssetAction: () => (
      <AssetActionField
        key="AssetForm--ActionField"
        isHidden={isHidden("AssetAction")}
        isEditMode={isEditMode}
        asset={asset}
      />
    ),
    AdUrl: () => (
      <AdUrlField
        key="AssetForm--AdUrlField"
        isHidden={!isEditMode || isHidden("AdUrl")}
        isEditMode={isEditMode}
        asset={asset}
      />
    ),
  };

  const formFields = useMemo(
    () =>
      formFieldNames.map((field: string) =>
        formFieldsMapping[field] ? formFieldsMapping[field]() : null
      ),
    [formFieldNames, asset, parentId]
  );

  return (
    <Form
      {...formLayout}
      form={form}
      name={section ? `AssetForm-${section}` : "AssetForm"}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      className="AssetForm"
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          {formFields}
          {isEditMode && (
            <Form.Item {...formLayouts.tailFormItem}>
              <Button type="primary" htmlType="submit" disabled={!sendable}>
                {t("BUTTON_SAVE")}
              </Button>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};
