import React from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../../constants";
import { AuthContainer } from "../AuthContainer";

export const ForgotPasswordSuccess = () => {
  const [t] = useTranslation();
  return (
    <AuthContainer
      footerLink={{
        href: ROUTES.LOGIN,
        title: t("FORGOT_PASSWORD_BACK_TO_LOGIN_LINK"),
      }}
    >
      <h3 style={{ textAlign: "center" }}>
        {t("FORGOT_PASSWORD_SUCCESS_TITLE")}
      </h3>
    </AuthContainer>
  );
};
