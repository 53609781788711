import {
  CountriesService,
  CountryModel,
  GuidHelper,
  RecordStatus,
  useDataLoader,
  useServiceCaller,
} from "@bms/common-services";
import {
  Button,
  IBreadcrumbProps,
  Icon,
  ITableColumnProps,
  ITablePaginationConfig,
  NotificationService,
  PageContent,
  PageHeader,
  Popconfirm,
  TableWithDraggableSorter,
  Tooltip,
} from "@bms/common-ui";
import { generateBreadcrumb } from "../../../../helpers";
import { ROUTES } from "../../../../modules/Dictionaries/constants";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { FormModal } from "../../../../components";
import { DictionaryCountriesForm } from "../DictionaryCountriesForm";
import "./DictionaryCountriesList.scss";

const countriesService = new CountriesService().promisify();
const notificationService = NotificationService.getInstance();

const defaultCountry: CountryModel = {
  Id: -1,
  Code: "",
  Flag: "",
  Guid: "",
  Name: "",
  PhoneMaxLength: 0,
  PhoneMinLength: 0,
  PhonePrefix: "",
  Sequence: 1,
  RecordStatus: RecordStatus.Inserted,
  UpToDate: true,
};

export const DictionaryCountriesList = () => {
  const { t } = useTranslation();
  const [showCountryModal, setShowCountryModal] = useState(false);
  const [editableCountry, setEditableCountry] = useState<CountryModel>(
    defaultCountry
  );

  const countryLoader = useDataLoader({
    loader: () => countriesService.select(),
    deps: [],
  });

  const CountryFlag = ({ src }: { src: string | undefined }) => (
    <img src={src} className="CountryFlag" />
  );

  const [insertCountry, { processing: insertProcessing }] = useServiceCaller(
    async (country: CountryModel) => {
      const result = await countriesService.insert({
        ...country,
        Guid: GuidHelper.newGuid(),
        RecordStatus: RecordStatus.Inserted,
      });
      if (result.ok) {
        notificationService.success({
          message: t("DICTIONARY_COUNTRY_INSERT_SUCCESS"),
        });
        await countryLoader.refresh();
        closeModal();
      } else {
        notificationService.error({
          message: t("DICTIONARY_COUNTRY_INSERT_FAILURE"),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const [updateCountry, { processing: updateProcessing }] = useServiceCaller(
    async (country: CountryModel) => {
      const result = await countriesService.update({
        ...country,
        RecordStatus: RecordStatus.Updated,
      });
      if (result.ok) {
        notificationService.success({
          message: t("DICTIONARY_COUNTRY_UPDATE_SUCCESS"),
        });
        await countryLoader.refresh();
        closeModal();
      } else {
        notificationService.error({
          message: t("DICTIONARY_COUNTRY_UPDATE_FAILURE"),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const [deleteCountry, { processing: deleteProcessing }] = useServiceCaller(
    async (country: CountryModel) => {
      const result = await countriesService.delete({
        ...country,
        RecordStatus: RecordStatus.Deleted,
      });
      if (result.ok) {
        notificationService.success({
          message: t("DICTIONARY_COUNTRY_DELETE_SUCCESS"),
        });
        await countryLoader.refresh();
        closeModal();
      } else {
        notificationService.error({
          message: t("DICTIONARY_COUNTRY_DELETE_FAILURE"),
          description: result.error?.Message,
        });
      }
    },
    []
  );

  const onMoveRow = (dragIndex: number, hoverIndex: number) => {
    const draggedCountry = countryLoader.data?.[dragIndex];
    const hoveredCountry = countryLoader.data?.[hoverIndex];

    if (!draggedCountry || !hoveredCountry) {
      return;
    }

    const countryToUpdate: CountryModel = {
      ...draggedCountry,
      Sequence: hoveredCountry.Sequence ?? 1,
      RecordStatus: RecordStatus.Updated,
    };

    const sequenceChanged =
      draggedCountry.Sequence !== countryToUpdate.Sequence;
    const draggedToNewPosition = draggedCountry.Guid !== hoveredCountry.Guid;

    if (draggedToNewPosition && sequenceChanged) {
      updateCountry(countryToUpdate);
    }
  };

  const getColumnsProps = (): Array<ITableColumnProps<CountryModel>> => {
    return [
      {
        key: "Code",
        dataIndex: "Code",
        title: t("MODEL_CODE"),
        sorter: (a, b) => a.Code.localeCompare(b.Code),
        render: (_: any, row: CountryModel) => {
          return (
            <a
              title={row.Code}
              className="imageUrl"
              onClick={() => {
                setShowCountryModal(true);
                setEditableCountry(row);
              }}
            >
              {row.Code}
            </a>
          );
        },
      },
      {
        key: "Name",
        dataIndex: "Name",
        className: "drag-visible",
        title: t("MODEL_NAME"),
        sorter: (a, b) => a.Name.localeCompare(b.Name),
      },
      {
        key: "Flag",
        dataIndex: "Flag",
        title: t("FLAG"),
        render: (_: any, row: CountryModel) => {
          return <CountryFlag src={row.Flag} />;
        },
      },
      {
        key: "Sequence",
        dataIndex: "Sequence",
        title: t("MODEL_SEQUENCE"),
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        title: t("TABLE_ACTIONS_COLUMN"),
        render: (_: any, row: CountryModel) => (
          <Popconfirm
            title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              deleteCountry(row);
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT")}
            />
          </Popconfirm>
        ),
      },
    ];
  };

  const onRefreshClick = () => {
    countryLoader.refresh();
  };

  const onDeleteCountry = () => {
    if (editableCountry) {
      deleteCountry(editableCountry);
    }
  };

  const onAddNewCountryClick = () => {
    setShowCountryModal(true);
  };

  const getExtraButtons = () => {
    return (
      <>
        <Button
          key="add"
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddNewCountryClick}
          title={t("DICTIONARY_COUNTRY_ADD_NEW")}
        />
        <Button
          key="reload"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={onRefreshClick}
          title={t("DICTIONARY_COUNTRY_REFRESH")}
        />
      </>
    );
  };

  const closeModal = () => {
    setShowCountryModal(false);
    setEditableCountry(defaultCountry);
  };

  const pageHeaderTitle = <>{t("DICTIONARY_COUNTRY_TITLE")}</>;

  const paginationConfig = useMemo((): ITablePaginationConfig => {
    return {
      total: countryLoader.data?.length,
      showTotal: (total, range) =>
        t("TABLE_PAGINATION_TOTAL", {
          rangeFrom: range[0],
          rangeTo: range[1],
          total: total,
        }),
      showSizeChanger: true,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "30", "50", "100"],
    };
  }, []);

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.DICTIONARY_BASE}`,
        breadcrumbName: t("MENU_ADMINISTRATION_DICTIONARIES"),
      },
      {
        path: `${ROUTES.DICTIONARY_COUNTRIES_LIST}`,
        breadcrumbName: t("DICTIONARY_COUNTRY_TITLE"),
      },
    ]);
  };

  return (
    <div className="DictionaryCountriesList">
      <PageHeader
        title={pageHeaderTitle}
        extra={getExtraButtons()}
        breadcrumb={getBreadcrumbProps()}
      />
      <PageContent>
        <TableWithDraggableSorter<CountryModel>
          dragType="handler"
          columns={getColumnsProps()}
          dataSource={countryLoader.data}
          loading={countryLoader.loading}
          pagination={paginationConfig}
          onMoveRow={onMoveRow}
        />
      </PageContent>
      <FormModal
        isLoading={insertProcessing || updateProcessing || deleteProcessing}
        isVisible={showCountryModal}
        isNewForm={editableCountry.Id >= 0 ? false : true}
        isDeleteButtonEnabled={editableCountry.Id >= 0 ? true : false}
        createFormTitle={t("DICTIONARY_COUNTRY_MODAL_NEW")}
        editFormTitle={t("DICTIONARY_COUNTRY_MODAL_EDIT")}
        modalClassName="DictionaryCountryModal"
        submitFormName="DictionaryCountryForm"
        onCloseModal={closeModal}
        onDeleteButtonClick={onDeleteCountry}
      >
        <DictionaryCountriesForm
          country={editableCountry}
          insertCountry={insertCountry}
          updateCountry={updateCountry}
          isProcessing={
            insertProcessing || updateProcessing || deleteProcessing
          }
        />
      </FormModal>
    </div>
  );
};
