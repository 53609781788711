import {
  ExportOutlined,
  ImportOutlined,
  MoreOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  ApplicationConfigurationStore,
  CommonStore,
  IApplicationModel,
  IErrorModel,
  ILanguageModel,
  IPlatformModel,
  IResourceModel,
  IResourcesListModel,
  IResourcesSearchFilterModel,
  PlatformType,
} from "@bms/common-services";
import {
  Button,
  DomainTag,
  Dropdown,
  Icon,
  InputSearch,
  IPaginationProps,
  ITableColumnProps,
  ITableFilter,
  Link,
  Menu,
  MenuItem,
  MenuItemClickEvent,
  NotificationService,
  PageContent,
  PageHeader,
  Pagination,
  Popconfirm,
  setTableColumnSearchProps,
  Table,
} from "@bms/common-ui";
import { first } from "lodash";
import React from "react";
import { WithTranslation } from "react-i18next";
import { Action, ActionCreator } from "redux";
import { ROUTES } from "../../constants";
import { TranslationDetailsModal } from "../TranslationDetailsModal";
import {
  TranslationExportModal,
  TranslationImportModal,
} from "../TranslationImportExportModal";

const notificationService = NotificationService.getInstance();

export interface ITranslationsListProps extends WithTranslation {
  actionType?: string;
  resources: IResourcesListModel;
  resource?: IResourceModel;
  languages: ILanguageModel[];
  platforms: IPlatformModel[];
  applications: IApplicationModel[];
  isLoadingData: boolean;
  goToDetails: ActionCreator<Action>;
  selectLanguages: ActionCreator<Action>;
  searchTranslations: ActionCreator<Action>;
  selectApplications: ActionCreator<Action>;
  selectPlatforms: ActionCreator<Action>;
  deleteResourcesByKey: ActionCreator<Action>;
  error?: IErrorModel;
}

interface ITranslationsListState {
  isOpenFormModal: boolean;
  isOpenImportModal: boolean;
  isOpenExportModal: boolean;
  translation: IResourceModel;
}

export class TranslationsList extends React.PureComponent<
  ITranslationsListProps,
  ITranslationsListState
> {
  private translation: IResourceModel = {
    ApplicationKey: "",
    LanguageCode: "",
    LanguageName: "",
    OriginalValue: "",
    PlatformCode: PlatformType.Any,
    ResourceKey: "",
    ResourceValue: "",
  };

  public state: Readonly<ITranslationsListState> = {
    isOpenFormModal: false,
    isOpenImportModal: false,
    isOpenExportModal: false,
    translation: { ...this.translation },
  };

  public componentDidMount() {
    const {
      languages,
      searchTranslations,
      selectLanguages,
      applications,
      selectApplications,
      platforms,
      selectPlatforms,
      resources,
    } = this.props;

    const filter = resources.Filter || {
      IncludeCount: true,
      LanguageCodes: [],
      PageNumber: 1,
      PageSize: 10,
    };

    searchTranslations(filter);

    if (!languages || languages.length === 0) {
      selectLanguages();
    }
    if (!applications || applications.length === 0) {
      selectApplications();
    }
    if (!platforms || platforms.length === 0) {
      selectPlatforms();
    }
  }

  componentDidUpdate(prevProps: ITranslationsListProps) {
    const {
      resources,
      actionType,
      error,
      resource,
      t,
      goToDetails,
    } = this.props;

    if (prevProps.actionType === actionType) {
      return;
    }

    switch (actionType) {
      case CommonStore.Consts.INSERT_RESOURCE_SUCCESS:
        this.closeModal();

        if (resource) {
          goToDetails(resource.ResourceKey);
        } else {
          this.refreshData();
        }

        return notificationService.success({
          message: t("TRANSLATION_INSERT_RESOURCES"),
        });

      case CommonStore.Consts.INSERT_RESOURCE_FAILURE:
        return notificationService.error({
          description: error?.Message,
          message: t(
            "INSERT_RESOURCE_FAILURE_MESSAGE",
            "There was an error while inserting translation."
          ),
        });

      case ApplicationConfigurationStore.Consts.EXPORT_TRANSLATIONS_FAILURE:
        this.setState({
          isOpenExportModal: false,
        });

        return notificationService.error({
          description: error?.Message,
          message: t(
            "EXPORT_TRANSLATIONS_FAILURE_MESSAGE",
            "There was an error while exporting translations."
          ),
        });
      case ApplicationConfigurationStore.Consts.IMPORT_TRANSLATIONS_SUCCESS:
        this.setState({
          isOpenImportModal: false,
        });
        this.refreshData();

        return notificationService.success({
          message: t(
            "IMPORT_TRANSLATIONS_SUCCESS_MESSAGE",
            "Translations import success."
          ),
        });
      case ApplicationConfigurationStore.Consts.IMPORT_TRANSLATIONS_FAILURE:
        return notificationService.error({
          message: t(
            "IMPORT_TRANSLATIONS_FAILURE_MESSAGE",
            "There was an error while importing translations."
          ),
        });
      case CommonStore.Consts.DELETE_RESOURCES_BY_KEY_SUCCESS:
        notificationService.success({
          message: t("TRANSLATION_DELETE_RECOURCE_BY_KEY_SUCCESS"),
        });
        this.onRefreshClick();
        break;
      case CommonStore.Consts.DELETE_RESOURCES_BY_KEY_FAILURE:
        notificationService.error({
          description: error ? error.Message : undefined,
          message: t("TRANSLATION_DELETE_RECOURCE_BY_KEY_FAILURE"),
        });
        break;
      default:
        if (
          resources?.Error &&
          resources?.Error !== prevProps.resources?.Error
        ) {
          notificationService.error({
            message:
              resources?.Error?.Message ??
              t(
                "LOADING_DATA_ERROR_MESSAGE",
                "There was an error while loading data."
              ),
          });
        }

        break;
    }
  }

  private onAdditionalOptionsMenuItemClick = (e: MenuItemClickEvent) => {
    switch (e.key) {
      case "OPTION_CLEAR_FILTERS":
        this.onClearFiltersClick();
        break;
    }
  };

  public onRefreshClick = () => {
    this.refreshData();
  };

  private refreshData = () => {
    const { resources } = this.props;
    this.props.searchTranslations(resources.Filter);
  };

  public onClearFiltersClick = () => {
    const { resources } = this.props;
    const filter = {
      IncludeCount: resources.Filter?.IncludeCount,
      LanguageCodes: ["en"],
      PageNumber: resources.Filter?.PageNumber,
      PageSize: resources.Filter?.PageSize,
    };

    this.props.searchTranslations(filter);
  };

  public onAddNewTranslationClick = () => {
    this.setState({
      isOpenFormModal: true,
      translation: { ...this.translation },
    });
  };

  private getColumnsProps(): Array<ITableColumnProps<IResourceModel>> {
    const { resources, t, applications, platforms, languages } = this.props;
    const filter: IResourcesSearchFilterModel = resources.Filter || {
      LanguageCodes: ["en"],
    };

    return [
      {
        dataIndex: "ResourceKey",
        filteredValue: filter.ResourceKey ? [filter.ResourceKey] : null,
        key: "ResourceKey",
        title: t("MODEL_TRANSLATION_KEY"),
        render: (text: any, row: IResourceModel) => {
          return (
            <Link
              to={`${ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${row.ResourceKey}`}
            >
              {row.ResourceKey}
            </Link>
          );
        },
        ...setTableColumnSearchProps("ResourceKey"),
      },
      {
        dataIndex: "ResourceValue",
        filteredValue: filter.ResourceValue ? [filter.ResourceValue] : null,
        key: "ResourceValue",
        title: t("TRANSLATION__TRANSLATION_VALUE"),
        ...setTableColumnSearchProps("ResourceValue"),
      },
      {
        dataIndex: "LanguageCode",
        filteredValue: filter.LanguageCodes || null,
        filters: languages.map((language) => ({
          text: language.Name,
          value: language.Code,
        })),
        key: "LanguageCode",
        width: "100px",
        align: "center",
        title: t("MODEL_LANGUAGE"),
        render: (text: any, row: IResourceModel) => (
          <DomainTag domain="language" value={row.LanguageCode} />
        ),
      },
      {
        dataIndex: "ApplicationKey",
        filteredValue: filter.ApplicationKeys || null,
        filters: applications.map((application) => ({
          text: application.DisplayName,
          value: application.Key,
        })),
        key: "ApplicationKey",
        width: "200px",
        align: "center",
        title: t("MODEL_APPLICATION"),
        render: (text: any, row: IResourceModel) => (
          <DomainTag domain="application" value={row.ApplicationKey} />
        ),
      },
      {
        dataIndex: "PlatformCode",
        filteredValue: filter.PlatformCodes || null,
        filters: platforms.map((platform) => ({
          text: platform.DisplayName,
          value: platform.Code,
        })),
        width: "250px",
        align: "center",
        key: "PlatformCode",
        title: t("MODEL_PLATFORM"),
        render: (text: any, row: IResourceModel) => (
          <DomainTag domain="platform" value={row.PlatformCode} />
        ),
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        width: "100px",
        title: t("TABLE_ACTIONS_COLUMN", "Actions"),
        render: (_: any, row: IResourceModel) => (
          <Popconfirm
            title={t(
              "DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION",
              "Are you sure you want to delete element?"
            )}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              this.props.deleteResourcesByKey(row.ResourceKey);
            }}
            okText={t("BUTTON_YES", "Yes")}
            cancelText={t("BUTTON_NO", "No")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT", "Delete element")}
            />
          </Popconfirm>
        ),
      },
    ];
  }

  private rowKey = (row: IResourceModel) =>
    `${row.ResourceKey}${row.ApplicationKey}${row.PlatformCode}${row.LanguageCode}`;

  public onTableChange = (
    pagination: IPaginationProps,
    filters: ITableFilter
  ) => {
    const { resources } = this.props;
    const filter: IResourcesSearchFilterModel = resources.Filter || {};
    filter.ApplicationKeys = filters.ApplicationKey?.length
      ? filters.ApplicationKey.map((row) => `${row}`)
      : undefined;
    filter.ResourceKey = filters.ResourceKey?.length
      ? `${first(filters.ResourceKey)}`
      : undefined;
    filter.ResourceValue = filters.ResourceValue?.length
      ? `${first(filters.ResourceValue)}`
      : undefined;
    filter.LanguageCodes = filters.LanguageCode?.length
      ? filters.LanguageCode.map((row) => `${row}`)
      : undefined;
    filter.PlatformCodes = filters.PlatformCode?.length
      ? filters.PlatformCode.map((row) => `${row}`)
      : undefined;

    filter.PageSize = pagination.pageSize;
    filter.PageNumber = pagination.current;

    if (filter.PageSize !== pagination.pageSize) {
      filter.PageNumber = 1;
    }

    this.props.searchTranslations(filter);
  };

  public onSearch = (value: string) => {
    const { resources } = this.props;
    const filter: IResourcesSearchFilterModel = resources.Filter || {
      IncludeCount: true,
      LanguageCodes: ["en"],
      PageNumber: 1,
      PageSize: 10,
    };

    filter.FullTextSearch = value;
    filter.PageNumber = 1;

    this.props.searchTranslations(filter);
  };

  public closeModal = () => {
    this.setState({ isOpenFormModal: false });
  };

  public onPageChange = (page: number, pageSize?: number) => {
    const { resources } = this.props;
    const filter: IResourcesSearchFilterModel = resources.Filter || {};
    const searchFilter = {
      ...filter,
      PageNumber: page,
      PageSize: pageSize,
    };
    this.props.searchTranslations(searchFilter);
  };

  public render() {
    const { resources, isLoadingData, t } = this.props;
    const filter: IResourcesSearchFilterModel = resources.Filter || {};

    const pagination: IPaginationProps = {
      current: filter.PageNumber ?? 1,
      defaultPageSize: 10,
      pageSize: filter.PageSize ?? 10,
      pageSizeOptions: ["10", "30", "50", "100"],
      showSizeChanger: true,
      showTotal: (total, range) =>
        t("TABLE_PAGINATION_TOTAL", {
          rangeFrom: range[0],
          rangeTo: range[1],
          total: total,
        }),
      total: resources.TotalCount,
      onChange: this.onPageChange,
    };
    const columns = this.getColumnsProps();

    return (
      <PageContent footer={<Pagination {...pagination} />}>
        <PageHeader
          title={t("CUSTOM_TRANSLATIONS")}
          extra={
            <>
              <InputSearch
                key="search"
                placeholder={t("SEARCH_PLACEHOLDER")}
                defaultValue={resources?.Filter?.FullTextSearch}
                onSearch={this.onSearch}
                style={{ width: 250 }}
                allowClear={true}
              />
              <Button
                key="add"
                shape="circle"
                type="primary"
                icon={<PlusOutlined />}
                onClick={this.onAddNewTranslationClick}
                title={t("TRANSLATION_DETAILS_ADD_NEW")}
              />
              <Button
                key="import"
                shape="circle"
                icon={<ImportOutlined />}
                onClick={() => {
                  this.setState({
                    isOpenImportModal: true,
                  });
                }}
                title={t("TRANSLATION_IMPORT_TRANSLATION")}
              />
              <Button
                key="export"
                shape="circle"
                icon={<ExportOutlined />}
                onClick={() => {
                  this.setState({
                    isOpenExportModal: true,
                  });
                }}
                title={t("TRANSLATION_EXPORT_TRANSLATION")}
              />
              <Button
                key="reload"
                shape="circle"
                icon={<ReloadOutlined />}
                onClick={this.onRefreshClick}
                title={t("BUTTON_REFRESH_TITLE")}
              />
              <Dropdown
                key="dropdown"
                overlay={
                  <Menu onClick={this.onAdditionalOptionsMenuItemClick}>
                    <MenuItem key="OPTION_CLEAR_FILTERS">
                      {t("MENU_OPTION_CLEAR_FILTERS")}
                    </MenuItem>
                  </Menu>
                }
              >
                <Button icon={<MoreOutlined />} />
              </Dropdown>
            </>
          }
        />

        <Table<IResourceModel>
          columns={columns}
          dataSource={resources.Entities}
          loading={isLoadingData}
          pagination={false}
          rowKey={this.rowKey}
          onChange={this.onTableChange}
        />

        <TranslationExportModal
          visible={this.state.isOpenExportModal}
          closeModal={() =>
            this.setState({
              isOpenExportModal: false,
            })
          }
        />

        <TranslationImportModal
          visible={this.state.isOpenImportModal}
          closeModal={() =>
            this.setState({
              isOpenImportModal: false,
            })
          }
        />

        <TranslationDetailsModal
          isOpen={this.state.isOpenFormModal}
          translation={this.state.translation}
          isNewTranslation={true}
          closeModal={this.closeModal}
        />
      </PageContent>
    );
  }
}
