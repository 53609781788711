import React from "react";
import { Typography as AntTypography } from "antd";
import { TypographyProps as AntTypographyProps } from "antd/lib/typography";

export const Paragraph: AntTypographyProps["Paragraph"] = (props) => {
  const { children, ...rest } = props;
  return (
    <AntTypography.Paragraph {...rest}>{children}</AntTypography.Paragraph>
  );
};
