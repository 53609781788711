import {
  IApplicationComponentTypeModel,
  IApplicationConfigurationBrandingModel,
  IApplicationConfigurationBrandingListModel,
  IApplicationConfigurationBrandingKeyTypeListModel,
  IApplicationConfigurationModel,
  IApplicationsConfigurationsListModel,
  IApplicationsConfigurationsSearchFilterModel,
  IApplicationScreenModel,
  IApplicationScreenTypeModel,
  IApplicationsScreensListModel,
  IApplicationsScreensSearchFilterModel,
  IApplicationConfigurationBrandingSearchFilterModel,
  IErrorModel,
  UploadFileInfoModel,
  OperationResult,
  IApplicationScreenCopyModel,
} from "../../models";
import * as Consts from "./consts";
import {
  ApplicationConfigurationActionsTypes,
  IDeleteApplicationScreenAction,
  IDeleteApplicationScreenFailureAction,
  IDeleteApplicationScreenSuccessAction,
  IGetApplicationConfigurationAction,
  IGetApplicationConfigurationFailureAction,
  IGetApplicationConfigurationSuccessAction,
  IGetApplicationScreenAction,
  IGetApplicationScreenFailureAction,
  IGetApplicationScreenSuccessAction,
  IInsertApplicationScreenAction,
  IInsertApplicationScreenFailureAction,
  IInsertApplicationScreenSuccessAction,
  IPublishApplicationConfigurationAction,
  IPublishApplicationConfigurationFailureAction,
  IPublishApplicationConfigurationSuccessAction,
  IUpdateApplicationScreenAction,
  IUpdateApplicationScreenFailureAction,
  IUpdateApplicationScreenSuccessAction,
  IGetApplicationBrandingAction,
  IGetApplicationBrandingSuccessAction,
  IGetApplicationBrandingFailureAction,
  IGetDefaultApplicationBrandingAction,
  IGetDefaultApplicationBrandingSuccessAction,
  IGetDefaultApplicationBrandingFailureAction,
  ISelectKeysApplicationBrandingAction,
  ISelectKeysApplicationBrandingSuccessAction,
  ISelectKeysApplicationBrandingFailureAction,
  ISearchApplicationBrandingAction,
  ISearchApplicationBrandingSuccessAction,
  ISearchApplicationBrandingFailureAction,
  IInsertApplicationBrandingAction,
  IInsertApplicationBrandingSuccessAction,
  IInsertApplicationBrandingFailureAction,
  IUpdateApplicationBrandingAction,
  IUpdateApplicationBrandingSuccessAction,
  IUpdateApplicationBrandingFailureAction,
  IDeleteApplicationBrandingAction,
  IDeleteApplicationBrandingSuccessAction,
  IDeleteApplicationBrandingFailureAction,
  IGetImageUploadFileInfoSuccessAction,
  IGetImageUploadFileInfoAction,
  IGetImageUploadFileInfoFailureAction,
  IUploadBrandingImageSuccessAction,
  IUploadBrandingImageAction,
  IUploadBrandingImageFailureAction,
  IExportTranslationsAction,
  IExportTranslationsSuccessAction,
  IExportTranslationsFailureAction,
  IImportTranslationsAction,
  IImportTranslationsSuccessAction,
  IImportTranslationsFailureAction,
  ICopyApplicationScreenAction,
  ICopyApplicationScreenSuccessAction,
  ICopyApplicationScreenFailureAction,
} from "./types";

export const searchConfigurations = (
  filter: IApplicationsConfigurationsSearchFilterModel
): ApplicationConfigurationActionsTypes => {
  return {
    filter,
    type: Consts.SEARCH_APPLICATION_CONFIGURATION,
  };
};

export const searchConfigurationsSuccess = (
  data: IApplicationsConfigurationsListModel
): ApplicationConfigurationActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_APPLICATION_CONFIGURATION_SUCCESS,
  };
};

export const searchConfigurationsFailure = (
  error?: IErrorModel
): ApplicationConfigurationActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_APPLICATION_CONFIGURATION_FAILURE,
  };
};

export const getConfiguration = (
  id: number
): IGetApplicationConfigurationAction => {
  return {
    id,
    type: Consts.GET_APPLICATION_CONFIGURATION,
  };
};

export const getConfigurationSuccess = (
  data: IApplicationConfigurationModel
): IGetApplicationConfigurationSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_APPLICATION_CONFIGURATION_SUCCESS,
  };
};

export const getConfigurationFailure = (
  error?: IErrorModel
): IGetApplicationConfigurationFailureAction => {
  return {
    error,
    type: Consts.GET_APPLICATION_CONFIGURATION_FAILURE,
  };
};

export const updateConfiguration = (
  data: IApplicationConfigurationModel
): ApplicationConfigurationActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_APPLICATION_CONFIGURATION,
  };
};

export const updateConfigurationSuccess = (
  data: IApplicationConfigurationModel
): ApplicationConfigurationActionsTypes => {
  return {
    payload: data,
    type: Consts.UPDATE_APPLICATION_CONFIGURATION_SUCCESS,
  };
};

export const updateConfigurationFailure = (
  error?: IErrorModel
): ApplicationConfigurationActionsTypes => {
  return {
    error,
    type: Consts.UPDATE_APPLICATION_CONFIGURATION_FAILURE,
  };
};

export const publishConfiguration = (
  id: number,
  rowVersion: string
): IPublishApplicationConfigurationAction => {
  return {
    id,
    rowVersion,
    type: Consts.PUBLISH_APPLICATION_CONFIGURATION,
  };
};

export const publishConfigurationSuccess = (
  data: IApplicationConfigurationModel
): IPublishApplicationConfigurationSuccessAction => {
  return {
    payload: data,
    type: Consts.PUBLISH_APPLICATION_CONFIGURATION_SUCCESS,
  };
};

export const publishConfigurationFailure = (
  error?: IErrorModel
): IPublishApplicationConfigurationFailureAction => {
  return {
    error,
    type: Consts.PUBLISH_APPLICATION_CONFIGURATION_FAILURE,
  };
};

export const deleteConfiguration = (
  data: IApplicationConfigurationModel
): ApplicationConfigurationActionsTypes => {
  return {
    payload: data,
    type: Consts.DELETE_APPLICATION_CONFIGURATION,
  };
};

export const deleteConfigurationSuccess = (): ApplicationConfigurationActionsTypes => {
  return {
    type: Consts.DELETE_APPLICATION_CONFIGURATION_SUCCESS,
  };
};

export const deleteConfigurationFailure = (
  error?: IErrorModel
): ApplicationConfigurationActionsTypes => {
  return {
    error,
    type: Consts.DELETE_APPLICATION_CONFIGURATION_FAILURE,
  };
};

export const getScreen = (id: number): IGetApplicationScreenAction => {
  return {
    id,
    type: Consts.GET_APPLICATION_SCREEN,
  };
};

export const getScreenSuccess = (
  data: IApplicationScreenModel
): IGetApplicationScreenSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_APPLICATION_SCREEN_SUCCESS,
  };
};

export const getScreenFailure = (
  error?: IErrorModel
): IGetApplicationScreenFailureAction => {
  return {
    error,
    type: Consts.GET_APPLICATION_SCREEN_FAILURE,
  };
};

export const searchScreens = (
  filter: IApplicationsScreensSearchFilterModel
): ApplicationConfigurationActionsTypes => {
  return {
    filter,
    type: Consts.SEARCH_APPLICATION_SCREEN,
  };
};

export const searchScreensSuccess = (
  data: IApplicationsScreensListModel
): ApplicationConfigurationActionsTypes => {
  return {
    payload: data,
    type: Consts.SEARCH_APPLICATION_SCREEN_SUCCESS,
  };
};

export const searchScreensFailure = (
  error?: IErrorModel
): ApplicationConfigurationActionsTypes => {
  return {
    error,
    type: Consts.SEARCH_APPLICATION_SCREEN_FAILURE,
  };
};

export const browseScreens = (
  filter: IApplicationsScreensSearchFilterModel
): ApplicationConfigurationActionsTypes => {
  return {
    filter,
    type: Consts.BROWSE_APPLICATION_SCREEN,
  };
};

export const browseScreensSuccess = (
  data: IApplicationsScreensListModel
): ApplicationConfigurationActionsTypes => {
  return {
    payload: data,
    type: Consts.BROWSE_APPLICATION_SCREEN_SUCCESS,
  };
};

export const browseScreensFailure = (
  error?: IErrorModel
): ApplicationConfigurationActionsTypes => {
  return {
    error,
    type: Consts.BROWSE_APPLICATION_SCREEN_FAILURE,
  };
};

export const browseScreensCancel = (): ApplicationConfigurationActionsTypes => {
  return {
    type: Consts.BROWSE_APPLICATION_SCREEN_CANCEL,
  };
};

export const insertScreen = (
  data: IApplicationScreenModel
): IInsertApplicationScreenAction => {
  return {
    payload: data,
    type: Consts.INSERT_APPLICATION_SCREEN,
  };
};

export const insertScreenSuccess = (
  data: IApplicationScreenModel
): IInsertApplicationScreenSuccessAction => {
  return {
    payload: data,
    type: Consts.INSERT_APPLICATION_SCREEN_SUCCESS,
  };
};

export const insertScreenFailure = (
  error?: IErrorModel
): IInsertApplicationScreenFailureAction => {
  return {
    error,
    type: Consts.INSERT_APPLICATION_SCREEN_FAILURE,
  };
};

export const updateScreen = (
  data: IApplicationScreenModel
): IUpdateApplicationScreenAction => {
  return {
    payload: data,
    type: Consts.UPDATE_APPLICATION_SCREEN,
  };
};

export const updateScreenSuccess = (
  data: IApplicationScreenModel
): IUpdateApplicationScreenSuccessAction => {
  return {
    payload: data,
    type: Consts.UPDATE_APPLICATION_SCREEN_SUCCESS,
  };
};

export const updateScreenFailure = (
  error?: IErrorModel
): IUpdateApplicationScreenFailureAction => {
  return {
    error,
    type: Consts.UPDATE_APPLICATION_SCREEN_FAILURE,
  };
};

export const copyScreen = (
  data: IApplicationScreenCopyModel
): ICopyApplicationScreenAction => {
  return {
    payload: data,
    type: Consts.COPY_APPLICATION_SCREEN,
  };
};

export const copyScreenSuccess = (
  data: IApplicationScreenCopyModel
): ICopyApplicationScreenSuccessAction => {
  return {
    payload: data,
    type: Consts.COPY_APPLICATION_SCREEN_SUCCESS,
  };
};

export const copyScreenFailure = (
  error?: IErrorModel
): ICopyApplicationScreenFailureAction => {
  return {
    error,
    type: Consts.COPY_APPLICATION_SCREEN_FAILURE,
  };
};

export const deleteScreen = (
  data: IApplicationScreenModel
): IDeleteApplicationScreenAction => {
  return {
    payload: data,
    type: Consts.DELETE_APPLICATION_SCREEN,
  };
};

export const deleteScreenSuccess = (): IDeleteApplicationScreenSuccessAction => {
  return {
    type: Consts.DELETE_APPLICATION_SCREEN_SUCCESS,
  };
};

export const deleteScreenFailure = (
  error?: IErrorModel
): IDeleteApplicationScreenFailureAction => {
  return {
    error,
    type: Consts.DELETE_APPLICATION_SCREEN_FAILURE,
  };
};

//export const saveApplicationConfiguration = (
//  data: ConfigurationModel,
//  onSuccess?: () => void,
//  platformCode?: PlatformType,
//): ApplicationConfigurationActionsTypes => {
//  return {
//    data,
//    platformCode,
//    onSuccess,
//    type: Consts.SAVE_APPLICATION_CONFIGURATION,
//  };
//};

//export const saveApplicationConfigurationSuccess = (configuration: ConfigurationModel): ApplicationConfigurationActionsTypes => {
//  return {
//    payload: {
//      configuration,
//    },
//    type: Consts.SAVE_APPLICATION_CONFIGURATION_SUCCESS,
//  };
//};

//export const saveApplicationConfigurationFailure = (error?: IErrorModel): ApplicationConfigurationActionsTypes => {
//  return {
//    error,
//    type: Consts.SAVE_APPLICATION_CONFIGURATION_FAILURE,
//  };
//};

export const selectScreensTypes = (): ApplicationConfigurationActionsTypes => {
  return {
    type: Consts.SELECT_APPLICATION_SCREEN_TYPE,
  };
};

export const selectScreensTypesSuccess = (
  data: IApplicationScreenTypeModel[]
): ApplicationConfigurationActionsTypes => {
  return {
    payload: data,
    type: Consts.SELECT_APPLICATION_SCREEN_TYPE_SUCCESS,
  };
};

export const selectScreensTypesFailure = (
  error?: IErrorModel
): ApplicationConfigurationActionsTypes => {
  return {
    error,
    type: Consts.SELECT_APPLICATION_SCREEN_TYPE_FAILURE,
  };
};

export const selectComponentsTypes = (): ApplicationConfigurationActionsTypes => {
  return {
    type: Consts.SELECT_APPLICATION_COMPONENT_TYPE,
  };
};

export const selectComponentsTypesSuccess = (
  data: IApplicationComponentTypeModel[]
): ApplicationConfigurationActionsTypes => {
  return {
    payload: data,
    type: Consts.SELECT_APPLICATION_COMPONENT_TYPE_SUCCESS,
  };
};

export const selectComponentsTypesFailure = (
  error?: IErrorModel
): ApplicationConfigurationActionsTypes => {
  return {
    error,
    type: Consts.SELECT_APPLICATION_COMPONENT_TYPE_FAILURE,
  };
};

//export const getDataSource = (filter: ISourceOptionsFilterModel): ApplicationConfigurationActionsTypes => {
//  return {
//    type: Consts.GET_DATA_SOURCE,
//    filter
//  }
//}

//export const getDataSourceSuccess = (sourceId: number, data: IAssetListModel): ApplicationConfigurationActionsTypes => {
//  return {
//    type: Consts.GET_DATA_SOURCE_SUCCESS,
//    sourceId,
//    data
//  }
//}

//export const getDataSourceFailure = (sourceId: number, error?: IErrorModel): ApplicationConfigurationActionsTypes => {
//  return {
//    type: Consts.GET_DATA_SOURCE_FAILURE,
//    sourceId,
//    error
//  }
//}

//export const getDataSourceCancel = (): ApplicationConfigurationActionsTypes => {
//  return {
//    type: Consts.GET_DATA_SOURCE_CANCEL
//  }
//}

// Branding

export const getBranding = (id: number): IGetApplicationBrandingAction => {
  return {
    id,
    type: Consts.GET_APPLICATION_BRANDING,
  };
};

export const getBrandingSuccess = (
  data: IApplicationConfigurationBrandingModel
): IGetApplicationBrandingSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_APPLICATION_BRANDING_SUCCESS,
  };
};

export const getBrandingFailure = (
  error?: IErrorModel
): IGetApplicationBrandingFailureAction => {
  return {
    error,
    type: Consts.GET_APPLICATION_BRANDING_FAILURE,
  };
};

export const getDefaultBranding = (
  applicationConfigurationId: number
): IGetDefaultApplicationBrandingAction => {
  return {
    applicationConfigurationId,
    type: Consts.GET_APPLICATION_DEFAULT_BRANDING,
  };
};

export const getDefaultBrandingSuccess = (
  data: IApplicationScreenModel
): IGetDefaultApplicationBrandingSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_APPLICATION_DEFAULT_BRANDING_SUCCESS,
  };
};

export const getDefaultBrandingFailure = (
  error?: IErrorModel
): IGetDefaultApplicationBrandingFailureAction => {
  return {
    error,
    type: Consts.GET_APPLICATION_DEFAULT_BRANDING_FAILURE,
  };
};

export const selectBrandingKeys = (
  applicationConfigurationId: number
): ISelectKeysApplicationBrandingAction => {
  return {
    applicationConfigurationId,
    type: Consts.SELECT_KEYS_APPLICATION_BRANDING,
  };
};

export const selectBrandingKeysSuccess = (
  data: IApplicationConfigurationBrandingKeyTypeListModel
): ISelectKeysApplicationBrandingSuccessAction => {
  return {
    payload: data,
    type: Consts.SELECT_KEYS_APPLICATION_BRANDING_SUCCESS,
  };
};

export const selectBrandingKeysFailure = (
  error?: IErrorModel
): ISelectKeysApplicationBrandingFailureAction => {
  return {
    error,
    type: Consts.SELECT_KEYS_APPLICATION_BRANDING_FAILURE,
  };
};

export const searchBranding = (
  filter: IApplicationConfigurationBrandingSearchFilterModel
): ISearchApplicationBrandingAction => {
  return {
    filter,
    type: Consts.SEARCH_APPLICATION_BRANDING,
  };
};

export const searchBrandingSuccess = (
  data: IApplicationConfigurationBrandingListModel
): ISearchApplicationBrandingSuccessAction => {
  return {
    payload: data,
    type: Consts.SEARCH_APPLICATION_BRANDING_SUCCESS,
  };
};

export const searchBrandingFailure = (
  error?: IErrorModel
): ISearchApplicationBrandingFailureAction => {
  return {
    error,
    type: Consts.SEARCH_APPLICATION_BRANDING_FAILURE,
  };
};

export const insertBranding = (
  data: IApplicationConfigurationBrandingModel
): IInsertApplicationBrandingAction => {
  return {
    payload: data,
    type: Consts.INSERT_APPLICATION_BRANDING,
  };
};

export const insertBrandingSuccess = (
  data: IApplicationConfigurationBrandingModel
): IInsertApplicationBrandingSuccessAction => {
  return {
    payload: data,
    type: Consts.INSERT_APPLICATION_BRANDING_SUCCESS,
  };
};

export const insertBrandingFailure = (
  error?: IErrorModel
): IInsertApplicationBrandingFailureAction => {
  return {
    error,
    type: Consts.INSERT_APPLICATION_BRANDING_FAILURE,
  };
};

export const updateBranding = (
  data: IApplicationConfigurationBrandingModel
): IUpdateApplicationBrandingAction => {
  return {
    payload: data,
    type: Consts.UPDATE_APPLICATION_BRANDING,
  };
};

export const updateBrandingSuccess = (
  data: IApplicationConfigurationBrandingModel
): IUpdateApplicationBrandingSuccessAction => {
  return {
    payload: data,
    type: Consts.UPDATE_APPLICATION_BRANDING_SUCCESS,
  };
};

export const updateBrandingFailure = (
  error?: IErrorModel
): IUpdateApplicationBrandingFailureAction => {
  return {
    error,
    type: Consts.UPDATE_APPLICATION_BRANDING_FAILURE,
  };
};

export const deleteBranding = (
  data: IApplicationConfigurationBrandingModel
): IDeleteApplicationBrandingAction => {
  return {
    payload: data,
    type: Consts.DELETE_APPLICATION_BRANDING,
  };
};

export const deleteBrandingSuccess = (
  data: IApplicationConfigurationBrandingModel
): IDeleteApplicationBrandingSuccessAction => {
  return {
    payload: data,
    type: Consts.DELETE_APPLICATION_BRANDING_SUCCESS,
  };
};

export const deleteBrandingFailure = (
  error?: IErrorModel
): IDeleteApplicationBrandingFailureAction => {
  return {
    error,
    type: Consts.DELETE_APPLICATION_BRANDING_FAILURE,
  };
};

export const uploadBrandingImage = (
  fileUploadInfo: UploadFileInfoModel,
  file: File,
  onProgress: (event: { percent: number }) => void,
  onSuccess: () => void
): IUploadBrandingImageAction => {
  return {
    file,
    fileUploadInfo,
    onProgress,
    onSuccess,
    type: Consts.UPLOAD_BRANDING_IMAGE,
  };
};

export const uploadBrandingImageSuccess = (
  data: OperationResult<UploadFileInfoModel>
): IUploadBrandingImageSuccessAction => {
  return {
    payload: data,
    type: Consts.UPLOAD_BRANDING_IMAGE_SUCCESS,
  };
};

export const uploadBrandingImageFailure = (
  error?: IErrorModel
): IUploadBrandingImageFailureAction => {
  return {
    error,
    type: Consts.UPLOAD_BRANDING_IMAGE_FAILURE,
  };
};

// Branding end

export const getImageUploadFileInfo = (
  id: number
): IGetImageUploadFileInfoAction => {
  return {
    id,
    type: Consts.GET_IMAGE_UPLOAD_FILE_INFO,
  };
};

export const getImageUploadFileInfoSuccess = (
  data: UploadFileInfoModel
): IGetImageUploadFileInfoSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_IMAGE_UPLOAD_FILE_INFO_SUCCESS,
  };
};

export const getImageUploadFileInfoFailure = (
  error?: IErrorModel
): IGetImageUploadFileInfoFailureAction => {
  return {
    error,
    type: Consts.GET_IMAGE_UPLOAD_FILE_INFO_FAILURE,
  };
};

export const exportTranslations = (): IExportTranslationsAction => {
  return {
    type: Consts.EXPORT_TRANSLATIONS,
  };
};

export const exportTranslationsSuccess = (
  data: Blob
): IExportTranslationsSuccessAction => {
  return {
    payload: data,
    type: Consts.EXPORT_TRANSLATIONS_SUCCESS,
  };
};

export const exportTranslationsFailure = (
  error?: IErrorModel
): IExportTranslationsFailureAction => {
  return {
    error,
    type: Consts.EXPORT_TRANSLATIONS_FAILURE,
  };
};

export const importTranslations = (file: File): IImportTranslationsAction => {
  return {
    file,
    type: Consts.IMPORT_TRANSLATIONS,
  };
};

export const importTranslationsSuccess = (
  data: any
): IImportTranslationsSuccessAction => {
  return {
    payload: data,
    type: Consts.IMPORT_TRANSLATIONS_SUCCESS,
  };
};

export const importTranslationsFailure = (
  error?: IErrorModel
): IImportTranslationsFailureAction => {
  return {
    error,
    type: Consts.IMPORT_TRANSLATIONS_FAILURE,
  };
};

export const Actions = {
  getConfiguration,
  deleteConfiguration,
  searchConfigurations,
  getScreen,
  searchScreens,
  browseScreens,
  insertScreen,
  updateScreen,
  deleteScreen,
  copyScreen,
  copyScreenFailure,
  copyScreenSuccess,
  //saveApplicationConfiguration,
  selectScreensTypes,
  selectComponentsTypes,
  updateConfiguration,
  publishConfiguration,
  //getDataSource,
  getBranding,
  getBrandingSuccess,
  getBrandingFailure,
  getDefaultBranding,
  getDefaultBrandingSuccess,
  getDefaultBrandingFailure,
  selectBrandingKeys,
  selectBrandingKeysSuccess,
  selectBrandingKeysFailure,
  searchBranding,
  searchBrandingSuccess,
  searchBrandingFailure,
  insertBranding,
  insertBrandingSuccess,
  insertBrandingFailure,
  updateBranding,
  updateBrandingSuccess,
  updateBrandingFailure,
  deleteBranding,
  deleteBrandingSuccess,
  deleteBrandingFailure,
  uploadBrandingImage,
  uploadBrandingImageSuccess,
  uploadBrandingImageFailure,
  getImageUploadFileInfo,
  getImageUploadFileInfoSuccess,
  getImageUploadFileInfoFailure,
  exportTranslations,
  exportTranslationsSuccess,
  exportTranslationsFailure,
  importTranslations,
  importTranslationsSuccess,
  importTranslationsFailure,
};
