import React from "react";
import { SectionGrid, SectionGridItem } from "@bms/common-ui";
import { AssetForm } from "../../AssetForm";
import {
  descriptionCommonFields,
  getSectionsFields,
} from "../../AssetForm/AssetFormUtils";
import { ICommonAssetDetailsTabProps } from "./ICommonAssetDetailsTabProps";
import { AssetType } from "@bms/common-services";
import { useTranslation } from "react-i18next";

export const DetailsTab = ({
  asset,
  updateAssetState,
  updateAsset,
}: ICommonAssetDetailsTabProps) => {
  const { t } = useTranslation();
  const sections = asset?.AssetTypeCode
    ? getSectionsFields(asset.AssetTypeCode)
    : null;

  return (
    <SectionGrid style={{ maxWidth: "90%" }}>
      {(sections?.general?.length || 0) > 0 && (
        <SectionGridItem
          header={t("ASSET_DETAILS_SECTION_GENERAL")}
          processing={updateAssetState?.processing}
        >
          <AssetForm
            key={`AssetForm-${asset?.Id}`}
            isEditMode={true}
            asset={asset}
            section="general"
            onSubmit={updateAsset}
            hiddenFields={[...descriptionCommonFields]}
          />
        </SectionGridItem>
      )}
      {(sections?.restriction?.length || 0) > 0 && (
        <SectionGridItem
          header={t("ASSET_DETAILS_SECTION_RESTRICTION")}
          processing={updateAssetState?.processing}
        >
          <AssetForm
            key={`AssetForm-${asset?.Id}`}
            isEditMode={true}
            asset={asset}
            section="restriction"
            onSubmit={updateAsset}
          />
        </SectionGridItem>
      )}
      {asset?.AssetTypeCode !== AssetType.Podcast &&
        (sections?.people?.length || 0) > 0 && (
          <SectionGridItem
            header={t("ASSET_DETAILS_SECTION_PEOPLE")}
            processing={updateAssetState?.processing}
          >
            <AssetForm
              key={`AssetForm-${asset?.Id}`}
              isEditMode={true}
              asset={asset}
              section="people"
              onSubmit={updateAsset}
            />
          </SectionGridItem>
        )}
    </SectionGrid>
  );
};
