import { Button, Modal } from "@bms/common-ui";
import { FormModal } from "../../../../components";
import React from "react";
import { useTranslation } from "react-i18next";

interface IAddAssetModal {
  visible: boolean;
  close: () => void;
  processing: boolean;
  translated?: {
    title: string;
  };
}

export const AddAssetModal: React.FC<IAddAssetModal> = ({
  visible,
  close,
  processing,
  translated = {},
  children,
}) => {
  const { t } = useTranslation();

  const title: string = translated.title || t("ASSET_ADD_NEW");
  return (
    <FormModal
      isLoading={processing}
      createFormTitle={title}
      editFormTitle={title}
      isNewForm={true}
      isVisible={visible}
      isDeleteButtonEnabled={false}
      onDeleteButtonClick={() => {}}
      modalClassName="AddAssetModal"
      submitFormName="AssetForm"
      onCloseModal={close}
    >
      {children}
    </FormModal>
  );
};
