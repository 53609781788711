import { DataProvider } from "@bms/common-services";
import { NotificationService } from "@bms/common-ui";
import { BrowserHistoryBuildOptions, createBrowserHistory } from "history";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { createLogger } from "redux-logger";
import App from "./modules";
import { ReduxStoreConfigurator } from "./store";

const notificationService = NotificationService.getInstance();
notificationService.config({
  placement: "bottomLeft",
  bottom: 8,
  duration: 5,
});

// Init HttpFactory
DataProvider.initHttpFactory();

const browserHistoryOptions: BrowserHistoryBuildOptions = {};

if (process.env.PUBLIC_URL) {
  browserHistoryOptions.basename = process.env.PUBLIC_URL;
}

const appHistory = createBrowserHistory(browserHistoryOptions);
const middlewares = [];

if (process.env.NODE_ENV === "development") {
  middlewares.push(createLogger({ diff: true, collapsed: true }));
}
const reduxStoreConfigurator = new ReduxStoreConfigurator(
  appHistory,
  middlewares
);
const appStore = reduxStoreConfigurator.initStore();

class InitApp extends React.Component {
  public render = () => (
    <Provider store={appStore}>
      <App history={appHistory} />
    </Provider>
  );
}

window.onload = () => render(<InitApp />, document.getElementById("root"));
