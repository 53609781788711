/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IdentifierType } from "../../../enums";
import {
  IAssetCollectionListModel,
  IAssetCollectionModel,
  IAssetCollectionSearchFilterModel,
  IAssetListModel,
  IErrorModel,
} from "../../../models";
import {
  AssetCollectionService,
  HttpFactory,
  HttpRequestFulfilledInterceptor,
  HttpRequestRejectedInterceptor,
  HttpResponseFulfilledInterceptor,
  HttpResponseRejectedInterceptor,
} from "../../../services";
import { IDataProvider } from "../IDataProvider";

const assetCollectionService: AssetCollectionService = new AssetCollectionService();

export class InternalDataProvider implements IDataProvider {
  initHttpFactory() {
    const httpFactory = HttpFactory.getInstance();
    httpFactory.addRequestInterceptor(
      "HttpRequestInterceptor",
      HttpRequestFulfilledInterceptor,
      HttpRequestRejectedInterceptor
    );
    httpFactory.addResponseInterceptor(
      "HttpResponseInterceptor",
      HttpResponseFulfilledInterceptor,
      HttpResponseRejectedInterceptor
    );
  }

  AssetCollection = {
    Options: {
      IdentifierType: IdentifierType.Integer,
      InsertEnabled: true,
      UpdateEnabled: true,
      DeleteEnabled: true,
    },
    async get(id: number): Promise<IAssetCollectionModel> {
      try {
        return assetCollectionService.get(id).toPromise();
      } catch (error) {
        return Promise.reject(error as IErrorModel);
      }
    },

    async insert(data: IAssetCollectionModel): Promise<IAssetCollectionModel> {
      try {
        return assetCollectionService.insert(data).toPromise();
      } catch (error) {
        return Promise.reject(error as IErrorModel);
      }
    },

    async update(data: IAssetCollectionModel): Promise<IAssetCollectionModel> {
      try {
        return assetCollectionService.update(data).toPromise();
      } catch (error) {
        return Promise.reject(error as IErrorModel);
      }
    },

    async delete(data: IAssetCollectionModel): Promise<void> {
      try {
        return assetCollectionService.delete(data).toPromise();
      } catch (error) {
        return Promise.reject(error as IErrorModel);
      }
    },

    async search(
      filter: IAssetCollectionSearchFilterModel
    ): Promise<IAssetCollectionListModel> {
      try {
        return assetCollectionService.search(filter).toPromise();
      } catch (error) {
        return Promise.reject(error as IErrorModel);
      }
    },

    async searchAssetsByAssetCollectionId(
      filter: IAssetCollectionSearchFilterModel
    ): Promise<IAssetListModel> {
      try {
        return assetCollectionService
          .searchAssetsByAssetCollectionId(filter)
          .toPromise();
      } catch (error) {
        return Promise.reject(error as IErrorModel);
      }
    },
  };
}
