import {
  IAssetCollectionModel,
  DataProvider,
  RecordStatus,
  useServiceCaller,
} from "@bms/common-services";
import { Button, NotificationService } from "@bms/common-ui";
import { FormModal } from "../../../../components";
import { push } from "connected-react-router";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ROUTES } from "../../constants/routes";
import { AssetCollectionForm } from "../AssetCollectionForm";
import "./AddAssetCollectionModal.scss";

export interface IAddAssetCollectionModalProps {
  visible: boolean;
  onCancel: () => void;
  onSuccess?: () => void;
}

const notificationService = NotificationService.getInstance();

const newAssetCollection: IAssetCollectionModel = {
  Id: -1,
  RecordStatus: RecordStatus.Inserted,
};

export const AddAssetCollectionModal: React.FC<IAddAssetCollectionModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { visible, onSuccess, onCancel } = props;

  const [insertAssetCollection, { processing }] = useServiceCaller(
    (data: IAssetCollectionModel) => {
      return DataProvider.AssetCollection.insert(data)
        .then((data) => {
          onSuccess?.();
          notificationService.success({
            message: t("ASSET_ADD_PLAYLIST_COLLECTION_SUCCESS"),
          });
          dispatch(push(`${ROUTES.ASSET_COLLECTION_DETAILS}/${data.Id}`));
          return true;
        })
        .catch((error) => {
          notificationService.error({
            message: t("ASSET_ADD_PLAYLIST_COLLECTION_FAILURE"),
            description: error?.Message,
          });
          return false;
        });
    },
    []
  );

  return (
    <FormModal
      isLoading={processing}
      isVisible={visible}
      isNewForm={true}
      isDeleteButtonEnabled={false}
      createFormTitle={t("ADD_NEW_PLAY_LIST")}
      editFormTitle={t("ADD_NEW_PLAY_LIST")}
      modalClassName="AddAssetCollectionModal"
      submitFormName="AssetCollectionForm"
      onCloseModal={onCancel}
      onDeleteButtonClick={() => {}}
    >
      <AssetCollectionForm
        assetCollection={newAssetCollection}
        onSubmit={insertAssetCollection}
      />
    </FormModal>
  );
};
