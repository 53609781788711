import { useCallback, useMemo, useState } from "react";

export const useSeriesRank = <T extends { y: number }>(series?: T[][]) =>
  useMemo(() => {
    if (!series || !series[0] || !series[0].length) {
      return 0;
    }
    return Math.floor(
      Math.log10(
        Math.max(
          ...series[0]?.map((_, index) =>
            series?.map((serie) => serie[index].y).reduce((a, b) => a + b, 0)
          )
        )
      )
    );
  }, [series]);

export const useChartHover = <T extends { x: any; y: any }>(series: T[][]) => {
  const [nearestX, setNearestX] = useState<number | undefined>(undefined);
  const [highlightedIndex, setHighlightedIndex] = useState<number | undefined>(
    undefined
  );

  const onNearestX = useCallback(
    ({ x }: { x: string | number }) => {
      setNearestX(typeof x === "string" ? Number.parseInt(x) : x);
    },
    [setNearestX]
  );

  const onPlotMouseLeave = useCallback(() => {
    setNearestX(undefined);
    setHighlightedIndex(undefined);
  }, [setNearestX, setHighlightedIndex]);

  const onSeriesMouseOver = useCallback(
    (index: number) => setHighlightedIndex(index),
    [setHighlightedIndex]
  );

  const nearestItem = useMemo(
    () =>
      nearestX === undefined
        ? undefined
        : series.map(
            (serie) =>
              serie.find((point) => point.x >= nearestX) ?? {
                x: undefined,
                y: undefined,
              }
          ),
    [nearestX]
  );

  return {
    highlightedIndex,
    onSeriesMouseOver,
    onNearestX,
    onPlotMouseLeave,
    nearestItem,
  };
};

export const getOpacity = (
  highlightedIndex: number | undefined,
  index: number
) =>
  highlightedIndex === undefined ? 0.5 : highlightedIndex === index ? 1 : 0.3;

export const tickFormat = (xDate?: boolean) => (tick: number | string) =>
  xDate ? new Date(tick).toLocaleDateString() : `${tick}`;
