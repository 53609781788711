// tslint:disable:object-literal-sort-keys
import { BooleanHelper } from "@bms/studio/src/helpers";
import React, { useCallback, useEffect, useState } from "react";
import {
  AssetStore,
  AssetType,
  ContentStatus,
  IAssetContentModel,
  IAssetModel,
  IAssetSearchFilterModel,
  ICommonAppState,
  IRestrictedContentCookieModel,
  TimeHelper,
  useExternalSources,
} from "@bms/common-services";
import {
  Button,
  Heading,
  Icon,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  Modal,
  NotificationService,
  Popconfirm,
  setTableColumnSearchProps,
  Spin,
  Table,
  Tag,
  useIntervalEffect,
} from "@bms/common-ui";
import Cookies, { CookieAttributes } from "js-cookie";
import {
  EditExternalAsset,
  EditType,
} from "../../../../components/EditExternalAsset/EditExternalAsset";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FormModal, Player, MycujooPlayer } from "../../../../components";
import { ContentStatusHelper } from "../../../../helpers";
import { AssetContentPathModal } from "../AssetContentPathModal";
import { AssetContentUploadModal } from "../AssetContentUploadModal";
import { FilterCleanIcon } from "../../../../resources/icons";

import "./AssetContentList.scss";

const notificationService = NotificationService.getInstance();

const assetSelector = (state: ICommonAppState) => state.asset;
type ColumnValue = string | number | boolean;

const AUTO_REFRESH_INTERVAL = 10_000;
const STATUSES_REQUIRING_UPDATE: ContentStatus[] = [
  ContentStatus.Processing,
  ContentStatus.Queued,
];

interface IAssetContentListProps {
  asset?: IAssetModel;
  refreshAsset: () => void;
}

const CONTENT_ADD_PATH_ENABLED = BooleanHelper.toBool(
  process.env.REACT_APP_CONTENT_ADD_PATH_ENABLED,
  true
);

const CONTENT_UPLOAD_ENABLED = BooleanHelper.toBool(
  process.env.REACT_APP_CONTENT_UPLOAD_ENABLED,
  true
);

export const AssetContentList = ({
  asset,
  refreshAsset,
}: IAssetContentListProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const sources = useExternalSources();

  const [refreshInterval, setRefreshInterval] = useIntervalEffect(refreshAsset);
  const [stateFilter, setStateFilter] = useState<IAssetSearchFilterModel>({
    PageSize: 10,
    PageNumber: 1,
    IncludeCount: true,
  });
  const [editableContent, setEditableContent] = useState<IAssetContentModel>(
    {}
  );
  const [previewContentUrl, setPreviewContentUrl] = useState<string>("");
  const [showContentPreviewModal, setShowContentPreviewModal] = useState<
    boolean
  >(false);
  const [showContentPathModal, setShowContentPathModal] = useState<boolean>(
    false
  );
  const [showContentUploadModal, setShowContentUploadModal] = useState<boolean>(
    false
  );

  const {
    assetContentTypes,
    assetContentStreamTypes,
    action,
    isProcessing,
  } = useSelector(assetSelector);

  const getAssetContentTypes = useCallback(
    () => dispatch(AssetStore.Actions.getAssetContentTypes()),
    [dispatch]
  );
  const getAssetContentStreamTypes = useCallback(
    () => dispatch(AssetStore.Actions.getAssetContentStreamTypes()),
    [dispatch]
  );
  const deleteAssetContent = useCallback(
    (data: IAssetContentModel) =>
      dispatch(AssetStore.Actions.deleteAssetContent(data)),
    [dispatch]
  );

  const cleanupAfterActions = () => {
    setShowContentPathModal(false);
    setShowContentUploadModal(false);
    setEditableContent({});
    refreshAsset();
  };

  useEffect(() => {
    getAssetContentTypes();
    getAssetContentStreamTypes();
  }, []);

  useEffect(() => {
    const shouldRefresh = asset?.Contents?.some(({ ContentStatusCode }) =>
      STATUSES_REQUIRING_UPDATE.includes(ContentStatusCode as ContentStatus)
    );

    if (shouldRefresh) {
      if (refreshInterval === 0) {
        setRefreshInterval(AUTO_REFRESH_INTERVAL);
      }
    } else {
      setRefreshInterval(0);
    }
  }, [asset?.Contents]);

  useEffect(() => {
    switch (action?.type) {
      case AssetStore.Consts.ADD_ASSET_CONTENT_SUCCESS:
        cleanupAfterActions();
        notificationService.success({
          message: t("ADD_ASSET_CONTENT_SUCCESS"),
        });
        break;
      case AssetStore.Consts.ADD_ASSET_CONTENT_FAILURE:
        notificationService.error({
          message: t("ADD_ASSET_CONTENT_FAILURE"),
          description: action?.error?.Message,
        });
        break;
      case AssetStore.Consts.UPDATE_ASSET_CONTENT_SUCCESS:
        cleanupAfterActions();
        notificationService.success({
          message: t("UPDATE_ASSET_CONTENT_SUCCESS"),
        });
        break;
      case AssetStore.Consts.UPDATE_ASSET_CONTENT_FAILURE:
        notificationService.error({
          message: t("UPDATE_ASSET_CONTENT_FAILURE"),
          description: action?.error?.Message,
        });
        break;
      case AssetStore.Consts.DELETE_ASSET_CONTENT_SUCCESS:
        cleanupAfterActions();
        notificationService.success({
          message: t("DELETE_ASSET_CONTENT_SUCCESS"),
        });
        break;
      case AssetStore.Consts.DELETE_ASSET_CONTENT_FAILURE:
        notificationService.error({
          message: t("DELETE_ASSET_CONTENT_FAILURE"),
          description: action?.error?.Message,
        });
        break;
    }
  }, [action]);

  const getColumnsProps = (): Array<ITableColumnProps<IAssetContentModel>> => {
    return [
      {
        key: "ContentTypeDisplayName",
        dataIndex: "ContentTypeDisplayName",
        title: t("ASSET_CONTENT_TYPE"),
        width: "150px",
        align: "center",
        filters: assetContentTypes?.data?.map((type) => ({
          text: type.DisplayName,
          value: type.Code,
        })),
        filteredValue: stateFilter.AssetContentTypeDisplayNames || null,
        onFilter: (value: ColumnValue, record: IAssetContentModel) =>
          record.ContentTypeCode!.includes(`${value}`),
        render: (value) => <Tag colorRotate={value}>{value}</Tag>,
      },
      {
        key: "StreamTypeDisplayName",
        dataIndex: "StreamTypeDisplayName",
        title: t("ASSET_CONTENT_STREAM_TYPE_LABEL"),
        width: "150px",
        align: "center",
        filters: assetContentStreamTypes?.data?.map((type) => ({
          text: type.DisplayName,
          value: type.Code,
        })),
        filteredValue: stateFilter.AssetStreamTypeDisplayNames || null,
        onFilter: (value: ColumnValue, record: IAssetContentModel) =>
          record.StreamTypeCode!.includes(`${value}`),
        render: (value, record: IAssetContentModel) => (
          <Tag colorRotate={value}>{record.StreamTypeDisplayName}</Tag>
        ),
      },
      {
        key: "Url",
        dataIndex: "Url",
        ellipsis: true,
        title: t("COMMON_URL"),
        ...setTableColumnSearchProps("Url"),
        onFilter: (value: ColumnValue, record: IAssetContentModel) =>
          record.Url!.includes(`${value}`),
        filteredValue: stateFilter.Url ? [stateFilter.Url] : null,
        render: (_, record: IAssetContentModel) => {
          return (
            <span
              title={record.Url}
              className="AssetContentList__TableColumn__Url"
              onClick={() => {
                setEditableContent(record);
                setShowContentPathModal(true);
              }}
            >
              {record.Url}
            </span>
          );
        },
      },
      {
        key: "DurationMiliseconds",
        dataIndex: "DurationMiliseconds",
        title: t("MODEL_DURATION_MILISECONDS"),
        width: "150px",
        align: "center",
        render: (value) => {
          if (value) {
            return TimeHelper.formatPlayTimeFromMiliseconds(value);
          }

          return null;
        },
      },
      {
        key: "ContentStatusDisplayName",
        dataIndex: "ContentStatusDisplayName",
        title: t("COMMON_STATUS"),
        width: "150px",
        filters: ContentStatusHelper.getFilterOptions(),
        filteredValue: stateFilter.AssetContentStatusDisplayNames || null,
        onFilter: (value: ColumnValue, record: IAssetContentModel) =>
          record.ContentStatusCode!.includes(`${value}`),
        align: "center",
        render: (_, row: IAssetContentModel) => {
          return ContentStatusHelper.getTag(row.ContentStatusCode);
        },
      },
      {
        key: "Preview",
        dataIndex: "Preview",
        title: t("COMMON_PREVIEW"),
        width: "100px",
        ellipsis: true,
        align: "center",
        render: (_, record: IAssetContentModel) => {
          switch (record.ContentStatusCode) {
            case ContentStatus.Ready:
              return (
                <Button
                  shape="circle"
                  type="text"
                  icon={<Icon type="PlayCircle" style={{ fontSize: "24px" }} />}
                  onClick={() => onOpenContentPreviewModal(record)}
                />
              );
            case ContentStatus.Queued:
            case ContentStatus.Processing:
              return <Spin />;
            default:
              return null;
          }
        },
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        width: "100px",
        title: t("TABLE_ACTIONS_COLUMN"),
        render: (_, assetContent: IAssetContentModel) => (
          <div className="asset-table__actions">
            <EditExternalAsset
              asset={assetContent}
              externalSource={assetContent.ExternalSource}
              assetSourceEntities={sources}
              type={EditType.AssetDetails}
            />
            <Popconfirm
              title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
              onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
                e?.preventDefault();
                await deleteAssetContent(assetContent);
              }}
              okText={t("BUTTON_YES")}
              cancelText={t("BUTTON_NO")}
            >
              <Button
                danger={true}
                icon={<Icon type="delete" />}
                title={t("BUTTON_DELETE")}
              />
            </Popconfirm>
          </div>
        ),
      },
    ];
  };

  const onOpenContentPreviewModal = (assetContent: IAssetContentModel) => {
    if (assetContent.Cookies && assetContent.Cookies.length > 0) {
      assetContent.Cookies.forEach((item: IRestrictedContentCookieModel) => {
        Cookies.remove(item.Name);
        const options: CookieAttributes = {
          path: item.Path,
          domain: item.Domain,
          secure: item.IsSecure,
          sameSite: item.SameSite,
        };
        if (item.Expires) {
          options.expires = new Date(item.Expires);
        }
        Cookies.set(item.Name, item.Value || "", options);
      });
    }

    setShowContentPreviewModal(true);
    setPreviewContentUrl(assetContent.Url!);
  };

  const onOpenContentPathModal = () => {
    setShowContentPathModal(true);
  };

  const onOpenContentUploadModal = () => {
    setShowContentUploadModal(true);
  };

  const onCancelContentPreviewModal = () => {
    setShowContentPreviewModal(false);
    setPreviewContentUrl("");
    Modal.destroyAll();
  };

  const onCancelContentPathModal = () => {
    setShowContentPathModal(false);
    setEditableContent({});
    Modal.destroyAll();
  };

  const onCancelContentUploadModal = () => {
    setShowContentUploadModal(false);
    setEditableContent({});
    Modal.destroyAll();
  };

  const onSuccessContentUploadModal = () => {
    refreshAsset();
    setShowContentUploadModal(false);
    setEditableContent({});
    Modal.destroyAll();
  };

  const onDeleteAssetContent = () => {
    deleteAssetContent(editableContent);
    Modal.destroyAll();
  };

  const onTableChange = (
    pagination: ITablePaginationConfig,
    filters: ITableFilter
  ) => {
    const filter: IAssetSearchFilterModel = { ...stateFilter };
    filter.PageNumber = pagination.current!;
    filter.PageSize = pagination.pageSize;

    filter.Url =
      filters.Url && filters.Url.length ? `${filters.Url[0]}` : undefined;

    filter.AssetContentTypeDisplayNames = filters.ContentTypeDisplayName?.length
      ? filters.ContentTypeDisplayName.map((row) => `${row}`)
      : undefined;

    filter.AssetContentStatusDisplayNames = filters.ContentStatusDisplayName
      ?.length
      ? filters.ContentStatusDisplayName.map((row) => `${row}`)
      : undefined;

    filter.AssetStreamTypeDisplayNames = filters.StreamTypeDisplayName?.length
      ? filters.StreamTypeDisplayName.map((row) => `${row}`)
      : undefined;

    setStateFilter(filter);
  };

  const onClearFiltersClick = () => {
    setStateFilter({});
  };

  const contentType =
    asset?.AssetTypeCode === AssetType.Podcast ? "audio" : "video";

  const showProperPlayer = () => {
    if (!showContentPreviewModal) {
      return;
    }

    if (
      asset?.AssetTypeCode === AssetType.Podcast &&
      !asset.ExternalId &&
      asset.ExternalSource !== "MCLS"
    ) {
      return <audio src={previewContentUrl} controls />;
    }

    if (asset?.ExternalId && asset.ExternalSource === "MCLS") {
      return (
        <MycujooPlayer
          eventId={asset.ExternalId}
          publicKey={process.env.REACT_APP_PLAYER_MCLS_PUBLIC_TOKEN || ""}
          showPictureInPicture={false}
          autoplay
        />
      );
    } else {
      return <Player contentUrl={previewContentUrl} fill={false} />;
    }
  };

  return (
    <div className="AssetContentList">
      <Heading
        title={t("ASSET_CONTENT_LIST_TITLE")}
        actions={
          <>
            <Button
              key="clear-filters"
              shape="circle"
              icon={<Icon component={FilterCleanIcon} />}
              onClick={onClearFiltersClick}
              title={t("MENU_OPTION_CLEAR_FILTERS")}
            />
            {CONTENT_ADD_PATH_ENABLED && (
              <Button
                key="add"
                type="primary"
                shape="circle"
                icon={<Icon type="link" />}
                onClick={onOpenContentPathModal}
                title={t("ASSET_CONTENT_LIST_ADD_CONTENT_URL_TITLE", {
                  contentType,
                })}
              />
            )}
            {CONTENT_UPLOAD_ENABLED && (
              <Button
                key="upload"
                type="primary"
                shape="circle"
                icon={<Icon type="cloud-upload" />}
                onClick={onOpenContentUploadModal}
                title={t("ASSET_CONTENT_LIST_ADD_CONTENT_FILE_TITLE", {
                  contentType,
                })}
              />
            )}
          </>
        }
      />
      <Table<IAssetContentModel>
        columns={getColumnsProps()}
        dataSource={asset?.Contents}
        rowKey="Id"
        loading={isProcessing}
        pagination={false}
        onChange={onTableChange}
      />
      <Modal
        visible={showContentPreviewModal}
        footer={null}
        onCancel={onCancelContentPreviewModal}
        preview
      >
        {showProperPlayer()}
      </Modal>
      <FormModal
        isVisible={showContentPathModal}
        isLoading={isProcessing}
        isNewForm={editableContent.Id ? false : true}
        isDeleteButtonEnabled={editableContent.Id ? true : false}
        createFormTitle={t("ASSET_CONTENT_FORM_ADD_TITLE")}
        editFormTitle={t("ASSET_CONTENT_FORM_EDIT_TITLE")}
        modalClassName="AssetContentPathForm"
        submitFormName="AssetContentPathForm"
        onCloseModal={onCancelContentPathModal}
        onDeleteButtonClick={onDeleteAssetContent}
      >
        <AssetContentPathModal asset={asset} assetContent={editableContent} />
      </FormModal>
      <AssetContentUploadModal
        visible={showContentUploadModal}
        asset={asset}
        assetContent={editableContent}
        onCancel={onCancelContentUploadModal}
        onSuccess={onSuccessContentUploadModal}
      />
    </div>
  );
};
