import {
  CellType,
  DataProvider,
  IAssetCollectionModel,
  IResourceModel,
  Orientation,
} from "@bms/common-services";
import {
  Button,
  Choose,
  Col,
  Form,
  IChooseValue,
  Icon,
  IFormValues,
  Input,
  InputNumber,
  Link,
  Row,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ROUTES } from "../../../../../../constants";
import { AssetCollectionBrowserModal } from "../../../../../Asset/components/AssetCollectionBrowserModal";
import * as TranslationsModule from "../../../../../Translations";
import { CellTypeHelper, OrientationHelper } from "../../../../helpers";
import { ListComponentPropertyModel, SourceType } from "../../models";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";
import "./ListComponentProperties.scss";

export interface IListComponentPropertiesStateProps {
  isProcessingData: boolean;
}

export interface IListComponentPropertiesDispatchProps {}

export interface IListComponentPropertiesOwnProps {}

export interface IListComponentPropertiesProps
  extends IListComponentPropertiesStateProps,
    IListComponentPropertiesDispatchProps,
    IListComponentPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface IListComponentPropertiesState
  extends IComponentPropertiesBaseState {
  assetCollectionsBrowserVisible: boolean;
  translationsBrowserVisible: boolean;
}

export class ListComponentProperties extends ComponentPropertiesBase<
  IListComponentPropertiesProps,
  IListComponentPropertiesState,
  ListComponentPropertyModel
> {
  public static defaultProps = {
    isProcessingData: false,
  };

  public state: Readonly<IListComponentPropertiesState> = {
    assetCollectionsBrowserVisible: false,
    translationsBrowserVisible: false,
  };

  getPropertiesModel(): ListComponentPropertyModel {
    const { component } = this.props;
    const properties = new ListComponentPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public onTitleTranslationKeyCancel = () => {
    this.setState({ translationsBrowserVisible: false });
  };

  public onTitleTranslationKeySelect = (row: IResourceModel) => {
    this.properties.TitleTranslationKey = row.ResourceKey;
    this.onPropertyChange("TitleTranslationKey");

    this.setState({ translationsBrowserVisible: false });
  };

  public onTitleTranslationKeyClear = () => {
    const { component, onComponentChange } = this.props;

    this.properties.TitleTranslationKey = undefined;
    this.properties.deleteProperty("TitleTranslationKey", component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  };

  public renderTitleTranslationKeyProperty = () => {
    const { component, t } = this.props;
    const { translationsBrowserVisible } = this.state;

    let titleTranslationKeyView: React.ReactElement = this.properties
      .TitleTranslationKey ? (
      <label>
        <Link
          to={`${TranslationsModule.ROUTES.CONFIGURATION_TRANSLATION_DETAILS}/${this.properties.TitleTranslationKey}`}
        >
          {this.properties.TitleTranslationKey}
        </Link>
      </label>
    ) : (
      <label>{t("MODEL_TRANSLATION_KEY_PLACEHOLDER")}</label>
    );

    return (
      <Form.Item
        name="TitleTranslationKey"
        label={t("MODEL_TITLE_KEY")}
        style={{ marginBottom: 0 }}
      >
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item>{titleTranslationKeyView}</Form.Item>
            <TranslationsModule.Components.TranslationsBrowserModal
              key={`TranslationsBrowser-${component.Id}`}
              visible={translationsBrowserVisible}
              onCancel={this.onTitleTranslationKeyCancel}
              onSelect={this.onTitleTranslationKeySelect}
            />
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {this.properties.TitleTranslationKey && (
              <Button
                icon={<Icon type="delete" />}
                onClick={this.onTitleTranslationKeyClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                this.setState({ translationsBrowserVisible: true });
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  public renderTitleProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="Title"
        label={t("MODEL_TITLE")}
        initialValue={this.properties.Title}
      >
        <Input
          disabled={isProcessingData}
          placeholder={t("MODEL_TITLE_PLACEHOLDER")}
          onChange={(e) => {
            this.properties.Title = e.target.value;
            this.onPropertyChange("Title");
          }}
        />
      </Form.Item>
    );
  };

  public onVisibleItemsPropertyChange = (
    value: string | number | undefined
  ) => {
    this.properties.VisibleItemsCount = value ? +value : 1;
    this.onPropertyChange("VisibleItemsCount");
  };

  public renderVisibleItemsProperty = () => {
    const { isProcessingData, t } = this.props;

    let disableInput = false;
    let minValue = 1;
    let maxValue = 10;

    switch (this.properties.CellType) {
      case CellType.HeroLandscape:
      case CellType.Highlights:
      case CellType.HighlightsWidescreen:
      case CellType.Promo:
        disableInput = true;
        minValue = 1;
        maxValue = 1;
        break;
      case CellType.Round:
      case CellType.Square:
      case CellType.Cover:
        minValue = 1;
        maxValue = 10;
        break;
      case CellType.Frame:
      case CellType.Default:
      default:
        minValue = 1;
        maxValue = 10;
        break;
    }

    return (
      <Form.Item
        name="VisibleItemsCount"
        label={t("COMPONENT_PROPERTIES__VISIBLE_ITEMS")}
        initialValue={this.properties.VisibleItemsCount}
      >
        <InputNumber
          disabled={isProcessingData || disableInput}
          placeholder={t("COMPONENT_PROPERTIES__VISIBLE_ITEMS_PLACEHOLDER")}
          onChange={this.onVisibleItemsPropertyChange}
          min={minValue}
          max={maxValue}
          defaultValue={3}
        />
      </Form.Item>
    );
  };

  public onCellTypePropertyChange = (value: IChooseValue) => {
    this.properties.CellType = CellTypeHelper.getValue(value as string);
    this.onPropertyChange("CellType");
  };

  public renderCellTypeProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="CellType"
        label={t("COMPONENT_PROPERTIES__CELL_TYPE")}
        initialValue={this.properties.CellType}
      >
        <Choose
          disabled={isProcessingData}
          placeholder={t("COMPONENT_PROPERTIES__CELL_TYPE_PLACEHOLDER")}
          defaultValue={CellType.Default}
          onChange={this.onCellTypePropertyChange}
        >
          {CellTypeHelper.getOptions()}
        </Choose>
      </Form.Item>
    );
  };

  public onOrientationPropertyChange = (value: IChooseValue) => {
    this.properties.Orientation = OrientationHelper.getValue(value as string);
    this.onPropertyChange("Orientation");
  };

  public renderOrientationProperty = () => {
    const { isProcessingData, t } = this.props;

    return (
      <Form.Item
        name="Orientation"
        label={t("COMPONENT_PROPERTIES__ORIENTATION")}
        initialValue={this.properties.Orientation}
      >
        <Choose
          disabled={isProcessingData}
          placeholder={t("COMPONENT_PROPERTIES__ORIENTATION_PLACEHOLDER")}
          defaultValue={Orientation.Default}
          onChange={this.onOrientationPropertyChange}
        >
          {OrientationHelper.getOptions()}
        </Choose>
      </Form.Item>
    );
  };

  public onSourcePropertyCancel = () => {
    this.setState({ assetCollectionsBrowserVisible: false });
  };

  public onSourcePropertySelect = (row: IAssetCollectionModel) => {
    this.properties.SourceId = row.Id;
    this.properties.SourceName = row.Name;
    this.onPropertyChange("SourceId");
    this.onPropertyChange("SourceName");

    const getSourceType = () => {
      if (row.FilterRecentlyWatched && !row.FilterMyFavourites) {
        return SourceType.RecentlyWatched;
      } else if (!row.FilterRecentlyWatched && row.FilterMyFavourites) {
        return SourceType.MyFavorites;
      } else if (
        !row.FilterRecentlyWatched &&
        !row.FilterMyFavourites &&
        row.FilterPurchased
      ) {
        return SourceType.Purchased;
      } else {
        return SourceType.Standard;
      }
    };

    this.properties.SourceType = getSourceType();
    this.onPropertyChange("SourceType");
    this.setState({ assetCollectionsBrowserVisible: false });
  };

  public onSourcePropertyClear = () => {
    const { component, onComponentChange } = this.props;

    this.properties.SourceId = undefined;
    this.properties.SourceName = undefined;
    this.properties.deleteProperty("SourceId", component);
    this.properties.deleteProperty("SourceName", component);
    this.properties.deleteProperty("SourceType", component);

    if (onComponentChange) {
      onComponentChange(component);
    }
  };

  renderSourcePropertyLabel = () => {
    const { t } = this.props;

    if (!this.properties.SourceId) {
      return <label>{t("COMPONENT_PROPERTIES__SOURCE_PLACEHOLDER")}</label>;
    }

    let link = DataProvider.AssetCollection.link?.({
      Id: this.properties.SourceId,
      Name: this.properties.SourceName || `${this.properties.SourceId}`,
    });

    if (!link) {
      link = {
        url: `${ROUTES.ASSET_COLLECTION_DETAILS}/${this.properties.SourceId}`,
        target: "_self",
      };
    }

    return (
      <label>
        <Link target={link.target} to={link.url}>
          {this.properties.SourceName || this.properties.SourceId}
        </Link>
      </label>
    );
  };

  public renderSourceProperty = () => {
    const { t } = this.props;
    const { assetCollectionsBrowserVisible } = this.state;

    return (
      <Form.Item
        name="SourceId"
        label={t("COMPONENT_PROPERTIES__SOURCE")}
        initialValue={{
          key: `${this.properties.SourceId}`,
          label: this.properties.SourceName,
        }}
      >
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item>{this.renderSourcePropertyLabel()}</Form.Item>
            <AssetCollectionBrowserModal
              visible={assetCollectionsBrowserVisible}
              onCancel={this.onSourcePropertyCancel}
              onSelect={this.onSourcePropertySelect}
            />
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {this.properties.SourceId && (
              <Button
                icon={<Icon type="delete" />}
                onClick={this.onSourcePropertyClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => {
                this.setState({ assetCollectionsBrowserVisible: true });
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
        lg: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 18 },
      },
    };

    return (
      <Form
        name="ListComponentProperties"
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>
            {this.renderTitleProperty()}
            {this.renderTitleTranslationKeyProperty()}
            {this.renderVisibleItemsProperty()}
            {this.renderCellTypeProperty()}
            {this.renderOrientationProperty()}
            {this.renderSourceProperty()}
          </Col>
        </Row>
      </Form>
    );
  }
}
