import React from "react";
import { CustomArrowProps } from "react-slick";
import { Carousel as AntCarousel } from "antd";
import { CarouselProps as AntCarouselProps } from "antd/lib/carousel";
import cx from "classnames";
import { Icon } from "..";
import "./Carousel.scss";

const PrevArrow = (props: CustomArrowProps) => {
  return (
    <Icon
      type="left"
      style={props.style}
      className={props.className}
      onClick={props.onClick}
    />
  );
};

const NextArrow = (props: CustomArrowProps) => {
  return (
    <Icon
      type="right"
      style={props.style}
      className={cx(props.className)}
      onClick={props.onClick}
    />
  );
};

export const Carousel = React.forwardRef<AntCarousel, AntCarouselProps>(
  (props, ref) => {
    const { children, ...rest } = props;

    return (
      <AntCarousel
        ref={ref}
        className="gm-carousel"
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
        {...rest}
      >
        {children}
      </AntCarousel>
    );
  }
);
