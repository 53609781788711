import React, { memo, useMemo } from "react";
import {
  IAnalyticsSalesRevenueFilterModel,
  TimeHelper,
} from "@bms/common-services";
import {
  Button,
  DatePicker,
  IBreadcrumbProps,
  Icon,
  PageHeader,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { SalesRevenueAggregationTypeSelect } from "../SalesRevenueAggregationTypeSelect";
import "./SalesRevenueHeader.scss";
import { SalesRevenueCreatorSelect } from "../SalesRevenueCreatorSelect";
import { PaymentAssetSelect } from "../../PaymentAssetSelect";
import { generateBreadcrumb } from "../../../../../helpers";
import { ROUTES } from "../../../../../modules/Payment/constants";
import { IDateRangePickerProps } from "@bms/common-ui";
import { FilterCleanIcon } from "../../../../../resources/icons";

interface ISalesRevenueHeaderProps {
  filter: IAnalyticsSalesRevenueFilterModel;
  setFilter: <T>(
    property: keyof IAnalyticsSalesRevenueFilterModel,
    value: T
  ) => void;
  refreshData: () => void;
  resetFilter: () => void;
}

export const SalesRevenueHeader = memo(
  ({
    filter,
    setFilter,
    refreshData,
    resetFilter,
  }: ISalesRevenueHeaderProps) => {
    const { t } = useTranslation();

    const disabledDateTo: IDateRangePickerProps["disabledDate"] = (current) => {
      return filter.PaymentDateFrom
        ? current && current <= TimeHelper.parse(filter.PaymentDateFrom)
        : false;
    };
    const disabledDateFrom: IDateRangePickerProps["disabledDate"] = (
      current
    ) => {
      return filter.PaymentDateTo
        ? current &&
            current >= TimeHelper.parse(filter.PaymentDateTo).endOf("day")
        : false;
    };

    const period = useMemo(() => filter.AggregationPeriod, [
      filter.AggregationPeriod,
    ]);

    const getBreadcrumbProps = (): IBreadcrumbProps => {
      return generateBreadcrumb([
        {
          path: `${ROUTES.SALES_REVENUE}`,
          breadcrumbName: t("SALES_REVENUE_TITLE"),
        },
      ]);
    };

    return (
      <PageHeader
        title={t("SALES_REVENUE_TITLE")}
        breadcrumb={getBreadcrumbProps()}
        extra={
          <div className="SalesRevenueHeader__container">
            <div className="SalesRevenueHeader__container-row">
              <SalesRevenueAggregationTypeSelect
                value={period}
                onChange={(val) => setFilter("AggregationPeriod", val)}
              />
              <DatePicker
                showTime={false}
                placeholder={t("SALES_REVENUE_DATE_FROM")}
                style={{ width: "100%" }}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                onChange={(val) => {
                  setFilter(
                    "PaymentDateFrom",
                    val
                      ? TimeHelper.toString(
                          TimeHelper.getDateStartOf(val.toDate(), "date")
                        )
                      : undefined
                  );
                }}
                value={filter.PaymentDateFrom}
                disabledDate={disabledDateFrom}
              />
              <DatePicker
                showTime={false}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                placeholder={t("SALES_REVENUE_DATE_TO")}
                style={{ width: "100%" }}
                onChange={(val) => {
                  setFilter(
                    "PaymentDateTo",
                    val
                      ? TimeHelper.toString(
                          TimeHelper.getDateEndOf(val.toDate(), "date")
                        )
                      : undefined
                  );
                }}
                value={filter.PaymentDateTo}
                disabledDate={disabledDateTo}
              />
              <Button
                shape="circle"
                icon={<Icon type="reload" />}
                onClick={refreshData}
                title={t("BUTTON_REFRESH_TITLE")}
              />
              <Button
                shape="circle"
                icon={<FilterCleanIcon />}
                onClick={resetFilter}
                title={t("BUTTON_RESET")}
              />
            </div>
            <div className="SalesRevenueHeader__container-row">
              <SalesRevenueCreatorSelect
                filter={filter}
                onChange={(val) => setFilter("CreatorUsers", val)}
              />
            </div>
            <div className="SalesRevenueHeader__container-row">
              <PaymentAssetSelect
                filter={filter}
                onChange={(val) => setFilter("Assets", val)}
              />
            </div>
          </div>
        }
      />
    );
  }
);
