import {
  AssetImageType,
  AssetService,
  AssetType,
  IAssetModel,
  IAssetSearchFilterModel,
  TimeHelper,
  useDataLoader,
  useExternalSources,
  useServiceCaller,
} from "@bms/common-services";
import {
  AgeRestrictionTag,
  Button,
  IBreadcrumbProps,
  Icon,
  InputSearch,
  ITableColumnFilterDropdownProps,
  ITableColumnProps,
  ITableFilter,
  Link,
  NotificationService,
  PageContent,
  PageHeader,
  Pagination,
  setTableColumnSearchProps,
  Slider,
  Table,
  Tag,
  Tooltip,
  Popconfirm,
} from "@bms/common-ui";
import { generateBreadcrumb, useTableDataProvider } from "../../../../helpers";
import { createAssetDataPagerSource } from "../../hooks";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { ROUTES } from "../../constants/routes";
import { AddAssetModal } from "../AddAssetModal";
import { AssetForm } from "../AssetForm";
import { useMemo } from "react";
import {
  EditExternalAsset,
  EditType,
} from "../../../../components/EditExternalAsset/EditExternalAsset";
import "./AssetsList.scss";
import { FilterCleanIcon } from "../../../../resources/icons";
import { AssetImportModal } from "../AssetImportModal";

const notificationService = NotificationService.getInstance();
const assetService = new AssetService().promisify();

const CREATE_ASSET_WITH_EXTERNAL_SOURCE =
  process.env.REACT_APP_CREATE_ASSET_WITH_EXTERNAL_SOURCE;

const toNumberOrUndefined = (value: any | undefined) =>
  typeof value === "undefined" ? undefined : +value;

enum ListType {
  Upcoming = "upcoming",
  Past = "past",
  Article = "article",
  Channel = "channel",
}

interface RouteParams {
  type: ListType;
}

export const AssetsList = ({
  match,
  location,
  history,
}: RouteComponentProps<RouteParams>) => {
  const [t] = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isImportVisible, setIsImportVisible] = useState(false);
  const sources = useExternalSources();

  const listType = match.params.type;

  const assetTypesLoader = useDataLoader({
    loader: () => assetService.getAssetTypes(),
    deps: [],
  });

  const assetTypes = useMemo(() => assetTypesLoader.data ?? [], [
    assetTypesLoader.data,
  ]);

  const assetsFilter = useMemo<IAssetSearchFilterModel>(() => {
    return {
      ...(listType == ListType.Upcoming
        ? {
            StartDateTimeFrom: TimeHelper.getCurrentTimezoneTime(),
            Types: [AssetType.Event],
          }
        : {}),
      ...(listType == ListType.Past
        ? {
            StartDateTimeTo: TimeHelper.getCurrentTimezoneTime(),
            Types: [AssetType.Event],
          }
        : {}),
      ...(listType == ListType.Article
        ? {
            Types: [AssetType.Article],
          }
        : {}),
      ...(listType == ListType.Channel
        ? {
            Types: [AssetType.Channel],
            IncludeImages: true,
          }
        : {}),
    };
  }, [listType]);

  const {
    dataLoader: assetsLoader,
    filters,
    pagination,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Types: "strings",
      AgeRestrictionFrom: "number",
      AgeRestrictionTo: "number",
      IncludeImages: "boolean",
      Title: "string",
    },
    loader: (filters, pagination) => {
      return assetService.search({
        ...filters,
        ...assetsFilter,
        ...pagination,
      });
    },
    deps: [listType],
    onError: (error) =>
      notificationService.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const dataPagerSource = createAssetDataPagerSource(assetsLoader.data, {
    ...filters.current,
    ...pagination.current,
    ...assetsFilter,
  });

  const onTableChange = (_: any, incomingFilters: ITableFilter) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...incomingFilters,
      AgeRestrictionFrom: toNumberOrUndefined(
        incomingFilters.AssetAgeRestrictionValueMin?.[0]
      ),
      AgeRestrictionTo: toNumberOrUndefined(
        incomingFilters.AssetAgeRestrictionValueMin?.[1]
      ),
    }));

  const onSearch = (value: string) =>
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value,
    }));

  const titleColumn: ITableColumnProps<IAssetModel> = {
    key: "Title",
    dataIndex: "Title",
    title: t("MODEL_TITLE"),
    width: "300px",
    ellipsis: true,
    filteredValue: filters.asTableArray.Title,
    render: (_, row: IAssetModel) => {
      const assetDetailLocation = {
        pathname: `${ROUTES.ASSET_DETAILS}/${row.Id}`,
        state: {
          from: location,
          dataPagerSource: dataPagerSource,
        },
      };
      return <Link to={assetDetailLocation}>{row.Title}</Link>;
    },
    ...setTableColumnSearchProps("Title", t("MODEL_TITLE")),
  };
  const descriptionColumn: ITableColumnProps<IAssetModel> = {
    key: "Description",
    dataIndex: "Description",
    align: "left",
    ellipsis: true,
    title: t("MODEL_DESCRIPTION"),
    render: (_, row: IAssetModel) => <span>{row.ShortDescription}</span>,
  };
  const typesColumn: ITableColumnProps<IAssetModel> = {
    key: "Types",
    dataIndex: "Types",
    align: "center",
    width: "180px",
    title: t("MODEL_TYPE_CODE"),
    filters: assetTypes.map((assetType) => ({
      text: assetType.DisplayName,
      value: assetType.Code,
    })),
    filteredValue: filters.asTableArray.Types,
    render: (_, row: IAssetModel) => (
      <Tag
        colorRotate={assetTypes.findIndex(
          ({ Code }) => Code === row.AssetTypeCode
        )}
      >
        {row.AssetTypeDisplayName}
      </Tag>
    ),
  };
  const ageRestrictionColumn: ITableColumnProps<IAssetModel> = {
    key: "AssetAgeRestrictionValueMin",
    dataIndex: "AssetAgeRestrictionValueMin",
    title: t("MODEL_AGE_RESTRICTION_VALUE_MIN"),
    align: "center",
    width: "180px",
    filteredValue:
      filters.current.AgeRestrictionFrom !== undefined &&
      filters.current.AgeRestrictionTo !== undefined
        ? [filters.current.AgeRestrictionFrom, filters.current.AgeRestrictionTo]
        : null,
    filterDropdown: (event: ITableColumnFilterDropdownProps) => {
      const { setSelectedKeys, selectedKeys, confirm, clearFilters } = event;
      const filterValue: [number, number] | undefined =
        selectedKeys && selectedKeys.length > 1
          ? [+selectedKeys[0], +selectedKeys[1]]
          : undefined;

      return (
        <div style={{ padding: 8 }}>
          <Slider
            range
            min={0}
            max={18}
            value={filterValue || [0, 0]}
            key="AssetAgeRestrictionValueMin"
            onChange={(value: [number, number]) => {
              if (setSelectedKeys) {
                setSelectedKeys(value || []);
              }
            }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              type="link"
              size="small"
              disabled={!filterValue}
              onClick={() => {
                if (clearFilters) {
                  if (setSelectedKeys) {
                    setSelectedKeys([]);
                  }

                  clearFilters();
                }
              }}
            >
              <Trans i18nKey="BUTTON_RESET">Reset</Trans>
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (confirm) {
                  confirm();
                }
              }}
              size="small"
            >
              <Trans i18nKey="BUTTON_OK">OK</Trans>
            </Button>
          </div>
        </div>
      );
    },
    render: (_, row: IAssetModel) => (
      <AgeRestrictionTag age={row.AssetAgeRestrictionValueMin} />
    ),
  };
  const actionsColumn: ITableColumnProps<IAssetModel> = {
    key: "Actions",
    dataIndex: "Actions",
    align: "center",
    width: "110px",
    title: t("TABLE_ACTIONS_COLUMN"),
    render: (_, asset: IAssetModel) => (
      <div className="asset-table__actions">
        <EditExternalAsset
          asset={asset}
          externalSource={asset.ExternalSource}
          assetSourceEntities={sources}
          type={EditType.AssetDetails}
        />
        <Popconfirm
          title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
          onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
            e?.preventDefault();
            const result = await assetService.deleteAsset(asset!);
            if (result.ok) {
              await assetsLoader.refresh();
            } else {
              notificationService.error({
                message: t("DELETE_ELEMENT_FAILTURE"),
                description: result.error?.Message,
              });
            }
          }}
          okText={t("BUTTON_YES")}
          cancelText={t("BUTTON_NO")}
        >
          <Button
            danger={true}
            icon={<Icon type="delete" />}
            title={t("DELETE_ELEMENT")}
          />
        </Popconfirm>
      </div>
    ),
  };
  const availableFromColumn: ITableColumnProps<IAssetModel> = {
    key: "AvailableFrom",
    dataIndex: "AvailableFrom",
    width: "200px",
    align: "center",
    title: t("MODEL_AVAILABLE_FROM"),
    render: (text: string) => TimeHelper.format(new Date(text)),
  };
  const availableToColumn: ITableColumnProps<IAssetModel> = {
    key: "AvailableTo",
    dataIndex: "AvailableTo",
    width: "200px",
    align: "center",
    title: t("MODEL_AVAILABLE_TO"),
    render: (text: string) => {
      if (!text) {
        return null;
      }
      return TimeHelper.format(new Date(text));
    },
  };
  const imageColumn: ITableColumnProps<IAssetModel> = {
    key: "Image",
    dataIndex: "Image",
    width: "100px",
    title: t("MODEL_IMAGE"),
    render: (text: any, row: IAssetModel) => {
      const image = row.Images?.find(
        (img) => img.AssetImageTypeCode === AssetImageType.Frame
      );

      if (image) {
        return <img src={image.Url} alt="" className="asetImagePreview" />;
      }

      return null;
    },
  };
  const orderInParentColumn: ITableColumnProps<IAssetModel> = {
    key: "OrderInParent",
    dataIndex: "OrderInParent",
    width: "80px",
    align: "center",
    title: t("MODEL_NO"),
  };

  let columns: Array<ITableColumnProps<IAssetModel>> = [];

  switch (listType) {
    case ListType.Article:
    case ListType.Past:
    case ListType.Upcoming:
      delete typesColumn.filters;
      delete typesColumn.filteredValue;
      columns = [
        titleColumn,
        descriptionColumn,
        typesColumn,
        ageRestrictionColumn,
        actionsColumn,
      ];
      break;
    case ListType.Channel:
      columns = [
        orderInParentColumn,
        imageColumn,
        titleColumn,
        ageRestrictionColumn,
        availableFromColumn,
        availableToColumn,
        actionsColumn,
      ];
      break;
    default:
      columns = [
        titleColumn,
        descriptionColumn,
        typesColumn,
        ageRestrictionColumn,
        actionsColumn,
      ];
      break;
  }

  const [createAsset, createAssetState] = useServiceCaller(
    async (data: IAssetModel) => {
      const isFree =
        data.AssetTypeCode === AssetType.Advertisement ? true : false;

      const result = await assetService.createAsset({
        ...data,
        IsFree: isFree,
        ExternalSource: CREATE_ASSET_WITH_EXTERNAL_SOURCE,
      });
      if (result.ok) {
        setModalOpen(false);
        notificationService.success({
          message: t("ASSET_CREATE_SUCCESS"),
        });
        history.push(`${ROUTES.ASSET_DETAILS}/${result.data.Id}`);
      } else {
        notificationService.error({
          message: t("ASSET_CREATE_FAILURE"),
          description: result.error?.Message,
        });
      }
      return result.ok;
    },
    []
  );

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    const breadcrumbName = () => {
      const translations = {
        [`${ROUTES.ASSET_LIST}/${ListType.Upcoming}`]: t(
          "MENU_CMS_MEDIA_COLLECTIONS_UPCOMING"
        ),
        [`${ROUTES.ASSET_LIST}/${ListType.Past}`]: t(
          "MENU_CMS_MEDIA_COLLECTIONS_PAST"
        ),
        [`${ROUTES.ASSET_LIST}/${ListType.Article}`]: t(
          "MENU_CMS_MEDIA_COLLECTIONS_ARTICLE"
        ),
        [`${ROUTES.ASSET_LIST}`]: t("MENU_CMS_MEDIA_COLLECTIONS_ALL"),
      };
      return translations[match.url] ?? "";
    };

    const paths = [
      {
        path: `${ROUTES.ASSET_LIST}`,
        breadcrumbName: t("MENU_CMS_MEDIA"),
      },
    ];
    if (match.url !== ROUTES.ASSET_LIST) {
      paths.push({
        path: match.url,
        breadcrumbName: t(breadcrumbName()),
      });
    }

    return generateBreadcrumb(paths);
  };

  const onImportSuccess = () => {
    setIsImportVisible(false);
    assetsLoader.refresh();
  };

  return (
    <>
      <AddAssetModal
        visible={isModalOpen}
        close={() => setModalOpen(false)}
        processing={createAssetState.processing || assetsLoader.loading}
      >
        <AssetForm
          isEditMode={false}
          onSubmit={createAsset}
          availableAssets={assetTypes}
          hiddenFields={[
            "Categories",
            "IsFree",
            "Director",
            "Writer",
            "Cast",
            "Year",
            "StartEndDates",
            "AvailableDate",
            "Year",
            "AssetAgeRestrictionValueMin",
          ]}
        />
      </AddAssetModal>
      <PageContent footer={<Pagination {...pagination.props} />}>
        <PageHeader
          title={t("MENU_CMS_MEDIA")}
          breadcrumb={getBreadcrumbProps()}
          extra={
            <>
              <InputSearch
                key="search"
                placeholder={t("SEARCH_PLACEHOLDER")}
                value={fullTextSearch}
                onChange={({ target: { value } }) => setFullTextSearch(value)}
                onSearch={onSearch}
                style={{ width: 250 }}
                allowClear={true}
              />
              <Tooltip overlay={t("MENU_OPTION_CLEAR_FILTERS")}>
                <Button
                  key="clear-filters"
                  shape="circle"
                  className="asset-table-clear-button"
                  icon={<FilterCleanIcon />}
                  onClick={filters.clear}
                  title={t("MENU_OPTION_CLEAR_FILTERS")}
                />
              </Tooltip>
              <Button
                key="reload"
                shape="circle"
                icon={<Icon type="reload" />}
                onClick={assetsLoader.refresh}
                title={t("BUTTON_REFRESH_TITLE")}
              />
              <Button
                key="import"
                shape="circle"
                icon={<Icon type="import" />}
                onClick={() => setIsImportVisible(true)}
                title={t("BUTTON_IMPORT")}
              />
              <Button
                key="add"
                shape="circle"
                type="primary"
                icon={<Icon type="plus" />}
                onClick={() => setModalOpen(true)}
                title={t("BUTTON_ADD")}
              />
              {sources.map(({ SourceSystemAlias, SourceSystemConfig }) => (
                <Button
                  key="add"
                  shape="round"
                  type="primary"
                  icon={<Icon type="plus" />}
                  onClick={() =>
                    window.open(
                      `${SourceSystemConfig.BaseUri}${SourceSystemConfig.AddAsset}`
                    )
                  }
                  title={SourceSystemAlias}
                >
                  {SourceSystemAlias}
                </Button>
              ))}
            </>
          }
        />
        <Table<IAssetModel>
          rowKey="Id"
          columns={columns}
          dataSource={assetsLoader.data?.Entities}
          loading={assetsLoader.loading}
          pagination={false}
          onChange={onTableChange}
        />
        <AssetImportModal
          visible={isImportVisible}
          onCancel={() => setIsImportVisible(false)}
          onSuccess={onImportSuccess}
        />
      </PageContent>
    </>
  );
};
