import { AssetContentType } from "../enums";

enum MediaElementType {
  Audio = "audio",
  Video = "video",
}

export class FileHelper {
  static fileToBase64(file: File | Blob) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  static blobToFile = (
    theBlob: Blob,
    fileName: string,
    type?: string
  ): File => {
    return new File([theBlob], fileName, { type });
  };

  static async getMediaDurationUrl(
    url: string,
    contentType: AssetContentType
  ): Promise<number> {
    let mediaType = MediaElementType.Video;

    if (contentType.startsWith("audio/")) {
      mediaType = MediaElementType.Audio;
    }

    return this._getMediaDuration(url, mediaType);
  }

  static async getMediaDurationFile(file: File): Promise<number> {
    let mediaType = MediaElementType.Video;

    if (file.type.startsWith("audio/")) {
      mediaType = MediaElementType.Audio;
    } else if (file.type.startsWith("video/")) {
      mediaType = MediaElementType.Video;
    } else {
      return Promise.reject("Unsupported media file");
    }
    const url = URL.createObjectURL(file);

    return this._getMediaDuration(url, mediaType);
  }

  private static async _getMediaDuration(
    url: string,
    mediaType: MediaElementType
  ): Promise<number> {
    return new Promise((resolve) => {
      const mediaElement = document.createElement(mediaType) as
        | HTMLVideoElement
        | HTMLAudioElement;
      mediaElement.muted = true;
      const source = document.createElement("source");
      source.src = url;
      mediaElement.preload = "metadata";
      mediaElement.appendChild(source);
      mediaElement.onloadedmetadata = function () {
        resolve(mediaElement.duration);
      };
      mediaElement.onerror = function () {
        resolve(0);
      };
    });
  }
}
