import {
  IUserModel,
  IUsersSearchFilterModel,
  NotificationRecipientsService,
  RecordStatus,
  useDataLoader,
  UserService,
  useServiceCaller,
} from "@bms/common-services";
import {
  Button,
  Heading,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  ITableRowSelection,
  Modal,
  NotificationService,
  Table,
} from "@bms/common-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTableDataProvider } from "../../../../../../helpers";

interface IUserModalComponent {
  notificationId: number;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  refreshRecipients: () => void;
}

interface ISeletedUsersIdsObject {
  page: number | undefined;
  users: number[];
}

const PAGE_SIZE = 15;
const userService = new UserService().promisify();
const recipientNotificationService = new NotificationRecipientsService().promisify();
const notificationService = NotificationService.getInstance();

function sequenceMap<T>(array: T[], mapper: (item: T) => Promise<any>) {
  return array.reduce(
    (previous, next) => previous.then(() => mapper(next)),
    Promise.resolve()
  );
}

export const UsersModal = ({
  notificationId,
  isVisible,
  setIsVisible,
  refreshRecipients,
}: IUserModalComponent) => {
  const { t } = useTranslation();
  const [userFilter, setUserFilter] = useState<IUsersSearchFilterModel>({
    PageSize: PAGE_SIZE,
    PageNumber: 1,
    IncludeCount: true,
    FullTextSearch: "",
  });

  const [selectedUsersIds, setSelectedUsersIds] = useState<number[]>([]);
  const [seletedUsersIdsObject, setSeletedUsersIdsObject] = useState<
    ISeletedUsersIdsObject[]
  >([]);

  const recipientsInNotificationLoader = useDataLoader({
    loader: () =>
      recipientNotificationService.search({
        NotificationId: notificationId,
        IncludeCount: true,
      }),
    deps: [notificationId],
  });

  const {
    dataLoader: userDataLoader,
    pagination: userPagination,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
    },
    loader: (filters, userPagination) => {
      return userService.search({
        ...filters,
        ...userPagination,
        PageNumber: userFilter.PageNumber,
        FullTextSearch: userFilter.FullTextSearch,
      });
    },
    deps: [userFilter],
  });

  const alreadyRecipientsId = recipientsInNotificationLoader.data?.Entities.map(
    (recipient) => recipient.UserId
  );

  const onSearch = (value: string) => {
    setUserFilter({ ...userFilter, FullTextSearch: value });
  };

  const getUserColumns = (): Array<ITableColumnProps<IUserModel>> => [
    {
      key: "FullUserName",
      dataIndex: "FullName",
      title: t("MODEL_NAME"),
    },
    {
      key: "Email",
      dataIndex: "Email",
      title: t("TAB_RECIPIENTS_RECIPIENT_EMAIL"),
    },
  ];

  const userTablePagination: ITablePaginationConfig = {
    pageSize: userFilter?.PageSize,
    total: userDataLoader.data?.TotalCount,
    current: userFilter.PageNumber,
    showTotal: (total, range) =>
      t("TABLE_PAGINATION_TOTAL", {
        rangeFrom: range[0],
        rangeTo: range[1],
        total: total,
      }),
    showSizeChanger: true,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "30", "50", "100"],
  };

  const onTableChange = (
    pagination: ITablePaginationConfig,
    tableFilters: ITableFilter
  ) => {
    const newFilter: IUsersSearchFilterModel = {
      ...tableFilters,
      PageSize: pagination.pageSize,
      PageNumber: pagination.current,
    };
    setUserFilter(newFilter);
  };

  const checkElements = (checked: React.Key[]) => {
    const currentPage = userFilter.PageNumber;
    const convertedChecked = checked.map((el) => parseInt(el.toString()));
    const temporaryObject = { page: currentPage, users: convertedChecked };
    let temporarySelectedUsersIdsObjct = seletedUsersIdsObject;

    let tempResult = [];
    if (seletedUsersIdsObject.some((element) => element.page === currentPage)) {
      let selectedUsersFromOtherPages = seletedUsersIdsObject.filter(
        (element) => element.page !== currentPage
      );
      selectedUsersFromOtherPages.push(temporaryObject);
      tempResult = selectedUsersFromOtherPages;
      setSeletedUsersIdsObject(selectedUsersFromOtherPages);
    } else {
      temporarySelectedUsersIdsObjct.push(temporaryObject);
      tempResult = temporarySelectedUsersIdsObjct;
      setSeletedUsersIdsObject(temporarySelectedUsersIdsObjct);
    }

    const result = tempResult.map((el) => el.users).flat();
    setSelectedUsersIds(result);
  };

  const rowSelection: ITableRowSelection<IUserModel> = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IUserModel[]) => {
      checkElements(selectedRowKeys);
    },
    type: "checkbox",
    selectedRowKeys: selectedUsersIds,
    getCheckboxProps: (record: IUserModel) => ({
      disabled: alreadyRecipientsId?.includes(record.Id),
    }),
  };

  const [insertRecipientInNotification] = useServiceCaller(
    async (recipientIds: number[]) => {
      let errorOccurred = false;
      let errorMessage;
      await sequenceMap(recipientIds, async (recipientId: number) => {
        const result = await recipientNotificationService.insert({
          NotificationId: notificationId,
          UserId: recipientId,
          RecordStatus: RecordStatus.Inserted,
        });
        if (!result.ok) {
          errorOccurred = true;
          errorMessage = result.error.Message;
        }
      });

      if (errorOccurred) {
        notificationService.error({
          message: t("USER_MODAL_ADD_USER_FAILURE"),
          description: errorMessage,
        });
      } else {
        notificationService.success({
          message: t("USER_MODAL_ADD_USER_SUCCESS"),
        });
      }
      await refreshRecipients();
      setIsVisible(false);
      setSeletedUsersIdsObject([]);
      setSelectedUsersIds([]);
    },
    []
  );

  return (
    <>
      <Modal
        title={
          <Heading
            title={t("ADD_USER")}
            actions={[
              <InputSearch
                key="search"
                placeholder={t("SEARCH_PLACEHOLDER")}
                onSearch={onSearch}
              />,
            ]}
          />
        }
        visible={isVisible}
        footer={
          <>
            <Button key="cancel" onClick={() => setIsVisible(false)}>
              {t("BUTTON_CANCEL")}
            </Button>
            <Button
              key="confirm"
              onClick={() => insertRecipientInNotification(selectedUsersIds)}
              disabled={selectedUsersIds.length === 0}
            >
              {t("BUTTON_CONFIRM")}
            </Button>
          </>
        }
        centered={true}
        width="70%"
        onCancel={() => setIsVisible(false)}
      >
        <Table<IUserModel>
          rowKey="Id"
          dataSource={userDataLoader.data?.Entities}
          columns={getUserColumns()}
          pagination={userTablePagination}
          loading={userDataLoader.loading}
          onChange={onTableChange}
          rowSelection={rowSelection}
        />
      </Modal>
    </>
  );
};
