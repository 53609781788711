import {
  INotificationRecipientModel,
  INotificationRecipientSearchFilterModel,
  NotificationRecipientsService,
  RecordStatus,
  TimeHelper,
  useServiceCaller,
} from "@bms/common-services";
import {
  Button,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  ITableRowSelection,
  NotificationService,
  PageContent,
  PageHeader,
  Pagination,
  Popconfirm,
  SectionGrid,
  SectionGridItem,
  setTableColumnSearchProps,
  Table,
} from "@bms/common-ui";
import { useTableDataProvider } from "../../../../../helpers";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationForm } from "../../NotificationForm";
import { ITabProps } from "./ITabProps";
import { UsersModal } from "../components/UsersModal";

const notificationService = NotificationService.getInstance();
const recipientNotificationService = new NotificationRecipientsService().promisify();

interface ISeletedUsersIdsObject {
  page: number | undefined;
  users: number[];
}

function sequenceMap<T>(array: T[], mapper: (item: T) => Promise<any>) {
  return array.reduce(
    (previous, next) => previous.then(() => mapper(next)),
    Promise.resolve()
  );
}

export const TabRecipients = ({ notification, onSubmit }: ITabProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [selectedUsersIds, setSelectedUsersIds] = useState<number[]>([]);
  const [seletedUsersIdsObject, setSeletedUsersIdsObject] = useState<
    ISeletedUsersIdsObject[]
  >([]);
  const [recipientType, setRecipientType] = useState(
    notification.RecipientType
  );

  const showSelectedUsers = recipientType !== "ALL";
  const enableEdit =
    notification.Status === "SCHEDULED" || notification.Status === "DRAFT";

  const [
    deleteRecipientInNotification,
    { processing: deleteProcessing },
  ] = useServiceCaller(async (recipientIds: number[] | number) => {
    let errorOccurred = false;
    let errorMessageDescription;
    const errorMessage = t("TAB_RECIPIENT_NOTIFICATION_DELETE_FAILURE");
    if (Array.isArray(recipientIds)) {
      await sequenceMap(recipientIds, async (rescipientId: number) => {
        const result = await recipientNotificationService.delete({
          UserId: rescipientId,
          NotificationId: notification.Id,
          RecordStatus: RecordStatus.Deleted,
        });
        if (!result.ok) {
          errorOccurred = true;
          errorMessageDescription = result.error.Message;
        }
      });
      if (errorOccurred) {
        notificationService.error({
          message: errorMessage,
          description: errorMessageDescription,
        });
      }
    } else {
      const result = await recipientNotificationService.delete({
        UserId: recipientIds,
        NotificationId: notification.Id,
        RecordStatus: RecordStatus.Deleted,
      });
      if (!result.ok) {
        notificationService.error({
          message: errorMessage,
          description: result.error.Message,
        });
      }
    }
    await recipientInNotificationTableDataLoader.refresh();
  }, []);

  const {
    dataLoader: recipientInNotificationTableDataLoader,
    pagination,
    filters: recipientNotificationFilters,
    fullTextSearch,
    setFullTextSearch,
  } = useTableDataProvider({
    filtersSchema: {
      UserFullName: "string",
      UserEmail: "string",
      RecordStatus: "strings",
      FullTextSearch: "string",
    },
    loader: (recipientNotificationFilters, pagination) => {
      return recipientNotificationService.search({
        NotificationId: notification.Id,
        ...recipientNotificationFilters,
        ...pagination,
      });
    },
    deps: [],
    onError: (error) =>
      notificationService.error({
        message: t("LOADING_DATA_ERROR_MESSAGE"),
        description: error.Message,
      }),
  });

  const onSearch = (value: string) => {
    return recipientNotificationFilters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value,
    }));
  };

  const getColumnsProps = (): Array<
    ITableColumnProps<INotificationRecipientModel>
  > => [
    {
      key: "UserFullName",
      dataIndex: "UserFullName",
      title: t("TAB_RECIPIENTS_RECIPIENT_NAME"),
      filteredValue: recipientNotificationFilters.current.UserFullName
        ? [recipientNotificationFilters.current.UserFullName]
        : null,
      ...setTableColumnSearchProps("UserFullName"),
    },
    {
      key: "UserEmail",
      dataIndex: "UserEmail",
      title: t("TAB_RECIPIENTS_RECIPIENT_EMAIL"),
      filteredValue: recipientNotificationFilters.current.UserEmail
        ? [recipientNotificationFilters.current.UserEmail]
        : null,
      ...setTableColumnSearchProps("UserEmail"),
    },
    {
      key: "ReadOn",
      dataIndex: "ReadOn",
      title: t("TAB_RECIPIENTS_RECIPIENT_READ_ON"),
      render: (row: INotificationRecipientModel) =>
        row?.ReadOn ? TimeHelper.format(row.ReadOn) : "",
    },
    {
      key: "RecordStatus",
      dataIndex: "RecordStatus",
      title: t("TAB_RECIPIENS_STATUSES"),
    },
    {
      key: "Actions",
      dataIndex: "Actions",
      align: "center",
      title: t("TABLE_ACTIONS_COLUMN", "Actions"),
      render: (_: any, row: INotificationRecipientModel) => (
        <Popconfirm
          title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
          onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
            e?.preventDefault();
            deleteRecipientInNotification(row.UserId);
          }}
          okText={t("BUTTON_YES")}
          cancelText={t("BUTTON_NO")}
        >
          <Button
            danger={true}
            icon={<Icon type="delete" />}
            title={t("DELETE_ELEMENT")}
            disabled={!enableEdit}
          />
        </Popconfirm>
      ),
    },
  ];

  const extraButtons = (
    <>
      <InputSearch
        key="search"
        placeholder={t("SEARCH_PLACEHOLDER")}
        onChange={({ target: { value } }) => setFullTextSearch(value)}
        value={fullTextSearch}
        onSearch={onSearch}
        allowClear
      />
      <Button
        shape="circle"
        icon={<Icon type="plus" />}
        onClick={() => setShowModal(true)}
        disabled={!enableEdit}
      />
      <Button
        shape="circle"
        icon={<Icon type="delete" />}
        onClick={() => deleteRecipientInNotification(selectedUsersIds)}
        disabled={selectedUsersIds.length === 0 || !enableEdit}
      />
    </>
  );

  const checkElements = (checked: React.Key[]) => {
    const currentPage = pagination.current.PageNumber;
    const convertedChecked = checked.map((el) => parseInt(el.toString()));
    const temporaryObject = { page: currentPage, users: convertedChecked };
    let temporarySelectedUsersIdsObjct = seletedUsersIdsObject;

    let tempResult = [];
    if (seletedUsersIdsObject.some((element) => element.page === currentPage)) {
      let selectedUsersFromOtherPages = seletedUsersIdsObject.filter(
        (element) => element.page !== currentPage
      );
      selectedUsersFromOtherPages.push(temporaryObject);
      tempResult = selectedUsersFromOtherPages;
      setSeletedUsersIdsObject(selectedUsersFromOtherPages);
    } else {
      temporarySelectedUsersIdsObjct.push(temporaryObject);
      tempResult = temporarySelectedUsersIdsObjct;
      setSeletedUsersIdsObject(temporarySelectedUsersIdsObjct);
    }

    const result = tempResult.map((el) => el.users).flat();
    setSelectedUsersIds(result);
  };

  const rowSelection: ITableRowSelection<INotificationRecipientModel> = {
    onChange: (selectedRowKeys: React.Key[]) => {
      checkElements(selectedRowKeys);
    },
    type: "checkbox",
    selectedRowKeys: selectedUsersIds,
    getCheckboxProps: () => ({
      disabled: !enableEdit,
    }),
  };

  const handleTableChange = (
    _: ITablePaginationConfig,
    tableFilters: ITableFilter
  ) => {
    const newFilters: INotificationRecipientSearchFilterModel = {
      UserFullName: (tableFilters.UserFullName || "") as string,
      UserEmail: (tableFilters.UserEmail || "") as string,
    };
    recipientNotificationFilters.update((oldFilters) => ({
      ...oldFilters,
      ...newFilters,
    }));
  };

  return (
    <PageContent
      footer={showSelectedUsers && <Pagination {...pagination.props} />}
    >
      <UsersModal
        notificationId={notification.Id}
        isVisible={showModal}
        setIsVisible={setShowModal}
        refreshRecipients={recipientInNotificationTableDataLoader.refresh}
      />
      <SectionGrid style={{ maxWidth: "1200px" }}>
        <SectionGridItem>
          <NotificationForm
            notification={notification}
            onSubmit={onSubmit}
            isEditMode
            hiddenFields={[
              "Type",
              "Name",
              "ImageUrl",
              "UrlContent",
              "DataContent",
              "DataType",
              "Priority",
              "Provider",
              "TextContent",
              "ScheduledOn",
              "Status",
              "CreatorUserFullName",
              "UrlContent",
              "UrlDescription",
            ]}
            onChangeRecipients={setRecipientType}
          />
          {showSelectedUsers && (
            <>
              <PageHeader
                title={t("TAB_RECIPIENS_TABLE_TITLE")}
                extra={extraButtons}
              />
              <Table<INotificationRecipientModel>
                rowKey="UserId"
                columns={getColumnsProps()}
                dataSource={
                  recipientInNotificationTableDataLoader.data?.Entities || []
                }
                loading={recipientInNotificationTableDataLoader.loading}
                pagination={false}
                rowSelection={rowSelection}
                onChange={handleTableChange}
              />
            </>
          )}
        </SectionGridItem>
      </SectionGrid>
    </PageContent>
  );
};
