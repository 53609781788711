import React from "react";
import { Alert as AntAlert } from "antd";
import { AlertProps as AntAlertProps } from "antd/lib/alert";

export class Alert extends React.PureComponent<AntAlertProps> {
  public render() {
    const { children, ...rest } = this.props;
    return <AntAlert {...rest}>{children}</AntAlert>;
  }
}
