import {
  ICommonAppState,
  IResendConfirmationModel,
  IUsersSearchFilterModel,
  UserStore,
} from "@bms/common-services";
import { IUserState } from "@bms/common-services/dist/store/user/types";
import {
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  NotificationService,
  Row,
  Spin,
  TimePicker,
} from "@bms/common-ui";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./UserResendConfirmationEmailModal.scss";

const notificationService = NotificationService.getInstance();

const useUserState = () =>
  useSelector<ICommonAppState, IUserState>((state) => state.user);

interface UserResendConfirmationEmailModalProps {
  usersCount?: number;
  usersLoading: boolean;
  filters: IUsersSearchFilterModel;
  visible: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const UserResendConfirmationEmailModal: React.FC<UserResendConfirmationEmailModalProps> = ({
  visible,
  onCancel,
  onSuccess,
  usersCount,
  usersLoading,
  filters,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { actionType, error, isProcessingData } = useUserState();

  useEffect(() => {
    switch (actionType) {
      case UserStore.Consts.RESEND_CONFIRMATION_EMAILS_FAILURE:
        return notificationService.error({
          message: t(
            "RESEND_CONFIRMATION_EMAILS_FAILURE_MESSAGE",
            "Failed to send confirmation emails"
          ),
          description: error?.Message || error,
        });
      case UserStore.Consts.RESEND_CONFIRMATION_EMAILS_SUCCESS:
        notificationService.success({
          message: t(
            "RESEND_CONFIRMATION_EMAILS_SUCCESS_MESSAGE",
            "You have successfully sent confirmation emails"
          ),
        });

        if (onSuccess) {
          onSuccess();
        }
        break;
      default:
        break;
    }
  }, [actionType, error]);

  const onFinish = useCallback(
    (values) => {
      const payload: IResendConfirmationModel = {
        SearchFilter: filters || {},
        TokenValidationTime: `${values.TokenValidationDays || 0}`,
      };

      if (values.TokenValidationTime) {
        const tokenValidationTime = moment(values.TokenValidationTime);
        payload.TokenValidationTime += `.${tokenValidationTime.format(
          "HH:mm:ss"
        )}`;
      }

      dispatch(UserStore.Actions.resendConfirmationEmails(payload));
    },
    [filters]
  );

  return (
    <Modal
      title={t("USER_RESEND_CONFIRMATION_EMAIL_TITLE")}
      className="UserResendConfirmationEmailModal"
      visible={visible}
      processing={isProcessingData}
      onCancel={onCancel}
      footer={
        <div>
          <Button
            type="link"
            onClick={onCancel}
            disabled={usersLoading || isProcessingData}
          >
            {t("BUTTON_CANCEL")}
          </Button>
          <Button
            type="primary"
            onClick={form.submit}
            loading={isProcessingData}
            disabled={usersLoading}
          >
            {t("BUTTON_SEND")}
          </Button>
        </div>
      }
    >
      <Spin
        spinning={usersLoading}
        wrapperClassName="UserResendConfirmationEmailSpin"
      >
        <Form
          form={form}
          name="ResendConfirmationEmailForm"
          className="ResendConfirmationEmailForm"
          onFinish={onFinish}
          style={{ textAlign: "left" }}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
          }}
        >
          <Form.Item
            name="Information"
            label={t(
              "USER_RESEND_CONFIRMATION_EMAIL_INFORMATION_LABEL",
              "Information"
            )}
            labelAlign="left"
          >
            <label>
              {t(
                "USER_RESEND_CONFIRMATION_EMAIL_INFORMATION_VALUE",
                "The email confirmation link will be re-sent to the {{count}} selected users.",
                { count: usersCount }
              )}
            </label>
          </Form.Item>
          <Form.Item
            name="TokenValidation"
            label={t(
              "USER_RESEND_CONFIRMATION_EMAIL_TOKEN_VALIDATION_INTERVAL_LABEL",
              "Token validation interval"
            )}
            labelAlign="left"
          >
            <Row gutter={8}>
              <Col>
                <Form.Item
                  name="TokenValidationDays"
                  key="TokenValidationDays"
                  label={t(
                    "USER_RESEND_CONFIRMATION_EMAIL_TOKEN_VALIDATION_DAYS_LABEL",
                    "Days"
                  )}
                  initialValue="1"
                >
                  <InputNumber
                    min={0}
                    max={30}
                    defaultValue={1}
                    placeholder={t(
                      "USER_RESEND_CONFIRMATION_EMAIL_TOKEN_VALIDATION_DAYS_PLACEHOLDER",
                      "Enter token validation days interval"
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="TokenValidationTime"
                  key="TokenValidationTime"
                  label={t(
                    "USER_RESEND_CONFIRMATION_EMAIL_TOKEN_VALIDATION_TIME_LABEL",
                    "Time"
                  )}
                >
                  <TimePicker
                    placeholder={t(
                      "USER_RESEND_CONFIRMATION_EMAIL_TOKEN_VALIDATION_TIME_PLACEHOLDER",
                      "Select a token validation time"
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
