import { InputNumber as AntInputNumber } from "antd";
import { InputNumberProps as AntInputNumberProps } from "antd/lib/input-number";
import React from "react";

export interface InputNumberProps extends AntInputNumberProps {}

export class InputNumber extends React.PureComponent<InputNumberProps> {
  public render() {
    return <AntInputNumber {...this.props} />;
  }
}
