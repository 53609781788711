import { PlusOutlined, ReloadOutlined, ClearOutlined } from "@ant-design/icons";
import {
  AssetStore,
  IAssetCategoriesListModel,
  IAssetCategoriesSearchFilterModel,
  IAssetCategoryModel,
  RecordStatus,
} from "@bms/common-services";
import {
  Button,
  Empty,
  IBreadcrumbProps,
  Icon,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  NotificationService,
  PageContent,
  PageHeader,
  Pagination,
  Popconfirm,
  setTableColumnSearchProps,
  TableWithDraggableSorter,
  TagUpToDate,
  useSearchParamsFilters,
  useSearchParamsPagination,
} from "@bms/common-ui";
import { generateBreadcrumb } from "../../../../helpers";
import React, { useEffect, useState } from "react";
import { WithTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Action, ActionCreator } from "redux";
import { AssetCategoryModal } from "../AssetCategoryModal";
import { ROUTES } from "../../constants";
import { FilterCleanIcon } from "../../../../resources/icons";

const notificationService = NotificationService.getInstance();

export interface IAssetCategoryListProps extends WithTranslation {
  action?: AssetStore.Types.AssetActionsTypes;
  categories: IAssetCategoriesListModel;
  isFetching: boolean;
  isProcessing: boolean;
  searchAssetCategories: ActionCreator<Action>;
  updateCategory: ActionCreator<Action>;
}

const parseBool = (value: any): boolean => value && value === "true";

const defaultCategory: IAssetCategoryModel = {
  Code: "",
  Name: "",
  Description: "",
  UpToDate: true,
  Sequence: 1,
};

const rowKey = (row: IAssetCategoryModel): string => `${row.Guid}`;

export const AssetCategoryList: React.FC<IAssetCategoryListProps> = ({
  categories,
  searchAssetCategories,
  action,
  t,
  updateCategory,
  isProcessing,
  isFetching,
}) => {
  const [state, setState] = useState({
    isNewCategory: true,
    isOpenFormModal: false,
    selectedCategory: { ...defaultCategory },
  });

  const location = useLocation();
  const { replace } = useHistory();
  const dispatch = useDispatch();

  const pagination = useSearchParamsPagination(categories.TotalCount);
  const filters = useSearchParamsFilters({
    FullTextSearch: "string",
    Name: "string",
    Description: "string",
    UpToDate: "boolean",
  });

  useEffect(() => {
    searchAssetCategories({
      ...filters.current,
      ...pagination.current,
    });
  }, [pagination.current, filters, location.key]);

  const refreshData = () => replace(location);

  useEffect(() => {
    switch (action?.type) {
      case AssetStore.Consts.INSERT_ASSET_CATEGORY_SUCCESS:
        refreshData();
        closeModal();
        notificationService.success({
          message: t("ASSET_CATEGORY_LIST__ADD_CATEGORY_SUCCESS"),
        });
        break;

      case AssetStore.Consts.INSERT_ASSET_CATEGORY_FAILURE:
        notificationService.error({
          message: t("ASSET_CATEGORY_LIST__ADD_CATEGORY_FAILURE"),
          description: action?.error?.Message,
        });
        break;

      case AssetStore.Consts.UPDATE_ASSET_CATEGORY_SUCCESS:
        refreshData();
        closeModal();
        notificationService.success({
          message: t("ASSET_CATEGORY_LIST__UPDATE_CATEGORY_SUCCESS"),
        });
        break;

      case AssetStore.Consts.UPDATE_ASSET_CATEGORY_FAILURE:
        notificationService.error({
          message: t("ASSET_CATEGORY_LIST__UPDATE_CATEGORY_FAILURE"),
          description: action?.error?.Message,
        });
        break;

      case AssetStore.Consts.DELETE_ASSET_CATEGORY_SUCCESS:
        refreshData();
        closeModal();
        notificationService.success({
          message: t("ASSET_CATEGORY_LIST__DELETE_CATEGORY_SUCCESS"),
        });
        break;

      case AssetStore.Consts.DELETE_ASSET_CATEGORY_FAILURE:
        notificationService.error({
          message: t("ASSET_CATEGORY_LIST__DELETE_CATEGORY_FAILURE."),
          description: action?.error?.Message,
        });
        break;

      default:
        break;
    }
  }, [action?.type]);

  const onTableChange = (
    _: any,
    { Name, Description, UpToDate }: ITableFilter
  ) =>
    filters.update((data) => ({
      ...data,
      Name: Name?.[0]?.toString(),
      Description: Description?.[0]?.toString(),
      UpToDate:
        UpToDate && UpToDate?.length == 1
          ? parseBool(UpToDate?.[0])
          : undefined,
    }));

  const onSearch = (value: string) =>
    filters.update((data) => ({
      ...data,
      FullTextSearch: value || undefined,
    }));

  const closeModal = () => {
    setState((oldState) => ({ ...oldState, isOpenFormModal: false }));
  };

  const onAddNewCategoryClick = () => {
    setState((oldState) => ({
      ...oldState,
      isNewCategory: true,
      isOpenFormModal: true,
      selectedCategory: { ...defaultCategory },
    }));
  };

  const getColumnsProps = (): Array<ITableColumnProps<IAssetCategoryModel>> => {
    const filter: IAssetCategoriesSearchFilterModel = categories.Filter || {};
    return [
      {
        dataIndex: "Code",
        key: "Code",
        title: t("MODEL_CODE"),
        className: "drag-visible",
        render: (_: any, row: IAssetCategoryModel) => {
          return (
            <a
              onClick={() => {
                setState((oldState) => ({
                  ...oldState,
                  isNewCategory: false,
                  isOpenFormModal: true,
                  selectedCategory: row,
                }));
              }}
            >
              {row.Code}
            </a>
          );
        },
      },
      {
        dataIndex: "Name",
        key: "Name",
        title: t("MODEL_NAME"),
        filteredValue: filter?.Name ? [filter.Name] : null,
        ...setTableColumnSearchProps("Name"),
      },
      {
        dataIndex: "Description",
        key: "Description",
        title: t("MODEL_DESCRIPTION"),
        filteredValue: filter?.Description ? [filter.Description] : null,
        ...setTableColumnSearchProps("Description"),
      },
      {
        dataIndex: "UpToDate",
        key: "UpToDate",
        title: t("MODEL_UP_TO_DATE"),
        width: "130px",
        align: "center",
        filters: [
          { text: t("CLASS_LIST_TABLE_UPTODATE_COLUMN_FILTER"), value: "true" },
          {
            text: t("CLASS_LIST_TABLE_OUTTODATE_COLUMN_FILTER"),
            value: "false",
          },
        ],
        filteredValue:
          filter.UpToDate !== undefined ? [`${filter.UpToDate}`] : null,
        render: (upToDate: boolean) => <TagUpToDate value={upToDate} />,
      },
      {
        key: "Actions",
        dataIndex: "Actions",
        align: "center",
        width: "100px",
        title: t("TABLE_ACTIONS_COLUMN"),
        render: (_: any, row: IAssetCategoryModel) => (
          <Popconfirm
            title={t("DELETE_ELEMENT_DOUBLE_CONFIRMATION_QUESTION")}
            onConfirm={async (e?: React.MouseEvent<HTMLElement>) => {
              e?.preventDefault();
              dispatch(AssetStore.Actions.deleteAssetCategory(row));
            }}
            okText={t("BUTTON_YES")}
            cancelText={t("BUTTON_NO")}
          >
            <Button
              danger={true}
              icon={<Icon type="delete" />}
              title={t("DELETE_ELEMENT")}
            />
          </Popconfirm>
        ),
      },
    ];
  };

  const [entities, setEntities] = useState(categories.Entities);

  useEffect(() => setEntities(categories.Entities), [categories.Entities]);

  const onMoveRow = async (dragIndex: number, hoverIndex: number) => {
    const draggedCategory = entities[dragIndex];
    const hoveredCategory = entities[hoverIndex];

    const categoryToUpdate: IAssetCategoryModel = {
      ...draggedCategory,
      Sequence: hoveredCategory.Sequence ?? 1,
      RecordStatus: RecordStatus.Updated,
    };

    const sequenceChanged =
      draggedCategory.Sequence !== categoryToUpdate.Sequence;
    const draggedToNewPosition = draggedCategory.Guid !== hoveredCategory.Guid;

    if (draggedToNewPosition && sequenceChanged) {
      await updateCategory(categoryToUpdate);
    }
  };

  const columns = getColumnsProps();

  const renderHandleWrapper = (handle: React.ReactNode) =>
    filters.anyActive ? (
      <Popconfirm
        trigger="hover"
        placement="right"
        title={t("CLEAR_FILTERS_TO_REORDER_LABEL")}
        onConfirm={filters.clear}
        okText={t("MENU_OPTION_CLEAR_FILTERS")}
        cancelText={t("BUTTON_CANCEL")}
      >
        {handle}
      </Popconfirm>
    ) : (
      handle
    );

  const [localSearch, setLocalSearch] = useState("");
  useEffect(() => {
    setLocalSearch(filters.current.FullTextSearch || "");
  }, [filters.current.FullTextSearch]);

  const getBreadcrumbProps = (): IBreadcrumbProps => {
    return generateBreadcrumb([
      {
        path: `${ROUTES.ASSET_CATEGORIES}`,
        breadcrumbName: t("MENU_CMS_ASSET_CATEGORIES"),
      },
    ]);
  };

  return (
    <PageContent footer={<Pagination {...pagination.props} />}>
      <PageHeader
        title={t("MENU_CMS_ASSET_CATEGORIES")}
        breadcrumb={getBreadcrumbProps()}
        extra={
          <>
            <InputSearch
              key="search"
              placeholder={t("SEARCH_PLACEHOLDER")}
              onChange={(e) => setLocalSearch(e.target.value)}
              value={localSearch}
              onSearch={onSearch}
              style={{ width: 250 }}
              allowClear={true}
            />
            <Button
              key="clear-filters"
              shape="circle"
              disabled={!filters.anyActive}
              icon={<FilterCleanIcon />}
              onClick={filters.clear}
              title={t("MENU_OPTION_CLEAR_FILTERS")}
            />
            <Button
              key="reload"
              shape="circle"
              icon={<ReloadOutlined />}
              onClick={refreshData}
              title={t("BUTTON_REFRESH_TITLE")}
            />
            <Button
              key="add"
              shape="circle"
              type="primary"
              icon={<PlusOutlined />}
              onClick={onAddNewCategoryClick}
              title={t("ASSET_CATEGORY__ADD_NEW_CATEGORY")}
            />
          </>
        }
      />

      <TableWithDraggableSorter<IAssetCategoryModel>
        locale={{
          emptyText: (
            <>
              <Empty />
              {filters.anyActive && (
                <Button icon={<ClearOutlined />} onClick={filters.clear}>
                  {t("MENU_OPTION_CLEAR_FILTERS")}
                </Button>
              )}
            </>
          ),
        }}
        dragType="handler"
        columns={columns}
        dragDisabled={filters.anyActive}
        renderHandleWrapper={renderHandleWrapper}
        dataSource={entities}
        loading={categories.IsLoading ?? false}
        pagination={false}
        rowKey={rowKey}
        onChange={onTableChange}
        onMoveRow={onMoveRow}
      />

      <AssetCategoryModal
        loading={isProcessing || isFetching}
        visible={state.isOpenFormModal}
        selectedCategory={state.selectedCategory}
        isNewCategory={state.isNewCategory}
        closeModal={closeModal}
      />
    </PageContent>
  );
};
