import React from "react";
import { Steps as AntSteps } from "antd";
import { StepsProps as AntStepsProps } from "antd/lib/steps";

export class Steps extends React.PureComponent<AntStepsProps> {
  public render() {
    const { children, ...rest } = this.props;
    return <AntSteps {...rest}>{children}</AntSteps>;
  }
}
