import {
    AdvertisementStore,
    IAdvertisementBlockModel,
    IAdvertisementBoardModel,
    IErrorModel,
    IStateModel,
    RecordStatus,
} from "@bms/common-services";
import {
  Button,
  Heading,
  Icon,
  ITableColumnProps,
  Link,
  NotificationService,
  TableWithDraggableSorter,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ActionCreator } from "redux";
import { ROUTES } from "../../constants";
import {
    AdvertisementBoardTypeHelper,
    advertisementBlockStatusHelper as utils,
} from "../../helpers";
import { AdvertisementBoardAddModal } from "../AdvertisementBoardAddModal";
import "./AdvertisementBoardsList.scss";

const notificationService = NotificationService.getInstance();

export interface IAdvertisementBoardsListStateProps {
    actionType?: string;
    advertisementBlock: IStateModel<IAdvertisementBlockModel>;
    advertisementBlockId?: number;
    error?: IErrorModel;
    addBoardModalVisible: boolean;
    isLoadingData: boolean;
}

export interface IAdvertisementBoardsListDispatchProps {
  toggleAdvertisementBoardModal: ActionCreator<
    AdvertisementStore.Types.IToggleAdvertisementBoardModalAction
  >;
  updateAdvertisementBlockWithDetails: ActionCreator<
    AdvertisementStore.Types.IUpdateAdvertisementBlockWithDetailsAction
  >;
  updateTemporaryAdvertisementBlock: ActionCreator<
    AdvertisementStore.Types.IUpdateTemporaryAdvertisementBlockAction
  >;
}

export interface IAdvertisementBoardsListOwnProps {}

export interface IAdvertisementBoardsListProps
  extends IAdvertisementBoardsListStateProps,
    IAdvertisementBoardsListDispatchProps,
    IAdvertisementBoardsListOwnProps,
    WithTranslation {}

export class AdvertisementBoardsList extends React.PureComponent<
  IAdvertisementBoardsListProps
> {

  private getColumnsProps(): Array<ITableColumnProps<IAdvertisementBoardModel>> {
    const { t } = this.props;

    return [
      {
        key: "Name",
        dataIndex: "Name",
        title: t("NAME"),
        render: (text: any, row: IAdvertisementBoardModel) => {
          return (
            <Link to={`${ROUTES.ADVERTISEMENT_BOARD_DETAILS}/${row.Id}`}>
              {row.Name}
            </Link>
          );
        },
      },
      {
        key: "BoardType",
        dataIndex: "BoardType",
        title: t("BOARD_TYPE"),
        render: (text: any, row: IAdvertisementBoardModel) =>
          AdvertisementBoardTypeHelper.getTag(row.BoardType),
      },
      {
        dataIndex: "ContentUrl",
        title: t("CONTENT_URL"),
      },
      {
        dataIndex: "Sequence",
        title: t("SEQUENCE"),
      },
    ];
  }

  public onAddClick = () => {
    const { advertisementBlockId } = this.props;

    if (!advertisementBlockId) {
      return;
    }

    this.props.toggleAdvertisementBoardModal(true);
  };

  private onAddCancel = () => {
    this.props.toggleAdvertisementBoardModal(false)
  };

  private onAddSuccess = () => {
    const { advertisementBlock, updateTemporaryAdvertisementBlock } = this.props;

    updateTemporaryAdvertisementBlock(advertisementBlock);
  };

  onMoveRow = (dragIndex: number, hoverIndex: number) => {
    const {
      advertisementBlock,
      t,
      updateAdvertisementBlockWithDetails
    } = this.props;

    if (advertisementBlock.Data?.Status == "PUBLISHED") {
      return notificationService.error({
        message: t("SAVE_FAILURE"),
        description: t("ADVERTISEMENT_BLOCK_ALREADY_PUBLISHED"),
      });
    }

    const draggedItem = advertisementBlock.Data?.Boards[dragIndex];
    const hoveredItem = advertisementBlock.Data?.Boards[hoverIndex];

    if (
      !draggedItem || draggedItem.Sequence === undefined ||
      !hoveredItem || hoveredItem.Sequence === undefined ||
      draggedItem.Id === hoveredItem.Id
    ) {
      return;
    }

    const itemToUpdate: IAdvertisementBoardModel = {
      ...draggedItem,
      Sequence: hoveredItem.Sequence,
      RecordStatus: RecordStatus.Updated,
    };

    if (advertisementBlock.Data) {
      advertisementBlock.Data.Boards = advertisementBlock.Data.Boards.map(
        (x) => {
          if (x.Id == itemToUpdate.Id) {
            return itemToUpdate;
          } else {
            if (x.Sequence >= hoveredItem.Sequence && x.Sequence < draggedItem.Sequence) {
              return {
                ...x,
                Sequence: x.Sequence + 1,
                RecordStatus: RecordStatus.Updated,
              }
            } else {
              return x;
            }
          }
        }
      );

      updateAdvertisementBlockWithDetails(advertisementBlock);
    }
  };

  public render() {
    const {
      addBoardModalVisible,
      advertisementBlock,
      advertisementBlockId,
      isLoadingData,
      t
    } = this.props;

    const columns = this.getColumnsProps();

    return (
      <div className="AdvertisementBoardsList">
        <Heading
          title={t("ADVERTISEMENT_BOARDS")}
          actions={
            <Button
              icon={<Icon type="plus" />}
              onClick={this.onAddClick}
              title={t("ADD_NEW_ADVERTISEMENT_BOARD")}
            />
          }
        />
        <TableWithDraggableSorter<IAdvertisementBoardModel>
          dragDisabled={!utils.isEditable(advertisementBlock)}
          dragType="handler"
          columns={columns}
          dataSource={advertisementBlock.Data?.Boards}
          loading={isLoadingData}
          rowKey="Guid"
          onMoveRow={this.onMoveRow}
        />
        <AdvertisementBoardAddModal
          advertisementBlockId={advertisementBlockId!}
          visible={addBoardModalVisible}
          onCancel={this.onAddCancel}
          onSuccess={this.onAddSuccess}
        />
      </div>
    );
  }
}
