import React from "react";
import { Form, IDatePickerMoment, TimePicker } from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { IAssetFormFieldCommonProps } from "./IAssetFormFieldCommonProps";
import { StreamType, TimeHelper } from "@bms/common-services";

interface IDurationFieldProps extends IAssetFormFieldCommonProps {
  onDurationChange?: (date: IDatePickerMoment | null) => void;
}

export const DurationField = ({
  isEditMode,
  isHidden,
  asset,
  onDurationChange,
}: IDurationFieldProps) => {
  const { t } = useTranslation();
  if (!isEditMode || isHidden) {
    return null;
  }

  const durationMiliseconds = asset?.Contents?.find(
    (c) => c.StreamTypeCode === StreamType.Main
  )?.DurationMiliseconds;

  return (
    <Form.Item
      name="DurationMiliseconds"
      label={t("MODEL_DURATION_MILISECONDS")}
      key="duration"
      initialValue={
        isEditMode
          ? TimeHelper.parse(
              TimeHelper.fromDurationMilliseconds(
                asset?.DurationMiliseconds || durationMiliseconds || 0
              )
            )
          : undefined
      }
    >
      <TimePicker
        placeholder={t("ASSET_DURATION_PICKER_PLACEHOLDER")}
        popupClassName="hide-now-btn"
        style={{ width: "100%" }}
        onChange={(val) => onDurationChange?.(val)}
      />
    </Form.Item>
  );
};
