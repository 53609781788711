import {
  IAnalyticsAssetImpressionsFilterModel,
  TimeHelper,
} from "@bms/common-services";
import {
  Button,
  DatePicker,
  IBreadcrumbProps,
  Icon,
  IDateRangePickerProps,
  PageHeader,
} from "@bms/common-ui";
import React, { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../constants/routes";
import { AssetImpressionsAggregationTypeSelect } from "../AssetImpressionsAggregationTypeSelect";
import { FilterCleanIcon } from "../../../../../resources/icons";
import { AssetImpressionsPlatformSelect } from "../AssetImpressionsPlatformSelect";
import { AssetImpressionsUserSelect } from "../AssetImpressionsUserSelect/AssetImpressionsUserSelect";
import { AssetImpressionsAssetSelect } from "../AssetImpressionsAssetSelect";
import { AssetImpressionsExportModal } from "../../AssetImpressionsExportModal";
import { generateBreadcrumb } from "../../../../../helpers/breadcrumb";

import "./AssetImpressionsHeader.scss";

interface IAssetImpressionsProps {
  filter: IAnalyticsAssetImpressionsFilterModel;
  setFilter: <T>(
    property: keyof IAnalyticsAssetImpressionsFilterModel,
    value: T
  ) => void;
  refreshData: () => void;
  resetFilter: () => void;
}

export const AssetImpressionsHeader = memo(
  ({ filter, setFilter, refreshData, resetFilter }: IAssetImpressionsProps) => {
    const { t } = useTranslation();
    const [
      isAssetImpressionsExportVisible,
      setIsAssetImpressionsExportVisible,
    ] = useState(false);

    const period = useMemo(() => filter.AggregationPeriod, [
      filter.AggregationPeriod,
    ]);

    const getBreadcrumbProps = (): IBreadcrumbProps => {
      return generateBreadcrumb([
        {
          path: `${ROUTES.ASSET_IMPRESSIONS}`,
          breadcrumbName: t("MENU_CMS_ASSET_IMPRESSIONS"),
        },
      ]);
    };

    const disabledDateTo: IDateRangePickerProps["disabledDate"] = (current) => {
      return filter.DateFrom
        ? current && current <= TimeHelper.parse(filter.DateFrom)
        : false;
    };

    const disabledDateFrom: IDateRangePickerProps["disabledDate"] = (
      current
    ) => {
      return filter.DateTo
        ? current && current >= TimeHelper.parse(filter.DateTo).endOf("day")
        : false;
    };
    return (
      <PageHeader
        title={t("MENU_CMS_ASSET_IMPRESSIONS")}
        breadcrumb={getBreadcrumbProps()}
        extra={
          <div className="AssetImpressionsHeader__container">
            <div className="AssetImpressionsHeader__container-row">
              <AssetImpressionsAggregationTypeSelect
                value={period}
                onChange={(val) => setFilter("AggregationPeriod", val)}
              />
              <DatePicker
                showTime={false}
                placeholder={t("ASSET_IMPRESSIONS_DATE_FROM")}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                style={{ width: "100%" }}
                onChange={(val) =>
                  setFilter(
                    "DateFrom",
                    val
                      ? TimeHelper.toString(
                          TimeHelper.getDateStartOf(val.toDate(), "date")
                        )
                      : undefined
                  )
                }
                value={filter.DateFrom}
                disabledDate={disabledDateFrom}
              />
              <DatePicker
                showTime={false}
                placeholder={t("ASSET_IMPRESSIONS_DATE_TO")}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                style={{ width: "100%" }}
                onChange={(val) =>
                  setFilter(
                    "DateTo",
                    val
                      ? TimeHelper.toString(
                          TimeHelper.getDateEndOf(val.toDate(), "date")
                        )
                      : undefined
                  )
                }
                value={filter.DateTo}
                disabledDate={disabledDateTo}
              />
              <Button
                key="export"
                shape="circle"
                onClick={() => setIsAssetImpressionsExportVisible(true)}
                icon={<Icon type="export" />}
                title={t("BUTTON_EXPORT")}
              />
              <Button
                shape="circle"
                icon={<Icon type="reload" />}
                onClick={refreshData}
                title={t("BUTTON_REFRESH_TITLE")}
              />
              <Button
                shape="circle"
                icon={<FilterCleanIcon />}
                onClick={resetFilter}
                title={t("BUTTON_RESET")}
              />
            </div>
            <div className="AssetImpressionsHeader__container-row">
              <AssetImpressionsPlatformSelect
                filter={filter}
                onChange={(val) => setFilter("Platforms", val)}
              />
            </div>
            <div className="AssetImpressionsHeader__container-row">
              <AssetImpressionsUserSelect
                filter={filter}
                onChange={(val) => setFilter("Users", val)}
              />
            </div>
            <div className="AssetImpressionsHeader__container-row">
              <AssetImpressionsAssetSelect
                filter={filter}
                onChange={(val) => setFilter("Assets", val)}
              />
            </div>
            <AssetImpressionsExportModal
              visible={isAssetImpressionsExportVisible}
              filter={filter}
              onCancel={() => setIsAssetImpressionsExportVisible(false)}
              onSuccess={() => setIsAssetImpressionsExportVisible(false)}
            />
          </div>
        }
      />
    );
  }
);
