import {
  AssetStore,
  AssetType,
  ContentStatus,
  FileHelper,
  IAssetContentModel,
  IAssetModel,
  ICommonAppState,
  StreamType,
  TimeHelper,
} from "@bms/common-services";
import {
  Button,
  Choose,
  ChooseOption,
  Col,
  Form,
  Icon,
  IDatePickerMoment,
  IFormValues,
  Input,
  required,
  Row,
  TimePicker,
  urlValid,
} from "@bms/common-ui";
import { assign } from "lodash";
import { assetContentTypeHelper } from "../../helpers";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const assetSelector = (state: ICommonAppState) => state.asset;

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

interface IAssetContentPathModalProps {
  asset?: IAssetModel;
  assetContent?: IAssetContentModel;
}

export const AssetContentPathModal: React.FC<IAssetContentPathModalProps> = (
  props
) => {
  const { asset, assetContent } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let { assetContentTypes } = useSelector(assetSelector);
  const { assetContentStreamTypes } = useSelector(assetSelector);
  const [form] = Form.useForm();
  const [contentTypeState, setContentTypeState] = useState<string>();
  const [contentStreamTypeState, setContentStreamTypeState] = useState<
    string
  >();
  const [isDurationCalculating, setDurationCalculating] = useState(false);

  const assetType = asset?.AssetTypeCode;
  const isAlbumOrPodcast =
    assetType === AssetType.Podcast || assetType === AssetType.Album;

  const filteredContentType = assetContentTypes.data?.filter((type) => {
    switch (asset?.AssetTypeCode) {
      case AssetType.Album:
      case AssetType.Podcast:
        return type.Code.includes("audio");
      case AssetType.Article:
        return type.Code.includes("text");
      default:
        return !type.Code.startsWith("text") && !type.Code.startsWith("audio");
    }
  });

  if (isAlbumOrPodcast) {
    assetContentTypes = assetContentTypeHelper?.audioType(assetContentTypes);
  }

  const contentExistsForSteamType =
    contentTypeState &&
    contentStreamTypeState &&
    (asset?.Contents?.find(
      (content) =>
        content.ContentTypeCode == contentTypeState &&
        content.StreamTypeCode == contentStreamTypeState
    ) ??
      false);

  const addAssetContent = useCallback(
    (data: IAssetContentModel) =>
      dispatch(AssetStore.Actions.addAssetContent(data)),
    [dispatch]
  );

  const updateAssetContent = useCallback(
    (data: IAssetContentModel) =>
      dispatch(AssetStore.Actions.updateAssetContent(data)),
    [dispatch]
  );

  const validateContentTypeUniqueness = (
    _rule: any,
    value: string | number,
    callback: (error?: string) => void
  ) => {
    return contentExistsForSteamType
      ? callback(t("CONTENT_TYPE_EXISTS_FOR_STREAM_TYPE"))
      : callback();
  };

  useEffect(() => {
    if (contentTypeState && contentStreamTypeState) {
      form.validateFields(["AssetContentTypeCode"]);
    }
  }, [contentTypeState, contentStreamTypeState]);

  const onFinish = (values: IFormValues) => {
    const {
      AssetContentTypeCode,
      AssetContentPath,
      AssetContentStreamTypeCode,
      DurationMiliseconds,
    } = values;
    const payload: IAssetContentModel = assign({}, assetContent, {
      AssetId: asset?.Id,
      ContentTypeCode: AssetContentTypeCode,
      ContentStatusCode: ContentStatus.Ready,
      StreamTypeCode: AssetContentStreamTypeCode,
      Path: AssetContentPath,
    });

    const date = DurationMiliseconds as IDatePickerMoment;

    if (date) {
      payload.DurationMiliseconds =
        date.millisecond() +
        1000 * (date.second() + 60 * (date.minute() + 60 * date.hour()));
    }

    if (assetContent?.Id) {
      updateAssetContent(payload);
    } else {
      addAssetContent(payload);
    }
  };

  const renderContentTypeField = () => {
    return (
      <Form.Item
        name="AssetContentTypeCode"
        label={t("MODEL_TYPE_CODE")}
        key="contentType"
        initialValue={assetContent?.ContentTypeCode}
        rules={[required(), { validator: validateContentTypeUniqueness }]}
      >
        <Choose
          placeholder={t("MODEL_TYPE_PLACEHOLDER")}
          loading={assetContentTypes.isFetching}
          disabled={assetContentTypes.isFetching}
          onChange={(value) => setContentTypeState(value as string)}
        >
          {filteredContentType &&
            filteredContentType.map((assetContentType) => (
              <ChooseOption
                key={assetContentType.Code}
                value={assetContentType.Code}
              >
                {assetContentType.DisplayName}
              </ChooseOption>
            ))}
        </Choose>
      </Form.Item>
    );
  };

  const isContainingAssets =
    asset?.AssetTypeCode === AssetType.Album ||
    asset?.AssetTypeCode === AssetType.Season ||
    asset?.AssetTypeCode === AssetType.Series;

  const renderContentStreamTypeField = () => {
    return (
      <Form.Item
        name="AssetContentStreamTypeCode"
        label={t("ASSET_CONTENT_STREAM_TYPE_LABEL")}
        key="streamType"
        initialValue={
          isContainingAssets ? StreamType.Trial : assetContent?.StreamTypeCode
        }
        rules={[required()]}
      >
        <Choose
          placeholder={t("ASSET_CONTENT_STREAM_TYPE_PLACEHOLDER")}
          loading={assetContentStreamTypes.isFetching}
          disabled={assetContentStreamTypes.isFetching || isContainingAssets}
          onChange={(value) => setContentStreamTypeState(value as string)}
        >
          {assetContentStreamTypes.data?.map((assetContentStreamType) => (
            <ChooseOption
              key={assetContentStreamType.Code}
              value={assetContentStreamType.Code}
            >
              {assetContentStreamType.DisplayName}
            </ChooseOption>
          ))}
        </Choose>
      </Form.Item>
    );
  };

  const renderPathField = () => {
    return (
      <Form.Item
        name="AssetContentPath"
        label={t("COMMON_URL")}
        key="path"
        initialValue={assetContent?.Url}
        rules={[urlValid(), required()]}
      >
        <Input placeholder={t("ASSET_CONTENT__URL_PLACEHOLDER")} />
      </Form.Item>
    );
  };

  const onDurationCalculateClick = async () => {
    let url: string | undefined = form.getFieldValue("AssetContentPath");

    if (!url || !url.startsWith("http")) {
      url = assetContent?.Url;
    }

    if (url && assetContent?.ContentTypeCode) {
      setDurationCalculating(true);
      const duration = await FileHelper.getMediaDurationUrl(
        url,
        assetContent.ContentTypeCode
      );

      if (duration) {
        const durationMiliseconds = TimeHelper.parse(
          TimeHelper.fromDurationMilliseconds(Math.floor(duration * 1000))
        );

        form.setFieldsValue({ DurationMiliseconds: durationMiliseconds });
      }
      setDurationCalculating(false);
    }
  };

  const renderDurationField = () => {
    return (
      <Form.Item label={t("MODEL_DURATION_MILISECONDS")}>
        <Row gutter={8}>
          <Col flex="auto">
            <Form.Item
              name="DurationMiliseconds"
              key="DurationMiliseconds"
              initialValue={
                assetContent?.DurationMiliseconds
                  ? TimeHelper.parse(
                      TimeHelper.fromDurationMilliseconds(
                        assetContent?.DurationMiliseconds
                      )
                    )
                  : undefined
              }
            >
              <TimePicker
                placeholder={t("ASSET_DURATION_PICKER_PLACEHOLDER")}
                popupClassName="hide-now-btn"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          {(assetContent?.ContentTypeCode?.startsWith("video") ||
            assetContent?.ContentTypeCode?.startsWith("audio")) && (
            <Col>
              <Button
                title={t("ASSET_DURATION_ACTION_CALCULATE_TITLE")}
                icon={<Icon type="FieldTime" />}
                onClick={onDurationCalculateClick}
                loading={isDurationCalculating}
              />
            </Col>
          )}
        </Row>
      </Form.Item>
    );
  };

  return (
    <div className="AssetContentModal">
      <Form
        form={form}
        name="AssetContentPathForm"
        className="AssetContentForm"
        onFinish={onFinish}
        {...formLayout}
      >
        {renderContentStreamTypeField()}
        {renderContentTypeField()}
        {renderPathField()}
        {renderDurationField()}
      </Form>
    </div>
  );
};
