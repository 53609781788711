import {
  ComponentTypeHelper,
  IApplicationComponentModel,
  IApplicationScreenModel,
  PlatformType,
  RecordStatus,
} from "@bms/common-services";
import { Avatar, Col, Dropdown, Menu, MenuItem, Row } from "@bms/common-ui";
import cx from "classnames";
import React from "react";
import { WithTranslation } from "react-i18next";
import { WithScreenContextProps } from "../../../../context";
import { WithScreenDesignerContextProps } from "../../context";
import { ApplicationMenuItemPropertyModel } from "../../models";
import { ApplicationMenuItemPreview } from "../ApplicationMenuItemPreview";
import "./ApplicationMenuPreview.scss";

export interface IApplicationMenuPreviewStateProps {}

export interface IApplicationMenuPreviewDispatchProps {}

export interface IApplicationMenuPreviewOwnProps {
  applicationMenuScreen: IApplicationScreenModel;
}

export interface IApplicationMenuPreviewProps
  extends IApplicationMenuPreviewStateProps,
    IApplicationMenuPreviewDispatchProps,
    IApplicationMenuPreviewOwnProps,
    WithTranslation,
    WithScreenDesignerContextProps,
    WithScreenContextProps {}

export interface IApplicationMenuPreviewState {}

export class ApplicationMenuPreview extends React.PureComponent<
  IApplicationMenuPreviewProps,
  IApplicationMenuPreviewState
> {
  public static defaultProps = {};

  public state: Readonly<IApplicationMenuPreviewState> = {};

  public onComponentSelect = (componentId: number) => {
    const { applicationMenuScreen, screen, onComponentSelect } = this.props;

    if (
      screen &&
      screen.Id === applicationMenuScreen.Id &&
      screen.Components &&
      onComponentSelect
    ) {
      const component = screen.Components.find(
        (row: IApplicationComponentModel) => row.Id === componentId
      );

      onComponentSelect(component);
    }
  };

  public renderApplicationItemsForMobile = () => {
    const {
      applicationMenuScreen,
      screen,
      component,
      t,
      layoutOptions,
    } = this.props;

    const itemsView: React.ReactNode[] = [];
    const moreMenuItemsView: React.ReactNode[] = [];
    const moreMenuView: React.ReactNode = (
      <div className="Component">
        <Dropdown
          key="dropdown"
          placement="topRight"
          trigger={["click"]}
          overlay={
            <Menu
              className={cx(
                "ApplicationMenuPreview__Items--Dropdown-Menu",
                { "zoom-50": layoutOptions.Zoom === 0.5 },
                { "zoom-75": layoutOptions.Zoom === 0.75 }
              )}
            >
              {moreMenuItemsView}
            </Menu>
          }
        >
          <div>
            <span className="ApplicationMenuPreview__Items--Dropdown-Title">
              ⋮
            </span>
          </div>
        </Dropdown>
      </div>
    );

    if (applicationMenuScreen.Components) {
      const itemsIterations: number = Math.max(
        5,
        applicationMenuScreen.Components.length
      );

      for (let i = 0; i < itemsIterations; i++) {
        if (i >= applicationMenuScreen.Components.length) {
          itemsView.push(<Col></Col>);

          continue;
        }

        const screenComponent = applicationMenuScreen.Components[i];

        const properties = new ApplicationMenuItemPropertyModel(
          screenComponent
        );

        const style: React.CSSProperties = {
          borderWidth: "1px",
          borderStyle: "dashed",
          borderColor: ComponentTypeHelper.getColor(
            screenComponent.ComponentTypeCode
          ),
          cursor: "pointer",
        };

        if (component && component.Id === screenComponent.Id) {
          style.borderWidth = "2px";
          style.borderStyle = "solid";
        }

        if (i < 4) {
          itemsView.push(
            <div
              key={screenComponent.Id}
              className="Component"
              onClick={() => this.onComponentSelect(screenComponent.Id)}
            >
              <div className="Component--content">
                <div className="Component--options"></div>
                <div className="Component--preview" style={style}>
                  <ApplicationMenuItemPreview
                    key={screenComponent.Id}
                    component={screenComponent}
                    properties={properties}
                  />
                </div>
              </div>
            </div>
          );
        }

        if (i === 3) {
          itemsView.push(moreMenuView);
        }

        if (i >= 4) {
          moreMenuItemsView.push(
            <MenuItem style={{ padding: "8px" }}>
              <div
                key={screenComponent.Id}
                className="Component"
                style={{ padding: 0 }}
                onClick={() => this.onComponentSelect(screenComponent.Id)}
              >
                <div className="Component--content">
                  <div className="Component--options"></div>
                  <div className="Component--preview" style={style}>
                    <ApplicationMenuItemPreview
                      key={screenComponent.Id}
                      component={screenComponent}
                      properties={properties}
                    />
                  </div>
                </div>
              </div>
            </MenuItem>
          );
        }
      }
    }

    return itemsView;
  };

  public renderApplicationItemsForWeb = () => {
    const { screen, component, t, layoutOptions } = this.props;

    const itemsView: React.ReactNode[] = [];
    const menuComponents = screen?.Components?.filter(
      (component) => component.RecordStatus !== RecordStatus.Deleted
    );
    if (menuComponents && menuComponents.length) {
      for (let i = 0; i < menuComponents.length; i++) {
        const screenComponent = menuComponents[i];

        const isHorizontal =
          layoutOptions.Platform === PlatformType.Web ||
          layoutOptions.Platform === PlatformType.AppleTV;

        const properties = new ApplicationMenuItemPropertyModel(
          screenComponent
        );
        const style: React.CSSProperties = {
          borderWidth: "1px",
          borderStyle: "dashed",
          borderColor: ComponentTypeHelper.getColor(
            screenComponent.ComponentTypeCode
          ),
          cursor: "pointer",
        };

        if (component && component.Id === screenComponent.Id) {
          style.borderWidth = "2px";
          style.borderStyle = "solid";
        }

        itemsView.push(
          <MenuItem
            style={{
              margin: "0 1%",
              padding: "8px",
              height: "56px",
            }}
          >
            <div
              key={screenComponent.Id}
              className="Component"
              style={{ padding: 0 }}
              onClick={() => this.onComponentSelect(screenComponent.Id)}
            >
              <div className="Component--content">
                <div className="Component--options"></div>
                <div className="Component--preview" style={style}>
                  <ApplicationMenuItemPreview
                    key={screenComponent.Id}
                    component={screenComponent}
                    properties={properties}
                  />
                </div>
              </div>
            </div>
          </MenuItem>
        );
      }
    }

    return itemsView;
  };

  public render() {
    const { layoutOptions, t } = this.props;
    const isRoku = layoutOptions.Platform === PlatformType.Roku ? "TV" : "";
    switch (layoutOptions.Platform) {
      case PlatformType.AndroidTV:
      case PlatformType.Tizen:
      case PlatformType.WebOS:
      case PlatformType.Roku:
        return (
          <div className={`ApplicationMenuPreview ${isRoku}`}>
            <Row style={{ width: "100%", height: "30%" }}>
              <h3
                className="ApplicationMenuPreview__Title"
                style={{ margin: "60px 0", textAlign: "center" }}
              >
                {t("APPLICATION_PREVIEW_TITLE")}
              </h3>
            </Row>
            <Row style={{ width: "100%", height: "100%" }}>
              <Menu
                className="ApplicationMenuPreview"
                mode="inline"
                selectable={false}
                inlineIndent={10}
              >
                {this.renderApplicationItemsForWeb()}
              </Menu>
            </Row>
          </div>
        );
      case PlatformType.AppleTV:
        return (
          <div className="ApplicationMenuPreview">
            <Row align="middle" direction="row" wrap="nowrap" gutter={[8, 8]}>
              <Col
                className="ApplicationMenuPreview__Col"
                style={{
                  width: "20%",
                }}
              >
                <h3
                  className="ApplicationMenuPreview__Title"
                  style={{ fontSize: "14px" }}
                >
                  {t("APPLICATION_PREVIEW_TITLE")}
                </h3>
              </Col>
              <Col
                style={{
                  width: "65%",
                }}
              >
                <div className="ApplicationMenuPreview__AppleTVMenu">
                  <Menu mode="horizontal" selectable={false}>
                    {this.renderApplicationItemsForWeb()}
                  </Menu>
                </div>
              </Col>
            </Row>
          </div>
        );
      case PlatformType.AndroidPhone:
      case PlatformType.AndroidTablet:
      case PlatformType.iOSPhone:
      case PlatformType.iPad:
        return (
          <>
            <Row align="middle" direction="row" wrap="nowrap" gutter={[8, 8]}>
              <Col style={{ width: "100%", padding: "16px" }}>
                <h3 className="ApplicationMenuPreview__Title">
                  {t("APPLICATION_PREVIEW_TITLE")}
                </h3>
              </Col>

              <Col>
                <Avatar
                  icon="user"
                  className="ApplicationMenuPreview__Avatar"
                ></Avatar>
              </Col>
            </Row>
            <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
              <div className="ApplicationMenuPreview">
                <div className="ApplicationMenuPreview__Items">
                  {this.renderApplicationItemsForMobile()}
                </div>
              </div>
            </div>
          </>
        );
      case PlatformType.Any:
      case PlatformType.Web:
        return (
          <div className="ApplicationMenuPreview">
            <Row align="middle" direction="row" wrap="nowrap" gutter={[8, 8]}>
              <Col
                className="ApplicationMenuPreview__Col"
                style={{
                  width: "20%",
                }}
              >
                <h3 className="ApplicationMenuPreview__Title">
                  {t("APPLICATION_PREVIEW_TITLE")}
                </h3>
              </Col>
              <Col
                style={{
                  width: "100%",
                }}
              >
                <Menu
                  className="ApplicationMenuPreview__WebMenu"
                  mode="horizontal"
                  selectable={false}
                >
                  {this.renderApplicationItemsForWeb()}
                </Menu>
              </Col>
              <Col className="ApplicationMenuPreview__Col">
                <Avatar
                  icon="user"
                  className="ApplicationMenuPreview__Avatar"
                ></Avatar>
              </Col>
            </Row>
          </div>
        );
      default:
        return (
          <div className="ApplicationMenuPreview">
            <div className="ApplicationMenuPreview__Items"></div>
          </div>
        );
    }
  }
}
