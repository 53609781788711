import { ICommonAppState } from "..";

export const textComponentByIdSelector = (id?: number) => (
  state: ICommonAppState
) => (id ? state.textComponents[id] : undefined);

export const uploadInfoByIdSelector = (guid?: string) => (
  state: ICommonAppState
) => (guid ? state.textComponents.uploadInfo[guid] : undefined);

export const fileInfoByIdSelector = (guid?: string) => (
  state: ICommonAppState
) => (guid ? state.textComponents.fileInfo[guid] : undefined);
