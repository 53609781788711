import React, { useRef, useCallback } from "react";
import { Modal } from "@bms/common-ui";

import { IPlayerRef, Player } from "../../../../../../components/Player";

export interface IVideoPreviewProps {
  visible: boolean;
  contentUrl?: string;
  contentType?: string;
  onCancel?: () => void;
}

export const VideoPreview = ({
  visible,
  contentUrl,
  contentType = "video/mp4",
  onCancel,
}: IVideoPreviewProps) => {
  const player = useRef<IPlayerRef>();

  const onCancelModal = useCallback(() => {
    player.current?.pause();
    onCancel?.();
  }, [contentUrl]);

  return (
    <Modal visible={visible} footer={null} onCancel={onCancelModal} preview>
      <Player
        ref={player}
        contentUrl={contentUrl}
        contentType={contentType}
        fill={false}
      />
    </Modal>
  );
};
