import React from "react";
import { Transfer as AntTransfer } from "antd";
import { TransferItem, TransferProps } from "antd/lib/transfer";

export type ITransferProps = TransferProps;
export type ITransferItem = TransferItem;

export const Transfer = (props: TransferProps) => {
  return <AntTransfer {...props} />;
};
