import {
  GuidHelper,
  IApplicationComponentModel,
  IApplicationComponentPropertyModel,
  PropertyType,
  RecordStatus,
} from "@bms/common-services";

export abstract class ComponentPropertyModel {
  private _applicationComponentId: number = -1;

  abstract setProperties(component: IApplicationComponentModel): void;

  abstract setProperty(property: IApplicationComponentPropertyModel): void;

  abstract setPropertyValue(property: IApplicationComponentPropertyModel): void;

  init(component: IApplicationComponentModel) {
    this._applicationComponentId = component.Id;
    this.setProperties(component);
  }

  onPropertyChange(
    propertyName: string,
    component: IApplicationComponentModel
  ): void {
    let property: IApplicationComponentPropertyModel | undefined;

    if (!component.Properties) {
      component.Properties = [];
    } else {
      property = component.Properties.find(
        (row: IApplicationComponentPropertyModel) => row.Name === propertyName
      );
    }

    if (!property) {
      property = {
        Id: -1,
        RecordStatus: RecordStatus.Inserted,
        ApplicationComponentId: this._applicationComponentId,
        Guid: GuidHelper.newGuid(),
        Name: propertyName,
        PropertyType: PropertyType.String,
        IsEnumerable: false,
      };

      this.setProperty(property);

      component.Properties.push(property);
    } else {
      // Update property atributes
      this.setProperty(property);
    }

    if (
      (property.RecordStatus || RecordStatus.NoChange) === RecordStatus.NoChange
    ) {
      property.RecordStatus = RecordStatus.Updated;
    }

    if (property.IsEnumerable && !property.Values) {
      property.Values = [];
    } else if (!property.IsEnumerable) {
      if (!property.Value) {
        property.Value = {
          Id: -1,
          ApplicationComponentPropertyId: property.Id,
          RecordStatus: RecordStatus.Inserted,
          Guid: GuidHelper.newGuid(),
        };
      } else {
        if (
          (property.Value.RecordStatus || RecordStatus.NoChange) ===
          RecordStatus.NoChange
        ) {
          property.Value.RecordStatus = RecordStatus.Updated;
        }
      }
    }

    this.setPropertyValue(property);
  }

  deleteProperty(
    propertyName: string,
    component: IApplicationComponentModel
  ): void {
    if (!component.Properties || component.Properties.length === 0) {
      return;
    }

    let index = component.Properties.findIndex(
      (row: IApplicationComponentPropertyModel) => row.Name === propertyName
    );

    if (index < 0) {
      return;
    }

    let property = component.Properties[index];

    let propertyRecordStatus = property.RecordStatus || RecordStatus.NoChange;

    if (
      propertyRecordStatus === RecordStatus.NoChange ||
      propertyRecordStatus === RecordStatus.Updated
    ) {
      property.RecordStatus = RecordStatus.Deleted;
    } else {
      component.Properties.splice(index, 1);
    }

    if (this.hasOwnProperty(propertyName)) {
      // @ts-ignore
      this[propertyName] = undefined;
    }
  }
}
