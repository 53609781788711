import { DEFAULT_TAG_ROTATE, TAG_PALETTE } from "../Tag";

export type Domain =
  | "currency"
  | "language"
  | "payment-status"
  | "purchase-type"
  | "application"
  | "platform"
  | "purchase-period"
  | "notification-status"
  | "notification-recipients";

export const DOMAIN_PALETTE_MAP: Record<
  Domain,
  { [key: string]: string } | string[]
> = {
  "payment-status": {
    COMPLETED: TAG_PALETTE.GREEN,
    CREATED: TAG_PALETTE.GREY,
  },
  "notification-status": {
    DRAFT: TAG_PALETTE.GREY,
    SCHEDULED: TAG_PALETTE.YELLOW,
    ERROR: TAG_PALETTE.RED,
    IN_PROGRESSS: TAG_PALETTE.FUCHSIA,
    SENT: TAG_PALETTE.GREEN,
  },
  "notification-recipients": {
    ALL: TAG_PALETTE.TEAL,
    SELECTED_USERS: TAG_PALETTE.YELLOW,
    BUSINESS_UNITS_COUNTRIES: TAG_PALETTE.VIOLET,
  },
  currency: {
    EUR: TAG_PALETTE.BLUE,
    PLN: TAG_PALETTE.GREEN,
  },
  language: {
    en: TAG_PALETTE.YELLOW,
    pl: TAG_PALETTE.GREEN,
  },
  application: DEFAULT_TAG_ROTATE,
  platform: DEFAULT_TAG_ROTATE,
  "purchase-period": {
    SUBSCRIPTION: TAG_PALETTE.GREEN,
    RENT_48H: TAG_PALETTE.TEAL,
    ONE_TIME: TAG_PALETTE.FUCHSIA,
  },
  "purchase-type": [
    TAG_PALETTE.FUCHSIA,
    TAG_PALETTE.BLUE,
    TAG_PALETTE.VIOLET,
    TAG_PALETTE.RED,
    TAG_PALETTE.YELLOW,
  ],
};
