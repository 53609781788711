import React from "react";
import {
  ContentStatus,
  ContentStatusHelper as CommonContentStatusHelper,
} from "@bms/common-services";
import { Tag } from "@bms/common-ui";

export class ContentStatusHelper extends CommonContentStatusHelper {
  static getTag(
    value?: ContentStatus,
    style?: React.CSSProperties
  ): React.ReactElement | null {
    if (!value) {
      return null;
    }

    const color = ContentStatusHelper.getColor(value);
    const description = ContentStatusHelper.getDescription(value);

    return (
      <Tag color={color} style={style}>
        {description}
      </Tag>
    );
  }
}
