import { assign } from "lodash";
import { MessageType } from "../../enums";
import { ViewModelBase } from "../Base";
import { MessageUserModel } from "./";

export class MessageModel extends ViewModelBase {
  constructor(props?: MessageModel) {
    super();

    assign(this, props);
  }

  public Id?: number;

  public Guid?: string;

  public RoomId!: number;

  public Text?: string;

  public Created?: string;

  public Modified?: string;

  public Read?: boolean;

  public Type!: MessageType;

  public IsNewMessage?: boolean;

  public User!: MessageUserModel;

  // TODO: Tmp solution waiting for backend fix
  public UserId?: number;

  public FullName?: string;

  public AvatarPath?: string;

  public AvatarUrl?: string;
}
